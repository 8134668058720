import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components';

import {BellIcon} from '../../../static/svg';
import logo from 'assets/ui/plika-logo.png';
import Modal from 'components/SideMenu/organisms/Modal';
import GuestThumbnail from 'components/SideMenu/molecules/GuestThumbnail';
import MemberInfo from '../molecules/MemberInfo';
import {TextComponent} from 'components/common/atoms/Text';

const GuestItemComponent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;

    ${TextComponent} {
        transition: all 0.3s;
    }

    &:hover ${TextComponent} {
        text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;
    }
`;

const GuestButton = styled.div`
    background-color: #cccccc;
    border-radius: 30px;
    text-align: center;
    box-sizing: border-box;

    width: 60px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #e5e5e5;
        cursor: pointer;
    }
`;

function GuestItem(props: any) {
    const {
        accountInfo,
        memberInfo,
        setSelMemberInfo,
        webviewPopupChange,
        status,
        sendRingMsg,
        isOff,
    } = props;

    const [guestMenuPopup, setGuestMenuPopup] = useState(false);

    const guestMenuRef: any = useRef();

    const guestMenuBtnRef: any = useRef();

    function closePopup(e: any) {
        if (guestMenuRef.current != null) {
            if (
                !guestMenuRef.current.contains(e.target) &&
                !guestMenuBtnRef.current.contains(e.target)
            ) {
                setGuestMenuPopup(false);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('click', closePopup);
        return () => {
            window.removeEventListener('click', closePopup);
        };
    }, []);

    /**
     * RING 기능을 위해 대상에게 webSocket 메시지를 보내는 function
     * sendRingMsg의 인자로 대상의 stomp sessionId를 넣어야 합니다.
     */
    function ring() {
        sendRingMsg(memberInfo.sessionId);
    }

    return (
        <GuestItemComponent>
            <GuestThumbnail
                src={
                    memberInfo.detail.thumbnailUri != null &&
                    memberInfo.detail.thumbnailUri.length > 0
                        ? memberInfo.detail.thumbnailUri
                        : logo
                }
                alt="thumbnail"
                width="100%"
                height="100%"
                borderRadius="50px"
            />
            <MemberInfo memberInfo={memberInfo} status={status} />
            <ButtonBox>
                {status === 'on' &&
                    memberInfo.detail.isOff != null &&
                    memberInfo.detail.isOff && (
                        <LingBellButton onClick={ring}>
                            <BellIcon />
                        </LingBellButton>
                    )}
                <GuestButton
                    ref={guestMenuBtnRef}
                    onClick={
                        accountInfo.isAdmin
                            ? () => setGuestMenuPopup(!guestMenuPopup)
                            : undefined
                    }
                >
                    Guest
                </GuestButton>
                {guestMenuPopup ? (
                    <Modal
                        memberInfo={memberInfo}
                        ref={guestMenuRef}
                        onClickEvent={
                            memberInfo.detail.login
                                ? () => {
                                      setSelMemberInfo(memberInfo);
                                      webviewPopupChange(
                                          'changeMemberAlert',
                                          true,
                                      );
                                  }
                                : undefined
                        }
                    />
                ) : (
                    <></>
                )}
            </ButtonBox>
        </GuestItemComponent>
    );
}

const ButtonBox = styled.div`
    display: flex;
    gap: 10px;
`;

const LingBellButton = styled.div`
    width: 24px;
    height: 24px;
    background: #ee3030;
    border-radius: 50%;
    cursor: pointer;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 18px;
        height: 18px;
    }
`;

export default GuestItem;
