import React from 'react'

export default function Labels({ spaceName, setState, setSelect, portalList, portalTitle }) {
  return (
    <>
      <h3 className="mb-12 text-xl font-semibold uppercase md:text-2xl text-slate-900">
        {portalTitle}
      </h3>
      {portalList != null &&
        portalList.map((portal) => {
          return (
            <button
              className="relative flex w-full gap-8 px-5 py-3 mb-6 bg-white border border-solid rounded-3xl disabled:border-gray-300 duration-400 disabled:text-gray-300 disabled:hover:bg-white hover:bg-secondary-100 border-slate-900 text-slate-900"
              onClick={() =>
                setSelect((prev) => ({
                  ...prev,
                  portalLabel: portal.portalLabel,
                  portalName: portal.portalName,
                  accessUrl: portal.accessUrl,
                  portalTitle: portal.portalTitle,
                }))
              }
              disabled={spaceName === portal.accessUrl ? true : false}
            >
              <p className="text-sm font-semibold">{portal.portalLabel}</p>
              <div>
                <p className="text-sm font-semibold text-left">{portal.portalName}</p>
                <pre className="text-xs text-left">{portal.portalDesc}</pre>
                {/* <ul className="text-xs ">
                  <li className="before:inline-block before:font-bold before:pr-1 before:content-['\2022']">
                    {portal.portalName}
                  </li>
                </ul> */}
              </div>
            </button>
          )
        })}

      <div className="flex items-center justify-end">
        <button
          type="submit"
          className="inline-flex justify-center px-12 py-3 mt-6 font-semibold duration-500 bg-gray-300 border border-transparent rounded-full text-slate-900 shadow-86 sm:text-xl hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
          onClick={() => setState(false)}
        >
          Cancel
        </button>
      </div>
    </>
  )
}
