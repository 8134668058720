import React from 'react';
import styled from 'styled-components';
import Text from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import InputWithTitle from 'components/ui/InputWithTitle';
import Button from 'components/atoms/Button';

const YoutubeEmbedModalComponent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px 0 24px 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);

    @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const Modal = styled.div`
    background-color: white;
    width: 600px;
    height: 370px;
    border-radius: 20px;
    padding: 33px 30px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const OkButton = styled(Button)`
    width: 140px;
    height: 40px;
    border-radius: 20px;
`;

const YoutubeEmbedModal = ({onClickEvent, onClose}: any) => {
    return (
        <YoutubeEmbedModalComponent>
            <Modal>
                <form onSubmit={e => onClickEvent(e)}>
                    <Header>
                        <Text size={2.2} weight={700}>
                            Youtube link embed
                        </Text>
                        <Button
                            type="button"
                            bgColor="white"
                            hoveredBgColor="#cccccc"
                            onClick={() => onClose()}
                        >
                            <Icon type="close" />
                        </Button>
                    </Header>

                    <InputWithTitle
                        title="Youtube link"
                        name="youtubeUri"
                        autoFocus={true}
                    ></InputWithTitle>

                    <ButtonWrap>
                        <OkButton
                            type="submit"
                            bgColor="#FEE500"
                            hoveredBgColor="#FFF280"
                        >
                            <Text size={1.5} weight={700}>
                                OK
                            </Text>
                        </OkButton>
                    </ButtonWrap>
                </form>
            </Modal>
        </YoutubeEmbedModalComponent>
    );
};

export default YoutubeEmbedModal;
