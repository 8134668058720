import React from 'react';
import styled from 'styled-components';
import {useMediaQuery} from 'react-responsive';

import Text from 'components/atoms/Text';
import Button, {ButtonComponent} from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import {
    InteractionImg,
    InteractionWebImg,
    LookImg,
    LookWebImg,
    MoveImg,
    MoveWebImg,
    RotationWebImg,
    RotationImg,
    MoveWebImg2,
    LookWebImg2,
    InteractionWebImg2,
    RotationWebImg2,
    RotationImg2,
    InteractionImg2,
    LookImg2,
    MoveImg2,
} from '../../../static/svg';

const ControlComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    // 임시, 추후에 transparent로 변경
    background-color: #cccccc;
`;

const Modal = styled.div`
    position: relative;

    display: flex;

    justify-content: center;
    align-items: center;

    width: 1460px;
    height: 600px;

    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);

    padding: 40px 108px;
    z-index: 900;

    ${ButtonComponent}:first-child {
        position: absolute;
        top: 30px;
        right: 30px;
    }

    ${ButtonComponent}:last-child {
        width: 140px;
        height: 40px;
    }

    @media (max-width: 1440px) {
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 1024px) {
        width: 100%;
        height: calc(100% - 120px);
        border-radius: 0;
        z-index: 999;
        margin: 0;
    }

    @media (max-width: 768px) {
        height: calc(100% - 100px);
    }
`;

const ModalWrap = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const ControlsWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 60px;

    @media (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
`;

const ControlWrap = styled.div`
    width: 100%;
    height: 100%;
`;

const ImageWrap = styled.div`
    width: 100%;
    height: 100%;

    svg,
    img {
        width: 100%;
        height: 100%;
    }

    @media (max-width: 1024px) {
        height: 260px;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
`;

const ControlA = ({setControlWebview, sendMsg}) => {
    const isMobile = useMediaQuery({query: '(max-width: 765px)'});
    function close() {
        sendMsg('Webreceiver', 'ControlPopupClose');
        setControlWebview(false);
    }

    return (
        <ControlComponent>
            <Modal>
                <Button
                    type="button"
                    bgColor="white"
                    hoveredBgColor="#cccccc"
                    onClick={() => close()}
                >
                    <Icon type="close" size={40} />
                </Button>
                <ModalWrap>
                    <Text size={2.8} weight={700}>
                        조작방법
                    </Text>
                    <ControlsWrap>
                        <ControlWrap>
                            {isMobile ? (
                                <ImageWrap>
                                    <MoveImg2 />
                                </ImageWrap>
                            ) : (
                                <>
                                    <ImageWrap>
                                        <MoveWebImg2 />
                                    </ImageWrap>
                                </>
                            )}
                        </ControlWrap>
                        <ControlWrap>
                            {isMobile ? (
                                <ImageWrap>
                                    <LookImg2 />
                                </ImageWrap>
                            ) : (
                                <>
                                    <ImageWrap>
                                        <LookWebImg2 />
                                    </ImageWrap>
                                </>
                            )}
                        </ControlWrap>
                        <ControlWrap>
                            {isMobile ? (
                                <ImageWrap>
                                    <InteractionImg2 />
                                </ImageWrap>
                            ) : (
                                <ImageWrap>
                                    <InteractionWebImg2 />
                                </ImageWrap>
                            )}
                        </ControlWrap>
                        <ControlWrap>
                            {isMobile ? (
                                <ImageWrap>
                                    <RotationImg2 />
                                </ImageWrap>
                            ) : (
                                <>
                                    <ImageWrap>
                                        <RotationWebImg2 />
                                    </ImageWrap>
                                </>
                            )}
                        </ControlWrap>
                    </ControlsWrap>

                    <Button
                        type="button"
                        bgColor="#FEE500"
                        hoveredBgColor="#FFF280"
                        onClick={() => close()}
                    >
                        <Text size={1.8} weight={700}>
                            OK
                        </Text>
                    </Button>
                </ModalWrap>
            </Modal>
        </ControlComponent>
    );
};

export default ControlA;
