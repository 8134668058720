import React, {useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons';

import uiStyle from '../../styles/ui.module.scss';
import styles from '../../styles/modal.module.scss';

function ChangeMemberAlert(props) {
    const {memberInfo, addTeamMember, onClose} = props;

    document.body.style.overflow = 'hidden';

    const webviewInner: any = useRef();

    function closeWebview(e) {
        if (!webviewInner.current.contains(e.target)) {
            onClose();
        }
    }

    return (
        <>
            <div
                className={`${styles.modal_container}`}
                onPointerDown={e => closeWebview(e)}
            >
                <div
                    ref={webviewInner}
                    className={`${styles.container_inner} ${styles.alarm}`}
                >
                    <div className={styles.modal_header}>
                        <p>Team Member</p>
                        <button onClick={() => onClose(false)}>
                            <FontAwesomeIcon icon={faX} />
                        </button>
                    </div>
                    <div className={styles.modal_desc}>
                        {`${memberInfo.detail.nickname}(${memberInfo.detail.email})`}
                        님을 멤버로 변경합니다.
                    </div>
                    <div className={styles.modal_btn_wrapper}>
                        <button
                            onClick={() => onClose(false)}
                            className={uiStyle.btn_theme_gray}
                        >
                            Cancel
                        </button>
                        <button
                            className={uiStyle.btn_theme_yel}
                            onClick={() => addTeamMember(memberInfo)}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangeMemberAlert;
