import React, {useEffect, useState} from 'react';
import '../../styles/toggle.scss';
import uiStyle from '../../styles/ui.module.scss';
import styled from 'styled-components';
import {BiHelpCircle} from 'react-icons/bi';
import {useTranslation} from 'react-i18next';

function ToggleWithTitle(props) {
    const {
        title,
        toggle,
        toggleChange,
        toggleName,
        name,
        placeholder,
        value,
        onChange,
        alert,
        helpType,
        id,
    } = props;

    const {t} = useTranslation();

    const helpMessage = {
        password: [`${t('create.help.set_password.text1')}`],
    };

    return (
        <div className={uiStyle.toggle_input_container}>
            <div className={uiStyle.title_box}>
                <StyledLabel>
                    <span>{title}</span>
                    {helpType && (
                        <HelpBox>
                            <IconHelpCircle />
                            <div>
                                {helpMessage[helpType].map((el, i) => (
                                    <StyledP key={i}>{el}</StyledP>
                                ))}
                            </div>
                        </HelpBox>
                    )}
                </StyledLabel>
                <div className={uiStyle.toggle_btn}>
                    <input
                        type="checkbox"
                        id={id}
                        className="toggle toggle-primary tgl tgl-flat"
                        name={toggleName}
                        checked={toggle || ''}
                        onChange={toggleChange}
                    ></input>
                    <label
                        className="cursor-pointer tgl-btn"
                        htmlFor={id}
                    ></label>
                </div>
            </div>
            {toggle ? (
                <input
                    type="text"
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    className={
                        alert
                            ? `${uiStyle.input_round} ${uiStyle.alert}`
                            : uiStyle.input_round
                    }
                    onChange={onChange}
                />
            ) : (
                <></>
            )}
        </div>
    );
}

const StyledLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const HelpBox = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    div {
        display: none;
        position: absolute;
        width: 210px;
        height: 90px;
        left: 0px;
        top: 24px;

        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        transition: 0.3s;
        padding: 15px;
        z-index: 10;

        flex-direction: column;
    }

    &:hover div {
        display: flex;
    }

    &:hover svg {
        color: #000;
    }
`;

const StyledP = styled.p`
    font-size: 12px;
    font-weight: 400;
`;

const IconHelpCircle = styled(BiHelpCircle)`
    font-size: 24px;
    color: #ccc;
    cursor: pointer;
    transition: all 0.3s;
`;

export default ToggleWithTitle;
