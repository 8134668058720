import React from "react"
import uiStyle from '../../styles/ui.module.scss'

function InputRound (props) {

    const { name, placeholder, value, inputValueHandler } = props;

    return (
        <input type="text" name={name} placeholder={placeholder} value={value} onChange={(e) => inputValueHandler(e)} className={uiStyle.input_round}/>
    )
}

export default InputRound