import React from 'react';

import GoogleLoginBtn from '../../assets/img/btn_google_signin_light_normal_web.png';

function BeforeLogin() {
    return (
        <>
            <h1>Need To Login</h1>
            <a href="http://localhost:8085/auth/authorization/google?redirect_uri=http://localhost:3000/auth/redirect">
                <img src={GoogleLoginBtn} alt="Google Signin" />
            </a>
        </>
    );
}

export default BeforeLogin;
