import {UnityContext} from 'react-unity-webgl';

function createUnityContext(prefix, version): UnityContext {
    const UnityContent = new UnityContext({
        loaderUrl: prefix + 'Build_' + version + '.loader.js',
        dataUrl: prefix + 'Build_' + version + '.data.unityweb',
        frameworkUrl: prefix + 'Build_' + version + '.framework.js.unityweb',
        codeUrl: prefix + 'Build_' + version + '.wasm.unityweb',
    });

    return UnityContent;
}

function sendMsg(unityObj) {
    unityObj.send('Webreceiver', 'GpSampleVoidTest');
}

export {createUnityContext, sendMsg};
