import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
import uiStyle from '../../styles/ui.module.scss';
import {usePagination} from '../../services/pagination';

function Pagination(props) {
    const {totalList, itemPerPage, setCurPageList} = props;

    const [
        pageNum,
        pageStatus,
        lastPage,
        pageCount,
        setPage,
        setNextPage,
        setPrevPage,
        setFirstPage,
        setLastPage,
    ] = usePagination(totalList, itemPerPage, setCurPageList);

    return (
        <>
            {pageCount != null && pageCount.length > 0 && (
                <div className={uiStyle.page_container}>
                    <div className={uiStyle.page_inner}>
                        <div className={uiStyle.page_item_list}>
                            <button
                                className={
                                    pageStatus.current != 'first' &&
                                    pageStatus.current != 'one'
                                        ? uiStyle.page_btn
                                        : `${uiStyle.page_btn} ${uiStyle.disabled}`
                                }
                                onClick={
                                    pageStatus.current != 'first'
                                        ? setFirstPage
                                        : undefined
                                }
                            >
                                <FontAwesomeIcon icon={faAnglesLeft} />
                            </button>
                            <button
                                className={
                                    pageStatus.current != 'first' &&
                                    pageStatus.current != 'one'
                                        ? uiStyle.page_btn
                                        : `${uiStyle.page_btn} ${uiStyle.disabled}`
                                }
                                onClick={
                                    pageStatus.current != 'first'
                                        ? setPrevPage
                                        : undefined
                                }
                            >
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </button>
                            {pageCount != null &&
                                pageCount.map((el, i) => (
                                    <button
                                        key={i}
                                        className={
                                            el == pageNum
                                                ? `${uiStyle.page_item} ${uiStyle.active}`
                                                : uiStyle.page_item
                                        }
                                        onClick={() => setPage(el)}
                                    >
                                        {el}
                                    </button>
                                ))}
                            <button
                                className={
                                    pageStatus.current != 'last' &&
                                    pageStatus.current != 'one'
                                        ? uiStyle.page_btn
                                        : `${uiStyle.page_btn} ${uiStyle.disabled}`
                                }
                                onClick={
                                    pageStatus.current != 'last'
                                        ? setNextPage
                                        : undefined
                                }
                            >
                                <FontAwesomeIcon icon={faAngleRight} />
                            </button>
                            <button
                                className={
                                    pageStatus.current != 'last' &&
                                    pageStatus.current != 'one'
                                        ? uiStyle.page_btn
                                        : `${uiStyle.page_btn} ${uiStyle.disabled}`
                                }
                                onClick={
                                    pageStatus.current != 'last'
                                        ? setLastPage
                                        : undefined
                                }
                            >
                                <FontAwesomeIcon icon={faAnglesRight} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Pagination;
