import React, { ReactElement } from 'react'

import { useFeaturedLands } from './hooks/useFeaturedLands'

import FeaturedLandCard from './FeaturedLandCard'

export default function FeaturedLands(): ReactElement {
  const { featuredLands } = useFeaturedLands()

  return (
    <section className="relative w-full px-4 py-12 mx-auto max-w-container sm:px-6 lg:px-8 ">
      <h2 className="mb-4 text-xl font-semibold md:text-2xl">Featured Lands</h2>
      <div className="grid grid-cols-2  gap-1.5 md:gap-4">
        {featuredLands.map((landData) => (
          <FeaturedLandCard key={landData.spaceId} landData={landData} />
        ))}
      </div>
    </section>
  )
}
