import React from 'react';
import styled from 'styled-components';
import {MintLogo, SquareIcon} from '../../static/svg';

function ShowRoomContent() {
    return (
        <Block>
            <Wrap>
                <div>
                    <h3>SESSION 2</h3>
                    <h3>Future Costume Lab</h3>
                </div>
                <ul>
                    <li>
                        <p>
                            <SquareIcon />
                            <b>세션 2</b>에서
                            <MintLogo className="mint mg" />는 실제 제품의
                            룩북을 볼 수 있어요
                        </p>
                    </li>
                    <li>
                        <p>
                            <SquareIcon />
                            <b>세션 2</b>에서는 현장에서 스캔했던 여러분들의
                            물품을
                        </p>
                        <p>3차원 뷰어를 통해 볼 수 있어요</p>
                    </li>
                    <li>
                        <p>
                            <SquareIcon />
                            <b>세션 2</b>에서
                            <MintLogo className="mint mg" /> 의 팀원을
                            만나보세요
                        </p>
                    </li>
                </ul>
            </Wrap>
        </Block>
    );
}

const Block = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    div:first-child {
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 24px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    li {
        display: flex;
        flex-direction: column;

        font-size: 12px;
        overflow-wrap: break-word;

        p {
            display: flex;
            align-items: center;
        }

        p:nth-child(2) {
            margin-left: 16px;
        }

        svg {
            height: 14px;
            color: #000;
            margin-left: 5px;
        }

        svg:first-child {
            width: 5px;
            margin-right: 5px;
        }

        .mint {
            margin-left: 0;
            width: 32px;

            &.mg {
                margin-left: 5px;
            }
        }

        .mint_plika {
            width: 80px;
        }
    }
`;

export default ShowRoomContent;
