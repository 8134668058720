interface Props {
  type: 'starter' | 'team' | 'enterprise'
}

export default function TierCardSummary({ type }: Props) {
  if (type === 'starter')
    return (
      <>
        <p className="font-light text-gray-500 sm:text-lg ">Best option for personal use</p>
        <p className="font-light text-white sm:text-lg ">Best option for personal use</p>
      </>
    )
  if (type === 'team')
    return (
      <p className="font-light text-gray-500 sm:text-lg ">
        Relevant for multiple users, extended & premium support.
      </p>
    )
  return (
    <p className="font-light text-gray-500 sm:text-lg ">
      Best for large scale uses and extended redistribution rights.
    </p>
  )
}
