import React, {useEffect} from 'react';
import ChannelService from '../utils/channeltalk';
interface Props {
  type: 'starter' | 'team' | 'enterprise'
  accountInfo : any
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TierCardButton({ type, accountInfo }: Props) {

  function openChat() {
    if(accountInfo != null && accountInfo.accountId != null) {
      ChannelService.openChat('');
    } else {
      window.localStorage.setItem('login_redirect_url', '/pricing');
      window.localStorage.setItem('ch-auto-open', 'true');
      window.location.href = '/login';
    }

  }


  if (type === 'starter')
    return (
      <a
        href="/create"
        className="text-white bg-primary-100 duration-200 hover:opacity-80  font-bold rounded-full text-xl  px-5 py-2.5 text-center   "
      >
        Go Free
      </a>
    )

  return (
    <button type='button'
            onClick={() => openChat()}
      className={classNames(
        type === 'team' ? 'bg-secondary-100 ' : 'bg-black text-white',
        '  hover:opacity-80 duration-200  font-bold rounded-full text-xl px-5 py-2.5 text-center  contact-us ',
          'ch-btn'
      )}
    >
      1:1 문의
    </button>
  )
}
