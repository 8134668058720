import * as React from 'react'
import { SVGProps } from 'react'
const SvgIconChevronDubleDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19 13-7 7-7-7m14-8-7 7-7-7"
    />
  </svg>
)
export default SvgIconChevronDubleDown
