import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const StopWatch = ({time}) => {
    return (
        <Block>
            <div className="numbers">
                <span>
                    {('0' + Math.floor((time / 60000) % 60)).slice(-2)}:
                </span>
                <span>{('0' + Math.floor((time / 1000) % 60)).slice(-2)}</span>
                {/* <span>{('0' + ((time / 10) % 100)).slice(-2)}</span> */}
            </div>
            {/* <div className="buttons">
                <button onClick={() => setRunning(true)}>Start</button>
                <button onClick={() => setRunning(false)}>Stop</button>
                <button onClick={() => setTime(0)}>Reset</button>
            </div> */}
        </Block>
    );
};

const Block = styled.div`
    font-size: 60px;
    font-weight: 700;
`;

export default StopWatch;
