import React, { ReactNode} from 'react'
import styled from 'styled-components'

interface TextStyleProps  {
    size?: number;
    weight?: number;
    color?: string;
}

export interface TextProps extends TextStyleProps  {
    children: ReactNode
} 

export const TextComponent = styled.div<TextStyleProps>(
    ({size, weight, color}) => ({
        fontSize: size !== undefined ? `${size}px` : `initial`,
        fontWeight: weight,
        color,
    })
)

const Text: React.FC<TextProps> = function ({children, ...style}) {
    return <TextComponent {...style}>{children}</TextComponent>
}

export default Text