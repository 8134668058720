import LandCantCreateAlert from 'components/modals/LandCantCreateAlert'
import TierCard from 'components/tier-card'
import Guide from 'components/tile/Guide'
import { t } from 'i18next'
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next'
import Footer from '../components/tile/Footer'
import Header from '../components/tile/Header'
import styles from '../styles/pricing.module.scss'
import uiStyle from '../styles/ui.module.scss'
import ChannelService from '../utils/channeltalk';
import axios from 'axios';

function Pricing() {

  ChannelService.loadScript();

  const [accountInfo, setAccountInfo]: any = useState()

  function setAccountInfoState(account: any) {
    setAccountInfo(account)

      // 로그인 안했을 때
      if(account.accountId == null) {
          // axios.get(process.env.REACT_APP_API_HOST  + "api/ip/encode").then(res => {
          //     ChannelService.boot({
          //         "pluginKey": process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY as string, // fill your plugin key
          //         // "customLauncherSelector": ".ch-btn",
          //         "hideChannelButtonOnBoot" : true,
          //         // "memberId" : res.data.data[0],
          //         // "memberHash" : res.data.data[1],
          //         // "profile": {
          //         //     "name" : res.data.data[0]
          //         // }
          //         "unsubscribeEmail" : true,
          //         "unsubscribeTexting" : true
          //     });
          // })
      }
      // 로그인 했을 때
      else {
          axios.get(process.env.REACT_APP_API_HOST + "api/encode", {
              params: {email : account.email}
          }).then(res => {
              ChannelService.boot({
                  "pluginKey": process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY as string, // fill your plugin key
                  // "customLauncherSelector": ".ch-btn",
                  "hideChannelButtonOnBoot" : true,
                  "memberId" : account.email,
                  "memberHash" : res.data,
                  "profile": {
                      "name" : account.email,
                      "avatarUrl" : account.thumbnailUri,
                  },
                  "unsubscribeEmail" : true,
                  "unsubscribeTexting" : true
              });

              if(window.localStorage.getItem('ch-auto-open') != null && window.localStorage.getItem('ch-auto-open')) {
                  window.localStorage.removeItem('ch-auto-open');
                  ChannelService.openChat("");
              }
          })
      }
  }



  const [landCreateAlert, setLandCreateAlert]: any = useState(false)

  function createLand() {
    const accessToken = window.localStorage.getItem('plikaTk')
    if (accessToken != null && accessToken != 'null') {
      if (accountInfo.myLands != null && accountInfo.myLands.length > 0) {
        setLandCreateAlert(true)
      } else {
        window.location.href = '/lands/create'
      }
    } else {
      window.localStorage.setItem('login_redirect_url', '/lands/create')
      window.location.href = '/login'
    }
  }

  function landCreateAlertClose() {
    setLandCreateAlert(false)
  }

  const { t } = useTranslation()

  return (
    <>
      <Header show={null} setAccountInfoState={setAccountInfoState}></Header>
      <section className="py-40 border-t">
        <div className="px-4 mx-auto max-w-container">
          {/* <h2 className="mb-6 text-base font-bold sm:text-2xl">Pricing</h2> */}
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <TierCard type="starter" accountInfo={accountInfo} />
            <TierCard type="team" accountInfo={accountInfo}/>
            <TierCard type="enterprise" accountInfo={accountInfo}/>
          </div>
          <div className="mt-5 text-sm text-center">
            ※ 해당 비용은 VAT(부가세)가 포함되지 않은 금액입니다.
          </div>
        </div>
      </section>
      {landCreateAlert ? (
        <LandCantCreateAlert onClose={landCreateAlertClose}></LandCantCreateAlert>
      ) : (
        <></>
      )}
      <Guide />
      <Footer></Footer>
    </>
  )
}

export default Pricing
