import React from 'react';
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';
import styled from 'styled-components';

const Translation: React.FC = () => {
    const clickHandler = (lang: string) => {
        i18n.changeLanguage(lang);
    };
    return (
        <Block>
            <button onClick={() => clickHandler('ko')}>한국어</button>
            <button onClick={() => clickHandler('en')}>English</button>
        </Block>
    );
};

const Block = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    button:first-child {
        font-weight: 700;
    }

    @media (max-width: 400px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
    }
`;

export default Translation;
