import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

export default function BottomVideo() {
  const [memberCount, setMemberCount] = useState(0)
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_HOST + 'plika/member').then((e) => {
      setMemberCount(e.data.data)
    })
  }, [])

  return (
    <section className="relative aspect-video">
      <video className="object-cover w-full h-full" autoPlay loop muted>
        <source
          src={process.env.REACT_APP_CDN_URL + '/static/video/web/qna_video.mp4'}
          type="video/mp4"
        />
      </video>

      <div className="absolute inset-0 flex flex-col items-center justify-center gap-16 px-4 text-white lg:px-8">
        <div className="flex flex-col items-center justify-center w-full h-full lg:justify-between lg:flex-row max-auto max-w-container">
          <div className="flex flex-col gap-7">
            <p className="hidden font-semibold sm:block sm:text-2xl lg:text-3xl xl:text-4xl sm:font-bold drop-shadow-lg">
              플리카 커뮤니티에 방문하여 궁금한 점을 문의하세요
            </p>
            <ul className="flex-col hidden pl-8 font-medium lg:flex sm:font-semibold lg:text-2xl gap-7">
              <li className="list-disc">조작 및 활용방법 문의</li>
              <li className="list-disc">도입 문의</li>
              <li className="list-disc">제휴 문의</li>
            </ul>
          </div>
          <div className="flex flex-col items-center">
            <a
              className="px-8 py-3 mb-2 font-semibold text-center duration-500 rounded-full sm:my-6 lg:mt-12 lg:mb-16 md:py-6 md:text-2xl sm:py-4 sm:text-xl text-slate-900 focus:outline-none drop-shadow-lg focus:ring-4 focus:ring-secondary-50 bg-secondary-100 hover:bg-secondary-50"
              href="https://open.kakao.com/o/gUoLAote"
              target="_blank"
              rel="noreferrer"
            >
              카카오톡 오픈 채팅방
            </a>
            <a
              className="flex flex-col items-center justify-center gap-2 px-8 py-3 font-semibold duration-500 bg-white rounded-full md:py-6 md:text-2xl sm:py-4 sm:text-xl hover:opacity-75 drop-shadow-lg"
              href="/3d"
            >
              <p className="text-primary-100">COMPANY 방문하기</p>
              <p className="text-slate-900">
                <span className="font-bold">{memberCount}</span>명 접속중
              </p>
            </a>
            <p className="hidden mt-4 text-xs sm:block drop-shadow-lg">
              플리카 COMPANY는 10:00부터 19:00까지 운영합니다
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
