import React, {useState, useRef, useEffect} from 'react';
import RecordRTC, {invokeSaveAsDialog} from 'recordrtc';
import styled, {keyframes} from 'styled-components';
import * as StompJs from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import Dropzone, {IDropzoneProps, ILayoutProps} from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import axios from 'axios';
import axiosI, {source, cancelToken} from '../../utils/AxiosI';
import {
    CompleteIcon,
    OnlyLogo,
    PlikaLogo,
    PlikaSimpleLogo,
    SessionFirstImg,
} from '../../static/svg';
import Plika from '../lands/Plika';

import StopWatch from 'components/hanam/StopWatch';
import Header from 'components/tile/Header';
import {useDispatch} from 'react-redux';
import {setLogoutstatus} from '../../reducers/login';

// type Info = {
//     nickname: string;
//     thumbnailUri: string;
//     email: string;
//     isMember: boolean;
//     isLogin: boolean;
//     isAdmin: boolean;
//     sessionId: string;
//     isOff: boolean;
// };

const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: {maxFiles},
}: ILayoutProps) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            {files.length > 0 && submitButton}
        </div>
    );
};

function Upload() {
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [blob, setBlob] = useState<Blob | null>(null);
    const refVideo = useRef<HTMLVideoElement>(null);
    const recorderRef = useRef<RecordRTC | null>(null);
    const [file, setFile] = useState<Blob | File>();
    const accessToken = window.localStorage.getItem('plikaTk');
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data',
        },
    };
    const [chatRoomId, setChatRoomId] = useState<string>(
        '2c66ea09c2444fca8e2273a012af0729',
    );
    const [accountInfo, setAccountInfo] = useState<any>(null);
    const [accountLoaded, setAccountLoaded] = useState<boolean>(false);
    const [stompClient, setStompClient] = useState<any>(null);

    const handleRecording = async () => {
        const cameraStream = await navigator.mediaDevices.getUserMedia({
            video: {facingMode: 'environment'},
            audio: true,
        });
        // const mediaStream = await navigator.mediaDevices.getDisplayMedia({
        //     video: {
        //         width: 1920,
        //         height: 1080,
        //         frameRate: 30,
        //     },
        //     audio: false,
        // });
        setStream(cameraStream);

        console.log('촬영중이에요');
        recorderRef.current = new RecordRTC(cameraStream, {type: 'video'});
        recorderRef.current.startRecording();
    };

    const handleStop = () => {
        recorderRef.current?.stopRecording(() => {
            console.log('촬영이 끝났어요');
            if (recorderRef.current) {
                setBlob(recorderRef.current.getBlob());
            }
        });
    };

    const handleSave = () => {
        console.log('저장을 시작합니다');
        if (file) {
            invokeSaveAsDialog(file);
        }
    };

    enum messageType {
        OBJECT = 'OBJECT',
        MESSAGE = 'MESSAGE',
    }

    type codeNumber = 601;

    function sendObjMsg(code: codeNumber, object: object) {
        // stompClient.publish({
        //     destination: `/pub/send/info/${chatRoomId}`,
        //     body: JSON.stringify({
        //         messageType: messageType.INFO,
        //         code: code,
        //         sessionId: accountInfo.sessionId,
        //         // 'roomId' : chatRoomId,
        //         nickname: accountInfo.nickname,
        //         thumbnailUri: accountInfo.thumbnailUri,
        //         isMember: accountInfo.isMember,
        //         isLogin: accountInfo.isLogin,
        //         isAdmin: accountInfo.isAdmin,
        //         textContent: textContent,
        //         isOff: accountInfo.isOff,
        //     }),
        //     skipContentLengthHeader: true,
        // });
        stompClient.publish({
            destination: `/pub/send/object/${chatRoomId}`,
            body: JSON.stringify({
                messageType: messageType.OBJECT,
                code: code,
                sessionId: accountInfo.sessionId,
                obj: object,
            }),
            skipContentLengthHeader: true,
        });
    }

    function sendTextMsg(targetName: any) {
        stompClient.publish({
            destination: `/pub/send/${chatRoomId}`,
            body: JSON.stringify({
                messageType: messageType.MESSAGE,
                // 'roomId' : chatRoomId,
                nickname: accountInfo.nickname,
                thumbnailUri: accountInfo.thumbnailUri,
                textContent: targetName + '님의 스캔이 완료되었습니다.',
                isOff: accountInfo.isOff,
            }),
            skipContentLengthHeader: true,
        });
    }

    useEffect(() => {
        window.localStorage.setItem('login_redirect_url', '/hanam/upload');
        if (
            accessToken != null &&
            accessToken != 'null' &&
            accessToken.length > 0
        ) {
            axiosI
                .get(
                    process.env.REACT_APP_API_HOST +
                        `v1/profile/spaces/189c111cea2444cc9d0935cb77335fda`,
                    config,
                )
                .then(e => {
                    const memberInfo = {
                        accountId: e.data.data.accountId,
                        nickname: e.data.data.profileNickname,
                        thumbnailUri: e.data.data.thumbnailUri,
                        email: e.data.data.accountEmail,
                        isMember: true,
                        isLogin: true,
                        isAdmin: false,
                        sessionId: '',
                        isOff: false,
                    };
                    if (e.data.data.authInfo != null) {
                        memberInfo.isMember = true;
                        if (
                            e.data.data.authInfo.accountAuthType ==
                                'SPACE_ADMIN' ||
                            e.data.data.authInfo.accountAuthType ==
                                'SCENE_ADMIN'
                        ) {
                            memberInfo.isAdmin = true;
                        }
                    }
                    setAccountInfo(memberInfo);
                    setAccountLoaded(true);
                })
                .catch(() => {
                    // TODO: 로그인 정보를 받지 못했을 때 처리
                    window.localStorage.removeItem('plikaTk');
                    // axios로 쿠키 지우기 요청
                    axios
                        .delete(process.env.REACT_APP_API_HOST + 'auth/cookies')
                        .then(() => {
                            window.location.reload();
                        });
                });
        }
    }, []);

    useEffect(() => {
        if (accountLoaded != null && accountLoaded) {
            const stomp: any = new StompJs.Client({
                brokerURL: process.env.REACT_APP_WEBSOCKET_HOST + 'socket/chat',
                connectHeaders: {
                    login: '',
                    passcode: '',
                    roomId: chatRoomId,
                    accountInfo: JSON.stringify(accountInfo),
                    // nickname : accountInfo.nickname,
                    // email : accountInfo.email,
                    // thumbnailUri : accountInfo.imgUrl
                },
                debug: str => {
                    // console.log(str);
                },
                reconnectDelay: 5000,
                heartbeatIncoming: 1000,
                heartbeatOutgoing: 1000,
            });

            stomp.webSocketFactory = function () {
                return new SockJS(
                    process.env.REACT_APP_API_HOST + 'socket/chat',
                );
            };

            stomp.activate();

            setStompClient(stomp);

            stomp.onConnect = (frame: any) => {
                const sessionId = stomp.webSocket._transport.url.split('/')[6];

                setAccountInfo((prev: any) => ({
                    ...prev,
                    sessionId: sessionId,
                }));

                const callback = function (message: any) {
                    console.log(JSON.parse(message.body));
                };

                const subscription = stomp.subscribe(
                    '/sub/room/' + chatRoomId,
                    callback,
                );
                console.log(frame);
            };
        }
    }, [accountLoaded]);

    useEffect(() => {
        if (!refVideo.current) {
            return;
        }
        refVideo.current.muted = false;
        refVideo.current.volume = 0;

        refVideo.current.srcObject = stream;
    }, [stream, refVideo]);

    useEffect(() => {
        if (refVideo.current) refVideo.current.srcObject = null;
        if (blob) {
            const file = new File([blob], 'filename.webm', {
                type: 'video/webm',
            });
            setFile(file);
            const formData = new FormData();
            formData.append('file', file);
            console.log(file);
        }
    }, [blob]);

    const getUploadParams = ({meta}: any) => {
        console.log('meta', meta);
        const url = 'https://httpbin.org/post';
        return {
            url,
            meta: {fileUrl: `${url}/${encodeURIComponent(meta.name)}`},
        };
    };

    const handleChangeStatus = ({meta}: any, status: any) => {
        console.log(status, meta);
    };

    // const handleSubmit = (files, allFiles) => {
    //     const formData = new FormData();
    //     formData.append('file', files[0]);
    //     console.log(files);
    //     console.log(files[0]);
    //     axiosI.post(
    //         `https://dpapi.plika.land/api/v1/pliconvers/scan`,
    //         formData,
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`,
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         },
    //     );

    //     console.log(files.map(f => f.meta));
    //     allFiles.forEach(f => f.remove());
    // };

    function cancel() {
        source.cancel();
    }

    const [uploading, setUploading] = useState<boolean | null>(null);
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    const [title, setTitle] = useState<string>('');
    const [video, setVideo] = useState<any>(null);
    const [running, setRunning] = useState<boolean>(false);
    const [time, setTime] = useState(0);
    const [complete, setComplete] = useState(false);
    const [fail, setFail] = useState(false);
    const fileRef = useRef<HTMLInputElement | null>(null);

    function previewFile(e: React.ChangeEvent<HTMLInputElement>) {
        const reader = new FileReader();
        const files = e.target.files!;
        if (!files[0]) return;
        const selectedFile = files[0];
        setVideo(selectedFile);

        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }

        reader.onload = readerEvent => {
            if (selectedFile.type.includes('video') && readerEvent.target) {
                setVideoPreview(readerEvent.target.result as string);
            }
        };
    }

    // 업로드 하는 로직은 아래 submitTest를 사용
    // 업로드 후(axiosI.post 후) then부분에 sendTextMsg 사용
    // sendTextMsg의 인자로 input에 입력된 value를 사용
    const handleSubmit = (e: any) => {
        e.preventDefault();
        setUploading(true);
        setRunning(true);
        console.log('video', video);
        console.log('title', title);

        const formData = new FormData();
        formData.append('file', video);

        axiosI
            .post(`https://dpapi.plika.land/api/v1/pliconvers/scan`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 1800000,
                cancelToken: source.token,
            })
            .then(e => {
                console.log(e);

                sendObjMsg(601, e.data.data);
                sendTextMsg(title);
                setRunning(false);
                setComplete(true);
            })
            .catch(e => {
                // TODO: 스캔 실패 화면 구현
                // 스캔에 실패했습니다 화면
                // 403: 토큰이 없거나 만료되었습니다. -> 로그인 페이지로 이동
                // 그 외: 확인누르면 초기 업로드 화면으로 이동 또는 3초 뒤 이동
                console.log('스캔을 실패했습니다.', e);

                setRunning(false);
                setFail(true);
            });
    };

    function reset() {
        setVideoPreview(null);
        setTime(0);
        setUploading(null);
        setRunning(false);
        setComplete(false);
        setTitle('');
    }

    useEffect(() => {
        let interval: any;
        if (running) {
            interval = setInterval(() => {
                setTime(prevTime => prevTime + 10);
            }, 10);
        } else if (!running) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [running]);

    const dispatch = useDispatch();

    const handleLogout = () => {
        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        if (
            accessToken != null &&
            accessToken.length > 0 &&
            accessToken != 'null'
        ) {
            axiosI
                .put(process.env.REACT_APP_API_HOST + 'v1/auth/refresh', config)
                .then(e => {
                    dispatch(setLogoutstatus());
                    window.localStorage.removeItem('plikaTk');
                    setAccountInfo(null);
                    window.location.reload();
                });
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', evt => {
            if (evt.code === 'Enter') evt.preventDefault();
        });
    });

    return (
        <Block uploading={uploading}>
            <header>
                <PlikaSimpleLogo />

                <div className="right">
                    <p>3D SCAN STUDIO</p>
                    {accountInfo ? (
                        <div className="button__wrap">
                            <div className="user">
                                <div className="thumbnail">
                                    <img
                                        src={accountInfo.thumbnailUri}
                                        alt=""
                                    />
                                </div>
                                <p>{accountInfo.nickname}</p>
                            </div>
                            <button className="logout" onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    ) : (
                        <a href="/login">Log in</a>
                    )}
                </div>
            </header>
            <div className="form">
                <Left isTitle={title}>
                    <SessionFirstImg />
                    {!uploading && (
                        <div className="input__wrap">
                            <p>이름을 입력해주세요</p>
                            <input
                                id="title"
                                type="text"
                                name="title"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                            />
                        </div>
                    )}
                </Left>

                <Right uploading={uploading} isVideo={video}>
                    {!uploading && (
                        <>
                            <div className="preview">
                                {videoPreview && (
                                    <video
                                        src={videoPreview}
                                        controls
                                        autoPlay
                                    />
                                )}
                            </div>
                            <div className="button__wrap">
                                <input
                                    id="video"
                                    name="video"
                                    ref={fileRef}
                                    type="file"
                                    accept="video/*"
                                    onChange={previewFile}
                                    hidden
                                />
                                <div
                                    className="upload"
                                    onClick={() => fileRef.current?.click()}
                                >
                                    {videoPreview
                                        ? `다시 업로드`
                                        : `영상 업로드`}
                                </div>
                                <button
                                    onClick={handleSubmit}
                                    disabled={!title || !video}
                                >
                                    3D스캔시작
                                </button>
                            </div>
                        </>
                    )}
                    {uploading && (
                        <>
                            {complete ? (
                                <CompleteIcon />
                            ) : (
                                <div className="animation__wrap">
                                    <Bounce></Bounce>
                                    <Bounce></Bounce>
                                    <Bounce></Bounce>
                                </div>
                            )}
                            <StopWatch time={time} />
                            <div className="text__wrap">
                                {complete && (
                                    <>
                                        <p>{title}님의 물품이</p>
                                        <p>스캔 완료되었습니다.</p>
                                    </>
                                )}
                                {!complete && !fail && (
                                    <>
                                        <p>{title}님의 물품을</p>
                                        <p>스캔하고 있습니다.</p>
                                    </>
                                )}
                                {fail && <p>스캔을 실패했습니다.</p>}
                            </div>
                            {fail && (
                                <button className="cancel" onClick={reset}>
                                    처음으로
                                </button>
                            )}
                            {complete ? (
                                <button className="new" onClick={() => reset()}>
                                    새 영상 업로드
                                </button>
                            ) : null}
                        </>
                    )}
                </Right>
            </div>
            {/* <header className="App-header">
                <Start onClick={handleRecording}>start</Start>
                <button onClick={handleStop}>stop</button>
                <button onClick={handleSave}>save</button> */}

            {/* {blob ? (
                    <video
                        src={URL.createObjectURL(blob)}
                        controls
                        autoPlay
                        ref={refVideo}
                    />
                ) : (
                    <video
                        // src={URL.createObjectURL(blob)}
                        controls
                        autoPlay
                        ref={refVideo}
                    />
                )}
            </header> */}
            {/* <form onSubmit={submitTest}>
                <input id="filetest" type="file" name="filetest" />
                <button>submit</button>
            </form>
            <button onClick={() => cancel()}>cancel</button> */}
        </Block>
    );
}

const BounceDelay = keyframes`
     0%,
  80%,
  100% {
      transform: scale(0);
  }
  40% {
      transform: scale(1.0);
  }
`;

interface BlockProps {
    uploading: any;
}

const Block = styled.div<BlockProps>`
    width: 100vw;
    height: 100vh;

    header {
        width: 100%;
        height: 76px;
        background: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 110px;
        color: #fff;

        svg {
            height: 40px;
        }

        p {
            font-size: 24px;
            font-weight: 500;
        }

        a {
            width: 140px;
            height: 40px;
            background: #fee500;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 700;
            color: #000;
            cursor: pointer;
        }

        .user {
            width: 140px;
            height: 40px;
            background: #fee500;
            border-radius: 20px;
            display: flex;
            align-items: center;
            gap: 10px;

            p {
                font-size: 16px;
                font-weight: 700;
                color: #000;
                width: calc(100% - 70px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            cursor: pointer;
        }

        .thumbnail {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background: gray;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .button__wrap {
            display: flex;
            gap: 10px;
        }

        .logout {
            width: 140px;
            height: 40px;
            border-radius: 20px;
            background: #1e69cb;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
        }
    }

    .form {
        display: flex;
        width: 100%;
        height: calc(100% - 76px);
        background: #e5e5e5;
    }

    @media (max-width: 768px) {
        header {
            padding: 0 60px;
        }

        .form {
            flex-direction: column;
            justify-content: center;
            gap: ${props => (props.uploading ? '245px' : '40px')};
        }
    }
`;

interface LeftProps {
    isTitle: any;
}

const Left = styled.div<LeftProps>`
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 38px;

    .input__wrap {
        display: flex;
        flex-direction: column;
        gap: 6px;

        p {
            font-size: 12px;
            font-weight: 400;
            color: ${props => (props.isTitle === '' ? '#ff0000' : '#000')};
        }

        input {
            width: 330px;
            height: 40px;
            background: #fff;

            border-radius: 28px;
            padding: 8px 20px;
            font-size: 16px;
            border: 1px solid #ccc;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        height: max-content;
    }
`;

interface RightProps {
    uploading: any;
    isVideo: any;
}

const Right = styled.div<RightProps>`
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${props => (props.uploading ? '50px' : '60px')};

    .preview {
        width: 400px;
        height: 400px;
        background: #ccc;
        border: ${props => (props.isVideo ? 'none' : '4px solid #1e69cb')};

        video {
            width: 100%;
            height: 100%;
        }
    }

    .button__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 400px;
        height: 40px;

        button {
            width: 150px;
            height: 100%;
            border-radius: 20px;
            background: #fee500;
            font-size: 20px;
            font-weight: 700;

            &:disabled {
                opacity: 0.5;
            }
        }

        .upload {
            width: 150px;
            height: 100%;
            border-radius: 20px;
            background: #1e69cb;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .text__wrap {
        font-size: 24px;
        color: #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cancel {
        width: 150px;
        height: 40px;
        background: #ee3030;
        border-radius: 20px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
    }

    .new {
        width: 150px;
        height: 40px;
        background: #fee500;
        border-radius: 20px;

        font-size: 20px;
        font-weight: 700;
    }

    svg {
        width: 90px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
    @media (max-width: 768px) {
        width: 100%;
        height: max-content;
    }
`;

const Bounce = styled.div`
    width: 30px;
    height: 30px;
    background-color: white;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
    display: inline-block;
    animation: ${BounceDelay} 1.4s infinite ease-in-out both;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));

    &:nth-child(1) {
        animation-delay: -0.32s;
    }

    &:nth-child(2) {
        animation-delay: -0.16s;
    }
`;

export default Upload;
