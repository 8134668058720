import React, {useEffect, useState, useRef} from 'react';
import {gsap, Linear} from 'gsap';

import styles from '../../styles/spaceItem.module.scss';
import uiStyle from '../../styles/ui.module.scss';

import loading from '../../assets/ui/loading.png';
import logo from '../../assets/ui/plika-logo.png';
import users from '../../assets/ui/fi_users.png';
import Notice from 'components/atoms/Notice';

function SpaceDetail(props: any) {
    const detail: any = useRef();

    const {newItemPosition, space, slideInfoMouseOver, slideInfoMouseLeave} =
        props;

    const [spaceJoinedMemberFull, setSpaceJoinedMemberFull] = useState(false);

    function enterLand() {
        if (space.currentJoinMemberTotal >= space.connectUserLimit) {
            setSpaceJoinedMemberFull(true);

            setTimeout(() => {
                setSpaceJoinedMemberFull(false);
            }, 3000);
        } else {
            window.location.href = `/app/${space.idName}`;
        }
    }

    useEffect(() => {
        if (detail.current != null) {
            props.gsap.current = gsap.to(detail.current, {
                scale: 1.3,
                ease: Linear.easeNone,
                duration: 0.1,
            });
            detail.current.style.top = newItemPosition.top + 'px';
            detail.current.style.left = newItemPosition.left + 'px';
        }
    }, [detail.current]);

    return (
        <>
            <div
                className={styles.main_list_li_info + ' slide-info'}
                ref={detail}
                onPointerEnter={e => slideInfoMouseOver(true)}
                onPointerLeave={e => {
                    slideInfoMouseLeave(e);
                }}
            >
                <div className={styles.main_list_thumb}>
                    <img
                        src={
                            space.templateThumbnail != null
                                ? space.templateThumbnail
                                : loading
                        }
                        alt=""
                        className={styles.main_list_bg}
                    />
                    {space.logoUrl != null && (
                        <img
                            src={space.logoUrl != null ? space.logoUrl : logo}
                            alt=""
                            className={styles.main_list_logo}
                        />
                    )}

                    <div className={styles.main_list_users}>
                        <img src={users} alt="" />
                        <div>
                            <span>{space.currentJoinMemberTotal}</span>/
                            {space.connectUserLimit}
                        </div>
                    </div>
                    <div className={styles.main_list_name}>{space.name}</div>
                    <div className={styles.main_list_profile}>
                        <img src={space.thumbnailUri} alt="" />
                        <span>{space.nickname}</span>
                    </div>
                </div>
                <div
                    className={`${styles.main_list_detail} ${styles.slide_info_detail}`}
                >
                    <div className={styles.main_list_detail_text_wrap}>
                        <p>{space.name}</p>
                        <a
                            href={space.companyUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {space.companyUrl}
                        </a>
                        <p>{space.description}</p>
                    </div>
                    <button
                        type="button"
                        className={uiStyle.btn_theme_yel}
                        onClick={enterLand}
                    >
                        Enter
                    </button>
                </div>
            </div>
            {spaceJoinedMemberFull && (
                <Notice
                    width={330}
                    height={40}
                    bgColor={'#EE3030'}
                    innerText={'인원이 가득차서 입장할 수 없습니다'}
                ></Notice>
            )}
        </>
    );
}

export default SpaceDetail;
