import React, { forwardRef, useEffect, useRef, useState, useCallback, ChangeEvent } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faXmark } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import * as SockJS from 'sockjs-client'
import * as StompJs from '@stomp/stompjs'

import Header from 'components/SideMenu/molecules/Header'
import MemberList from 'components/SideMenu/organisms/MemberList'
import ChatList from 'components/SideMenu/organisms/ChatList'
import Input from 'components/SideMenu/molecules/Input'
import media from '../../../lib/styles/media'
import { toast } from 'react-hot-toast'
import axiosI from 'utils/AxiosI'
import { useDropzone } from 'react-dropzone'

// private String roomCode; -> RoomId
// private String roomName; -> RoomName

interface Props {
  sideMenuPopup: any
}

const SideMenuComponent = styled.div<Props>`
  position: absolute;
  right: 0;
  height: 100%;
  display: ${(props: any) => (props.sideMenuPopup ? 'flex' : 'none')};
  justify-content: center;
  z-index: 100;

  ${media.small} {
    width: 100%;
  }
`

const ExitBtn = styled.div`
  position: relative;
  top: 20px;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #fee500;
  }
`

const MemberListWrap = styled.div`
  overflow: auto;
  height: calc(100% - 80px);
  margin: 0 15px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 7px;
  }
`

const PlayerInfo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 360px;
  height: calc(100% - 60px);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);

  ${media.medium} {
    top: 70px;
    height: calc(100% - 140px);
  }

  ${media.small} {
    top: 50px;
    width: 100%;
    height: calc(100% - 100px);
  }
`

const Chat = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 360px;
  height: calc(100% - 60px);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;

  ${media.medium} {
    top: 70px;
    height: calc(100% - 140px);
  }

  ${media.small} {
    top: 50px;
    width: 100%;
    height: calc(100% - 100px);
  }
`

function SideMenu(props: any) {
  const {
    spaceName,
    accountInfo,
    connectedUsers,
    sendTextMsg,
    chatList,
    sideMenuPopup,
    setSelMemberInfo,
    chatStatus,
    chatRoomId,
    setChatStatus,
    webviewPopupChange,
    menuPopupChange,
    sendMsgWithParam,
    sendRingMsg,
  } = props

  const chatBodyRef = useRef<HTMLDivElement | null>(null)

  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [files, setFiles] = useState<FileList | null>(null)

  const handleDragIn = (e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    console.log('handleDragIn')
  }

  const handleDragOut = (e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragging(false)
  }

  const handleDragOver = (e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer!.files) {
      setIsDragging(true)
    }
  }

  const handleDrop = (e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    setFiles(e.dataTransfer!.files)

    setIsDragging(false)
  }

  const initDragEvents = (): void => {
    if (chatBodyRef.current !== null) {
      chatBodyRef.current.addEventListener('dragenter', handleDragIn)
      chatBodyRef.current.addEventListener('dragleave', handleDragOut)
      chatBodyRef.current.addEventListener('dragover', handleDragOver)
      chatBodyRef.current.addEventListener('drop', handleDrop)
    }
  }

  const resetDragEvents = (): void => {
    if (chatBodyRef.current !== null) {
      chatBodyRef.current.removeEventListener('dragenter', handleDragIn)
      chatBodyRef.current.removeEventListener('dragleave', handleDragOut)
      chatBodyRef.current.removeEventListener('dragover', handleDragOver)
      chatBodyRef.current.removeEventListener('drop', handleDrop)
    }
  }

  useEffect(() => {
    initDragEvents()

    return () => resetDragEvents()
  }, [initDragEvents, resetDragEvents])

  useEffect(() => {
    if (files) {
      console.log('files', files)
      const formData = new FormData()

      formData.append('roomId', chatRoomId)

      formData.append(
        'account',
        new Blob(
          [
            JSON.stringify({
              nickname: accountInfo.nickname,
              thumbnailUri: accountInfo.thumbnailUri,
              sessionId: accountInfo.sessionId,
            }),
          ],
          { type: 'application/json' },
        ),
      )

      for (const file of files) {
        formData.append('files', file)
      }

      axios.post(`${process.env.REACT_APP_API_HOST}chat/file/upload`, formData)
    }
  }, [files])

  // const onDrop = useCallback((acceptedFiles) => {
  //   // Do something with the files
  //   console.log(acceptedFiles)
  // }, [])

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  function exitChat(client: any) {
    client.deactivate() // 완전히 끝남
  }

  function sendBtnClick(e: any) {
    e.target.style.backgroundPositionX = '0'
  }

  function sendBtnHover(e: any) {
    e.target.style.backgroundPositionX = '100%'
  }

  function onCloseSideMenu() {
    setChatStatus('chatStatus', false)
    menuPopupChange('sideMenuPopup', false)
  }

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatList])

  function onSendChat(e: any) {
    sendBtnClick(e)
    // scrollToBottom();
  }

  useEffect(() => {
    if (chatStatus) {
      scrollToBottom()
    }
  }, [chatStatus])

  return (
    <>
      <SideMenuComponent sideMenuPopup={sideMenuPopup}>
        {/* <ExitBtn onClick={() => webviewPopupChange('exitPopup', true)}><FontAwesomeIcon icon={faArrowRightFromBracket} /></ExitBtn> */}
        {sideMenuPopup && !chatStatus && (
          <PlayerInfo>
            <Header title={spaceName} callBack={onCloseSideMenu} />
            <MemberListWrap>
              <MemberList
                title="TEAM"
                connectedUsers={connectedUsers}
                accountInfo={accountInfo}
                setSelMemberInfo={setSelMemberInfo}
                webviewPopupChange={webviewPopupChange}
                sendRingMsg={sendRingMsg}
              />
              <MemberList
                title="GUESTS"
                connectedUsers={connectedUsers}
                accountInfo={accountInfo}
                setSelMemberInfo={setSelMemberInfo}
                webviewPopupChange={webviewPopupChange}
                sendRingMsg={sendRingMsg}
              />
            </MemberListWrap>
          </PlayerInfo>
        )}

        {sideMenuPopup && chatStatus && (
          <Chat>
            <Header title="Chat" callBack={onCloseSideMenu} />
            <ChatList ref={chatBodyRef} chatList={chatList} />

            {/* <ChatList {...getRootProps()} ref={chatBodyRef} chatList={chatList} />
            <input {...getInputProps()} /> */}
            <Input
              sendTextMsg={sendTextMsg}
              sendBtnClick={sendBtnClick}
              sendBtnHover={sendBtnHover}
              onSendChat={onSendChat}
              sendMsgWithParam={sendMsgWithParam}
            />
          </Chat>
        )}
      </SideMenuComponent>
    </>
  )
}

export default SideMenu
