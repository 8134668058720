import React from 'react'

import { useAllLands } from './hooks/useAllLands'
import { IconChevronDoubleLeft, IconChevronDoubleRight } from 'components/vectors'

export default function Pagination({ allLands, limit, page, setPage, numPages }) {
  return (
    <div className="flex justify-center mt-4 text-slate-400">
      <nav className="flex items-center " aria-label="Page navigation">
        <button
          className="px-3 py-1.5"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
          aria-label="Previous"
        >
          <IconChevronDoubleLeft className="w-6 h-auto " />
        </button>

        {Array(numPages)
          .fill(0)
          .map((_, i) => (
            <button
              className={`${
                page === i + 1 && 'bg-secondary-100 text-slate-900 font-semibold'
              } flex items-center justify-center duration-500 rounded-full w-9 h-9 shrink-0 hover:text-slate-900 hover:font-semibold`}
              key={i + 1}
              onClick={() => setPage(i + 1)}
              //   aria-current={page === i + 1 ? 'page' : null}
            >
              {i + 1}
            </button>
          ))}

        <button
          className="px-3 py-1.5"
          onClick={() => setPage(page + 1)}
          disabled={page === numPages}
          aria-label="Next"
        >
          <IconChevronDoubleRight className="w-6 h-auto " />
        </button>
      </nav>
    </div>
  )
}
