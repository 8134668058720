import React from 'react';
import uiStyle from '../../styles/ui.module.scss';
import guideStyle from '../../styles/guide.module.scss';
import styled from 'styled-components';

import {useTranslation} from 'react-i18next';

function Guide() {
    const {t} = useTranslation();

    const TextData = [
        `${t('main.guide.text1')}`,
        `${t('main.guide.text2')}`,
        `${t('main.guide.text3')}`,
    ];
    return (
        <div className={guideStyle.container}>
            <div className={guideStyle.container_inner}>
                <div className={guideStyle.text_wrapper}>
                    <p
                        className={`${guideStyle.text_main} ${guideStyle.text_item}`}
                    >
                        {t('main.guide.title1')}
                    </p>
                    <ul>
                        {TextData.map(
                            (text, index) =>
                                text !== '' && (
                                    <li key={index}>
                                        <span
                                            className={`${guideStyle.text_sub} ${guideStyle.text_item}`}
                                        >
                                            {text}
                                        </span>
                                    </li>
                                ),
                        )}
                    </ul>
                </div>
                <ButtonBox>
                    {t('main.guide.button1') !== '' && (
                        <a
                            href="https://open.kakao.com/o/gUoLAote"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button>{t('main.guide.button1')}</button>
                        </a>
                    )}
                    <a
                        href="/3d"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${uiStyle.btn_theme_bl_negative} `}
                    >
                        <button>{t('main.guide.button2')}</button>
                    </a>
                </ButtonBox>
            </div>
        </div>
    );
}

const ButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: max-content;

    button {
        width: 290px;
        height: 56px;
        background: #fee500;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 700;

        a {
            width: 100%;
            height: 100%;
        }
    }

    a:last-child button {
        background: #fff;
        transition: all 0.3s;

        &:hover {
            background: #fee500;
        }
    }

    @media (max-width: 1024px) {
        flex-direction: row;
        button {
            width: 200px;
            height: 46px;
            font-size: 18px;
        }
    }

    @media (max-width: 768px) {
        button {
            width: 160px;
            font-size: 14px;
        }
    }
`;

export default Guide;
