import media from '../../../lib/styles/media';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import ButtonItem from '../molecules/ButtonItem';
import {CloseIcon} from '../../../static/svg';
import {useMediaQuery} from 'react-responsive';

export interface ModalProps {
    accountInfo: any;
    modalData: {
        home: {
            title: string;
            onClickEvent: () => void;
            icon: string;
        };
        copyLink: {
            title: string;
            onClickEvent: () => void;
            icon: string;
        };
        logout: {
            title: string;
            onClickEvent: () => void;
            icon: string;
        };
        login: {
            title: string;
            onClickEvent: () => void;
            icon: string;
        };
        leaveLand: {
            title: string;
            onClickEvent: () => void;
            icon: string;
        };
        helpCenter: {
            title: string;
            onClickEvent: () => void;
            icon: string;
        };
    };
    modalClose: any;
    menuPopupChange: any;
}

export const ModalComponent = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;

    ${media.medium} {
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin-top: 50px;

        svg {
            position: absolute;
            top: 16px;
            right: 16px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
`;

const Modal: React.FC<ModalProps> = React.forwardRef(function ({
    accountInfo,
    modalData,
    modalClose,
    menuPopupChange,
}) {
    const isMobile = useMediaQuery({query: '(max-width: 1024px)'});
    return (
        <ModalComponent>
            {isMobile && (
                <CloseIcon
                    onClick={() => menuPopupChange('logoMenuPopup', false)}
                />
            )}
            <ButtonItem
                title={modalData.home.title}
                onClickEvent={modalData.home.onClickEvent}
                icon={modalData.home.icon}
            />
            <ButtonItem
                title={modalData.copyLink.title}
                onClickEvent={modalData.copyLink.onClickEvent}
                icon={modalData.copyLink.icon}
            />
            <ButtonItem
                title={modalData.helpCenter.title}
                onClickEvent={modalData.helpCenter.onClickEvent}
                icon={modalData.helpCenter.icon}
            />
            <ButtonItem
                title={modalData.leaveLand.title}
                onClickEvent={modalData.leaveLand.onClickEvent}
                icon={modalData.leaveLand.icon}
            />
            {accountInfo.isLogin == true ? (
                <ButtonItem
                    title={modalData.logout.title}
                    onClickEvent={modalData.logout.onClickEvent}
                    icon={modalData.logout.icon}
                />
            ) : (
                <ButtonItem
                    title={modalData.login.title}
                    onClickEvent={modalData.login.onClickEvent}
                    icon={modalData.login.icon}
                />
            )}
        </ModalComponent>
    );
});
Modal.displayName = 'Modal';
export default Modal;
