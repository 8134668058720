export function createRandomNickname() {
    const rand_0_75 = Math.floor(Math.random() * 76);
    const nicknameList = [
        '아름다운 다람쥐',
        '답답한 고래',
        '눈부신 철쭉',
        '정직한 강아지',
        '진실한 고양이',
        '예의바른 사슴',
        '근면한 망아지',
        '호감가는 여우',
        '친절한 늑대',
        '상냥한 참치',

        '밝은 개구리',
        '야망있는 쥐',
        '너그러운 양',
        '꼼꼼한 개미',
        '창의적인 상어',
        '즉흥적인 여자',
        '총명한 남자',
        '유능한 로봇',
        '재미있는 상인',
        '침착한 송아지',

        '신중한 멸치',
        '숙련된 말',
        '윤리적인 비둘기',
        '호감가는 부엉이',
        '믿을만한 낚시꾼',
        '태평한 두더지',
        '협력하는 소나무',
        '낭만적인 개나리',
        '친절한 도둑',
        '마음이열린 도마뱀',

        '기쁜 선인장',
        '독창적인 까마귀',
        '순종적인 낙타',
        '어른스러운 고라니',
        '열정적인 물범',
        '열렬한 고릴라',
        '융통성있는 곰',
        '굴하지않는 기니피그',
        '죽지않는 나무늘보',
        '용감한 뉴트리아',

        '겸손한 아르마딜로',
        '보통의 알파카',
        '활동적인 노새',
        '온정이있는 얼룩말',
        '관대한 오리너구리',
        '위축된 이리',
        '모험적인 마르모트',
        '정이많은 미어캣',
        '확고한 청설모',
        '따뜻한 진돗개',

        '자상한 치타',
        '설레이는 반달곰',
        '재미있는 비버',
        '상쾌한 토끼',
        '솔직한 판다',
        '대담한 호랑이',
        '온순한 푸들',
        '자유로운 삵',
        '끈질긴 황소',
        '이상한 사이가',

        '수줍은 재규어',
        '결연한 원숭이',
        '지적인 오소리',
        '생각이많은 코끼리',
        '주장이강한 라마',
        '자유분방한 매머드',
        '엉뚱한 마르모트',
        '집착하는 자칼',
        '쾌할한 친질라',
        '만족하는 불독',

        '씩씩한 차우차우',
        '느긋한 천산갑',
        '안심하는 독수리',
        '열중하는 참새',
        '발빠른 수리',
    ];

    const nickname =
        nicknameList[Math.floor(Math.random() * nicknameList.length)];

    return nickname;
}
