import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Typewriter from 'typewriter-effect'
import { useMediaQuery } from 'react-responsive'
import styled, { keyframes } from 'styled-components'
import { GrClose } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'

import styles from '../styles/main.module.scss'
import uiStyle from '../styles/ui.module.scss'
import spaceStyle from '../styles/spaceItem.module.scss'


import { useSlide } from '../hooks/useSlide'
import media from '../lib/styles/media'

import { PlayersIcon } from '../static/svg'
import Logo from 'assets/ui/plikaland_logo_white.png'
import loading from '../assets/ui/loading.png'
// import Header from 'components/app/Header'
import Header from '../components/tile/Header'
import Guide from '../components/tile/Guide'
import Footer from '../components/tile/Footer'
import Text from 'components/common/atoms/Text'
import AvatarSelect from 'components/hanam/AvatarSelect'

// import MainVideo from 'components/home/MainVideo'
import LandCantCreateAlert from 'components/modals/LandCantCreateAlert'
import MiddleVideo from 'components/videos/MiddleVideo'
import LastVideo from 'components/home/LastVideo'
import FeaturedLands from 'components/lands/FeaturedLands'
// import PopularLands from 'components/home/PopularLands'
import CarouselLands from 'components/lands/CarouselLands'
import { ModalModeProvider } from 'contexts/ModalModeContext'
import { LandProvider } from 'contexts/LandContext'
import FeaturedLandDetail from 'components/lands/FeaturedLandDetail'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import MainVideo from 'components/videos/MainVideo'
import Solutions from 'components/app/Solutions'
import BottomVideo from 'components/videos/BottomVideo'
import { usePopularLands } from 'components/lands/hooks/usePopularLands'
import ChannelService from '../utils/channeltalk';






function Main() {

  const [accountInfo, setAccountInfo]: any = useState()
  const [specialLandLists, setSpecialLandLists]: any = useState(null)

  const queryClient = useQueryClient()

  function setAccountInfoState(account: any) {
    setAccountInfo(account)

  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_HOST + 'api/metaverse/lands/ranks').then((e) => {
      setSpaceInfo(e.data.data)
    })

    axios.get(process.env.REACT_APP_API_HOST + 'plika/member').then((e) => {
      setMemberCount(e.data.data)
    })

    axios.get(process.env.REACT_APP_API_HOST + 'api/metaverse/lands/by/expsr').then((e) => {
      setSpecialLandLists(e.data.data.featuredLand)
    })

    const data = queryClient.getQueryData(['account'])
    console.log('ㅇㅅㅇ', data)
  }, [])

  const [spaceInfo, setSpaceInfo]: any = useState()
  const [memberCount, setMemberCount] = useState(0)

  useEffect(() => {
    if (spaceInfo != null) {
      if (spaceInfo.landsOrderByRegdt.length < 4) {
        // slickSettingsForList1.slidesToShow =
        //     spaceInfo.landsOrderByRegdt.length;
        const slideArr = document.querySelectorAll('.slick-slide')
        slideArr.forEach((e) => e.classList.add(spaceStyle.list_item))
      } else {
        // slickSettingsForList1.slidesToShow = 4;
      }

      if (spaceInfo.landsOrderByCurrentJoinMemberTotal.length < 4) {
        // slickSettingsForList1.slidesToShow =
        //     spaceInfo.landsOrderByCurrentJoinMemberTotal.length;
        const slideArr = document.querySelectorAll('.slick-slide')
        slideArr.forEach((e) => e.classList.add(spaceStyle.list_item))
      } else {
        // slickSettingsForList1.slidesToShow = 4;
      }
    }
  }, [spaceInfo])

  const [
    onMouse,
    index,
    arrayName,
    newItemPosition,
    slideItemMouseOver,
    slideItemMouseLeave,
    slideInfoMouseOver,
    slideInfoMouseLeave,
    gsapAnimation,
  ] = useSlide()

  const [curElement, setCurElement] = useState()

  useEffect(() => {
    if (spaceInfo != null && onMouse) {
      setCurElement(spaceInfo[arrayName][index])
    }
  }, [onMouse])

  const [landCreateAlert, setLandCreateAlert]: any = useState(false)

  function validateMylands() {
    if (accountInfo != null && accountInfo.accountId != null) {
      if (accountInfo.myLands != null && accountInfo.myLands.length > 0) {
        setLandCreateAlert(true)
      } else {
        window.location.href = '/lands/create'
      }
    } else {
      window.location.href = '/login'
    }
  }

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [showLand, setShowLand] = useState(false)
  type landType = {
    accountId: number
    companyUrl: string
    currentJoinMemberTotal: number
    description: string
    email: string
    idName: string
    logoUrl: string
    name: string
    nickname: string
    space_id: number
    templateThumbnail: string
    template_id: number
    thumbnailUri: string
    connectUserLimit: number
  }
  const [currentLandInfo, setCurrentLandInfo] = useState<landType | null>(null)

  const currentLandInfoRef = useRef<landType | null>(null)
  function slideItemClick(space: any) {
    currentLandInfoRef.current = space
    setCurrentLandInfo((prev) => ({ ...prev, ...space }))
    setShowLand(true)
  }

  const [spaceJoinedMemberFull, setSpaceJoinedMemberFull] = useState(false)

  function enterLand() {
    if (currentLandInfo != null) {
      if (currentLandInfo.currentJoinMemberTotal >= currentLandInfo.connectUserLimit) {
        setSpaceJoinedMemberFull(true)

        setTimeout(() => {
          setSpaceJoinedMemberFull(false)
        }, 3000)
      } else {
        window.location.href = `/app/${currentLandInfo.idName}`
      }
    }
  }

  function landCreateAlertClose() {
    setLandCreateAlert(false)
  }

  const videoRef: any = useRef()

  const [autoplay, setAutoplay] = useState(false)

  const addPlaying = () => {
    const videoEl = videoRef.current
    if (videoEl != null) {
      if (videoEl.autoplay == false) {
        videoEl.autoplay = true
      }

      setAutoplay(videoEl.autoplay)

      videoEl.play()
    }
  }

  useEffect(() => {
    addPlaying()
  }, [autoplay])

  const handleVisibilityChange = () => {
    const videoElement = videoRef.current
    if (videoElement != null) {
      if (document.hidden) {
        videoElement.pause()
      } else {
        videoElement.play()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange, false)
    }
  })

  const { popularLands } = usePopularLands()

  return (

    <ModalModeProvider>
      <LandProvider>
        {/* <Header /> */}
        <Header
          show={null}
          setAccountInfoState={setAccountInfoState}
          setAlert={setLandCreateAlert}
        />
        {/* <MainVideo /> */}
        <MainVideo accountInfo={accountInfo} setAlert={setLandCreateAlert} />
        <FeaturedLands />
        {/* <FeaturedLands
            list={specialLandLists}
            setItem={setCurrentLandInfo}
            land={currentLandInfoRef.current}
            setAlert={setSpaceJoinedMemberFull}
            isClick={slideItemClick}
          /> */}
        <CarouselLands landList={popularLands} title="Popular Lands" />
        <MiddleVideo />
        <Solutions />
        <BottomVideo />
        {/* <LastVideo member={memberCount} ref={videoRef} /> */}

        <FeaturedLandDetail />
        <Footer />
        {showLand &&
          (isMobile ? (
            <ListDetail>
              <InfoBox>
                <Thumbnail>
                  <img
                    src={
                      currentLandInfo?.templateThumbnail != null
                        ? currentLandInfo?.templateThumbnail
                        : loading
                    }
                    alt=""
                  />
                </Thumbnail>
                <TextBox>
                  <h3>{currentLandInfo?.name}</h3>
                  <a href={currentLandInfo?.companyUrl} target="_blank" rel="noopener noreferrer">
                    {currentLandInfo?.companyUrl}
                  </a>
                  <p>{currentLandInfo?.description}</p>
                </TextBox>
              </InfoBox>
              <ButtonBox>
                <button onClick={enterLand}>Enter</button>
              </ButtonBox>
              <CloseIcon onClick={() => setShowLand(false)} />
            </ListDetail>
          ) : (
            <></>
          ))}
        {landCreateAlert ? (
          <LandCantCreateAlert onClose={landCreateAlertClose}></LandCantCreateAlert>
        ) : (
          <></>
        )}
      </LandProvider>
    </ModalModeProvider>
  )
}

const boxFade = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ListDetail = styled.div`
  position: sticky;
  width: 100%;
  height: max-content;
  background: #fff;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  border: 1px solid #cccccc;
  box-shadow: 0px -2px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;

  padding: 8px;
  padding-bottom: 20px;
`

const InfoBox = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`

const Thumbnail = styled.div`
  width: 46%;
  height: 260px;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.small} {
    height: 180px;
  }

  @media (max-width: 500px) {
    height: 140px;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 54%;
  gap: 5px;

  @media (max-width: 768px) {
    h3 {
      font-size: 16px;
      width: calc(100% - 20px);
    }

    p {
      font-size: 14px;
    }
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fee500;
    border-radius: 40px;

    font-size: 22px;
    font-weight: 700;
    transition: 0.3s;
  }

  button:hover {
    background: #fff280;
  }

  ${media.small} {
    button {
      height: 50px;
    }
  }
`

const CloseIcon = styled(GrClose)`
  position: absolute;
  top: 10px;
  right: 10px;

  font-size: 30px;
  font-weight: 900;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const MainButton = styled.button`
  width: 210px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 56px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;

  transition: all 0.3s;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #000;
    font-size: 20px;
    font-weight: 700;
  }

  span {
    font-size: 24px;
    font-weight: 900;
  }

  &:hover {
    background: #fee500;
  }
`

const IntroButton = styled.div``

const SpecialLandComponent = styled.section`
  padding-top: 144px;
  padding-bottom: 980px;
  width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 28px;

  h3 {
    font-size: 20px;
    font-weight: 700;
  }

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 120px;
  }
`

const TextWrap = styled.div`
  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-weight: 400px;
  }

  @media (max-width: 768px) {
    h3 {
      margin-left: 6px;
    }
  }
`

const Lists = styled.ul`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 58px;
  justify-items: center;

  @media (max-width: 1440px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    gap: 30px;
  }
`

const List = styled.li`
  width: 690px;
  height: 414px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    width: 400px;
    height: 280px;
  }
`

const LandLogo = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Players = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 90px;
  height: 30px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 12px;

  svg {
    width: 20px;
    height: 20px;
  }

  p {
    span {
      font-weight: 700;
    }
  }
`

const Bottom = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h4 {
    font-size: 24px;
    font-weight: 700;
  }
`

const Owner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-weight: 700;
  }
`

const ImageWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ListWrap = styled.div`
  position: relative;

  &:nth-child(3) {
    grid-column: 1 / 3;
  }

  .detail {
    flex-direction: column;
    position: absolute;
    top: 0;

    width: 700px;
    height: 420px;

    z-index: 999;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.25);

    display: none;
    transition: 0.4s ease-in-out;
  }

  &:hover {
    .detail {
      display: flex;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .image_wrap {
    position: relative;
    width: 100%;
    height: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text_wrap {
    position: relative;
    background: #fff;
    width: 100%;
    height: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    button {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);

      width: 140px;
      height: 56px;
      background: #fee500;
      border-radius: 36px;

      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      transition: all 0.3s ease;

      &:hover {
        background: #fff280;
      }
    }
  }
`

export default Main
