import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'
import styled, { css } from 'styled-components'
import { GrClose } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../styles/slick.scss'

import media from '../lib/styles/media'
import { useSlide } from '../hooks/useSlide'

import { ArrowLeftIcon, ArrowRightIcon } from '../static/svg'
import loading from '../assets/ui/loading.png'
import Space from '../components/spaces/Space'
import SpaceDetail from '../components/spaces/SpaceDetail'
import Header from '../components/tile/Header'
import Dropdown from '../components/ui/Dropdown'
import styles from '../styles/landlist.module.scss'
import Pagination from '../components/ui/Pagination'
import Guide from '../components/tile/Guide'
import Footer from '../components/tile/Footer'
import MobileSpace from 'components/spaces/MobileSpace'
import BottomVideo from 'components/videos/BottomVideo'
import NewLands from 'components/lands/NewLands'
import { LandProvider } from 'contexts/LandContext'
import { ModalModeProvider } from 'contexts/ModalModeContext'
import CarouselLands from 'components/lands/CarouselLands'
import { useNewLands } from 'components/lands/hooks/useNewLands'
import { usePopularLands } from 'components/lands/hooks/usePopularLands'
import { useRealTimeLands } from 'components/lands/hooks/useRealTemeLands'
import GridLands from 'components/lands/GridLands'

import FeaturedLandDetail from 'components/lands/FeaturedLandDetail'

function LandList() {
  const { t } = useTranslation()
  const landsOrderByRegdt = {
    key: 'landsOrderByRegdt',
    val: t('lands.sort.condition1'),
  }
  const landsOrderByCurrentJoinMemberTotal = {
    key: 'landsOrderByCurrentJoinMemberTotal',
    val: t('lands.sort.condition2'),
  }

  const dropArr = [landsOrderByRegdt, landsOrderByCurrentJoinMemberTotal]

  const [selectedDropdownValue, setSelectedDropdownValue] = useState('landsOrderByRegdt')

  const [landList, setLandList]: any = useState({
    landsOrderByRegdt: null,
    landsOrderByCurrentJoinMemberTotal: null,
  })

  const [curPageLandList, setCurPageLandList]: any = useState()
  const [targetElement, setTargetElement]: any = useState()

  useEffect(() => {
    if (selectedDropdownValue == 'landsOrderByRegdt') {
      axios.get(process.env.REACT_APP_API_HOST + 'api/metaverse/lands/order/regDt').then((e) => {
        const landsList = e.data.data.lands
        setLandList((prev: any) => ({
          ...prev,
          [selectedDropdownValue]: landsList,
        }))
      })
    } else if (selectedDropdownValue == 'landsOrderByCurrentJoinMemberTotal') {
      axios.get(process.env.REACT_APP_API_HOST + 'api/metaverse/lands/order/member').then((e) => {
        const landsList = e.data.data.lands
        setLandList((prev: any) => ({
          ...prev,
          [selectedDropdownValue]: landsList,
        }))
      })
    }
  }, [selectedDropdownValue])

  const [
    onMouse,
    index,
    arrayName,
    newItemPosition,
    slideItemMouseOver,
    slideItemMouseLeave,
    slideInfoMouseOver,
    slideInfoMouseLeave,
    gsapAnimation,
  ] = useSlide()

  const [
    onMouse2,
    index2,
    arrayName2,
    newItemPosition2,
    slideItemMouseOver2,
    slideItemMouseLeave2,
    slideInfoMouseOver2,
    slideInfoMouseLeave2,
    gsapAnimation2,
  ] = useSlide()

  useEffect(() => {
    if (landList[selectedDropdownValue] != null) {
      setTargetElement(curPageLandList[index])
    }
  }, [onMouse, index])

  useEffect(() => {
    if (spaceInfo != null) {
      setTargetElement(spaceInfo[arrayName2][index2])
    }
  }, [onMouse2, index2])

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [showLand, setShowLand] = useState(false)
  const [currentLandInfo, setCurrentLandInfo] = useState({
    accountId: null,
    companyUrl: '',
    currentJoinMemberTotal: 0,
    description: '',
    email: '',
    idName: '',
    logoUrl: null,
    name: '',
    nickname: '',
    space_id: null,
    templateThumbnail: '',
    template_id: null,
    thumbnailUri: '',
  })

  function slideItemClick(space: any) {
    setCurrentLandInfo((prev) => ({ ...prev, ...space }))
    setShowLand(true)
  }
  const [spaceJoinedMemberFull, setSpaceJoinedMemberFull] = useState(false)

  function enterLand() {
    if (currentLandInfo.currentJoinMemberTotal >= 6) {
      setSpaceJoinedMemberFull(true)

      setTimeout(() => {
        setSpaceJoinedMemberFull(false)
      }, 3000)
    } else {
      window.location.href = `/app/${currentLandInfo.idName}`
    }
  }

  const [spaceInfo, setSpaceInfo]: any = useState()
  const [memberCount, setMemberCount] = useState(0)

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_HOST + 'api/metaverse/lands/ranks').then((e) => {
      console.log('랜드 리스트', e.data.data.landsOrderByRegdt)
      setSpaceInfo(e.data.data)
    })

    axios.get(process.env.REACT_APP_API_HOST + 'plika/member').then((e) => {
      setMemberCount(e.data.data)
    })
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: false,

    prevArrow: (
      <ArrowButton pos="left">
        <ArrowLeftIcon />
      </ArrowButton>
    ),
    nextArrow: (
      <ArrowButton pos="right">
        <ArrowRightIcon />
      </ArrowButton>
    ),

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 2,
          draggable: true,
          infinite: false,
          arrows: false,
        },
      },
      {
        breakpoint: 765,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          draggable: true,
          infinite: false,
          arrows: false,
        },
      },
    ],
  }

  const { newLands } = useNewLands()
  const { realTimeLands } = useRealTimeLands()

  return (
    <ModalModeProvider>
      <LandProvider>
        <Header show={null}></Header>
        <div className="pt-12">
          <CarouselLands landList={newLands} title={t('main.lists.title1')} />
          <CarouselLands landList={realTimeLands} title={t('main.lists.title2')} />
          <GridLands title="All LANDs" />
        </div>
        <FeaturedLandDetail />
        {/* 
        <div className={styles.list_container}>
          <div className={styles.container_inner}>
            <section className={`${styles.main_section} ${styles.section_list}`}>
              <div className={styles.container_inner}>
                <div className={styles.main_list}>
                  <div className={styles.main_list_title}>{t('main.lists.title1')}</div>
                  <div className={styles.main_list_container}>
                    {
                      <ul className={styles.main_list_ul}>
                        {spaceInfo != null && (
                          <Slider {...settings}>
                            {spaceInfo != null &&
                              spaceInfo.landsOrderByRegdt != null &&
                              spaceInfo.landsOrderByRegdt.map((el: any, i: any) =>
                                isMobile ? (
                                  <MobileSpace
                                    key={i}
                                    index={i}
                                    space={el}
                                    arrayName={'landsOrderByRegdt'}
                                    slideItemClick={slideItemClick}
                                  ></MobileSpace>
                                ) : (
                                  <Space
                                    key={i}
                                    slideItemMouseOver={slideItemMouseOver2}
                                    slideItemMouseLeave={slideItemMouseLeave2}
                                    index={i}
                                    space={el}
                                    arrayName={'landsOrderByRegdt'}
                                  ></Space>
                                ),
                              )}
                          </Slider>
                        )}
                      </ul>
                    }
                  </div>
                </div>
                <div className={styles.main_list}>
                  <div className={styles.main_list_title}>{t('main.lists.title2')}</div>
                  <div className={styles.main_list_container}>
                    {
                      <ul className={styles.main_list_ul}>
                        {spaceInfo != null && (
                          <Slider {...settings}>
                            {spaceInfo != null &&
                              spaceInfo.landsOrderByCurrentJoinMemberTotal != null &&
                              spaceInfo != null &&
                              spaceInfo.landsOrderByCurrentJoinMemberTotal.map((el: any, i: any) =>
                                isMobile ? (
                                  <MobileSpace
                                    key={i}
                                    index={i}
                                    space={el}
                                    arrayName={'landsOrderByCurrentJoinMemberTotal'}
                                    slideItemClick={slideItemClick}
                                  ></MobileSpace>
                                ) : (
                                  <Space
                                    key={i}
                                    slideItemMouseOver={slideItemMouseOver2}
                                    slideItemMouseLeave={slideItemMouseLeave2}
                                    index={i}
                                    space={el}
                                    arrayName={'landsOrderByCurrentJoinMemberTotal'}
                                  ></Space>
                                ),
                              )}
                          </Slider>
                        )}
                      </ul>
                    }
                  </div>
                </div>
                <div className={styles.main_list}>
                            <div className={styles.main_list_title}>누적이용시간 Top10 LAND</div>
                                <div className={styles.main_list_container}>
                                    {
                                    <ul className={styles.main_list_ul}>
                                        { spaceInfo != null &&
                                            <Slider {...slickSettings}>
                                            {
                                                spaceInfo != null && spaceInfo.map((el, i) => (
                                                    <Space key={i} slideItemMouseOver={slideItemMouseOver} slideItemMouseLeave={slideItemMouseLeave} index={i} el={el}></Space>
                                                ))
                                            }
                                            </Slider>
                                        }
                                    </ul>
                                        }
                                </div>
                        </div>
              </div>
            </section>
            <div className={styles.list_header}>
              <p>ALL LANDs</p>
              <div className={styles.dropdown_btn}>
                <Dropdown
                  arr={dropArr}
                  setSelectedDropdownValue={setSelectedDropdownValue}
                ></Dropdown>
              </div>
            </div>
            <div className={styles.list_item}>
              {curPageLandList != null &&
                curPageLandList.map((el: any, i: any) => (
                  <div key={i} className={styles.list_item_box}>
                    {isMobile ? (
                      <MobileSpace
                        key={i}
                        index={i}
                        space={el}
                        arrayName={'landsOrderByRegdt'}
                        slideItemClick={slideItemClick}
                      ></MobileSpace>
                    ) : (
                      <Space
                        slideItemMouseOver={slideItemMouseOver}
                        slideItemMouseLeave={slideItemMouseLeave}
                        index={i}
                        space={el}
                        arrayName={selectedDropdownValue}
                      ></Space>
                    )}
                  </div>
                ))}
            </div>
            {landList[selectedDropdownValue] != null && (
              <Pagination
                totalList={landList[selectedDropdownValue]}
                itemPerPage={20}
                setCurPageList={setCurPageLandList}
              ></Pagination>
            )}
          </div>
        </div> */}
        <BottomVideo />
        <Footer></Footer>
        {showLand &&
          (isMobile ? (
            <ListDetail>
              <InfoBox>
                <Thumbnail>
                  <img
                    src={
                      currentLandInfo?.templateThumbnail != null
                        ? currentLandInfo?.templateThumbnail
                        : loading
                    }
                    alt=""
                  />
                </Thumbnail>
                <TextBox>
                  <h3>{currentLandInfo.name}</h3>
                  <a href={currentLandInfo.companyUrl} target="_blank" rel="noreferrer">
                    {currentLandInfo.companyUrl}
                  </a>
                  <p>{currentLandInfo.description}</p>
                </TextBox>
              </InfoBox>
              <ButtonBox>
                <button onClick={enterLand}>Enter</button>
              </ButtonBox>
              <CloseIcon onClick={() => setShowLand(false)} />
            </ListDetail>
          ) : (
            <></>
          ))}
        <div id="over_wrap" className={styles.main_over_wrap}>
          {onMouse && targetElement != null ? (
            <SpaceDetail
              space={targetElement}
              gsap={gsapAnimation}
              newItemPosition={newItemPosition.current}
              slideInfoMouseOver={slideInfoMouseOver}
              slideInfoMouseLeave={slideInfoMouseLeave}
            ></SpaceDetail>
          ) : (
            <></>
          )}
          {onMouse2 && targetElement != null ? (
            <SpaceDetail
              space={targetElement}
              gsap={gsapAnimation2}
              newItemPosition={newItemPosition2.current}
              slideInfoMouseOver={slideInfoMouseOver2}
              slideInfoMouseLeave={slideInfoMouseLeave2}
            ></SpaceDetail>
          ) : (
            <></>
          )}
        </div>
      </LandProvider>
    </ModalModeProvider>
  )
}

const Wrapper = styled.div`
  width: 1560px;
  margin: 0 auto;
`

const ListDetail = styled.div`
  position: sticky;
  width: 100%;
  height: max-content;
  background: #fff;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  border: 1px solid #cccccc;
  box-shadow: 0px -2px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;

  padding: 8px;
  padding-bottom: 20px;
`

const InfoBox = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`

const Thumbnail = styled.div`
  width: 50%;
  height: 260px;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.small} {
    height: 200px;
  }

  @media (max-width: 765px) {
    height: 140px;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 5px;

  p,
  a {
    word-wrap: break-word;
  }

  h3 {
    margin-bottom: 10px;
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fee500;
    border-radius: 40px;

    font-size: 22px;
    font-weight: 700;
    transition: 0.3s;
  }

  button:hover {
    background: #fff280;
  }

  ${media.small} {
    button {
      height: 50px;
    }
  }
`

const CloseIcon = styled(GrClose)`
  position: absolute;
  top: 10px;
  right: 10px;

  font-size: 30px;
  font-weight: 900;
  cursor: pointer;

  ${media.small} {
    font-size: 20px;
  }
`

const ArrowButton = styled.button<{ pos?: 'left' | 'right' }>`
  width: 60px;
  height: 100%;

  ${({ pos }) =>
    pos === 'left'
      ? css`
          left: -60px;
        `
      : css`
          right: -60px;
        `};
  &:before {
    content: initial;
  }
  svg {
    width: 30px;
    height: 30px;
    color: #000;
  }
`

export default LandList
