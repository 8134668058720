import React from 'react';
import BeforeLogin from '../components/auth/BeforeLogin';

function AuthTestPage() {
    return (
        <>
            <div>AuthTestPage</div>
            <BeforeLogin />
        </>
    );
}

export default AuthTestPage;
