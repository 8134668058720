import React, { ReactElement, useEffect, useState } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import { Land } from 'types'

import PopularLandCard from './PopularLandCard'
import { IconChevronLeft, IconChevronRight } from 'components/vectors'

interface SwiperLandsProps {
  landList: Land[]
  title: string
}

export default function CarouselLands({ landList, title }: SwiperLandsProps): ReactElement {
  SwiperCore.use([Navigation])

  const prevRef = React.useRef<HTMLButtonElement>(null)
  const nextRef = React.useRef<HTMLButtonElement>(null)
  const [swiperSetting, setSwiperSetting] = useState<any | null>(null)

  useEffect(() => {
    if (!swiperSetting) {
      setSwiperSetting({
        spaceBetween: 6,
        navigation: {
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        },
        scrollbar: { draggable: true, el: null },
        slidesPerView: 2.1,
        breakpoints: {
          768: {
            slidesPerView: 3.1,
          },
          1024: {
            slidesPerView: 4.1,
          },
        },
        onBeforeInit: (swiper: SwiperCore) => {
          if (typeof swiper.params.navigation !== 'boolean') {
            if (swiper.params.navigation) {
              swiper.params.navigation.prevEl = prevRef.current
              swiper.params.navigation.nextEl = nextRef.current
            }
          }

          swiper.navigation.update()
        },
      })
    }
  }, [swiperSetting])

  return (
    <section className="relative w-full px-4 pb-12 mx-auto max-w-container sm:px-6 lg:px-8 ">
      <h2 className="mb-4 text-xl font-semibold md:text-2xl">{title}</h2>
      <div className="relative">
        <button
          className="absolute z-10 flex items-center justify-center invisible duration-500 rounded-full -left-1 -translate-x-2/4 top-2/4 fle hover:bg-secondary-100 disabled:hover:bg-white/70 md:visible disabled:opacity-40 disabled:hover:shadow-86 hover:shadow-89 shadow-86 bg-white/70 w-11 h-11 "
          ref={prevRef}
        >
          <IconChevronLeft className="w-6 h-auto text-slate-900" />
        </button>
        {swiperSetting && (
          <Swiper {...swiperSetting}>
            {landList.map((landData) => (
              <SwiperSlide key={landData.spaceId}>
                <PopularLandCard landData={landData} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <button
          className="absolute z-10 flex items-center justify-center invisible duration-500 rounded-full -right-12 -translate-x-2/4 top-2/4 md:visible hover:bg-secondary-100 disabled:opacity-40 disabled:hover:shadow-86 hover:shadow-89 disabled:hover:bg-white/70 shadow-86 bg-white/70 w-11 h-11 "
          ref={nextRef}
        >
          <IconChevronRight className="w-6 h-auto text-slate-900" />
        </button>
      </div>
    </section>
  )
}
