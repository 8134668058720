import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import axiosI from '../../utils/AxiosI'
import footerStyle from '../../styles/footer.module.scss'
import logo from '../../assets/ui/plika_logo_col_black.png'
import media from '../../lib/styles/media'
import Translation from 'components/Translation'
import { useTranslation } from 'react-i18next'
import LandCantCreateAlert from 'components/modals/LandCantCreateAlert'

function Footer() {
  const { t } = useTranslation()

  const [landCreateAlert, setLandCreateAlert]: any = useState(false)
  const [accountInfo, setAccountInfo]: any = useState()

  useEffect(() => {
    const accessToken = window.localStorage.getItem('plikaTk')
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
    if (accessToken != null && accessToken.length > 0 && accessToken != 'null') {
      axiosI
        .get(process.env.REACT_APP_API_HOST + `v1/account/get`, config)
        .then((e) => {
          if (e.data.data != null) {
            setAccountInfo(e.data.data)
          }
        })
        .catch((err) => {
          // console.log(err);
        })
    }
  }, [])

  function createLand() {
    const accessToken = window.localStorage.getItem('plikaTk')
    if (accessToken != null && accessToken != 'null') {
      if (accountInfo.myLands != null && accountInfo.myLands.length > 0) {
        setLandCreateAlert(true)
      } else {
        window.location.href = '/lands/create'
      }
    } else {
      window.localStorage.setItem('login_redirect_url', '/lands/create')
      window.location.href = '/login'
    }
  }

  function landCreateAlertClose() {
    setLandCreateAlert(false)
  }
  return (
    <div className={footerStyle.footer_container}>
      <div className={footerStyle.container_inner}>
        <div className={footerStyle.box_item_wrapper}>
          <div className={footerStyle.item_info}>
            <img src={logo} alt="" />
            <Line />
            <NavBar>
              <div className={footerStyle.nav_header} onClick={createLand}>
                <a>+ Create</a>
              </div>

              <div className={footerStyle.nav_header}>
                <a href="/pricing">Pricing</a>
              </div>

              <div className={footerStyle.nav_header}>
                <a href="https://plika.io/" target="_blank" rel="noreferrer">
                  Company
                </a>
              </div>
              <div className={footerStyle.nav_header}>
                <a href="/lands">LANDs</a>
              </div>
              <div className={footerStyle.nav_header}>
                <a href="https://support.plika.io/" target="_blank" rel="noreferrer">
                  Help Center
                </a>
              </div>
              <div className={footerStyle.nav_header}>
                <a href="/contact">Contact</a>
              </div>
            </NavBar>
            <Line />
            <div className={footerStyle.text_wrapper}>
              <p className={footerStyle.info_text}>{t('main.footer.text1')}</p>
              <p className={footerStyle.info_text}>{t('main.footer.text2')}</p>
            </div>
            <div className={footerStyle.text_wrapper}>
              <p className={footerStyle.info_text}>
                <a href="/privacy">{t('main.footer.link1')}</a>
              </p>
              <p className={footerStyle.info_text}>
                <a href="/terms">{t('main.footer.link2')}</a>
              </p>
            </div>
          </div>
          <div className={footerStyle.nav_list}>
            <div className={footerStyle.nav_wrapper}>
              <div className={footerStyle.nav_header} onClick={createLand}>
                <a>+ Create</a>
              </div>
              <div className={footerStyle.nav_header}>
                <a href="/lands">LANDs</a>
              </div>
              <div className={footerStyle.nav_item}>
                <a href=""></a>
              </div>
            </div>
            <div className={footerStyle.nav_wrapper}>
              <div className={footerStyle.nav_header}>
                <a href="/pricing">Pricing</a>
              </div>
              <div className={footerStyle.nav_header}>
                <a href="https://support.plika.io/" target="_blank" rel="noreferrer">
                  Help Center
                </a>
              </div>
              <div className={footerStyle.nav_item}>
                <a href=""></a>
              </div>
            </div>
            <div className={footerStyle.nav_wrapper}>
              <div className={footerStyle.nav_header}>
                <a href="https://plika.io/" target="_blank" rel="noreferrer">
                  Company
                </a>
              </div>
              <div className={footerStyle.nav_header}>
                <a href="/contact">Contact</a>
              </div>
              <div className={footerStyle.nav_item}>
                <a href=""></a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={footerStyle.notice_wrapper}>
          <p className={footerStyle.copy_right}>© 2022 PLIKA, Inc. All Rights Reserved.</p>

          <Translation />
        </div>
      </div>
      {landCreateAlert ? (
        <LandCantCreateAlert onClose={landCreateAlertClose}></LandCantCreateAlert>
      ) : (
        <></>
      )}
    </div>
  )
}

const Line = styled.hr`
  display: none;
  width: 100%;

  ${media.medium} {
    display: block;
  }
`

const NavBar = styled.div`
  display: none;
  flex-direction: column;
  gap: 26px;

  a {
    font-size: 16px;
    font-weight: 700;
    transition: 0.3s;

    &:hover {
      color: #1e69cb;
    }
  }

  ${media.medium} {
    display: flex;
  }
`

export default Footer
