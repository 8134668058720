import React from 'react';
import styled from 'styled-components';
import FullHeightPage from '../system/FullHeightPage';

interface Props {
    children?: React.ReactNode;
}

function BasicLayout({children}: Props) {
    return (
        <FullHeightPage>
            <Content>{children}</Content>
        </FullHeightPage>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow: auto;

    & > img {
        position: absolute;
        z-index: -1;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

export default BasicLayout;
