import React, {useState, useEffect, useRef} from 'react';
import Axios from 'axios';

import '../../styles/webview.scss';

function useAxios() {
    const [loading, setLoading] = useState(true);
    const [webviewData, setWebviewData] = useState();
    const requestUri = process.env.REACT_APP_API_HOST + `fishes/items`;

    async function fetchUrl() {
        const result = await Axios.get(requestUri);
        const json = await result.data.data;

        setWebviewData(json);
        setLoading(false);
    }

    useEffect(() => {
        fetchUrl();
    }, []);

    return [webviewData, loading];
}

function FishItemList(props) {
    const {onClose} = props;

    const [webviewData, loading]: any = useAxios();

    const outerH: any = useRef();
    const innerH: any = useRef();

    window.addEventListener('resize', function () {
        calcVh();
    });

    window.addEventListener('DOMContentLoaded', event => {
        calcVh();
    });

    function calcVh() {
        const outerHeight = outerH.current.clientHeight;
        const innerHeight = innerH.current.offsetHeight;

        // let calc = Math.max(Math.min((outerH / innerH), 1), 0);
        const calc: any = Math.max(outerHeight / innerHeight, 0);
        document.documentElement.style.setProperty('--inner-scale', calc);
    }

    window.onload = () => {
        calcVh();
    };

    useEffect(() => {
        if (outerH.current != undefined) {
            calcVh();
        }
    }, [loading]);

    if (loading) {
        return <></>;
    } else {
        return (
            <div id="webview-container" className="webview-container">
                <div
                    id="webview-outer-size"
                    className="webview-outer-size webview-container-outer-rankinglist"
                    ref={outerH}
                >
                    <link
                        id="webview-container-inner"
                        className="webview-container-inner webview-container-inner-rankinglist"
                        ref={innerH}
                        onLoad={calcVh}
                    >
                        <div className="webview-header">
                            <div id="webview-title" className="text-box">
                                물고기 확률표
                            </div>
                            <button
                                id="webview-close-btn"
                                className="btn"
                                onClick={onClose}
                            ></button>
                        </div>

                        <div className="webview-body">
                            <div className="webview-body-inner">
                                <div className="content-container content-container-fish-items webview-pc">
                                    <div className="fish-items-column-header">
                                        <div>티어</div>
                                        <div>이름</div>
                                        <div>확률</div>
                                        <div>크기 범위</div>
                                    </div>
                                    {webviewData.fishDropItems.map(data => {
                                        return (
                                            <div
                                                key={data.name}
                                                className="fish-items-column"
                                            >
                                                <div className="fish-items-tier">
                                                    <figure className="item-img">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .REACT_APP_CDN_URL +
                                                                '/' +
                                                                data.fileUrl
                                                            }
                                                            alt=""
                                                            className={
                                                                data.tierName.includes(
                                                                    '아이언',
                                                                )
                                                                    ? 'border-iron'
                                                                    : data.tierName.includes(
                                                                          '브론즈',
                                                                      )
                                                                    ? 'border-bronze'
                                                                    : data.tierName.includes(
                                                                          '실버',
                                                                      )
                                                                    ? 'border-silver'
                                                                    : data.tierName.includes(
                                                                          '골드',
                                                                      )
                                                                    ? 'border-gold'
                                                                    : data.tierName.includes(
                                                                          '플레티넘',
                                                                      )
                                                                    ? 'border-platinum'
                                                                    : data.tierName.includes(
                                                                          '다이아',
                                                                      )
                                                                    ? 'border-diamond'
                                                                    : data.tierName.includes(
                                                                          '마스터',
                                                                      )
                                                                    ? 'border-master'
                                                                    : data.tierName.includes(
                                                                          '그랜드마스터',
                                                                      )
                                                                    ? 'border-grandmaster'
                                                                    : data.tierName.includes(
                                                                          '레전드',
                                                                      )
                                                                    ? 'border-legend'
                                                                    : ''
                                                            }
                                                        />
                                                    </figure>
                                                    <span>{data.tierName}</span>
                                                </div>
                                                <div>{data.name}</div>
                                                <div>{data.percent}%</div>
                                                <div>
                                                    {data.minSize}cm ~{' '}
                                                    {data.maxSize}cm
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="content-container content-container-fish-items webview-mobile">
                                    <div className="fish-items-column">
                                        <div>티어 이름</div>
                                        <div>확률</div>
                                        <div>크기 범위</div>
                                    </div>
                                    {webviewData.fishDropItems.map(data => {
                                        return (
                                            <div
                                                key={data.name}
                                                className="fish-items-column"
                                            >
                                                <div className="fish-items-tier">
                                                    <figure className="item-img">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .REACT_APP_CDN_URL +
                                                                '/' +
                                                                data.fileUrl
                                                            }
                                                            alt=""
                                                            className={
                                                                data.tierName.includes(
                                                                    '아이언',
                                                                )
                                                                    ? 'border-iron'
                                                                    : data.tierName.includes(
                                                                          '브론즈',
                                                                      )
                                                                    ? 'border-bronze'
                                                                    : data.tierName.includes(
                                                                          '실버',
                                                                      )
                                                                    ? 'border-silver'
                                                                    : data.tierName.includes(
                                                                          '골드',
                                                                      )
                                                                    ? 'border-gold'
                                                                    : data.tierName.includes(
                                                                          '플레티넘',
                                                                      )
                                                                    ? 'border-platinum'
                                                                    : data.tierName.includes(
                                                                          '다이아',
                                                                      )
                                                                    ? 'border-diamond'
                                                                    : data.tierName.includes(
                                                                          '마스터',
                                                                      )
                                                                    ? 'border-master'
                                                                    : data.tierName.includes(
                                                                          '그랜드마스터',
                                                                      )
                                                                    ? 'border-grandmaster'
                                                                    : data.tierName.includes(
                                                                          '레전드',
                                                                      )
                                                                    ? 'border-legend'
                                                                    : ''
                                                            }
                                                        />
                                                    </figure>
                                                    <div>
                                                        <span>
                                                            {data.tierName}
                                                        </span>
                                                        <span>{data.name}</span>
                                                    </div>
                                                </div>
                                                <div>{data.percent}%</div>
                                                <div>
                                                    {data.minSize}cm ~{' '}
                                                    {data.maxSize}cm
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </link>
                </div>
            </div>
        );
    }
}

export default FishItemList;
