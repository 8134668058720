import React from 'react';
import styled from 'styled-components';
import Text from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import InputWithTitle from 'components/ui/InputWithTitle';
import Button from 'components/atoms/Button';
import {useTranslation} from 'react-i18next';

const LogoutModalComponent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 33px 30px 30px 30px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 360px;
    height: 370px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const TextWrap = styled.div`
    width: 100%;
    margin-top: 50px;
    flex-grow: 1;
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const CancelButton = styled(Button)`
    width: 140px;
    height: 40px;
    border-radius: 20px;
`;

const OkButton = styled(Button)`
    width: 140px;
    height: 40px;
    border-radius: 20px;
`;

const LogoutModal = ({onClickEvent, onClose}) => {
    const {t} = useTranslation();
    return (
        <LogoutModalComponent>
            <Header>
                <Text size={2.2} weight={700}>
                    {t('main.header.logout.title1')}
                </Text>
                <Button
                    type="button"
                    bgColor="white"
                    hoveredBgColor="#cccccc"
                    onClick={() => onClose()}
                >
                    <Icon type="close" />
                </Button>
            </Header>

            <TextWrap>
                <Text size={1.8} weight={700}>
                    {t('main.header.logout.text1')}
                </Text>
            </TextWrap>

            <ButtonWrap>
                <CancelButton
                    type="button"
                    bgColor="#CCCCCC"
                    hoveredBgColor="#E5E5E5"
                    onClick={() => onClose()}
                >
                    <Text size={1.5} weight={700}>
                        Cancel
                    </Text>
                </CancelButton>
                <OkButton
                    type="button"
                    bgColor="#FEE500"
                    hoveredBgColor="#FFF280"
                    onClick={onClickEvent}
                >
                    <Text size={1.5} weight={700}>
                        OK
                    </Text>
                </OkButton>
            </ButtonWrap>
        </LogoutModalComponent>
    );
};

export default LogoutModal;
