import React from 'react';
import styled from 'styled-components';
import {
    CloseIcon,
    DtocButtonImg,
    DtocDirectionImg,
    DtocLogo,
    DtocMoveImg,
    DtocShowImg,
    DtocTransformImg,
    UnderLineIcon,
} from '../../static/svg';

export default function DtocControl(props) {
    console.log(props);
    function close() {
        props.sendMsg('Webreceiver', 'ControlPopupClose');
        props.setControlWebview(false);
    }
    return (
        <Block>
            <DtocLogo className="logo" />
            <button className="close" onClick={() => close()}>
                <CloseIcon />
            </button>
            <div className="wrapper">
                <div className="content">
                    <div className="title">
                        <p>
                            &lt;<span>청춘문화노리터</span>&gt;
                            <UnderLineIcon className="underline" />
                        </p>
                        <p>
                            온라인 전시관 이용 방법
                            <UnderLineIcon className="underline" />
                        </p>
                    </div>
                    <div className="first">
                        <p>
                            1. &lt;<span>청춘문화노리터</span>&gt; 온라인 전시관
                            입장하기
                        </p>
                    </div>
                    <div className="second">
                        <p>2. 키보드와 마우스 조작방법</p>
                        <div className="images">
                            <DtocMoveImg />
                            <DtocDirectionImg />
                            <DtocShowImg />
                            <DtocTransformImg />
                        </div>
                    </div>
                    <div className="first">
                        <p>
                            3. &lt;<span>청춘문화노리터</span>&gt; 온라인 전시관
                            즐기기!
                        </p>
                    </div>
                </div>
                <DtocButtonImg onClick={() => close()} className="go" />
            </div>
        </Block>
    );
}

const Block = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    height: 900px;
    background: #F8F4E0;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 30px;

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        width: 120px;
        height: auto;
        position: absolute;
        top: 30px;
        left: 30px;
    }

    .close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 40px;
        height: 40px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: auto;
        }
    }

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 46px;
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 48px;
        font-weight: 700;
        color: #6D2B0A;

        p {
        position: relative;
    }

        span {
            color: #F25130;
        }
    }
  

    .underline {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        height: 40px;
        width: 106%;
        z-index: -1;
    }

    .first {
        width: 100%;
        padding: 6px 0;
        background: #fff;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: 32px;
            font-weight: 700;
            color: #6D2B0A;
        }

        span {
            color: #F25130;
        }
    }

    .second {
        width: 100%;
        padding: 10px;
        background: #fff;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 32px;
            font-weight: 700;
            color: #6D2B0A;
        }

        .images {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            padding: 16px;
            padding-left: 0;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    .go {
        height: 88px;
        width: auto;
        cursor: pointer;
    }

    @media screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
        border-radius: 0;

        p {
            font-size: 24px;
        }

        .first {
            p {
                font-size: 24px;
            }
            
        }

        .second {
            p{
                font-size: 24px;
            }
        }

        .go {
            width: 100%;
            height: auto;
        }
    }


`;
