import React from 'react';
import styled from 'styled-components';
import Text from 'components/common/atoms/Text';
import MemberItem from './MemberItem';
import GuestItem from './GuestItem';
import {connectedGuest, connectedMember} from 'types';

interface MemberListProps {
    title: string;
    connectedUsers: {
        connectedMembers: connectedMember[];
        connectedGuests: connectedGuest[];
    };
    accountInfo: object;
    setSelMemberInfo: object;
    webviewPopupChange: boolean;
    sendRingMsg: void;
}

interface Members {
    Members: Member[];
}

interface Member {
    viewId: number;
    nickname: string;
    email: string;
    imgUrl: string;
    isMember: boolean;
    isLogin: boolean;
    isAdmin: boolean;
}
/**
 * {
	Team: 
		Members:[ 
				Member : {
					viewId
					nickname
					email
					imgUrl
					isMember
					isLogin
					isAdmin
				},
				Member : {
					viewId
					nickname
					email
					imgUrl
					isMember
					isLogin
					isAdmin
				},
		],
	Guest : 
		Members: [
				Member : {
					viewId
					nickname
					email
					imgUrl
					isMember
					isLogin
					isAdmin
				},
				Member : {
					viewId
					nickname
					email
					imgUrl
					isMember
					isLogin
					isAdmin
				},
		]
}
 */

export const MemberListComponent = styled.div`
    margin-top: 21px;
    margin-right: 5px;
    z-index: 100;
`;

const MemberList: React.FC<MemberListProps> = function ({
    title,
    connectedUsers,
    accountInfo,
    setSelMemberInfo,
    webviewPopupChange,
    sendRingMsg,
}) {
    const Members = connectedUsers.connectedMembers;
    const Guests = connectedUsers.connectedGuests;

    const statusOnMembers = Members.filter(member => member.detail.connected);
    const statusOffMembers = Members.filter(
        member => member.detail.connected === false,
    );
    const sortedMembers = [...statusOnMembers, ...statusOffMembers];

    return (
        <MemberListComponent>
            <Text size={20} weight={700} color="#FFFFFF">
                {title}
            </Text>
            {title == 'TEAM' &&
                Members.length > 0 &&
                sortedMembers.map((el, i) => (
                    <MemberItem
                        accountInfo={accountInfo}
                        memberInfo={el}
                        setSelMemberInfo={setSelMemberInfo}
                        key={i}
                        webviewPopupChange={webviewPopupChange}
                        admin={el.detail.admin ? true : false}
                        sendRingMsg={sendRingMsg}
                        status={el.detail.connected ? 'on' : 'off'}
                        isOff={el.detail.isOff}
                    ></MemberItem>
                ))}

            {title == 'GUESTS' &&
                Guests.length > 0 &&
                Guests.map((el, i) => (
                    <GuestItem
                        accountInfo={accountInfo}
                        key={i}
                        memberInfo={el}
                        setSelMemberInfo={setSelMemberInfo}
                        webviewPopupChange={webviewPopupChange}
                        status={el.detail.connected ? 'on' : 'off'}
                        sendRingMsg={sendRingMsg}
                        isOff={el.detail.isOff}
                    ></GuestItem>
                ))}
        </MemberListComponent>
    );
};

export default MemberList;
