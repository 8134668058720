import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import uiStyle from '../../styles/ui.module.scss'
import styles from '../../styles/modal.module.scss'
import { useTranslation } from 'react-i18next'

function ContentSaveAlert(props) {
  const { formSubmit, webviewClose, onClose, updateContent } = props

  document.body.style.overflow = 'hidden'

  const { t } = useTranslation()

  return (
    <>
      <div className={`${styles.modal_container}`}>
        <div className={`${styles.container_inner} ${styles.alarm}`}>
          <div className={styles.modal_header}>
            <p>{t('space.common.change_content.title1')}</p>
            <button type="button" onClick={() => onClose(false)}>
              <FontAwesomeIcon icon={faX} />
            </button>
          </div>
          <div className={styles.modal_desc}>{t('space.common.change_content.text1')}</div>
          <div className={styles.modal_btn_wrapper}>
            <button type="button" onClick={() => onClose(false)} className={uiStyle.btn_theme_gray}>
              Cancel
            </button>
            <button
              type="submit"
              className={uiStyle.btn_theme_yel}
              //   onClick={() => {
              //     onClose(false)
              //   }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentSaveAlert
