export const spaceKeys = {
  all: ['space'] as const,
  lists: () => [...spaceKeys.all, 'list'] as const,
  details: () => [...spaceKeys.all, 'details'] as const,
  detail: (id: number) => [...spaceKeys.details(), id] as const,
}

export const featuredKeys = {
  all: ['featured'] as const,
  lists: () => [...featuredKeys.all, 'list'] as const,
  details: () => [...featuredKeys.all, 'details'] as const,
  detail: (id: number) => [...featuredKeys.details(), id] as const,
}

export const popularKeys = {
  all: ['popular'] as const,
  lists: () => [...popularKeys.all, 'list'] as const,
  details: () => [...popularKeys.all, 'details'] as const,
  detail: (id: number) => [...popularKeys.details(), id] as const,
}

export const newKeys = {
  all: ['new'] as const,
  lists: () => [...newKeys.all, 'list'] as const,
  details: () => [...newKeys.all, 'details'] as const,
  detail: (id: number) => [...newKeys.details(), id] as const,
}

export const realTimeKeys = {
  all: ['realtime'] as const,
  lists: () => [...realTimeKeys.all, 'list'] as const,
  details: () => [...realTimeKeys.all, 'details'] as const,
  detail: (id: number) => [...realTimeKeys.details(), id] as const,
}

export const settingsKeys = {
  all: ['settings'] as const,
  lists: () => [...settingsKeys.all, 'list'] as const,
  details: () => [...settingsKeys.all, 'details'] as const,
  detail: (id: number) => [...settingsKeys.details(), id] as const,
}
