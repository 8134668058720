import React from 'react';
import styled from 'styled-components';
import Image from 'components/common/atoms/Image';

interface MemberThumbnailStyleProps {
    width: string;
    height: string;
    borderRadius: string;
}

export interface MemberThumbnailProps extends MemberThumbnailStyleProps {
    src: string;
    alt: string;
    status: 'on' | 'off';
}

export const MemberThumbnailComponent = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-right: 16px;

    figure {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`;

export const StatusLight = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffffff;
    border-radius: 10px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
    text-align: center;

    &.on {
        background: #1e69cb;
    }

    &.off {
        background: #ee3030;
    }
`;

const MemberThumbnail: React.FC<MemberThumbnailProps> = function ({
    src,
    alt,
    width,
    height,
    borderRadius,
    status,
}) {
    return (
        <MemberThumbnailComponent>
            <figure>
                <Image
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                    borderRadius={borderRadius}
                />
                {status === 'off' && <Status>OFF</Status>}
            </figure>
            {/* {status ? (
                <StatusLight className="on">
                    <span>On</span>
                </StatusLight>
            ) : (
                <StatusLight className="off">
                    <span>Off</span>
                </StatusLight>
            )} */}
        </MemberThumbnailComponent>
    );
};

const Status = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #ccc;

    position: absolute;
    top: 0;
    left: 0;
`;

export default MemberThumbnail;
