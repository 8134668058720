import React, {useEffect, useState, useRef} from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import styles from '../styles/login.module.scss';
import uiStyle from '../styles/ui.module.scss';

import axiosI from '../utils/AxiosI';

import PhotoBox from '../components/ui/PhotoBox';
import InputRound from '../components/ui/InputRound';
import Text from 'components/atoms/Text';

interface TextWrapProps {
    toggle: any;
}

const TextWrap = styled.div<TextWrapProps>`
    visibility: ${({toggle}: any) => (toggle ? 'visible' : 'hidden')};
`;

function LoginAfterProfile() {
    const [accountInfo, setAccountInfo]: any = useState();

    useEffect(() => {
        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        if (
            accessToken != null &&
            accessToken.length > 0 &&
            accessToken != 'null'
        ) {
            axiosI
                .get(process.env.REACT_APP_API_HOST + `v1/account/get`, config)
                .then(e => {
                    if (e.data.data != null) {
                        if (e.data.data.ageCheck && e.data.data.privacyCheck) {
                            {
                                window.location.replace('/');
                            }
                        }
                        setAccountInfo(e.data.data);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        } else {
            window.location.replace('/');
        }
    }, []);

    useEffect(() => {
        if (accountInfo != null) {
            setInputs(prev => ({
                ...prev,
                nickname: accountInfo.nickname,
            }));
        }
    }, [accountInfo]);

    const [inputs, setInputs] = useState({
        nickname: accountInfo != null ? accountInfo.nickname : '',
    });

    const {nickname} = inputs;

    const inputValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        setInvalidNickname(false);
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    type Keys = 'term_check' | 'age_check';
    type Values = true | false | null;
    type CheckStatusType = {[key in Keys]: Values};

    const [checkStatus, setCheckStatus] = useState<any>({
        term_check: null,
        age_check: null,
    });

    const checktValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {checked, name} = e.target;

        if (!checkStatus[name]) {
            if (checked) {
                setCheckStatus((prev: any) => ({
                    ...prev,
                    [name]: checked,
                }));
            }
        }
    };

    const [invalidNickname, setInvalidNickname]: any = useState(null);

    const profileImgRef: any = useRef();

    const [files, setFiles]: any = useState();

    const fileHandler = (e: React.ChangeEvent) => {
        const target = e.target as HTMLInputElement;

        if (target.files !== null) {
            const file = target.files;

            setFiles(file);
            profileImgRef.current.setAttribute(
                'src',
                URL.createObjectURL(file[0]),
            );
        }
    };

    async function updateProfile(e: any) {
        e.preventDefault();

        const {nickname, term_check, age_check} = e.target;

        if (nickname.value.length <= 0 || nickname.value.length > 15) {
            setInvalidNickname(true);
            return;
        }

        if (!term_check.checked || !age_check.checked) {
            if (!term_check.checked) {
                setCheckStatus((prev: any) => ({
                    ...prev,
                    term_check: false,
                }));
            }

            if (!age_check.checked) {
                setCheckStatus((prev: any) => ({
                    ...prev,
                    age_check: false,
                }));
            }

            return;
        }

        const formData = new FormData();

        const data = {
            nickname: nickname.value,
            chatSessionId: accountInfo.sessionId,
        };

        if (nickname.value.length <= 0 || nickname.value.length > 15) {
            setInvalidNickname(true);
            return;
        }

        if (files !== undefined && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('thumbnail', files[i]);
            }
        }

        formData.append(
            'accountProfileUpdateDto',
            new Blob([JSON.stringify(data)], {type: 'application/json'}),
        );

        const requestUri =
            process.env.REACT_APP_API_HOST + `v1/account/set/terms`;

        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };

        await axiosI
            .patch(requestUri, formData, config)
            .then(resp => {
                // UNITY 호출 로직
                if (resp.data.resp_code === 'OK') {
                    if (
                        window.localStorage.getItem('login_redirect_url') !=
                        null
                    ) {
                        const redirectUrl: string | URL =
                            window.localStorage.getItem(
                                'login_redirect_url',
                            ) as string;
                        window.localStorage.removeItem('login_redirect_url');
                        window.location.replace(redirectUrl);
                    } else {
                        window.location.replace('/');
                    }
                }
            })
            .catch(err => {
                alert('파일 업로드에 실패하였습니다.');
            });
    }

    const {t} = useTranslation();

    return (
        <>
            <div className={styles.login_container}>
                <div className={styles.login_bg_box}>
                    <video autoPlay loop muted>
                        <source
                            src={
                                process.env.REACT_APP_CDN_URL +
                                '/static/video/web/login_video.mp4'
                            }
                        />
                    </video>
                </div>
                <div className={`${styles.menu_box} ${styles.box_profile}`}>
                    <form onSubmit={e => updateProfile(e)}>
                        <div
                            className={`${styles.menu_box_inner} ${styles.profile_wrapper}`}
                        >
                            <p className={styles.profile_header}>
                                Welcome to PLIKALAND
                            </p>
                            <div className={styles.photo_box}>
                                <PhotoBox
                                    profileImgRef={profileImgRef}
                                    imgSrc={
                                        accountInfo != null &&
                                        accountInfo.thumbnailUri
                                    }
                                    fileHandler={fileHandler}
                                ></PhotoBox>
                            </div>
                            <div className={styles.input_box_wrapper}>
                                <p>Nickname</p>
                                <div className={styles.input_box}>
                                    <InputRound
                                        name={'nickname'}
                                        value={nickname}
                                        inputValueHandler={inputValueHandler}
                                    ></InputRound>
                                    <TextWrap toggle={invalidNickname}>
                                        <Text
                                            size={0.8}
                                            weight={400}
                                            color="#EE3030"
                                        >
                                            닉네임은 16자 이내로 적어주세요
                                        </Text>
                                    </TextWrap>
                                </div>
                            </div>
                            <div className={styles.term_box}>
                                <div className={styles.term_check_item}>
                                    <input
                                        id="term_check"
                                        name="term_check"
                                        type="checkbox"
                                        onChange={e => checktValueHandler(e)}
                                    />
                                    {i18next.language === 'en' ? (
                                        <p
                                            className={
                                                checkStatus.term_check == false
                                                    ? styles.false
                                                    : ''
                                            }
                                        >
                                            {t('join.checkbox1.text1')}
                                            <a target="_blank" href="/privacy">
                                                {t('join.checkbox1.link1')}
                                            </a>
                                            {t('join.checkbox1.text2')}
                                            <a target="_blank" href="/terms">
                                                {t('join.checkbox1.link2')}
                                            </a>
                                        </p>
                                    ) : (
                                        <p
                                            className={
                                                checkStatus.term_check == false
                                                    ? styles.false
                                                    : ''
                                            }
                                        >
                                            <a target="_blank" href="/privacy">
                                                {t('join.checkbox1.link1')}
                                            </a>
                                            {t('join.checkbox1.text1')}
                                            <a target="_blank" href="/terms">
                                                {t('join.checkbox1.link2')}
                                            </a>
                                            {t('join.checkbox1.text2')}
                                        </p>
                                    )}
                                </div>
                                <div className={styles.term_check_item}>
                                    <input
                                        id="age_check"
                                        name="age_check"
                                        type="checkbox"
                                        onChange={e => checktValueHandler(e)}
                                    />
                                    <p
                                        className={
                                            checkStatus.age_check == false
                                                ? styles.false
                                                : ''
                                        }
                                    >
                                        {t('join.checkbox2.text1')}
                                    </p>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className={`${uiStyle.btn_theme_yel} ${styles.btn_join}`}
                            >
                                Join PLIKALAND
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default LoginAfterProfile;
