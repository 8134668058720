import React from 'react';
import styled from 'styled-components';
import Image from 'components/common/atoms/Image';

interface GuestThumbnailStyleProps {
    width: string;
    height: string;
    borderRadius: string;
}

export interface GuestThumbnailProps extends GuestThumbnailStyleProps {
    src: string;
    alt: string;
}

export const GuestThumbnailComponent = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-right: 16px;

    figure {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`;

const GuestThumbnail: React.FC<GuestThumbnailProps> = function ({
    src,
    alt,
    width,
    height,
    borderRadius,
}) {
    return (
        <GuestThumbnailComponent>
            <figure>
                <Image
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                    borderRadius={borderRadius}
                />
            </figure>
        </GuestThumbnailComponent>
    );
};

export default GuestThumbnail;
