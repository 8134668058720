import React, { useState, useEffect, useRef } from 'react'
import Axios from 'axios'

import '../../../styles/webview.scss'

import firstmedal from '../../../assets/ui/first-medal.png'
import secondmedal from '../../../assets/ui/second-medal.png'
import thirdmedal from '../../../assets/ui/third-medal.png'
import myrank from '../../../assets/ui/my-rank.png'

function useAxios(uuid: string | undefined) {
  const [loading, setLoading] = useState(true)
  const [webviewData, setWebviewData] = useState()
  const requestUri = process.env.REACT_APP_API_HOST + `fishes/ranking/${uuid}`

  async function fetchUrl() {
    const result = await Axios.get(requestUri)
    const json = await result.data.data

    setWebviewData(json)
    setLoading(false)
  }

  useEffect(() => {
    fetchUrl()
  }, [])

  return [webviewData, loading]
}

function RankingListWebview(props) {
  const { webviewPopupChange, uuid } = props

  useEffect(() => {
    webviewPopupChange('fishResultPopup', false)
  }, [])

  const [webviewData, loading]: any = useAxios(uuid)

  const outerH: any = useRef()
  const innerH: any = useRef()

  window.addEventListener('resize', function () {
    calcVh()
  })

  // window.addEventListener('DOMContentLoaded', event => {
  //     calcVh();
  // });

  function calcVh() {
    const outerHeight = outerH.current.clientHeight
    const innerHeight = innerH.current.offsetHeight

    // let calc = Math.max(Math.min((outerH / innerH), 1), 0);
    const calc: any = Math.max(outerHeight / innerHeight, 0)
    document.documentElement.style.setProperty('--inner-scale', calc)
  }

  //   window.onload = () => {
  //     calcVh()
  //   }

  useEffect(() => {
    if (outerH.current != undefined) {
      calcVh()
    }
  }, [loading])

  function closePopup(e) {
    if (!outerH.current.contains(e.target)) {
      webviewPopupChange('fishRankingPopup', false)
    }
  }

  if (loading) {
    return <></>
  } else {
    return (
      <div id="webview-container" className="webview-container" onClick={(e) => closePopup(e)}>
        <div
          id="webview-outer-size"
          className="webview-outer-size webview-container-outer-rankinglist"
          ref={outerH}
        >
          <div
            id="webview-container-inner"
            className="webview-container-inner webview-container-inner-rankinglist"
            ref={innerH}
            // onLoad={calcVh}
          >
            <div className="webview-header">
              <div id="webview-title" className="text-box">
                대회 순위
              </div>
              <button
                id="webview-close-btn"
                className="btn"
                onClick={() => {
                  webviewPopupChange('fishRankingPopup', false)
                }}
              ></button>
            </div>

            <div className="webview-body">
              <div className="webview-body-inner">
                <div className="content-container content-container-rankinglist">
                  {webviewData.rankingDtoList.map((data, index) => {
                    if (index == 0) {
                      return (
                        <div key={data.uuid} className="rank-list-item">
                          <figure className="my-ranking">
                            {index + 1 == webviewData.rankIndex && <img src={myrank} alt="" />}
                          </figure>
                          <figure className="medal">
                            <img src={firstmedal} alt="" />
                          </figure>
                          <div className="ranking-text">
                            <span>1</span>st
                          </div>
                          <div className="phone-text">
                            {data.phone.slice(0, data.phone.length - 4)}
                            ****
                          </div>
                          <div className="tier-text webview-pc">{data.tierName}</div>
                          <div className="name-text webview-pc">{data.name}</div>
                          <div className="webview-mobile">
                            <div className="tier-text">{data.tierName}</div>
                            <div className="name-text">{data.name}</div>
                          </div>
                          <div className="size-text">
                            {data.size}
                            <span>cm</span>
                          </div>
                          <figure className="item-img">
                            <img
                              src={process.env.REACT_APP_CDN_URL + '/' + data.fileUrl}
                              alt=""
                              className={
                                data.tierName.includes('아이언')
                                  ? 'border-iron'
                                  : data.tierName.includes('브론즈')
                                  ? 'border-bronze'
                                  : data.tierName.includes('실버')
                                  ? 'border-silver'
                                  : data.tierName.includes('골드')
                                  ? 'border-gold'
                                  : data.tierName.includes('플레티넘')
                                  ? 'border-platinum'
                                  : data.tierName.includes('다이아')
                                  ? 'border-diamond'
                                  : data.tierName.includes('마스터')
                                  ? 'border-master'
                                  : data.tierName.includes('그랜드마스터')
                                  ? 'border-grandmaster'
                                  : data.tierName.includes('레전드')
                                  ? 'border-legend'
                                  : ''
                              }
                            />
                          </figure>
                        </div>
                      )
                    } else if (index == 1) {
                      return (
                        <div key={data.uuid} className="rank-list-item">
                          <figure className="my-ranking">
                            {index + 1 == webviewData.rankIndex && <img src={myrank} alt="" />}
                          </figure>
                          <figure className="medal">
                            <img src={secondmedal} alt="" />
                          </figure>
                          <div className="ranking-text">
                            <span>2</span>nd
                          </div>
                          <div className="phone-text">
                            {data.phone.slice(0, data.phone.length - 4)}
                            ****
                          </div>
                          <div className="tier-text webview-pc">{data.tierName}</div>
                          <div className="name-text webview-pc">{data.name}</div>
                          <div className="webview-mobile">
                            <div className="tier-text">{data.tierName}</div>
                            <div className="name-text">{data.name}</div>
                          </div>
                          <div className="size-text">
                            {data.size}
                            <span>cm</span>
                          </div>
                          <figure className="item-img">
                            <img
                              src={process.env.REACT_APP_CDN_URL + '/' + data.fileUrl}
                              alt=""
                              className={
                                data.tierName.includes('아이언')
                                  ? 'border-iron'
                                  : data.tierName.includes('브론즈')
                                  ? 'border-bronze'
                                  : data.tierName.includes('실버')
                                  ? 'border-silver'
                                  : data.tierName.includes('골드')
                                  ? 'border-gold'
                                  : data.tierName.includes('플레티넘')
                                  ? 'border-platinum'
                                  : data.tierName.includes('다이아')
                                  ? 'border-diamond'
                                  : data.tierName.includes('마스터')
                                  ? 'border-master'
                                  : data.tierName.includes('그랜드마스터')
                                  ? 'border-grandmaster'
                                  : data.tierName.includes('레전드')
                                  ? 'border-legend'
                                  : ''
                              }
                            />
                          </figure>
                        </div>
                      )
                    } else if (index == 2) {
                      return (
                        <div key={data.uuid} className="rank-list-item">
                          <figure className="my-ranking">
                            {index + 1 == webviewData.rankIndex && <img src={myrank} alt="" />}
                          </figure>
                          <figure className="medal">
                            <img src={thirdmedal} alt="" />
                          </figure>
                          <div className="ranking-text">
                            <span>3</span>rd
                          </div>
                          <div className="phone-text">
                            {data.phone.slice(0, data.phone.length - 4)}
                            ****
                          </div>
                          <div className="tier-text webview-pc">{data.tierName}</div>
                          <div className="name-text webview-pc">{data.name}</div>
                          <div className="webview-mobile">
                            <div className="tier-text">{data.tierName}</div>
                            <div className="name-text">{data.name}</div>
                          </div>
                          <div className="size-text">
                            {data.size}
                            <span>cm</span>
                          </div>
                          <figure className="item-img">
                            <img
                              src={process.env.REACT_APP_CDN_URL + '/' + data.fileUrl}
                              alt=""
                              className={
                                data.tierName.includes('아이언')
                                  ? 'border-iron'
                                  : data.tierName.includes('브론즈')
                                  ? 'border-bronze'
                                  : data.tierName.includes('실버')
                                  ? 'border-silver'
                                  : data.tierName.includes('골드')
                                  ? 'border-gold'
                                  : data.tierName.includes('플레티넘')
                                  ? 'border-platinum'
                                  : data.tierName.includes('다이아')
                                  ? 'border-diamond'
                                  : data.tierName.includes('마스터')
                                  ? 'border-master'
                                  : data.tierName.includes('그랜드마스터')
                                  ? 'border-grandmaster'
                                  : data.tierName.includes('레전드')
                                  ? 'border-legend'
                                  : ''
                              }
                            />
                          </figure>
                        </div>
                      )
                    } else {
                      return (
                        <div key={data.uuid} className="rank-list-item">
                          <figure className="my-ranking">
                            {index + 1 == webviewData.rankIndex && <img src={myrank} alt="" />}
                          </figure>

                          <figure className="medal"></figure>
                          <div className="ranking-text">
                            <span>{index + 1}</span>
                          </div>
                          <div className="phone-text">
                            {data.phone.slice(0, data.phone.length - 4)}
                            ****
                          </div>
                          <div className="tier-text webview-pc">{data.tierName}</div>
                          <div className="name-text webview-pc">{data.name}</div>
                          <div className="webview-mobile">
                            <div className="tier-text">{data.tierName}</div>
                            <div className="name-text">{data.name}</div>
                          </div>
                          <div className="size-text">
                            {data.size}
                            <span>cm</span>
                          </div>
                          <figure className="item-img">
                            <img
                              src={process.env.REACT_APP_CDN_URL + '/' + data.fileUrl}
                              alt=""
                              className={
                                data.tierName.includes('아이언')
                                  ? 'border-iron'
                                  : data.tierName.includes('브론즈')
                                  ? 'border-bronze'
                                  : data.tierName.includes('실버')
                                  ? 'border-silver'
                                  : data.tierName.includes('골드')
                                  ? 'border-gold'
                                  : data.tierName.includes('플레티넘')
                                  ? 'border-platinum'
                                  : data.tierName.includes('다이아')
                                  ? 'border-diamond'
                                  : data.tierName.includes('마스터')
                                  ? 'border-master'
                                  : data.tierName.includes('그랜드마스터')
                                  ? 'border-grandmaster'
                                  : data.tierName.includes('레전드')
                                  ? 'border-legend'
                                  : ''
                              }
                            />
                          </figure>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RankingListWebview
