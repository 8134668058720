import React from 'react';
import styled from 'styled-components';
import Image from 'components/common/atoms/Image';

interface ButtonItemProps {
    label: string;
    onClickEvent?: any;
    disabled?: boolean;
}

interface buttonProps {
    disabled?: any;
}

const ButtonItemComponent = styled.div<buttonProps>`
    padding-left: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    text-align: left;
    box-sizing: border-box;
    color: ${({disabled}) => (disabled ? '#CCCCCC' : '#000000')};

    &:hover {
        background-color: ${({disabled}) => (disabled ? '' : '#FEE500;')};
        cursor: ${({disabled}) => (disabled ? '' : 'pointer')};
    }
`;

const ButtonItem: React.FC<ButtonItemProps> = function ({
    label,
    disabled,
    onClickEvent,
}) {
    return (
        <ButtonItemComponent disabled={disabled} onClick={onClickEvent}>
            {label}
        </ButtonItemComponent>
    );
};

export default ButtonItem;
