import React, {useRef, useState} from 'react';
import styled from 'styled-components';

import Text from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import InputWithTitle from 'components/ui/InputWithTitle';
import Button from 'components/atoms/Button';

const LockScreenWebviewComponent = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
`;

const Modal = styled.div`
    background-color: white;
    width: 360px;
    height: 370px;
    border-radius: 20px;
    padding: 33px 30px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    gap: 52px;
`;

const OkButton = styled(Button)`
    width: 140px;
    height: 40px;
    border-radius: 20px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const PasswordWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const InputWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

interface TextWrapProps {
    toggle: any;
}

const TextWrap = styled.div<TextWrapProps>`
    visibility: ${({toggle}: any) => (toggle ? 'visible' : 'hidden')};
`;

const LockScreenWebview = ({
    onClose,
    passwordAt,
    passwordProp,
    sendMsgWithParam,
}: any) => {
    const webviewInner: any = useRef(null);

    function closePopup(e: any) {
        if (!webviewInner.current.contains(e.target)) {
            onClose();
        }
    }

    const [wrongPassword, setWrongPassword]: any = useState(null);

    const inputValueHandler = (e: any) => {
        setWrongPassword(false);
    };

    function onSubmitHandler(e: any) {
        e.preventDefault();
        const {password} = e.target;

        if (password.value == passwordProp) {
            sendMsgWithParam('Webreceiver', 'OpenPrivateDoor');
            onClose();
        } else {
            setWrongPassword(true);
        }
    }

    return (
        <LockScreenWebviewComponent onPointerDown={e => closePopup(e)}>
            <Modal ref={webviewInner}>
                <Header>
                    <Text size={1.8} weight={700}>
                        {passwordAt}
                    </Text>
                    <Button
                        type="button"
                        bgColor="white"
                        hoveredBgColor="#cccccc"
                        onClick={() => onClose()}
                    >
                        <Icon type="close" size={30} />
                    </Button>
                </Header>
                <form onSubmit={e => onSubmitHandler(e)}>
                    <PasswordWrap>
                        <Text size={1.2} weight={700}>
                            비밀번호를 입력해주세요
                        </Text>
                        <InputWrap>
                            <InputWithTitle
                                title="Password"
                                name="password"
                                placeholder="Password"
                                onChange={inputValueHandler}
                                autoFocus={true}
                            ></InputWithTitle>
                            <TextWrap toggle={wrongPassword}>
                                <Text size={0.8} weight={400} color="#EE3030">
                                    잘못된 비밀번호 입니다
                                </Text>
                            </TextWrap>
                        </InputWrap>
                        <ButtonWrap>
                            <OkButton
                                type="submit"
                                bgColor="#FEE500"
                                hoveredBgColor="#FFF280"
                            >
                                <Text size={1.5} weight={700}>
                                    OK
                                </Text>
                            </OkButton>
                        </ButtonWrap>
                    </PasswordWrap>
                </form>
            </Modal>
        </LockScreenWebviewComponent>
    );
};

export default LockScreenWebview;
