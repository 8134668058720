import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from '../styles/login.module.scss';

import logo from '../assets/img/plika_logo.png';
import googleButton from '../assets/img/google_btn.png';
import kakaoButton from '../assets/img/kakao_btn.png';

function Login() {
    const {t} = useTranslation();

    return (
        <>
            <div className={styles.login_container}>
                <div className={styles.login_bg_box}>
                    <video autoPlay loop muted>
                        <source
                            src={
                                process.env.REACT_APP_CDN_URL +
                                '/static/video/web/login_video.mp4'
                            }
                        />
                    </video>
                </div>
                <div className={`${styles.login_menu_box} ${styles.menu_box}`}>
                    <div
                        className={`${styles.login_menu_box_inner} ${styles.menu_box_inner}`}
                    >
                        <figure className={styles.logo_box}>
                            <img src={logo} alt="" />
                        </figure>
                        <div className={styles.text_box}>
                            <p className={styles.text__1}>
                                <span>{t('login.styled_title1')}</span>
                                {t('login.title1')}
                            </p>
                            <p className={styles.text__1}>
                                {t('login.title2')}
                                <span>{t('login.styled_title2')}</span>
                                {t('login.sub_title2')}
                            </p>
                            <p className={styles.text__1}>
                                {t('login.title3')}
                            </p>
                            <p className={styles.text__2}>
                                {t('login.text1')}
                                <br /> {t('login.text2')}
                            </p>
                        </div>
                        <div className={styles.login_btn_box}>
                            <a
                                className={styles.kakao_btn}
                                href={
                                    process.env.REACT_APP_API_HOST +
                                    'auth/authorization/kakao?redirect_uri=' +
                                    process.env.REACT_APP_HOST +
                                    'auth/redirect'
                                }
                            >
                                <img src={kakaoButton} alt="Google Signin" />
                                <span> {t('login.button1')}</span>
                            </a>
                            <a
                                className={styles.google_btn}
                                href={
                                    process.env.REACT_APP_API_HOST +
                                    'auth/authorization/google?redirect_uri=' +
                                    process.env.REACT_APP_HOST +
                                    'auth/redirect'
                                }
                            >
                                <img src={googleButton} alt="Google Signin" />
                                <span> {t('login.button2')}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
