import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faXmark, faLink } from '@fortawesome/free-solid-svg-icons'
import styles from '../../../styles/webview.module.scss'
import uiStyle from '../../../styles/ui.module.scss'
import linkIcon from '../../../assets/ui/link_icon.png'
import Rectangle from '../../../assets/ui/Rectangle.png'
import defaultImg from 'assets/ui/content_default.png'
import styled from 'styled-components'
import { LinkIcon } from '../../../static/svg'
import { useMediaQuery } from 'react-responsive'
import { Document, Page } from 'react-pdf'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { IconChevronLeft, IconChevronRight } from 'components/vectors'
import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function ContentShowWebview(props: any) {
  const { webviewData, param, contentType, onClose } = props
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const videoRef: any = useRef()
  const pdfRef: any = useRef()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [pageNum, setPageNum] = useState(1)
  const [pages, setPages] = useState(0)

  function onPdfDocumentLoadSuccess({ numPages }) {
    setPages(numPages)
  }

  const webviewDataTag = {
    IMAGE: (
      <img
        src={webviewData.fileInfoDtos[0] != null ? webviewData.fileInfoDtos[0].fileUrl : defaultImg}
        alt=""
      />
    ),
    VIDEO: (
      <video
        autoPlay
        muted
        loop
        ref={videoRef}
        controls={webviewData.fileInfoDtos[0] != null ? true : false}
        src={webviewData.fileInfoDtos[0] != null ? webviewData.fileInfoDtos[0].fileUrl : null}
        poster={webviewData.fileInfoDtos[0] == null ? Rectangle : undefined}
      ></video>
    ),
    YOUTUBE: (
      <iframe
        className={webviewData.type == 'YOUTUBE' ? '' : 'blind'}
        width="100%"
        height="100%"
        src={webviewData.youtubeUri}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    ),
    // PDF: (
    //   <Document
    //     ref={pdfRef}
    //     file={webviewData.fileInfoDtos[0] != null ? webviewData.fileInfoDtos[0].fileUrl : null}
    //     onLoadSuccess={onPdfDocumentLoadSuccess}
    //     className={`relative w-full h-full overflow-hidden max-h-max ${
    //       contentType.pdf ? 'block' : 'hidden'
    //     }`}
    //   >
    //     <Wrapper ref={wrapperRef}>
    //       <TransformWrapper initialScale={1} minScale={1} maxScale={10}>
    //         <TransformComponent>
    //           <Page
    //             // canvasRef={canvasRef}
    //             pageNumber={pageNum}
    //             width={840}
    //             height={640}
    //             renderAnnotationLayer={false}
    //             // className="w-full h-full min-w-full min-h-full"
    //           />
    //         </TransformComponent>
    //       </TransformWrapper>
    //       <div className="absolute z-20 flex items-center gap-4 px-2 py-1 text-white rounded-full bg-slate-900/50 shadow-86 bottom-4 min-h-max left-2/4 -translate-x-2/4 ">
    //         <button type="button" onClick={() => (pageNum > 1 ? setPageNum(pageNum - 1) : null)}>
    //           <IconChevronLeft className="w-4 h-auto" />
    //         </button>
    //         <p>
    //           {pageNum} / {pages}
    //         </p>
    //         <button
    //           type="button"
    //           onClick={() => (pageNum < pages ? setPageNum(pageNum + 1) : null)}
    //         >
    //           <IconChevronRight className="w-4 h-auto" />
    //         </button>
    //       </div>
    //     </Wrapper>
    //   </Document>
    // ),
  }

  function webviewDataComponent() {
    const type: 'IMAGE' | 'VIDEO' | 'YOUTUBE' = webviewData.type
    return <>{webviewDataTag[type]}</>
  }

  const [autoplay, setAutoplay] = useState(false)

  const addPlaying = () => {
    const videoEl = videoRef.current

    if (videoEl != null) {
      if (videoEl.autoplay == false) {
        videoEl.autoplay = true
      }

      setAutoplay(videoEl.autoplay)

      videoEl.play()
    }
  }

  useEffect(() => {
    addPlaying()
  }, [autoplay])

  useEffect(() => {
    console.log('webviewData', webviewData)
  }, [])

  const handleVisibilityChange = () => {
    const videoElement = videoRef.current
    if (videoElement != null) {
      if (document.hidden) {
        videoElement.pause()
      } else {
        videoElement.play()
      }
    }
  }

  useEffect(() => {
    if (videoRef) {
      document.addEventListener('visibilitychange', handleVisibilityChange, false)
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange, false)
      }
    }
  })

  return (
    <>
      <div className={styles.inner_box}>
        <div className={styles.title_bar}>
          <div className={styles.title}>{webviewData.name}</div>
          <div className={styles.close_btn} onClick={onClose}>
            <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
          </div>
        </div>
        <div className={styles.content_body}>
          <div className={styles.content_thumb_box}>
            <figure className={styles.content_box}>
              {webviewDataComponent()}
              <Document
                ref={pdfRef}
                file={
                  webviewData.fileInfoDtos[0] != null ? webviewData.fileInfoDtos[0].fileUrl : null
                }
                onLoadSuccess={onPdfDocumentLoadSuccess}
                className={`relative w-full h-full overflow-hidden max-h-max ${
                  webviewData.fileInfoDtos[0] != null
                    ? webviewData.fileInfoDtos[0]?.fileExt === 'pdf'
                      ? 'block'
                      : 'hidden'
                    : 'hidden'
                }`}
              >
                <Wrapper ref={wrapperRef}>
                  <TransformWrapper initialScale={1} minScale={1} maxScale={10}>
                    <TransformComponent>
                      <Page
                        // canvasRef={canvasRef}
                        pageNumber={pageNum}
                        width={840}
                        height={640}
                        renderAnnotationLayer={false}
                        // className="w-full h-full min-w-full min-h-full"
                      />
                    </TransformComponent>
                  </TransformWrapper>
                  <div className="absolute z-20 flex items-center gap-4 px-2 py-1 text-white rounded-full bg-slate-900/50 shadow-86 bottom-4 min-h-max left-2/4 -translate-x-2/4 ">
                    <button
                      type="button"
                      onClick={() => (pageNum > 1 ? setPageNum(pageNum - 1) : null)}
                    >
                      <IconChevronLeft className="w-4 h-auto" />
                    </button>
                    <p>
                      {pageNum} / {pages}
                    </p>
                    <button
                      type="button"
                      onClick={() => (pageNum < pages ? setPageNum(pageNum + 1) : null)}
                    >
                      <IconChevronRight className="w-4 h-auto" />
                    </button>
                  </div>
                </Wrapper>
              </Document>
            </figure>
            {isMobile === false && webviewData.isLinkOpen && (
              <LinkButton>
                {webviewData.isLinkOpen && (
                  <a href={webviewData.linkUrl} target="_blank" rel="noopener noreferrer">
                    <LinkIcon />
                    <span>{webviewData.linkName}</span>
                  </a>
                )}
              </LinkButton>
            )}
          </div>
          <div className={styles.content_desc}>
            <pre>{webviewData.desc}</pre>
          </div>
        </div>
        {isMobile && webviewData.isLinkOpen && (
          <LinkButton>
            {webviewData.isLinkOpen && (
              <a href={webviewData.linkUrl} target="_blank" rel="noopener noreferrer">
                <LinkIcon />
                <span>{webviewData.linkName}</span>
              </a>
            )}
          </LinkButton>
        )}
      </div>
    </>
  )
}

const LinkButton = styled.button`
  width: max-content;
  height: 40px;
  background: #1e69cb;
  display: flex;
  align-items: center;
  padding: 0 18px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  transition: all 0.3s;
  border-radius: 36px;

  &:hover {
    background: #74b0ff;
  }

  a {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .react-pdf__Page {
    max-width: auto !important;
    max-height: calc(100% - 60px) !important;
  }

  .textLayer {
    display: none;
  }

  canvas {
    /* width: 100% !important;*/
    height: 640px !important;
    object-fit: contain !important;
  }
`

export default ContentShowWebview
