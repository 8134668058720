import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';

import Text, {TextComponent} from 'components/common/atoms/Text';
import Button from 'components/common/atoms/Button';

interface MenuHeaderProps {
    title: string;
    callBack: any;
}

export const MenuHeaderComponent = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    height: 60px;
    margin: 0 15px;
    border-bottom: 1px solid #fff;

    box-sizing: border-box;

    ${TextComponent} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const MenuHeader: React.FC<MenuHeaderProps> = function ({title, callBack}) {
    return (
        <MenuHeaderComponent>
            <Text size={24} weight={700} color="#FFF">
                {title}
            </Text>
            <Button type="icon" onClick={callBack}>
                <FontAwesomeIcon icon={faXmark} />
            </Button>
        </MenuHeaderComponent>
    );
};

export default MenuHeader;
