import React from 'react';
import styled from 'styled-components';
import {BiHelpCircle} from 'react-icons/bi';
import uiStyle from '../../styles/ui.module.scss';
import {useTranslation} from 'react-i18next';

function TextareaWithTitle(props) {
    const {
        title,
        maxlength,
        name,
        value,
        onChange,
        alert,
        placeholder,
        helpType,
    } = props;

    const {t} = useTranslation();

    const helpMessage = {
        introduction: [
            `${t('create.help.introduction.text1')}`,
            `${t('create.help.introduction.text2')}`,
        ],
    };

    return (
        <div className={uiStyle.input_title_container}>
            <StyledLabel>
                <div className={uiStyle.title_box}>{title}</div>
                {helpType && (
                    <HelpBox>
                        <IconHelpCircle />
                        <div>
                            {helpMessage[helpType].map((el, i) => (
                                <p key={i}>{el}</p>
                            ))}
                        </div>
                    </HelpBox>
                )}
            </StyledLabel>
            <textarea
                name={name}
                placeholder={placeholder}
                maxLength={maxlength}
                className={
                    alert
                        ? `${uiStyle.input_round} ${uiStyle.alert}`
                        : uiStyle.input_round
                }
                value={value}
                onChange={onChange}
            ></textarea>
        </div>
    );
}

const StyledLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const HelpBox = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    div {
        display: none;
        position: absolute;
        width: 210px;
        height: 90px;
        left: 0px;
        top: 24px;

        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        transition: 0.3s;
        padding: 15px;
        z-index: 10;

        flex-direction: column;

        p {
            font-size: 12px;
        }
    }

    &:hover div {
        display: flex;
    }

    &:hover svg {
        color: #000;
    }
`;

const IconHelpCircle = styled(BiHelpCircle)`
    font-size: 24px;
    color: #ccc;
    cursor: pointer;
    transition: all 0.3s;
`;

export default TextareaWithTitle;
