import * as React from 'react'
import { SVGProps } from 'react'
const SvgIconUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.032 17.88a1 1 0 0 1 1.218-.72A5 5 0 0 1 27 21.999V24a1 1 0 1 1-2 0v-2s0 .001 0 0a3.001 3.001 0 0 0-2.25-2.902 1 1 0 0 1-.718-1.218ZM4.464 18.465A5 5 0 0 1 8 17h8a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.535ZM18.032 5.881a1 1 0 0 1 1.216-.72 5 5 0 0 1 0 9.687 1 1 0 0 1-.496-1.938 3 3 0 0 0 0-5.812 1 1 0 0 1-.72-1.217ZM12 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIconUsers
