import axios from 'axios';
import {deleteCookie} from '../utils/CookieUtil';
axios.defaults.withCredentials = true;

async function verifyToken(accessToken: any) {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const response = await axios.get(
        process.env.REACT_APP_API_HOST + 'v1/auth/refresh',
        config,
    );

    return response;
}

function isValidToken(token: string) : boolean {
    
    if ( token.includes('.') ) {
        if ( token.split('.').length === 3 ) {
            const encodeTokenPayload = token.split('.')[1];
            try {
                const decodePayload: any = JSON.parse(atob(encodeTokenPayload));
                if ( decodePayload.exp !== undefined ) {
                    if (typeof decodePayload.exp === "number") {
                        return true;
                    }
                }
            } catch( e ) {
                return false;
            }
        }
    }

    return false;
}

const axiosI = axios.create();
const cancelToken = axios.CancelToken;
const source = cancelToken.source();
axiosI.interceptors.request.use(
    async function (config) {
        // 토큰 꺼내오기
        const originAccessToken = window.localStorage.getItem('plikaTk');

        // 토큰 남은시간 확인
        if (originAccessToken !== null) {

            if(isValidToken(originAccessToken)){
                const base64Payload = originAccessToken.split('.')[1];
                const payload: any = JSON.parse(atob(base64Payload));
    
                const date1 = new Date(0);
                date1.setUTCSeconds(payload.exp);
    
                const date2 = new Date();
    
                const expDate = date1.getTime();
                const nowDate = date2.getTime();
    
                const elapsedMSec = expDate - nowDate;
                const elapsedMin = elapsedMSec / (1000 * 60);
    
                if (elapsedMin > 0.1) {
                    config.headers = {
                        Authorization: `Bearer ${originAccessToken}`,
                        Accept: 'application/json',
                    };
    
                    return config;
                }

                const resultCode = await verifyToken(originAccessToken);

                // FIXME : 추후 백엔드 응답코드에 따라 처리합니다.
                if (resultCode.data.resp_code == 'OK') {
                    const accessToken = window.localStorage.getItem('plikaTk');

                    window.localStorage.setItem('plikaTk', '');

                    window.localStorage.setItem('plikaTk', resultCode.data.data);

                    config.headers = {
                        Authorization: `Bearer ${resultCode.data.data}`,
                        Accept: 'application/json',
                    };

                    return config;
                }
                
            } else {
                window.localStorage.removeItem('plikaTk');
                axios
                    .delete(process.env.REACT_APP_API_HOST + 'auth/cookies')
                    .then(() => {
                        window.location.reload();
                    });
            }

           
        }

        if (originAccessToken === null) {
            return config;
        }

        

        return config;
    },
    function (error) {

        //!!

        return Promise.reject(error);
    },
);

axiosI.interceptors.response.use(
    function (response) {
        return response;
    },

    async function (error) {

        if(error.response != null) {
             if (error.response.status === 403) {
                window.localStorage.removeItem('plikaTk');
                axios
                    .delete(process.env.REACT_APP_API_HOST + 'auth/cookies')
                    .then(() => {
                        window.location.reload();
                    })
                    .catch(err => {
                        window.location.replace("/");
                    });
            } 
        } else {
            window.localStorage.removeItem('plikaTk');
            axios
                .delete(process.env.REACT_APP_API_HOST + 'auth/cookies')
                .then(() => {
                    window.location.reload();
                })
                .catch(err => {
                    window.location.replace("/");
            });
        }
        
        

        return Promise.reject(error);
    },
);

export default axiosI;

export {cancelToken, source};
