import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm, Resolver } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { LogoPlikalandBetaClear } from 'components/vectors'
import { getSpaceIdName, getSpaceInfo, useSettings } from './hooks/useSettings'
import Elevator from './Elevator'
import { AccountType, useAccount } from 'contexts/AccountContext'
import { getAccount, updateAccount } from 'components/settings/hooks/useSettings'
import SvgLogoGangnamMetaHub from 'components/vectors/LogoGangnamMetaHub'
import logo from 'assets/img/logo_gangnam_meta_hub.png'

type FormValues = {
  email: string | undefined
  nickname: string | undefined
  terms: boolean
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email('이메일 형식이 올바르지 않습니다.')
    .required('이메일은 필수 입력입니다.'),
  nickname: yup.string().required(),
  terms: yup.boolean().oneOf([true], '약관에 동의해주세요'),
})

export default function Settings() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
    reset,
    setValue,
  } = useForm<FormValues>({ resolver: yupResolver(schema), mode: 'onChange' })

  const { setStoredInfoData, getAccount } = useSettings()

  const onSubmit = handleSubmit((data) => {
    console.log(data)
    setStoredInfoData(spaceInfoState.portals, data.nickname, data.email)
    updateAccount(data.nickname).then((e) => {
      window.location.href = `/app/${spaceInfoState.idName}`
    })
  })

  const { serviceName } = useParams()

  const [accountInfoState, setAccountInfoState] = useState<AccountType | undefined>(undefined)

  // TODO: 나중에 컴포넌트로 분리
  // settingTemplate == 0 일때는 썸네일 이미지만
  // settingTemplate == 1 일때는 썸네일 이미지 + 텍스트 이미지

  const [spaceInfoState, setSpaceInfoState] = useState({
    name: '',
    id: 0,
    idName: '',
    portals: [],
    sceneInfoDtos: [
      {
        templateInfoDto: {
          thumbnail2: '',
          settingTemplate: null,
        },
      },
    ],
  })

  useEffect(() => {
    reset()
    getSpaceIdName(serviceName).then((e) => {
      getSpaceInfo(e).then((e) => {
        setSpaceInfoState(e)
      })
    })
    getAccount().then((e) => {
      setAccountInfoState(e)
    })
  }, [])

  useEffect(() => {
    setValue('email', accountInfoState?.email)
    setValue('nickname', accountInfoState?.nickname)
  }, [accountInfoState])

  return (
    <div className="relative w-screen h-screen overflow-hidden">
      <img
        className="object-cover w-full h-full"
        src={spaceInfoState.sceneInfoDtos[0].templateInfoDto.thumbnail2}
        alt="Space Thumbnail"
      />
      {spaceInfoState.sceneInfoDtos[0].templateInfoDto.settingTemplate == 1 && (
        <img
          className="absolute w-[617px] h-[180px] top-20 left-2/4 -translate-x-2/4"
          src={logo}
          alt="Gangnam Meta Hub"
        />
      )}
      <div className="absolute inset-0 flex items-center justify-center w-full">
        <form
          className="w-full mx-2 bg-white sm:max-w-md p-7 rounded-xl shadow-86"
          onSubmit={onSubmit}
        >
          <h3 className="mb-6 text-xl font-semibold md:text-2xl text-slate-900">
            {spaceInfoState && spaceInfoState.name}
          </h3>

          <div className="flex">
            <label className="block mb-2 text-sm font-medium text-slate-900" htmlFor="email">
              E-mail*
            </label>
            {errors.email ? (
              <small className="pl-2 text-red-500" role="alert">
                {errors.email.message}
              </small>
            ) : null}
          </div>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5"
            id="email"
            type="email"
            readOnly={accountInfoState?.email ? true : false}
            placeholder="이메일을 입력해 주세요"
            aria-invalid={!isDirty ? undefined : errors.email ? 'true' : 'false'}
            {...register('email', {
              required: '이메일은 필수 입력입니다.',
              pattern: { value: /\S+@\S+\.\S+/, message: '이메일 형식이 올바르지 않습니다.' },
            })}
            required
          />

          <div className="flex items-start mt-1 mb-6">
            <div className="flex items-center h-5">
              <input
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                id="terms"
                type="checkbox"
                aria-invalid={!isDirty ? undefined : errors.terms ? 'true' : 'false'}
                {...register('terms', { required: true })}
              />
            </div>
            <label
              htmlFor="terms"
              className="flex flex-col ml-2 text-sm font-medium text-slate-900 "
            >
              개인정보 수집 및 마케팅 활용에 동의합니다.
              <div className="flex gap-1">
                <Link
                  to="/privacy"
                  target="_blank"
                  className="text-sm text-primary-100 hover:underline "
                >
                  개인정보처리방침
                </Link>
                <Link
                  to="/terms"
                  target="_blank"
                  className="text-sm text-primary-100 hover:underline "
                >
                  이용약관
                </Link>
              </div>
            </label>
          </div>

          <label className="block mb-2 text-sm font-medium text-slate-900" htmlFor="nickname">
            Nick name
          </label>
          <input
            className="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5"
            type="text"
            id="nickname"
            placeholder="닉네임을 입력해 주세요"
            aria-invalid={!isDirty ? undefined : errors.nickname ? 'true' : 'false'}
            {...register('nickname', { required: true })}
          />
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="inline-flex justify-center px-12 py-3 font-semibold duration-500 border border-transparent rounded-full text-slate-900 shadow-86 sm:text-xl bg-secondary-100 hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-yellow-200 focus:ring-offset-2"
              disabled={isSubmitting}
            >
              Enter
            </button>
          </div>
        </form>
      </div>

      <LogoPlikalandBetaClear className="absolute h-auto text-slate-900 bottom-7 -translate-x-2/4 left-2/4 w-52" />
    </div>
  )
}
