import { useQueryClient, useQuery } from '@tanstack/react-query'

import { axiosInstance } from 'axiosInstance'
import { useEffect, useState } from 'react'
import { spaceKeys } from 'react-query/constants'
import { Land } from 'types'

async function getAllLands(selected): Promise<Land[]> {
  const { data } = await axiosInstance.get(
    `api/metaverse/lands/order/${selected === '최신순' ? 'regDt' : 'member'}`,
  )
  return data.data.lands
}

export function useAllLands() {
  const options = ['최신순', '접속자순']
  const [selected, setSelected] = useState(options[0])
  const [limit, setLimit] = useState(16)

  const [page, setPage] = useState(1)
  const offset = (page - 1) * limit

  const queryClient = useQueryClient()

  const fallback: Land[] = []
  const { data: allLands = fallback } = useQuery({
    queryKey: [`${selected === '최신순' ? 'recentLands' : 'liveLands'}`],
    queryFn: () => getAllLands(selected),
    onSuccess: (data) => {
      data.slice(offset, offset + limit).map((land: Land) => {
        queryClient.setQueryData(spaceKeys.detail(land.spaceId), land)
      })
    },
  })

  return {
    allLands,
    setSelected,
    selected,
    options,
    offset,
    page,
    setPage,
    limit,
    setLimit,
  }
}
