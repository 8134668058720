import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import en from './locales/en';
import ko from './locales/ko';

const defaultLanguage = 'ko';
const supportedLanguages = [
    {code: 'en', name: 'en'},
    {code: 'ko', name: 'ko'},
];

i18n.use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        resources: {
            en: en,
            ko: ko,
        },

        supportedLngs: supportedLanguages.map(lang => lang.code),

        nonExplicitSupportedLngs: true,

        fallbackLng: defaultLanguage,
        ns: ['page'],
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
