import React, { useEffect, useRef, useState } from 'react'
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Router,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import styled from 'styled-components'

import axiosI from '../../../utils/AxiosI'
import plikaLogoBlack from '@ui/plika_logo_black.png'
import logout from '@ui/log_out.png'
import linkIcon from '@ui/link_icon.png'
import Modal from 'components/LogoMenu/organisms/Modal'
import Notice from 'components/atoms/Notice'

import LinkIcon from '../../../static/svg/icon_link.svg'
import LogoIcon from '../../../static/svg/icon_logo.svg'
import LeaveIcon from '../../../static/svg/icon_leave.svg'
import media from '../../../lib/styles/media'
import HelpCircleIcon from '../../../static/svg/icon_help_circle.svg'
import UserCircleIcon from '../../../static/svg/icon_user_circle.svg'
import UserCircleMinusIcon from '../../../static/svg/icon_user_circle_minus.svg'

const LogoMenuComponent = styled.div`
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  width: 150px;
  bottom: 70px;
  right: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  z-index: 100;

  ${media.medium} {
    top: 70px;
    right: 0;
    left: 0;
    width: 100%;
    bottom: auto;
    border-radius: 0;
  }

  ${media.small} {
    top: 50px;
  }
`

const DropDownMenu = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`

function LogoMenu(props: any) {
  const navigate = useNavigate()

  const {
    accountInfo,
    logoMenuBtnRef,
    menuPopupChange,
    sendMsgWithParam,
    webviewPopupChange,
    copyLink,
    logoutModalPopup,
    modalClose,
  } = props

  // const [copyLinkNotice, setCopyLinkNotice]: any = useState();

  // useEffect(() => {
  //     if (copyLinkNotice) {
  //         setTimeout(() => {
  //             setCopyLinkNotice(false);
  //         }, 3000);
  //     }
  // }, [copyLinkNotice]);

  // function copyLink() {
  //     navigator.clipboard.writeText(window.document.location.href);
  //     setCopyLinkNotice(true);
  // }

  const logoMenuRef: any = useRef()

  function closeLogoMenuPopup(e: any) {
    if (!logoMenuRef.current.contains(e.target) && !logoMenuBtnRef.current.contains(e.target)) {
      sendMsgWithParam('Webreceiver', 'ContentSwitch', 1)
      menuPopupChange('logoMenuPopup', false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', closeLogoMenuPopup)
    return () => {
      window.removeEventListener('click', closeLogoMenuPopup)
    }
  }, [])

  // const [logoutModal, setLogoutModal]: any = useState();

  // const logoutModalPopup = () => {
  //     sendMsgWithParam('Webreceiver', 'ContentSwitch', 0);
  //     setLogoutModal(true);
  // };

  // function userMenuLogout() {
  //     const accessToken = window.localStorage.getItem('plikaTk');
  //     const config = {
  //         headers: {Authorization: `Bearer ${accessToken}`},
  //     };
  //     axiosI
  //         .put(process.env.REACT_APP_API_HOST + 'v1/auth/refresh', config)
  //         .then(e => {
  //             window.localStorage.removeItem('plikaTk');
  //             window.location.href = '/';
  //         })
  //         .catch(err => {
  //             console.log(err);
  //         });
  // }

  const url = useLocation()
  function userMenuLogin() {
    window.localStorage.setItem('login_redirect_url', url.pathname)
    window.location.replace('/login')
  }

  const modalData: any = {
    home: {
      title: 'Home',
      onClickEvent: () => window.location.replace('/'),
      icon: LogoIcon,
    },
    copyLink: {
      title: 'Copy Link',
      onClickEvent: () => copyLink(),
      icon: LinkIcon,
    },
    logout: {
      title: 'Log out',
      onClickEvent: () => logoutModalPopup(),
      icon: UserCircleMinusIcon,
    },
    login: {
      title: 'Log In',
      onClickEvent: () => userMenuLogin(),
      icon: UserCircleIcon,
    },
    leaveLand: {
      title: 'Leave Land',
      onClickEvent: () => webviewPopupChange('exitPopup', true),
      icon: LeaveIcon,
    },
    helpCenter: {
      title: ' Help Center',
      onClickEvent: () =>
        window.open(
          'https://plika.notion.site/PLIKALAND-Help-Center-90b487e2eff949a198e246c71fe8f923',
        ),
      icon: HelpCircleIcon,
    },
  }

  return (
    <>
      <LogoMenuComponent ref={logoMenuRef}>
        <Modal
          accountInfo={accountInfo}
          modalData={modalData}
          modalClose={modalClose}
          menuPopupChange={menuPopupChange}
        />
      </LogoMenuComponent>
    </>
  )
}

export default LogoMenu
