import React from 'react'
import styled from 'styled-components'
import Text from 'components/atoms/Text'

interface GuidePopupStyleProps {
  bgColor?: string
}

interface GuidePopupProps extends GuidePopupStyleProps {
  innerText: string
}

interface GuideProps {
  bgColor?: any
}

const GuidePopupComponent = styled.div<GuideProps>`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;

  padding: 0px;
  background: ${({ bgColor }) => (bgColor ? bgColor : 'white')};
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 2.4rem;

  padding: 1.5rem;
  text-align: center;
  word-break: keep-all;
  word-wrap: break-word;

  /* &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 10px 28px;
        border-color: ${({ bgColor }) => (bgColor ? bgColor : 'white')} transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -28px;
        left: 64px;
        
    } */
`

const GuidePopup = ({ innerText, ...style }: GuidePopupProps) => {
  return (
    <GuidePopupComponent {...style}>
      <p className="text-lg font-medium text-slate-900">{innerText}</p>
    </GuidePopupComponent>
  )
}

export default GuidePopup
