import React, {
    useRef,
    useState,
    useCallback,
    useEffect,
    ChangeEvent,
} from 'react';
import Axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';

import '../styles/webglupload.scss';
import {IFileTypes} from '../types';

const WebglUpload = () => {
    // 드래그 중일때와 아닐때의 스타일을 구분하기 위한 state 변수
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const [files, setFiles] = useState<IFileTypes[]>([]);

    const onChangeFiles = useCallback(
        (e: ChangeEvent<HTMLInputElement> | any): void => {
            let selectFiles: File[] = [];
            let tempFiles: IFileTypes[] = files;
            // temp 변수를 이용하여 선택했던 파일들을 담습니다.

            // 드래그 했을 때와 안했을 때 가리키는 파일 배열을 다르게 해줍니다.
            if (e.type === 'drop') {
                // 드래그 앤 드롭 했을때
                selectFiles = e.dataTransfer.files;
            } else {
                // "파일 첨부" 버튼을 눌러서 이미지를 선택했을때
                selectFiles = e.target.files;
            }

            for (const file of selectFiles) {
                // 스프레드 연산자를 이용하여 기존에 있던 파일들을 복사하고, 선택했던 파일들을 append 해줍니다.
                tempFiles = [
                    ...tempFiles,
                    {
                        id: fileId.current++, // fileId의 값을 1씩 늘려주면서 각 파일의 고유값으로 사용합니다.
                        fileObj: file,
                    },
                ];
            }

            setFiles(tempFiles);
        },
        [files],
    ); // 위에서 선언했던 files state 배열을 deps에 넣어줍니다.

    // 각 선택했던 파일들의 고유값 id
    const fileId = useRef<number>(0);

    const dragRef = useRef<HTMLDivElement | null>(null);

    const dragOnTextRef = useRef<HTMLDivElement | null>(null);

    const inputChange = useCallback(
        (e: ChangeEvent): void => {
            e.preventDefault();
            e.stopPropagation();

            onChangeFiles(e);
        },
        [onChangeFiles],
    );

    const handleDragIn = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragOut = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(false);
        if (dragRef.current !== null) {
            dragRef.current.style.borderColor = '#333333';
        }
    }, []);

    const handleDragOver = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer!.files) {
            setIsDragging(true);
            if (dragRef.current !== null) {
                dragRef.current.style.borderColor = '#1E69CB';
            }
        }
    }, []);

    const handleDrop = useCallback(
        (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();

            onChangeFiles(e);
            setIsDragging(false);
            if (dragRef.current !== null) {
                dragRef.current.style.borderColor = '#333333';
            }
        },
        [onChangeFiles],
    );

    const initDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener('dragenter', handleDragIn);
            dragRef.current.addEventListener('dragleave', handleDragOut);
            dragRef.current.addEventListener('dragover', handleDragOver);
            dragRef.current.addEventListener('drop', handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop, inputChange]);

    const resetDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener('dragenter', handleDragIn);
            dragRef.current.removeEventListener('dragleave', handleDragOut);
            dragRef.current.removeEventListener('dragover', handleDragOver);
            dragRef.current.removeEventListener('drop', handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    useEffect(() => {
        initDragEvents();

        return () => resetDragEvents();
    }, [initDragEvents, resetDragEvents]);

    const handleFilterFile = useCallback(
        (id: number): void => {
            // 매개변수로 받은 id와 일치하지 않는지에 따라서 filter 해줍니다.
            setFiles(files.filter(file => file.id !== id));
        },
        [files],
    );

    const [checkedItems, setCheckedItems] = useState([] as any);

    const checkHandler = (isChecked, file) => {
        if (isChecked) {
            setCheckedItems([...checkedItems, file.id.toString()]);
        } else {
            setCheckedItems(
                checkedItems.filter(el => el !== file.id.toString()),
            );
        }
    };

    const checkedAll = useCallback(
        checked => {
            if (checked) {
                const checkedListArray = [] as any;

                files.forEach(list =>
                    checkedListArray.push(list.id.toString()),
                );

                setCheckedItems(checkedListArray);
            } else {
                setCheckedItems([]);
            }
        },
        [files],
    );

    const removeItem = () => {
        const tempFiles: IFileTypes[] = files;

        const newFiles = tempFiles.filter(
            o => !checkedItems.includes(o.id.toString()),
        );

        setCheckedItems([]);
        setFiles(newFiles);
    };

    const [loading, setLoading] = useState(false);

    async function upload(e: any) {
        e.preventDefault();

        const {name, desc} = e.target;

        const formData = new FormData();

        const data = {
            name: name.value,
            spaceVerCreateDto: {
                desc: desc.value,
            },
        };

        if (files !== undefined && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('file', files[i].fileObj);
            }
        }

        formData.append(
            'space-add',
            new Blob([JSON.stringify(data)], {type: 'application/json'}),
        );

        const requestUri = process.env.REACT_APP_API_HOST + `space`;

        setLoading(true);

        await Axios.patch(requestUri, formData)
            .then(resp => {
                if (resp.data.resp_code === 'SUCCESS') {
                    alert('Webgl 빌드 파일이 업로드 되었습니다.');
                    setLoading(false);
                } else {
                    alert(resp.data.desc);
                    setLoading(false);
                }
            })
            .catch(err => {
                alert('파일 업로드에 실패하였습니다.');
                setLoading(false);
            });
    }

    return (
        <>
            <div
                className={
                    'webgl-upload-container ' + (!loading ? 'block' : 'blind')
                }
            >
                <div className="webgl-upload-container-inner">
                    <form onSubmit={upload}>
                        <div className="header">
                            <div className="inp-wrap">
                                <label className="inp-label" htmlFor="">
                                    3D 공간의 이름을 입력해 주세요
                                </label>
                                <input type="text" name="name" />
                            </div>
                            <div className="btn-wrap">
                                <label
                                    className="webgl-label"
                                    htmlFor="webgl-input"
                                >
                                    추가
                                </label>
                                <input
                                    id="webgl-input"
                                    type="file"
                                    multiple={true}
                                    onChange={onChangeFiles}
                                />
                                <button type="button" onClick={removeItem}>
                                    제거
                                </button>
                            </div>
                        </div>
                        <div
                            className={
                                'body ' +
                                (files.length > 0 ? 'solid' : 'dashed')
                            }
                            ref={dragRef}
                        >
                            {files.length > 0 ? (
                                <></>
                            ) : (
                                <>
                                    <div
                                        className="dragOnText"
                                        ref={dragOnTextRef}
                                    >
                                        우측 상단의 &quot;추가&quot; 버튼으로
                                        파일을 추가하거나 파일을 드래그해서
                                        이곳에 놓으세요
                                    </div>
                                </>
                            )}
                            <div className="body-header">
                                <input
                                    type="checkbox"
                                    onChange={e => checkedAll(e.target.checked)}
                                />
                                <div>번호</div>
                                <div>파일이름</div>
                                <div>용량</div>
                            </div>
                            <div className="body-list">
                                {files.length > 0 &&
                                    files.map((file: IFileTypes) => {
                                        const {id, fileObj} = file;

                                        return (
                                            <div
                                                key={id}
                                                className="file-list-item"
                                            >
                                                <div className="inp-wrap">
                                                    <input
                                                        type="checkbox"
                                                        onChange={e =>
                                                            checkHandler(
                                                                e.target
                                                                    .checked,
                                                                file,
                                                            )
                                                        }
                                                        value={id}
                                                        checked={
                                                            checkedItems.includes(
                                                                file.id.toString(),
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <FontAwesomeIcon
                                                        className="item-delete-btn"
                                                        onClick={() =>
                                                            handleFilterFile(id)
                                                        }
                                                        icon={faXmark}
                                                    />
                                                </div>
                                                <div>{id + 1}</div>
                                                <div>{fileObj.name}</div>
                                                <div>
                                                    {fileObj.size < 1048576 ? (
                                                        <>
                                                            {' '}
                                                            {(
                                                                fileObj.size /
                                                                1024
                                                            ).toFixed(2)}{' '}
                                                            KB{' '}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            {(
                                                                fileObj.size /
                                                                1024 /
                                                                1024
                                                            ).toFixed(2)}{' '}
                                                            MB{' '}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="footer">
                            <div className="inp-wrap">
                                <label htmlFor="">
                                    버전 설명을 입력해 주세요
                                </label>
                                <input
                                    name="desc"
                                    type="text"
                                    className="desc-input"
                                />
                            </div>
                            <div className="btn-wrap">
                                <button>저장</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {loading ? (
                <div className="loading-container">
                    <div>
                        업로드 중입니다. <br></br> 파일의 크기에 따라
                        수십초~수분 소요됩니다.
                    </div>
                    <div className="loading dot">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default WebglUpload;
