import React, {useEffect, useState, useLayoutEffect, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons';
import uiStyle from '../../styles/ui.module.scss';
import styles from '../../styles/modal.module.scss';
import {useTranslation} from 'react-i18next';

function AccountDeleteAlert(props) {
    const {onClose, onClickEvent} = props;

    document.body.style.overflow = 'hidden';

    function close() {
        document.body.style.overflow = 'unset';
        onClose(false);
    }

    const {t} = useTranslation()

    return (
        <>
            <div className={`${styles.modal_container} ${styles.alert}`}>
                <div
                    className={`${styles.container_inner} ${styles.alert_inner} ${styles.alarm}`}
                >
                    <div className={styles.modal_header}>
                        <p>{t('main.header.delete_account.title1')}</p>
                        <button onClick={() => close()}>
                            <FontAwesomeIcon icon={faX} />
                        </button>
                    </div>
                    <div className={styles.modal_desc}>
                    {t('main.header.delete_account.text1')}
                        <br />{t('main.header.delete_account.text2')}
                    </div>
                    <div className={styles.modal_btn_wrapper}>
                        <button
                            onClick={() => close()}
                            className={uiStyle.btn_theme_gray}
                        >
                            Cancel
                        </button>
                        <button
                            className={uiStyle.btn_theme_red}
                            onClick={onClickEvent}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountDeleteAlert;
