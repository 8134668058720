import TierCardListItem from './tier-card-list-item'

interface Props {
  type: 'starter' | 'team' | 'enterprise'
}

export default function TierCardBenefits({ type }: Props) {
  if (type === 'starter')
    return (
      <ul role="list" className="mb-auto space-y-4 text-left">
        <TierCardListItem text={['랜드 개설 최대 1개']} />
        <TierCardListItem text={['랜드 수용인원 최대 6명']} />
        <TierCardListItem text={['콘텐츠 업로드 최대 10개', '(images, videos, external URLs)']} />
        <TierCardListItem text={['기본 템플릿 제공']} />
        <TierCardListItem text={['Voice-chat and Screen-share']} />
      </ul>
    )

  if (type === 'team')
    return (
      <ul role="list" className="mb-auto space-y-4 text-left">
        <TierCardListItem text={['랜드 개설 최대 3개']} />
        <TierCardListItem text={['랜드 수용인원 최대 50명']} />
        <TierCardListItem text={['콘텐츠 업로드 최대 30개', '(images, videos, external URLs)']} />
        <TierCardListItem text={['오피스, 이벤트, 갤러리 전용 템플릿 추가 제공']} />
        <TierCardListItem text={['Voice-chat and Screen-share']} />
      </ul>
    )

  return (
    <ul role="list" className="mb-auto space-y-4 text-left">
      <TierCardListItem text={['랜드 개설 확장하기']} />
      <TierCardListItem text={['랜드 수용인원 확장하기']} />
      <TierCardListItem text={['콘텐츠 업로드 수 확장하기', '(images, videos, external URLs)']} />
      <TierCardListItem text={['커스텀 랜드 디자인 적용하기']} />
      <TierCardListItem text={['커스텀 기능 추가하기']} />
    </ul>
  )
}
