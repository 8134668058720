import React from 'react'
import { CheckCircle } from './vectors'

interface Props {
  text: string[]
}

export default function TierCardListItem({ text }: Props) {
  return (
    <li className="flex items-start space-x-3">
      <CheckCircle className="flex-shrink-0 w-5 text-primary-100" />
      <div className="flex flex-col">
        {text.map((t, i) => (
          <span className="text-sm" key={i}>
            {t}
          </span>
        ))}
      </div>
    </li>
  )
}
