import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import viewSplitLine from '../../../utils/ViewSplitLine'

import Text from 'components/common/atoms/Text'
import { DocumentArrowDown } from 'components/vectors'
import axios from 'axios'

interface ChatItemProps {
  key: number
  item: any
}

export const ChatItemComponent = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  padding-right: 15px;
  margin-top: 15px;
  box-sizing: border-box;
`

const ImgWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`

const ImgComponent = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ChatItemInfoComponent = styled.div`
  width: calc(100% - 40px);

  color: #ffffff;
`

export const InfoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Message = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  a {
    text-decoration: underline;
    transition: all 0.3s;
  }

  & a:hover {
    text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;
  }
`

const ChatItem: React.FC<ChatItemProps> = function ({ key, item }) {
  function onDownload(file) {
    axios
      .get(file.fileUrl, { withCredentials: false, responseType: 'blob' })
      .then((response) => {
        const blob = window.URL.createObjectURL(new Blob([response.data]))

        const aElement = document.createElement('a')

        aElement.href = blob
        aElement.setAttribute('download', file.originalFileName)
        aElement.setAttribute('hidden', 'true')
        aElement.setAttribute('target', '_blank')
        document.body.appendChild(aElement)
        aElement.click()
        document.body.removeChild(aElement)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <ChatItemComponent key={key}>
      <ImgWrap>
        <ImgComponent src={item.thumbnailUri} alt="유저 썸네일" />
      </ImgWrap>
      <ChatItemInfoComponent>
        <InfoBox>
          <Text size={16} weight={700}>
            {item.nickname}
          </Text>
          <Text size={14} weight={400} color="#CCCCCC">
            {item.timeStamp}
          </Text>
        </InfoBox>
        {item.messageType !== 'OBJECT' ? (
          <Message>{viewSplitLine(item.textContent)}</Message>
        ) : (
          <div>
            {item.obj.map((file: any, index: number) => {
              return (
                <button
                  className="flex items-center w-full px-2 py-4 mt-1 text-left duration-200 border border-white border-solid group hover:bg-black/60 rounded-2xl"
                  key={index}
                  onClick={() => {
                    console.log('download')
                    onDownload(file)
                  }}
                >
                  <DocumentArrowDown className="w-auto h-8 pr-2 shrink-0" />
                  <div className="flex flex-col">
                    <p className="text-sm line-clamp-1">{file.originalFileName}</p>
                    <div className="flex items-center text-xs">
                      <span className="hidden group-hover:block">Download&nbsp;</span>
                      <p>{file.originalFileName.split('.')[1]}</p>
                    </div>
                  </div>
                </button>
              )
            })}
          </div>
        )}
      </ChatItemInfoComponent>
    </ChatItemComponent>
  )
}

export default ChatItem
