import React from 'react';
import styled from 'styled-components';

interface InputStyleProps {
    size?: number;
}

interface InputProps extends InputStyleProps {
    placeholder?: string;
    action?: string;
    name?: string;
}

export const InputComponent = styled.input<InputStyleProps>(({size}) => ({
    fontSize: size !== undefined ? `${size}px` : `initial`,
}));

const Index = ({name, placeholder, action, ...style}: InputProps) => {
    return <InputComponent name={name} placeholder={placeholder} {...style} />;
};

export default Index;
