import { useLocation } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosInstance } from 'axiosInstance'

import axiosI from 'utils/AxiosI'
import { AccountType, useAccount } from 'contexts/AccountContext'

type PortalType = {
  accessSpaceId: number
  accessUrl: string
  currentSpaceId: number
  externalLink: string
  portalId: number
  portalName: string
  useExternalLink: boolean
}

interface UseSettings {
  setStoredInfoData: (spaceName: string, nickname: string) => Promise<void>
  getMemberInfo: () => Promise<AccountType>
  accountInfo: AccountType
}

export async function getSpaceIdName(idName: string | undefined) {
  const { data } = await axiosInstance.get(`/space/${idName}`)
  return data.data
}

export async function getSpaceInfo(idName: string | undefined) {
  const { data } = await axiosInstance.get(`/land/info/${idName}`)
  return data.data
}

export async function getAccount() {
  const accessToken = window.localStorage.getItem('plikaTk')
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  }

  if (accessToken != null && accessToken.length > 0 && accessToken != 'null') {
    const { data } = await axiosI.get(process.env.REACT_APP_API_HOST + `v1/account/get`, config)

    const accountInfo: AccountType = {
      id: data.data.accountId,
      email: data.data.email,
      nickname: data.data.nickname,
      profileImage: data.data.thumbnailUri,
    }
    return accountInfo
  }
}

export async function updateAccount(nickname: string | undefined) {
  const accessToken = window.localStorage.getItem('plikaTk')
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  }

  if (accessToken != null && accessToken.length > 0 && accessToken != 'null') {
    const formData = new FormData()
    const data = {
      nickname: nickname,
    }
    formData.append(
      'accountProfileUpdateDto',
      new Blob([JSON.stringify(data)], { type: 'application/json' }),
    )
    return await axiosI.patch(process.env.REACT_APP_API_HOST + `v1/account/`, formData, config)
  }
}

export function useSettings() {
  const { pathname } = useLocation()
  const { account, updateAccount } = useAccount()

  async function setStoredInfoData(
    portals: PortalType[],
    nickname: string | undefined,
    email: string | undefined,
  ) {
    // TODO: save id and nickname to session storage
    const filteredPortals = portals.filter((portal) => {
      return !portal.useExternalLink
    })
    const plikaSpaceInfoData = window.sessionStorage.getItem('plika_space_info_data')
    const plikaSpaceInfo: Array<{ spaceId; nickname; email }> =
      plikaSpaceInfoData && JSON.parse(plikaSpaceInfoData)
    const plikaInfoList = new Array()
    filteredPortals.forEach((portal) => {
      if (plikaSpaceInfo != null) {
        const findIndex = plikaSpaceInfo.findIndex((e) => {
          return e.spaceId == portal.accessSpaceId
        })
        if (findIndex >= 0) {
          plikaSpaceInfo[findIndex] = { spaceId: portal.accessSpaceId, nickname, email }
        } else {
          plikaSpaceInfo.push({ spaceId: portal.accessSpaceId, nickname, email })
        }
        window.sessionStorage.setItem('plika_space_info_data', JSON.stringify(plikaSpaceInfo))
      } else {
        plikaInfoList.push({ spaceId: portal.accessSpaceId, nickname, email })
        window.sessionStorage.setItem('plika_space_info_data', JSON.stringify(plikaInfoList))
      }
    })
  }

  async function getMemberInfo(): Promise<AccountType | undefined> {
    if (account.id == 0) {
      /**
       * 회원정보를 조회합니다.
       */
      const accessToken = window.localStorage.getItem('plikaTk')
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      if (accessToken != null && accessToken.length > 0 && accessToken != 'null') {
        await axiosI
          .get(process.env.REACT_APP_API_HOST + `v1/account/get`, config)
          .then((e) => {
            if (e.data.data != null) {
              const { accountId, nickname, email, thumbnailUri } = e.data.data
              updateAccount(accountId, nickname, email, thumbnailUri)
              if (
                e.data.data.age == null ||
                e.data.data.job == null ||
                e.data.data.age == null ||
                e.data.data.route == null ||
                e.data.data.object == null
              ) {
                window.location.replace('/login/info')
              }
              if (!e.data.data.ageCheck || !e.data.data.privacyCheck) {
                if (!pathname.startsWith('/privacy') && !pathname.startsWith('/terms')) {
                  window.location.replace('/login/profile')
                }
              }

              const accountInfo: AccountType = {
                id: accountId,
                email,
                nickname,
                profileImage: thumbnailUri,
              }

              return accountInfo
            }
          })
          .catch((err) => {
            console.log(err)
            return undefined
          })
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  const fallback = {}
  const { data: accountInfo = fallback } = useQuery({
    queryKey: ['account'],
    queryFn: getAccount,
    keepPreviousData: true,
    onSuccess: (data) => {
      // console.log(data)
    },
    onSettled: () => {
      // console.log('ddd')
    },
  })

  return { setStoredInfoData, getAccount, getMemberInfo, accountInfo, updateAccount }
}
