import React, {useRef, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons';

import uiStyle from '../../styles/ui.module.scss';
import styles from '../../styles/modal.module.scss';

import axiosI from '../../utils/AxiosI';
import {deleteCookie} from '../../utils/CookieUtil';

import InputWithTitle from '../ui/InputWithTitle';
import AccountDeleteAlert from './AccountDeleteAlert';
import Text from 'components/atoms/Text';

interface TextWrapProps {
    toggle: any;
}

const TextWrap = styled.div<TextWrapProps>`
    visibility: ${({toggle}: any) => (toggle ? 'visible' : 'hidden')};
`;

function Editprofile(props: any) {
    const {
        webviewData,
        accountInfo,
        setAccountInfo,
        setAccountInfoState,
        accountRef,
        sendInfoUpdateProfileoMsg,
        webviewPopupChange,
        inSpace,
        sendMsgWithParam,
        onClose,
    } = props;


    const [uploading, setUploading] = useState(false);

    document.body.style.overflow = 'hidden';

    const [popup, setPopup] = useState(false);

    function closePopup() {
        if (!uploading) {
            document.body.style.overflow = 'unset';
            setPopup(false);
        }
    }

    const [inputs, setInputs] = useState({
        nickname: accountInfo.nickname,
    });

    const {nickname} = inputs;

    const inpuValueHandler = (e: any) => {
        const {value, name} = e.target;
        setInvalidNickname(false);
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const profileImgRef: any = useRef();

    const [files, setFiles]: any = useState();

    const fileHandler = (e: any) => {
        setFiles(e.target.files);
        profileImgRef.current.setAttribute(
            'src',
            URL.createObjectURL(e.target.files[0]),
        );
    };

    async function updateProfile(e: any) {
        e.preventDefault();

        setUploading(true);

        const {nickname} = e.target;

        const formData = new FormData();
        const fileDetails: any = [];

        const data = {
            nickname: nickname.value,
            chatSessionId: accountInfo.sessionId,
        };

        if (nickname.value.length <= 0 || nickname.value.length > 15) {
            setInvalidNickname(true);
            setUploading(false);
            return;
        }

        if (accountInfo.isLogin || !inSpace) {
            if (files !== undefined && files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('thumbnail', files[i]);
                }
            }

            formData.append(
                'accountProfileUpdateDto',
                new Blob([JSON.stringify(data)], {type: 'application/json'}),
            );

            const requestUri = process.env.REACT_APP_API_HOST + `v1/account/`;

            const accessToken = window.localStorage.getItem('plikaTk');
            const config = {
                headers: {Authorization: `Bearer ${accessToken}`},
            };

            await axiosI
                .patch(requestUri, formData, config)
                .then(resp => {
                    // UNITY 호출 로직
                    if (resp.data.resp_code === 'OK') {
                        if (resp.data.data.thumbnailUri != null) {
                            setAccountInfo((prev: any) => ({
                                ...prev,
                                nickname: resp.data.data.nickname,
                                thumbnailUri: resp.data.data.thumbnailUri,
                            }));
                            if (accountRef != null) {
                                accountRef.current.nickname =
                                    resp.data.data.nickname;
                                accountRef.current.thumbnailUri =
                                    resp.data.data.thumbnailUri;
                            }
                        } else {
                            setAccountInfo((prev: any) => ({
                                ...prev,
                                nickname: resp.data.data.nickname,
                            }));
                            if (accountRef != null) {
                                accountRef.current.nickname =
                                    resp.data.data.nickname;
                            }
                        }

                        if (inSpace) {
                            const obj = {
                                Name: resp.data.data.nickname,
                                ImgUrl: null,
                            };

                            if (
                                resp.data.data.thumbnailUri != null &&
                                resp.data.data.thumbnailUri.length > 0
                            ) {
                                obj.ImgUrl = resp.data.data.thumbnailUri;
                            }
                            const param = JSON.stringify(obj);
                            sendMsgWithParam(
                                'Webreceiver',
                                'ModifyProfile',
                                param,
                            );

                            const updateProfile = {
                                nickname: accountRef.current.nickname,
                                thumbnailUri: accountRef.current.thumbnailUri,
                            };

                            const spaceInfoData = window.sessionStorage.getItem('plika_space_info_data');
                            if (spaceInfoData != null) {
                                const spaceInfoDataList: Array<any> = JSON.parse(spaceInfoData)
                                const spaceInfo = spaceInfoDataList.filter((e) => {
                                    return e.spaceId == webviewData.spaceId
                                })

                                if (spaceInfo.length > 0) {
                                    const newArr = spaceInfoDataList.map(e => {
                                        const findObj = webviewData.portals.find(portal => {
                                            return portal.accessSpaceId == e.spaceId;
                                        });
                                        if(findObj != undefined) {
                                            return {
                                                spaceId : findObj.accessSpaceId,
                                                nickname : resp.data.data.nickname,
                                                email : e.email
                                            }
                                        } else {
                                            return e;
                                        }
                                    })
                                    window.sessionStorage.setItem('plika_space_info_data', JSON.stringify(newArr));
                                }
                            }

                            sendInfoUpdateProfileoMsg(updateProfile);

                            webviewPopupChange('profilePopup', false);
                        }

                        if (!inSpace) {
                            if (resp.data.data.thumbnailUri != null) {
                                setAccountInfoState((prev: any) => ({
                                    ...prev,
                                    nickname: resp.data.data.nickname,
                                    thumbnailUri: resp.data.data.thumbnailUri,
                                }));
                            } else {
                                setAccountInfoState((prev: any) => ({
                                    ...prev,
                                    nickname: resp.data.data.nickname,
                                }));
                            }
                            document.body.style.overflow = 'unset';
                            onClose();
                        }

                        setUploading(false);
                    }
                    setUploading(false);
                })
                .catch(err => {
                    alert('파일 업로드에 실패하였습니다.');
                    setUploading(false);
                    //window.location.reload();
                });
        } else {
            const requestUri =
                process.env.REACT_APP_API_HOST + `api/guest/update`;

            if (files !== undefined && files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('file', files[i]);
                }
            }

            const data = {
                nickname: nickname.value,
                chatSessionId: accountInfo.sessionId,
            };

            formData.append(
                'guestUpdateDto',
                new Blob([JSON.stringify(data)], {type: 'application/json'}),
            );

            await axios
                .patch(requestUri, formData)
                .then(resp => {
                    if (resp.data.resp_code === 'SUCCESS') {
                        const obj = {
                            ImgUrl: resp.data.data.thumbnailUri,
                            Name: resp.data.data.nickname,
                        };
                        const param = JSON.stringify(obj);

                        sendMsgWithParam('Webreceiver', 'ModifyProfile', param);
                        setUploading(false);
                        if (resp.data.data.fileUrl != null) {
                            sessionStorage.setItem(
                                'tempProfileImg',
                                resp.data.data.thumbnailUri,
                            );
                        }

                        if (files !== undefined && files.length > 0) {
                            setAccountInfo((prev: any) => ({
                                ...prev,
                                nickname: nickname.value,
                                thumbnailUri: resp.data.data.thumbnailUri,
                            }));

                            accountRef.current.nickname =
                                resp.data.data.nickname;
                            accountRef.current.thumbnailUri =
                                resp.data.data.thumbnailUri;
                        } else {
                            setAccountInfo((prev: any) => ({
                                ...prev,
                                nickname: nickname.value,
                            }));

                            accountRef.current.nickname =
                                resp.data.data.nickname;
                        }

                        const updateProfile = {
                            nickname: accountRef.current.nickname,
                            thumbnailUri: accountRef.current.thumbnailUri,
                        };


                        const spaceInfoData = window.sessionStorage.getItem('plika_space_info_data');
                        if (spaceInfoData != null) {
                            const spaceInfoDataList: Array<any> = JSON.parse(spaceInfoData)
                            const spaceInfo = spaceInfoDataList.filter((e) => {
                                return e.spaceId == webviewData.spaceId
                            })

                            if (spaceInfo.length > 0) {
                                const newArr = spaceInfoDataList.map(e => {
                                    const findObj = webviewData.portals.find(portal => {
                                        return portal.accessSpaceId == e.spaceId;
                                    });
                                    if(findObj != undefined) {
                                        return {
                                            spaceId : findObj.accessSpaceId,
                                            nickname : resp.data.data.nickname,
                                            email : e.email
                                        }
                                    } else {
                                        return e;
                                    }
                                })
                                window.sessionStorage.setItem('plika_space_info_data', JSON.stringify(newArr));
                            }
                        }


                        sendInfoUpdateProfileoMsg(updateProfile);

                        webviewPopupChange('profilePopup', false);
                    }
                    setUploading(false);
                })
                .catch(err => {
                    alert('파일 업로드에 실패하였습니다.');
                    setUploading(false);
                    //window.location.reload();
                });
        }
    }

    function deleteProfile() {
        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        const data = {
            id: accountInfo.id,
        };
        axiosI
            .patch(
                process.env.REACT_APP_API_HOST + 'v1/account/delete',
                data,
                config,
            )
            .then(e => {
                window.localStorage.removeItem('plikaTk');
                deleteCookie('ref_token');
                window.location.replace('/login');
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const webviewInner: any = useRef();

    function closeWebview(e: any) {
        if (!uploading) {
            if (!webviewInner.current.contains(e.target)) {
                if (inSpace) {
                    webviewPopupChange('profilePopup', false);
                } else {
                    document.body.style.overflow = 'unset';
                    onClose();
                }
            }
        }
    }

    function close() {
        document.body.style.overflow = 'unset';
        onClose();
    }

    const [invalidNickname, setInvalidNickname]: any = useState(null);

    return (
        <>
            <form onSubmit={updateProfile}>
                <div
                    className={`${styles.modal_container}`}
                    onPointerDown={e => closeWebview(e)}
                >
                    <div
                        ref={webviewInner}
                        className={`${styles.container_inner} ${styles.profile}`}
                    >
                        <div className={styles.modal_header}>
                            <p>{accountInfo.nickname}</p>
                            <button
                                type="button"
                                onClick={() => {
                                    inSpace
                                        ? !uploading &&
                                          webviewPopupChange(
                                              'profilePopup',
                                              false,
                                          )
                                        : !uploading && close();
                                }}
                            >
                                <FontAwesomeIcon icon={faX} />
                            </button>
                        </div>
                        <div className={styles.modal_icon_box}>
                            <label
                                htmlFor="att-file"
                                className={`${styles.icon_box}`}
                            >
                                <figure>
                                    <img
                                        ref={profileImgRef}
                                        src={accountInfo.thumbnailUri}
                                        alt=""
                                    />
                                </figure>
                                <div
                                    className={`${styles.icon} ${styles.att}`}
                                ></div>
                            </label>
                            <input
                                type="file"
                                id="att-file"
                                className="blind"
                                accept="image/*"
                                onChange={e => fileHandler(e)}
                            />
                        </div>
                        <div className={styles.modal_desc}>
                            <InputWithTitle
                                title={'Nickname'}
                                name={'nickname'}
                                onChange={inpuValueHandler}
                                value={nickname}
                                alert={invalidNickname}
                            ></InputWithTitle>
                            <TextWrap toggle={invalidNickname}>
                                <Text size={0.8} weight={400} color="#EE3030">
                                    닉네임은 16자 이내로 적어주세요
                                </Text>
                            </TextWrap>
                            <div className={styles.modal_box_text}>
                                <div className={styles.modal_title}>E-Mail</div>
                                <div className={styles.modal_text}>
                                    {accountInfo.email}
                                </div>
                            </div>
                        </div>
                        <div className={styles.modal_btn_wrapper}>
                            {inSpace ? (
                                <>
                                    <div></div>
                                </>
                            ) : (
                                <button
                                    type="button"
                                    onClick={() => setPopup(true)}
                                    className={`${uiStyle.btn_theme_text_gray} ${styles.modal_del_btn}`}
                                >
                                    Delete <br /> account
                                </button>
                            )}

                            <button
                                type="submit"
                                className={uiStyle.btn_theme_yel}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            {popup ? (
                <AccountDeleteAlert
                    onClose={closePopup}
                    onClickEvent={deleteProfile}
                ></AccountDeleteAlert>
            ) : (
                <></>
            )}
        </>
    );
}

export default Editprofile;
