import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

async function verifyToken(accessToken: any) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const response = await axios.get(process.env.REACT_APP_API_HOST + 'api/auth/refresh', config)

  return response
}

abstract class HttpClient {
  protected readonly instance: AxiosInstance

  public interceptor = false

  public constructor(instance: AxiosInstance, interceptor: boolean) {
    this.instance = instance
    this.interceptor = interceptor
    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
  }

  private _initializeRequestInterceptor() {
    if (this.interceptor) {
      this.instance.interceptors.request.use(
        async function (config) {
          // 토큰 꺼내오기
          const originAccessToken = window.localStorage.getItem('plikaTk')

          // 토큰 남은시간 확인
          if (originAccessToken !== null) {
            const base64Payload = originAccessToken.split('.')[1]
            const payload: any = JSON.parse(atob(base64Payload))

            const date1 = new Date(0)
            date1.setUTCSeconds(payload.exp)

            const date2 = new Date()

            const expDate = date1.getTime()
            const nowDate = date2.getTime()

            const elapsedMSec = expDate - nowDate
            const elapsedMin = elapsedMSec / (1000 * 60)

            if (elapsedMin > 0.1) {
              config.headers = {
                Authorization: `Bearer ${originAccessToken}`,
                Accept: 'application/json',
              }

              return config
            }
          }

          if (originAccessToken === null) {
            return config
          }

          const resultCode = await verifyToken(originAccessToken)

          // FIXME : 추후 백엔드 응답코드에 따라 처리합니다.
          if (resultCode.data.resp_code == 'OK') {
            const accessToken = window.localStorage.getItem('plikaTk')

            window.localStorage.setItem('plikaTk', '')

            window.localStorage.setItem('plikaTk', resultCode.data.data)

            config.headers = {
              Authorization: `Bearer ${resultCode.data.data}`,
              Accept: 'application/json',
            }

            return config
          }

          return config
        },
        function (error) {
          return Promise.reject(error)
        },
      )
    } else {
      this.instance.interceptors.request.use(this._handleRequest, this._handleError)
    }
  }

  private _initializeResponseInterceptor() {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
  }

  protected _handleRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    return config
  }

  protected _handleResponse(axiosResponse: AxiosResponse): AxiosResponse {
    return axiosResponse
  }

  protected _handleError(error: AxiosError) {
    if (error.response) {
      // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
      alert('요청을 처리하는 중에 오류가 발생하였습니다.')
    } else if (error.request) {
      // 요청이 이루어 졌으나 응답을 받지 못했습니다.
      // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
      // Node.js의 http.ClientRequest 인스턴스입니다.
      alert('서버 또는 네트워크의 상태가 좋지 않습니다.')
    } else {
      // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
      // console.log('Error', error.message);
    }

    return Promise.reject(error)
  }

  public putByForm<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    const params = new URLSearchParams()
    for (const key in data) {
      params.append(key, data[key])
    }
    config = {} as AxiosRequestConfig
    config.headers = {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
    }

    return this.instance.put(url, params, config)
  }

  public postByForm<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    const params = new URLSearchParams()
    for (const key in data) {
      params.append(key, data[key])
    }
    config = {} as AxiosRequestConfig
    config.headers = {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
    }

    return this.instance.post(url, params, config)
  }

  public post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.instance.post(url, data, config)
  }

  public get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.instance.get(url, config)
  }
}

export class MainAxios extends HttpClient {
  public constructor(config: any) {
    super(
      axios.create({
        baseURL: process.env.REACT_APP_API_HOST,
        withCredentials: true,
      }),
      config,
    )
  }

  protected _handleRequest(config: AxiosRequestConfig) {
    return config
  }

  protected _handleResponse(axiosResponse: AxiosResponse): AxiosResponse {
    if (axiosResponse?.data?.requestCode == 'F-B') {
      alert('로그인 후 이용해주세요.')
      // eslint-disable-next-line no-restricted-globals
      location.replace('/login')
    }

    return axiosResponse
  }
}

class Singleton {
  static mainAxios: MainAxios
}

export const createMainAxios = (interceptor: any) => {
  if (Singleton.mainAxios == null) {
    Singleton.mainAxios = new MainAxios(interceptor)
  }
  if (Singleton.mainAxios.interceptor == interceptor) {
    return Singleton.mainAxios
  } else {
    Singleton.mainAxios = new MainAxios(interceptor)
  }
  return Singleton.mainAxios
}

export const getMainAxios = createMainAxios
