import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {IoIosArrowDown} from 'react-icons/io';
import background from '../assets/ui/loading.png';
import media from '../lib/styles/media';
import axiosI from '../utils/AxiosI';

function RegisterDetails() {
    useEffect(() => {
        const accessToken = window.localStorage.getItem('plikaTk');
        if (
            accessToken != null &&
            accessToken.length > 0 &&
            accessToken != 'null'
        ) {
            const config = {
                headers: {Authorization: `Bearer ${accessToken}`},
            };
            const requestUri =
                process.env.REACT_APP_API_HOST + `v1/account/get`;
            axiosI.get(requestUri, config).then(e => {
                if (
                    e.data.data.age != null ||
                    e.data.data.job != null ||
                    e.data.data.age != null ||
                    e.data.data.route != null ||
                    e.data.data.object != null
                ) {
                    if (!e.data.data.ageCheck || !e.data.data.privacyCheck) {
                        {
                            window.location.replace('/login/profile');
                        }
                    }

                    window.location.replace('/');
                }
            });
        }
    }, []);

    const [isOptionsOpen, setIsOptionsOpen] = useState<any>({
        route: false,
        age: false,
        job: false,
        field: false,
        object: false,
    });
    const [selectedOption, setSelectedOption] = useState<any>({
        route: -1,
        age: -1,
        job: -1,
        field: -1,
        object: -1,
    });
    const [optionValidation, setOptionValidation] = useState({
        route: true,
        age: true,
        job: true,
        field: true,
        object: true,
    });

    const optionsList = {
        route: ['검색', 'SNS 광고', '지인추천', '기타'],
        age: ['20대 이하', '20~30대', '40~50대', '60대 이상'],
        job: ['공공기관', '기업', '학생', '프리랜서', '기타'],
        field: [
            'IT/프로그래밍',
            '예술/디자인/영상',
            '마케팅/행사기획',
            '제조/유통/커머스',
            '과학기술/연구',
            '경제/금융',
            '인문/사회',
            '기타',
        ],
        object: ['이벤트', '원격 업무 및 회의', '전시'],
    };

    const toggleOptions = (e: any) => {
        const {name} = e.target;
        setIsOptionsOpen({
            ...isOptionsOpen,
            [name]: !isOptionsOpen[name],
        });
    };

    const setSelectedThenCloseDropdown = (e: any, index: any) => {
        const {name} = e.currentTarget.parentNode.previousSibling;
        setOptionValidation(prev => ({
            ...prev,
            [name]: true,
        }));

        setSelectedOption((prev: any) => ({
            ...prev,
            [name]: index,
        }));

        setIsOptionsOpen({
            ...isOptionsOpen,
            [name]: false,
        });
    };

    const handleKeyDown = (index: any) => (e: any) => {
        switch (e.key) {
            case ' ':
            case 'SpaceBar':
            case 'Enter':
                e.preventDefault();
                setSelectedThenCloseDropdown(e, index);
                break;
            default:
                break;
        }
    };

    // const handleListKeyDown = e => {
    //     console.log(e);
    //     switch (e.key) {
    //         case 'Escape':
    //             e.preventDefault();
    //             // setIsOptionsOpen(false);
    //             break;
    //         case 'ArrowUp':
    //             e.preventDefault();
    //             setSelectedOption(
    //                 selectedOption - 1 >= 0
    //                     ? selectedOption - 1
    //                     : optionsList['route'].length - 1,
    //             );
    //             break;
    //         case 'ArrowDown':
    //             e.preventDefault();
    //             setSelectedOption(
    //                 selectedOption == optionsList['route'].length - 1
    //                     ? 0
    //                     : selectedOption + 1,
    //             );
    //             break;
    //         default:
    //             break;
    //     }
    // };

    async function infoSubmit(e: any) {
        e.preventDefault();
        const requestUri =
            process.env.REACT_APP_API_HOST + `v1/account/set/info`;

        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };

        let valid = true;

        for (const key in selectedOption) {
            if (selectedOption[key] == -1) {
                setOptionValidation(prev => ({
                    ...prev,
                    [key]: false,
                }));
                valid = false;
            }
        }

        if (!valid) {
            return;
        }

        if (
            accessToken != null &&
            accessToken != 'null' &&
            accessToken.length > 0
        ) {
            await axiosI
                .patch(requestUri, selectedOption, config)
                .then(e => {
                    if (e.status == 200 && e.data.resp_code == '2000') {
                        window.location.replace('/login/profile');
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    }

    return (
        <Block>
            <Left>
                <video autoPlay loop muted>
                    <source
                        src={
                            process.env.REACT_APP_CDN_URL +
                            '/static/video/web/login_video.mp4'
                        }
                    />
                </video>
            </Left>
            <Right>
                <Content>
                    <h2>간단한 정보를 기입해주세요</h2>

                    <StyledForm onSubmit={e => infoSubmit(e)}>
                        <SelectBox>
                            <div>
                                <h3>유입경로</h3>
                                <SelectButton
                                    name="route"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded={isOptionsOpen}
                                    className={
                                        !optionValidation['route']
                                            ? 'alert'
                                            : ''
                                    }
                                    onClick={toggleOptions}
                                    index={selectedOption['route']}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {selectedOption['route'] != -1
                                        ? optionsList['route'][
                                              selectedOption['route']
                                          ]
                                        : '아래의 항목에서 선택해 주세요.'}
                                    <IconArrowDown
                                        show={isOptionsOpen['route']}
                                    />
                                </SelectButton>
                                <Options
                                    show={isOptionsOpen['route']}
                                    role="listbox"
                                    aria-activedescendant={
                                        optionsList['route'][
                                            selectedOption['route']
                                        ]
                                    }
                                    tabIndex={-1}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {optionsList['route'].map(
                                        (option, index) => (
                                            <li
                                                key={index}
                                                id={option}
                                                role="option"
                                                aria-selected={
                                                    selectedOption['route'] ==
                                                    index
                                                }
                                                tabIndex={0}
                                                onKeyDown={handleKeyDown(index)}
                                                onClick={e => {
                                                    setSelectedThenCloseDropdown(
                                                        e,
                                                        index,
                                                    );
                                                }}
                                            >
                                                {option}
                                            </li>
                                        ),
                                    )}
                                </Options>
                            </div>
                            <div>
                                <h3>연령</h3>
                                <SelectButton
                                    name="age"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded={isOptionsOpen}
                                    className={
                                        !optionValidation['age'] ? 'alert' : ''
                                    }
                                    onClick={toggleOptions}
                                    index={selectedOption['age']}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {selectedOption['age'] != -1
                                        ? optionsList['age'][
                                              selectedOption['age']
                                          ]
                                        : '아래의 항목에서 선택해 주세요.'}
                                    <IconArrowDown
                                        show={isOptionsOpen['age']}
                                    />
                                </SelectButton>
                                <Options
                                    show={isOptionsOpen['age']}
                                    role="listbox"
                                    aria-activedescendant={
                                        optionsList['age'][
                                            selectedOption['age']
                                        ]
                                    }
                                    tabIndex={-1}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {optionsList['age'].map((option, index) => (
                                        <li
                                            key={index}
                                            id={option}
                                            role="option"
                                            aria-selected={
                                                selectedOption['age'] == index
                                            }
                                            tabIndex={0}
                                            onKeyDown={handleKeyDown(index)}
                                            onClick={e => {
                                                setSelectedThenCloseDropdown(
                                                    e,
                                                    index,
                                                );
                                            }}
                                        >
                                            {option}
                                        </li>
                                    ))}
                                </Options>
                            </div>
                            <div>
                                <h3>직업</h3>
                                <SelectButton
                                    name="job"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded={isOptionsOpen}
                                    className={
                                        !optionValidation['job'] ? 'alert' : ''
                                    }
                                    onClick={toggleOptions}
                                    index={selectedOption['job']}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {selectedOption['job'] != -1
                                        ? optionsList['job'][
                                              selectedOption['job']
                                          ]
                                        : '아래의 항목에서 선택해 주세요.'}
                                    <IconArrowDown
                                        show={isOptionsOpen['job']}
                                    />
                                </SelectButton>
                                <Options
                                    show={isOptionsOpen['job']}
                                    role="listbox"
                                    aria-activedescendant={
                                        optionsList['job'][
                                            selectedOption['job']
                                        ]
                                    }
                                    tabIndex={-1}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {optionsList['job'].map((option, index) => (
                                        <li
                                            key={index}
                                            id={option}
                                            role="option"
                                            aria-selected={
                                                selectedOption['age'] == index
                                            }
                                            tabIndex={0}
                                            onKeyDown={handleKeyDown(index)}
                                            onClick={e => {
                                                setSelectedThenCloseDropdown(
                                                    e,
                                                    index,
                                                );
                                            }}
                                        >
                                            {option}
                                        </li>
                                    ))}
                                </Options>
                            </div>
                            <div>
                                <h3>분야</h3>
                                <SelectButton
                                    name="field"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded={isOptionsOpen}
                                    className={
                                        !optionValidation['field']
                                            ? 'alert'
                                            : ''
                                    }
                                    onClick={toggleOptions}
                                    index={selectedOption['field']}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {selectedOption['field'] != -1
                                        ? optionsList['field'][
                                              selectedOption['field']
                                          ]
                                        : '아래의 항목에서 선택해 주세요.'}
                                    <IconArrowDown
                                        show={isOptionsOpen['field']}
                                    />
                                </SelectButton>
                                <Options
                                    show={isOptionsOpen['field']}
                                    role="listbox"
                                    aria-activedescendant={
                                        optionsList['field'][
                                            selectedOption['field']
                                        ]
                                    }
                                    tabIndex={-1}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {optionsList['field'].map(
                                        (option, index) => (
                                            <li
                                                key={index}
                                                id={option}
                                                role="option"
                                                aria-selected={
                                                    selectedOption['age'] ==
                                                    index
                                                }
                                                tabIndex={0}
                                                onKeyDown={handleKeyDown(index)}
                                                onClick={e => {
                                                    setSelectedThenCloseDropdown(
                                                        e,
                                                        index,
                                                    );
                                                }}
                                            >
                                                {option}
                                            </li>
                                        ),
                                    )}
                                </Options>
                            </div>
                            <div>
                                <h3>활용목적</h3>
                                <SelectButton
                                    name="object"
                                    type="button"
                                    aria-haspopup="listbox"
                                    aria-expanded={isOptionsOpen}
                                    className={
                                        !optionValidation['object']
                                            ? 'alert'
                                            : ''
                                    }
                                    onClick={toggleOptions}
                                    index={selectedOption['object']}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {selectedOption['object'] != -1
                                        ? optionsList['object'][
                                              selectedOption['object']
                                          ]
                                        : '아래의 항목에서 선택해 주세요.'}
                                    <IconArrowDown
                                        show={isOptionsOpen['object']}
                                    />
                                </SelectButton>
                                <Options
                                    show={isOptionsOpen['object']}
                                    role="listbox"
                                    aria-activedescendant={
                                        optionsList['object'][
                                            selectedOption['object']
                                        ]
                                    }
                                    tabIndex={-1}
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {optionsList['object'].map(
                                        (option, index) => (
                                            <li
                                                key={index}
                                                id={option}
                                                role="option"
                                                aria-selected={
                                                    selectedOption['object'] ==
                                                    index
                                                }
                                                tabIndex={0}
                                                onKeyDown={handleKeyDown(index)}
                                                onClick={e => {
                                                    setSelectedThenCloseDropdown(
                                                        e,
                                                        index,
                                                    );
                                                }}
                                            >
                                                {option}
                                            </li>
                                        ),
                                    )}
                                </Options>
                            </div>
                        </SelectBox>

                        <NextButton type="submit">Next</NextButton>
                    </StyledForm>
                </Content>
            </Right>
        </Block>
    );
}

const Block = styled.div`
    display: flex;
    height: 100vh;
`;

const Left = styled.div`
    width: 70%;
    overflow: hidden;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${media.large} {
        display: none;
    }
`;

const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;

    ${media.large} {
        width: 100%;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32.5rem;
    height: 46.875rem;

    h2 {
        font-size: 24px;
        margin-bottom: 48px;
    }

    h3 {
        margin-bottom: 10px;
    }
`;

const StyledForm = styled.form`
    width: 100%;
    margin: 0 auto;
    position: relative;

    option {
        font-size: 20px;
    }
`;

const SelectBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

interface SelectButtonProps {
    index: any;
}

const SelectButton = styled.button<SelectButtonProps>`
    border: solid 1px #ccc;
    border-radius: 24px;
    background: #fff;
    width: 100%;
    padding: 10px 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({index}) => (index === -1 ? '#ccc' : '#000')};
    font-size: 14px;
    font-weight: 500;
    border: ${({className}) =>
        className == 'alert' ? '1px solid #EE3030' : ''};
`;

interface IconArrowDownProps {
    show: any;
}

const IconArrowDown = styled(IoIosArrowDown)<IconArrowDownProps>`
    font-size: 16px;
    color: #000;
    transition: 0.3s;
    transform: ${({show}) => (show ? 'rotate(180deg)' : '')};
`;

interface OptionsProps {
    show: any;
}

const Options = styled.ul<OptionsProps>`
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: ${({show}) => (show ? 'block' : 'none')};
    list-style: none;
    padding: 14px 0;
    margin-top: 8px;
    position: absolute;
    z-index: 10;
    width: 100%;

    li {
        padding: 6px 20px;
        font-size: 16px;
        font-weight: 500;
        transition: 0.3s;

        &:active,
        &:focus,
        &:hover,
        &[aria-selected='true'] {
            background: #fee500;
            cursor: pointer;
        }
    }
`;

const NextButton = styled.button`
    width: 100%;
    height: 40px;
    background: #fee500;
    border-radius: 20px;
    transition: 0.3s;
    font-size: 20px;
    font-weight: 700;
    margin-top: 46px;

    &:hover {
        background: #fff280;
    }
`;

export default RegisterDetails;
