import React from 'react';
import {
    DoubleClickImg,
    HanamLookImg,
    HanamMoveImg,
    MintInteractionImg,
    MintLogo,
    MintMoveImg,
    PlikaLogo,
    SquareIcon,
} from '../../static/svg';
import styled from 'styled-components';

function WelcomeContent() {
    return (
        <Block>
            <Wrap>
                <div>
                    <h3>두둥! 설명서 도착</h3>
                </div>
                <ul>
                    <li>
                        <SquareIcon />
                        이곳은 <MintLogo />와 <PlikaLogo /> 를 만날 수 있는
                        팝업스토어에요
                    </li>
                    <li>
                        <SquareIcon />
                        <b>하단 설명서</b>에 있는 간단한 조작방법을 확인하세요
                    </li>
                </ul>
            </Wrap>

            <ImageGroup>
                <div>
                    <MintMoveImg />
                    <p>이동: WASD 또는 화살표</p>
                </div>
                <div>
                    <MintInteractionImg />
                    <p>화면전환: 마우스 왼쪽 버튼</p>
                </div>
                <div>
                    <DoubleClickImg />
                    <p>상호작용: 마우스 더블클릭</p>
                </div>
            </ImageGroup>
        </Block>
    );
}

const Block = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    div:first-child {
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
    }

    h3 {
        font-size: 24px;
    }

    li {
        display: flex;
        align-items: center;
        font-size: 12px;

        svg {
            width: 32px;
            color: #000;
            margin-left: 5px;
        }

        svg:first-child {
            width: 5px;
            margin-right: 5px;
        }
    }
`;

const ImageGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    width: 100%;
    align-items: center;
    justify-content: center;

    div {
        width: 100%;
        height: 127px;
        padding: 8px;
        border: 0.5px solid #cccccc;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
    }
`;

export default WelcomeContent;
