import React from 'react'

import { useLand } from 'contexts/LandContext'
import { Land as PopularLandType } from 'types'

import LandUserBadge from './LandUserBadge'
import { useModalMode } from 'contexts/ModalModeContext'

interface PopularLandProps {
  landData: PopularLandType
}

export default function PopularLandCard({ landData }: PopularLandProps) {
  const { updateId } = useLand()
  const { toggleModalMode } = useModalMode()

  return (
    <div
      className="duration-500 cursor-pointer"
      onClick={() => {
        updateId(landData.spaceId)
        toggleModalMode('landDetail', true)
      }}
    >
      <div className="relative w-full overflow-hidden rounded-md shadow-86 aspect-video">
        <LandUserBadge
          currentUsers={landData.currentJoinMemberTotal}
          maxUsers={landData.connectUserLimit}
        />
        <img
          className="object-cover w-full h-full"
          src={landData.templateThumbnail}
          alt="thumbnail"
        />
      </div>
      <div className="py-1 sm:py-2">
        <h3 className="text-sm font-semibold md:text-base text-slate-900 line-clamp-1">
          {landData.name}
        </h3>
        <p className="text-[0.825rem] md:text-[0.9rem] line-clamp-1 text-slate-600">
          {landData.nickname}
        </p>
        {/* <div className="flex items-center gap-2">
          <div className="w-6 h-6 overflow-hidden rounded-full shadow-86 shrink-0">
            <img className="object-cover w-full h-full" src={landData.thumbnailUri} alt="avatar" />
          </div>
          
        </div> */}
      </div>
    </div>
  )
}
