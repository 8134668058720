import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';

import styles from '../../../styles/webview.module.scss';

import {LinkIcon} from '../../../static/svg';

function TextContentShowWebview(props) {
    const {webviewData, onClose} = props;

    return (
        <>
            <InnerBox>
                <TitleBar>
                    <div className={styles.title}>{webviewData.name}</div>
                    <div className={styles.close_btn} onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                    </div>
                </TitleBar>
                <div className={styles.content_body}>
                    <div className={styles.content_desc}>
                        <span>{webviewData.desc}</span>
                    </div>
                </div>
                {webviewData.isLinkOpen && (
                    <LinkButton>
                        <a
                            href={webviewData.linkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <LinkIcon />
                            <span>{webviewData.linkName}</span>
                        </a>
                    </LinkButton>
                )}
            </InnerBox>
        </>
    );
}

export const InnerBox = styled.div`
    display: flex;
    flex-direction: column;

    gap: 26px;

    width: 100%;
    height: 100%;
    padding: 30px;

    input {
        width: 840px;
        height: 40px;
        border: 1px solid #cccccc;
        border-radius: 20px;
        padding: 0 16px;

        &::placeholder {
            color: #cccccc;
        }
    }
`;

export const TitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const LinkButton = styled.button`
    width: max-content;
    height: 40px;
    background: #1e69cb;
    display: flex;
    align-items: center;
    padding: 0 18px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    transition: all 0.3s;
    border-radius: 36px;

    &:hover {
        background: #74b0ff;
    }

    a {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    svg {
        width: 18px;
        height: 18px;
    }
`;

export default TextContentShowWebview;
