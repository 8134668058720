import React, { useState, useEffect, useRef } from 'react'
import Axios from 'axios'

import '../../../styles/webview.scss'

import RankingListWebview from './RankingListWebview'

function useAxios(uuid: string | undefined) {
  const [loading, setLoading] = useState(true)
  const [webviewData, setWebviewData] = useState()
  const requestUri = process.env.REACT_APP_API_HOST + `fishes/${uuid}`

  async function fetchUrl() {
    const result = await Axios.get(requestUri)
    const json = await result.data.data

    setWebviewData(json)
    setLoading(false)
  }

  useEffect(() => {
    fetchUrl()
  }, [])

  return [webviewData, loading]
}

function FishResultWebview(props) {
  const { webviewPopupChange, param } = props

  async function regRanking(e: any) {
    e.preventDefault()

    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
    if (regPhone.test(inputValue) !== true) {
      alert('전화번호 양식에 맞게 입력해주세요.')
      return
    }

    const { phone } = e.target

    const formData = new FormData()

    const data = {
      phone: phone.value,
    }

    formData.append('rankRegDto', new Blob([JSON.stringify(data)], { type: 'application/json' }))

    const requestUri = process.env.REACT_APP_API_HOST + `fishes/${param.current}`

    await Axios.post(requestUri, formData)
      .then((resp) => {
        if (resp.data.resp_code === 'SUCCESS') {
          webviewPopupChange('fishResultPopup', false)
          webviewPopupChange('fishRankingPopup', true)
        }
      })
      .catch((err) => {
        // console.log(err);
        alert('랭킹 등록에 실패하였습니다.')
      })
  }

  const [webviewData, loading]: any = useAxios(param.current)

  const outerH: any = useRef()
  const innerH: any = useRef()

  window.addEventListener('resize', function () {
    calcVh()
  })

  window.addEventListener('DOMContentLoaded', (event) => {
    calcVh()
  })

  function calcVh() {
    const outerHeight = outerH.current.clientHeight
    const innerHeight = innerH.current.offsetHeight

    // let calc = Math.max(Math.min((outerH / innerH), 1), 0);
    const calc: any = Math.max(outerHeight / innerHeight, 0)
    document.documentElement.style.setProperty('--inner-scale', calc)
  }

  const [state, setState] = useState(false)

  useEffect(() => {
    if (outerH.current != undefined) {
      calcVh()
    }
  }, [loading])

  const handlePress = (e: any) => {
    const regex = /^[0-9\b -]{0,13}$/
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value)
    }
  }

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (inputValue.length === 10) {
      setInputValue(inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'))
    }
    if (inputValue.length === 12) {
      setInputValue(inputValue.replace(/-/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'))
    }
    if (inputValue.length === 13) {
      setInputValue(inputValue.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'))
    }
  }, [inputValue])

  if (loading) {
    return <></>
  } else {
    return (
      <>
        {state === true ? (
          <RankingListWebview
            sendMsg={props.sendMsg}
            sendMsgWithParam={props.sendMsgWithParam}
            webviewPopupChange={webviewPopupChange}
            param={param}
          />
        ) : (
          <div id="webview-container" className="webview-container">
            <div
              id="webview-outer-size"
              className="webview-outer-size webview-container-outer-fishresult"
              ref={outerH}
            >
              <div
                id="webview-container-inner"
                className="webview-container-inner webview-container-inner-fishresult"
                ref={innerH}
                onLoad={calcVh}
              >
                <div className="webview-header">
                  <div id="webview-title" className="text-box">
                    뭔가를 잡았다!
                  </div>
                  <button
                    id="webview-close-btn"
                    className="btn"
                    onClick={() => {
                      webviewPopupChange('fishResultPopup', false)
                    }}
                  ></button>
                </div>

                <div className="webview-body">
                  <div className="webview-body-inner">
                    <div className="content-container content-container-fishresult">
                      <form onSubmit={regRanking}>
                        <figure className="content-box">
                          <img
                            src={process.env.REACT_APP_CDN_URL + '/' + webviewData.fileUrl}
                            alt="image"
                            className={
                              'content ' +
                              (webviewData.tierName.includes('아이언')
                                ? 'border-iron'
                                : webviewData.tierName.includes('브론즈')
                                ? 'border-bronze'
                                : webviewData.tierName.includes('실버')
                                ? 'border-silver'
                                : webviewData.tierName.includes('골드')
                                ? 'border-gold'
                                : webviewData.tierName.includes('플레티넘')
                                ? 'border-platinum'
                                : webviewData.tierName.includes('다이아')
                                ? 'border-diamond'
                                : webviewData.tierName.includes('마스터')
                                ? 'border-master'
                                : webviewData.tierName.includes('그랜드마스터')
                                ? 'border-grandmaster'
                                : webviewData.tierName.includes('레전드')
                                ? 'border-legend'
                                : '')
                            }
                          />
                        </figure>
                        <div
                          className={
                            'fish-tier ' +
                            (webviewData.tierName.includes('아이언')
                              ? 'tier-text-iron'
                              : webviewData.tierName.includes('브론즈')
                              ? 'tier-text-bronze'
                              : webviewData.tierName.includes('실버')
                              ? 'tier-text-silver'
                              : webviewData.tierName.includes('골드')
                              ? 'tier-text-gold'
                              : webviewData.tierName.includes('플레티넘')
                              ? 'tier-text-platinum'
                              : webviewData.tierName.includes('다이아')
                              ? 'tier-text-diamond'
                              : webviewData.tierName.includes('마스터')
                              ? 'tier-text-master'
                              : webviewData.tierName.includes('그랜드마스터')
                              ? 'tier-text-grandmaster'
                              : webviewData.tierName.includes('레전드')
                              ? 'tier-text-legend'
                              : '')
                          }
                        >
                          {webviewData.tierName}
                        </div>
                        <div className="fish-name">{webviewData.name}</div>
                        <div className="fish-size">
                          {webviewData.size} <span className="fish-size-unit">cm</span>
                        </div>
                        <div className="fish-info">
                          확률 {webviewData.percent}
                          %의 {webviewData.tierName} {webviewData.name}
                        </div>
                        {webviewData.ranked === true ? (
                          <div className="fish-service-text">
                            <input
                              type="text"
                              placeholder="추첨용 핸드폰번호 입력"
                              name="phone"
                              className="inp-input"
                              onChange={handlePress}
                              value={inputValue}
                            />
                            <div>
                              랭킹등록을 클릭하면 서비스 이용약관 및 개인정보처리방침에 동의하고 만
                              13세 이상임을 확인하게 됩니다.
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="btn-wrap-fish">
                          {webviewData.ranked === true ? (
                            <button className="btn-round">
                              <span>랭킹 등록</span>
                            </button>
                          ) : (
                            <></>
                          )}
                          <button
                            type="button"
                            className="btn-stroke"
                            onClick={() => webviewPopupChange('fishResultPopup', false)}
                          >
                            <span>확인</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default FishResultWebview
