import React, { useState, createContext, useContext } from 'react'

type Props = {
  children: React.ReactNode
}

type LandContextType = { land: { id: number }; updateId: (id: number) => void }

const LandContext = createContext<LandContextType | null>(null)

export function LandProvider({ children }: Props) {
  const [land, setLand] = useState({
    id: 144,
  })

  const updateId = (id: number) => {
    setLand((prev) => ({
      ...prev,
      id,
    }))
  }

  return <LandContext.Provider value={{ land, updateId }}>{children}</LandContext.Provider>
}

export const useLand = () => useContext(LandContext) as LandContextType
