import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { CloseIcon } from 'static/svg'

export default function External({ setState, sendMsg, id }) {
  const [portalData, setPortalData] = useState({
    portalTitle: '',
    portalName: '',
    externalLink: '',
    accessUrl: '',
    useExternalLink: false,
  })

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_HOST}portal/${id}`).then((data) => {
      console.log('포탈 단건 조회', data.data.data)
      const { portalTitle, portalName, externalLink, accessUrl, useExternalLink } = data.data.data
      setPortalData((prev) => ({
        ...prev,
        portalTitle,
        portalName,
        externalLink,
        accessUrl,
        useExternalLink,
      }))
    })
  }, [id])

  return (
    <div className="absolute z-10 w-full mx-2 bg-white left-2/4 p-7 shadow-86 rounded-xl top-2/4 sm:max-w-md -translate-y-2/4 -translate-x-2/4">
      <CloseIcon
        className="absolute w-6 h-auto duration-500 cursor-pointer hover:text-slate-400 text-slate-900 top-3 right-3"
        onClick={() => {
          setState(false)
          sendMsg('Webreceiver', 'ContentSwitch', 1)
        }}
      />
      <h3 className="mb-12 text-xl font-semibold uppercase md:text-2xl text-slate-900">
        {portalData?.portalTitle}
      </h3>
      <p className="mb-40 font-medium text-slate-900">
        {portalData?.portalName} 공간으로 이동하시겠어요?
      </p>
      <div className="flex items-center justify-between gap-4">
        <button
          type="button"
          className="inline-flex justify-center w-full px-12 py-3 font-semibold duration-500 bg-gray-300 border border-transparent rounded-full text-slate-900 shadow-86 sm:text-xl hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
          onClick={() => {
            setState(false)
            sendMsg('Webreceiver', 'ContentSwitch', 1)
          }}
        >
          Cancel
        </button>
        <a
          href={!portalData?.useExternalLink ? `${portalData.accessUrl}` : portalData.externalLink}
          target={!portalData?.useExternalLink ? '_self' : '_black'}
          rel="noreferrer"
          className="inline-flex justify-center w-full px-12 py-3 font-semibold duration-500 border border-transparent rounded-full text-slate-900 shadow-86 sm:text-xl bg-secondary-100 hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-yellow-200 focus:ring-offset-2"
        >
          OK
        </a>
      </div>
    </div>
  )
}
