import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import styles from '../../styles/space.module.scss'
import ChatItem from '../molecules/ChatItem'

interface ChatBodyProps {
  ref: any
  chatList: any
}

interface ChatProps {
  ref: any
}

export const ChatBodyComponent = styled.div<ChatProps>`
  flex-grow: 1;
  margin: 15px 15px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-height: calc(100% - 60px - 90px);
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 7px;
  }
`

const ChatBody: React.FC<ChatBodyProps> = React.forwardRef(function ({ chatList }, ref) {
  return (
    <ChatBodyComponent ref={ref}>
      {chatList != null &&
        chatList.length > 0 &&
        chatList.map((el: any, i: any) => <ChatItem key={i} item={el} />)}
    </ChatBodyComponent>
  )
})
ChatBody.displayName = 'ChatBody'
export default ChatBody
