import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { GrClose } from 'react-icons/gr'

import styles from '../styles/landsearch.module.scss'

import media from '../lib/styles/media'
import axios from 'axios'
import axiosI from '../utils/AxiosI'
import { useSlide } from '../hooks/useSlide'

import loading from '../assets/ui/loading.png'
import Space from '../components/spaces/Space'
import SpaceDetail from '../components/spaces/SpaceDetail'
import Footer from '../components/tile/Footer'
import Guide from '../components/tile/Guide'
import Header from '../components/tile/Header'
import Pagination from '../components/ui/Pagination'

function LandSearch() {
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search)
  const landName = params.get('name')

  const [landList, setLandList]: any = useState()

  const [curPageLandList, setCurPageLandList]: any = useState()
  const [targetElement, setTargetElement]: any = useState()

  useEffect(() => {
    const accessToken = window.localStorage.getItem('plikaTk')
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }

    axios.get(process.env.REACT_APP_API_HOST + `spaces/search/${landName}`, config).then((e) => {
      const mylandsList = e.data.data

      setLandList(mylandsList)
    })
  }, [])

  const [
    onMouse,
    index,
    arrayName,
    newItemPosition,
    slideItemMouseOver,
    slideItemMouseLeave,
    slideInfoMouseOver,
    slideInfoMouseLeave,
    gsapAnimation,
  ] = useSlide()

  useEffect(() => {
    if (landList != null) {
      setTargetElement(curPageLandList[index])
    }
  }, [onMouse, index])

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [showLand, setShowLand] = useState(false)
  const [currentLandInfo, setCurrentLandInfo] = useState({
    accountId: null,
    company_url: '',
    currentJoinMemberTotal: 0,
    description: '',
    email: '',
    idName: '',
    logoUrl: null,
    name: '',
    nickname: '',
    space_id: null,
    templateThumbnail: '',
    template_id: null,
    thumbnailUri: '',
  })

  const { t } = useTranslation()

  const [spaceJoinedMemberFull, setSpaceJoinedMemberFull] = useState(false)

  function enterLand() {
    if (currentLandInfo.currentJoinMemberTotal >= 6) {
      setSpaceJoinedMemberFull(true)

      setTimeout(() => {
        setSpaceJoinedMemberFull(false)
      }, 3000)
    } else {
      window.location.href = `/app/${currentLandInfo.idName}`
    }
  }

  return (
    <>
      <Header show={null}></Header>
      {curPageLandList != null && curPageLandList.length > 0 ? (
        <div className={styles.search_container}>
          <div className={styles.container_inner}>
            <div className={styles.search_header}>
              {i18next.language === 'en' ? (
                <p>
                  <span className={styles.search_count}>{landList.length}</span>
                  {t('search.true.text1')}
                  <span className={styles.search_name}>{landName}</span>
                </p>
              ) : (
                <p>
                  <span className={styles.search_name}>{landName}</span>
                  {t('search.true.text1')}
                  <span className={styles.search_count}>{landList.length}</span>
                  {t('search.true.text2')}
                </p>
              )}
            </div>
            <div className={styles.list_container}>
              <div className={styles.list_item}>
                {curPageLandList.map((el, i) => (
                  <div key={i} className={styles.list_item_box}>
                    <Space
                      slideItemMouseOver={slideItemMouseOver}
                      slideItemMouseLeave={slideItemMouseLeave}
                      index={i}
                      space={el}
                    ></Space>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div id="over_wrap" className={styles.main_over_wrap}>
            {onMouse && targetElement != null ? (
              <SpaceDetail
                space={targetElement}
                gsap={gsapAnimation}
                newItemPosition={newItemPosition.current}
                slideInfoMouseOver={slideInfoMouseOver}
                slideInfoMouseLeave={slideInfoMouseLeave}
              ></SpaceDetail>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.search_container}>
          <div className={styles.container_inner}>
            <div className={`${styles.search_header} ${styles.no_result}`}>
              {i18next.language === 'en' ? (
                <p>
                  {t('search.false.text1')}
                  <span className={styles.search_name}>&ldquo;{landName}&rdquo;</span>
                </p>
              ) : (
                <p>
                  <span className={styles.search_name}>{landName}</span>
                  {t('search.false.text1')}
                </p>
              )}
            </div>
            <div className={styles.search_desc}>
              {t('search.false.text2')} <br /> {t('search.false.text3')}
            </div>
          </div>
        </div>
      )}
      {landList != null && landList.length > 0 ? (
        <Pagination
          totalList={landList}
          itemPerPage={20}
          setCurPageList={setCurPageLandList}
        ></Pagination>
      ) : (
        <></>
      )}
      <Guide></Guide>
      <Footer></Footer>

      {showLand &&
        (isMobile ? (
          <ListDetail>
            <InfoBox>
              <Thumbnail>
                <img
                  src={
                    currentLandInfo?.templateThumbnail != null
                      ? currentLandInfo?.templateThumbnail
                      : loading
                  }
                  alt=""
                />
              </Thumbnail>
              <TextBox>
                <h3>{currentLandInfo.name}</h3>
                <p>{currentLandInfo.company_url}</p>
                <p>{currentLandInfo.description}</p>
              </TextBox>
            </InfoBox>
            <ButtonBox>
              <button onClick={enterLand}>Enter</button>
            </ButtonBox>
            <CloseIcon onClick={() => setShowLand(false)} />
          </ListDetail>
        ) : (
          <></>
        ))}
    </>
  )
}

const ListDetail = styled.div`
  position: sticky;
  width: 100%;
  height: max-content;
  background: #fff;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  border: 1px solid #cccccc;
  box-shadow: 0px -2px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;

  padding: 8px;
  padding-bottom: 20px;
`

const InfoBox = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`

const Thumbnail = styled.div`
  width: 46%;
  height: 260px;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.small} {
    height: 180px;
  }

  @media (max-width: 500px) {
    height: 140px;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 54%;
  gap: 5px;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fee500;
    border-radius: 40px;

    font-size: 22px;
    font-weight: 700;
    transition: 0.3s;
  }

  button:hover {
    background: #fff280;
  }

  ${media.small} {
    button {
      height: 50px;
    }
  }
`

const CloseIcon = styled(GrClose)`
  position: absolute;
  top: 10px;
  right: 10px;

  font-size: 30px;
  font-weight: 900;
  cursor: pointer;
`

export default LandSearch
