import React from 'react'
import uiStyle from '../../styles/ui.module.scss'

function PhotoBox (props) {

    const { profileImgRef, imgSrc, fileHandler } = props;

    return (
        <div className={uiStyle.photo_box}>
            <figure className={uiStyle.photo}><img ref={profileImgRef} alt="" src={imgSrc != null ? imgSrc.toString() : ''}/>
            </figure>
            <label htmlFor="att-file" className={`${uiStyle.icon} ${uiStyle.att}`}><span className="blind">파일 수정</span></label>
            <input type="file" id="att-file" className="blind" accept="image/*" onChange={(e) => fileHandler(e)}/>
        </div>
    )
}

export default PhotoBox