import Axios, {AxiosRequestHeaders} from 'axios';
import { getMainAxios } from '../services/AxiosService';
import axiosI from './AxiosI';
export function initLocalStorage(tokenName: string, tokenValue: string) {
    const oldToken = window.localStorage.getItem(tokenName);

    if (oldToken != null) {
        window.localStorage.removeItem(tokenName);
    }

    window.localStorage.setItem(tokenName, tokenValue);
}

export function verifyToken(){
    
    const requestUri = process.env.REACT_APP_API_HOST + `api/auth/access`;
    const accessToken = window.localStorage.getItem('plikaTk');
    const config  = {
        headers: { Authorization: `Bearer ${accessToken}` }
    }

    const axios = getMainAxios(true);
    return axios.get( requestUri , config);
}