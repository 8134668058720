import React from 'react';
import {ReactNode} from 'react';
import styled from 'styled-components';

interface ButtonStyleProps {
    bgColor?: string;
    hoveredBgColor?: string;
}

export interface ButtonProps extends ButtonStyleProps {
    type: 'submit' | 'button';
    children: ReactNode;
    onClick?: () => void;
}

export const ButtonComponent = styled.button<ButtonStyleProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 0;
    border-radius: 20px;
    background-color: ${({bgColor}) => (bgColor ? bgColor : 'transparent')};

    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;

    &:hover {
        background-color: ${({hoveredBgColor}) =>
            hoveredBgColor ? hoveredBgColor : 'transparent'};
    }
`;

const Button = ({children, ...style}: ButtonProps) => {
    return <ButtonComponent {...style}>{children}</ButtonComponent>;
};

export default Button;
