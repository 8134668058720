import React from 'react'
import sanitizeHtml from 'sanitize-html'

function viewSplitLine(content) {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  // 링크를 감지하여 a 태그로 감싸기
  const replace = (content) => {
    const convertContent = content.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>'
    })

    const htmlArr: string[] = []
    convertContent.split('\n').forEach(function (text) {
      const cleanText = sanitizeHtml(text, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p'],
        allowedAttributes: {
          a: ['href', 'target'],
        },
        allowedIframeHostnames: ['www.youtube.com'],
      })

      if (cleanText != '') {
        const textHtml = '<p>' + cleanText + '</p>'
        htmlArr.push(textHtml)
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
      }
    })

    return { __html: htmlArr.join('') }
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={replace(content)}></div>
    </div>
  )
}

export default viewSplitLine
