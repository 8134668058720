import React from 'react';
import styled, {keyframes} from 'styled-components';

import loadingImg from 'assets/ui/loading.png';
import plikaBetaLogo from 'assets/ui/plika_beta_logo.png';
import plikaLogo from 'assets/ui/plika-logo.png';

import Text from 'components/common/atoms/Text';
import {CTECLogosImg, PlikalandBetaBlackLogo} from '../../static/svg';

const BounceDelay = keyframes`
     0%,
  80%,
  100% {
      transform: scale(0);
  }
  40% {
      transform: scale(1.0);
  }
`;

const Gradient = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(
        to bottom,
        rgb(101 100 100 / 0%),
        rgba(0, 0, 0, 0.9)
    );
    z-index: 2;
`;

const LoadingAnimation = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
`;

const Bounce = styled.div`
    width: 30px;
    height: 30px;
    background-color: white;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
    display: inline-block;
    animation: ${BounceDelay} 1.4s infinite ease-in-out both;

    &:nth-child(1) {
        animation-delay: -0.32s;
    }

    &:nth-child(2) {
        animation-delay: -0.16s;
    }
`;

const Logo = styled.img`
    position: absolute;
    left: 20px;
    bottom: 25px;
    z-index: 999999;

    @media (max-width: 1024px) {
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const TeamInfo = styled.div`
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translateX(-50%);

    display: flex;
    align-items: center;
    z-index: 999999;

    img {
        width: 40px;
        height: 40px;
        margin-right: 13px;
        border-radius: 50%;
    }

    p {
        font-size: 20px;
        font-weight: 700;
        color: #fff;
    }

    @media (max-width: 1024px) {
        top: 20px;
        bottom: inherit;
    }

    @media (max-width: 768px) {
        img {
            width: 30px;
            height: 30px;
            margin-right: 8px;
        }

        p {
            font-size: 14px;
        }
    }
`;

const InfoWrap = styled.div`
    display: flex;
    align-items: center;
    width: max-content;
    height: 40px;
    padding: 0 14px;
    justify-content: space-around;
`;

const CustomLoading = ({spaceName, logoUrl, templateThumbnail, usedCustom}) => {
    return (
        <Block>
            <img
                src={templateThumbnail != null ? templateThumbnail : loadingImg}
                alt=""
            />
            <LoadingAnimation>
                <Bounce></Bounce>
                <Bounce></Bounce>
                <Bounce></Bounce>
            </LoadingAnimation>
            <PlikaLogo />
            <div className="text__wrap">
                <p className="first">일상에서 경험하는 상상의 공간</p>
                <p className="second">2022 문화기술 전람회</p>
                <p className="third">
                    2022 Culture Technology Exhibition & Conference
                </p>
            </div>
            <Logos />
        </Block>
    );
};

const Block = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 666;
    }

    .text__wrap {
        width: 100%;
        height: max-content;
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        z-index: 888;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1.25rem;
    }

    .first {
        font-size: 2.5rem;
        padding-bottom: 1rem;
    }

    .second {
        font-size: 6rem;
    }

    .third {
        font-size: 2.5rem;
        font-weight: 500;
    }

    @media (max-width: 1320px) {
        .first,
        .third {
            font-size: 2rem;
        }

        .second {
            font-size: 4rem;
        }
    }

    @media (max-width: 500px) {
        .first,
        .third {
            font-size: 1.2rem;
        }

        .second {
            font-size: 3rem;
        }
    }
`;

const PlikaLogo = styled(PlikalandBetaBlackLogo)`
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    height: 30px;
    z-index: 999;
    width: 20%;
`;

const Logos = styled(CTECLogosImg)`
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 888;
    width: 33%;

    @media (max-width: 500px) {
        width: 70%;
    }
`;

export default CustomLoading;
