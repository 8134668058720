import React, { useRef, useEffect, useState } from 'react'
import styles from '../../styles/modal.module.scss'
import uiStyle from '../../styles/ui.module.scss'
import styled, {keyframes} from 'styled-components'
import { useMediaQuery } from 'react-responsive';
function Template (props) {
    const isMobile = useMediaQuery({query: '(max-width: 1024px)'});
    const Gradient = styled.div`
        position: absolute;
        bottom:0;
        width: 100%;
        height: 40%;
        background: linear-gradient(
            to bottom,
            rgb(101 100 100 / 0%),
            rgba(0, 0, 0, 0.6)
        );
        z-index:2;
    `

    const { template, setTemplateHandler } = props;

    const hoverRef:any = useRef(null);

    const [ hover , setHover ] = useState(false);
    return (
        <>
        <div ref={e => hoverRef.current = e} className={styles.template_item} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} onClick={isMobile ? () => setTemplateHandler(template) : undefined}>
            <Gradient/>
            <img src={template.thumbnail} alt="" />
            <span className={styles.template_name}>
                {template.name}
            </span>
            {
                hover
                ? <button className={`${styles.sel_btn} ${uiStyle.btn_theme_bl}`} onClick={() => setTemplateHandler(template)}>Select</button>
                : <></>
            }            
        </div>        
        </>
    )
}

export default Template