import React from 'react'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface NoticeStyleProps {
  width?: number
  height?: number
  bgColor?: string
  innerText?: string
}

export const NoticeComponent = styled.button<NoticeStyleProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};

  border: 0;
  border-radius: 20px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#FEE500')};

  font-size: 20px;
  font-weight: 700;

  cursor: auto;

  z-index: 500;
`

const Notice = ({ innerText, ...style }) => {
  return <NoticeComponent {...style}>{innerText}</NoticeComponent>
}

export default Notice
