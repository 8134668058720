import React, { Fragment, useRef, useState } from 'react'
import axios from 'axios'
import { Transition, Dialog } from '@headlessui/react'
import { v4 as uuidv4 } from 'uuid'

import { useModalMode } from 'contexts/ModalModeContext'
import SvgIconPlay from 'components/vectors/IconPlay'

export default function ScanUploader({ sendMsgWithParam, onClose }) {
  const { modalMode, toggleModalMode } = useModalMode()
  const [fileUrl, setFileUrl] = useState('')
  const [glbPath, setGlbPath] = useState('')
  const [scanObjectID, setScanObjectID] = useState('')
  const [file, setFile] = useState<any>(null)
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  function previewVideo(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0]
    if (!file) return

    setFile(file)
    setFileUrl(URL.createObjectURL(file))
  }

  function handleSubmit(e) {
    e.preventDefault()

    const accessToken = localStorage.getItem('plikaTk')
    const cancelToken = axios.CancelToken
    const source = cancelToken.source()

    const formData = new FormData()
    formData.append('file', file)

    // 1차 샌드메시지 to Unity

    console.log('Scan process initiated!!')
    // setScanObjectID(uuidv4().slice(-4))
    const scanID = uuidv4().slice(-4)
    const obj_init = { id: scanID }
    const data_init = JSON.stringify(obj_init)
    console.log(data_init)
    
    sendMsgWithParam('Webreceiver', 'OnStartScan', data_init)
    toggleModalMode('scanUploader', false) // 1차 샌드메시지 후 스캔용 영상 미리보기 창 닫기
    onClose()

    axios
      .post(`https://dpapi.plika.land/api/v1/converts/scan`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
        timeout: 1800000,
      })
      .then((e) => {
        // 스캔 성공
        // 2차 샌드메시지 to Unity
        // setGlbPath(e.data.file_url)
        console.log('Scan process finished!!')
        console.log(e.data.file_url)
        const obj = { url: e.data.file_url, id: scanID, isScanModel: true }
        const data = JSON.stringify(obj)
        console.log(data)
      
        sendMsgWithParam('Webreceiver', 'OnSpawnModel', data)
        
      })
      .catch((e) => {
        // 스캔 실패
      })
  }

  return (
    <Transition.Root show={modalMode.scanUploader} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[999]"
        onClose={() => toggleModalMode('scanUploader', false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity opacity-100 bg-slate-900/25 backdrop-blur"></div>
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl group sm:my-8 sm:w-full sm:max-w-lg">
                <div className="p-8 bg-white">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 mb-7 text-slate-900 line-clamp-1"
                  >
                    Upload your video to 3D Scan
                  </Dialog.Title>
                  <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center mb-6 bg-gray-300 w-80 h-80">
                      {fileUrl ? (
                        <video
                          className="object-cover w-full h-full"
                          src={fileUrl}
                          controls
                          autoPlay
                        />
                      ) : (
                        <SvgIconPlay />
                      )}
                    </div>
                    <a href="#" className="mb-6 text-gray-300 underline">
                      3D Scan에 대해 자세히 알아보기
                    </a>
                    <div className="flex gap-4">
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="video/*"
                        onChange={previewVideo}
                        hidden
                      />
                      <button
                        className="px-6 py-2 text-xl font-bold text-white duration-300 rounded-full bg-primary-100 hover:bg-primary-50"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        영상 업로드
                      </button>
                      <button
                        className="px-4 py-2 text-xl font-bold rounded-full bg-secondary-100 hover:bg-secondary-50"
                        onClick={handleSubmit}
                      >
                        3D Scan 시작
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
