import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import uiStyle from '../../../styles/ui.module.scss';
import styles from '../../../styles/space.module.scss';
import logo from '../../../assets/ui/plika-logo.png';

import Modal from 'components/SideMenu/organisms/Modal';
import MemberThumbnail from '../molecules/MemberThumbnail';
import MemberInfo from '../molecules/MemberInfo';
import {TextComponent} from 'components/common/atoms/Text';
import {BellIcon} from '../../../static/svg';

const MemberItemComponent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;

    ${TextComponent} {
        transition: all 0.3s;
    }

    &:hover ${TextComponent} {
        text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;
    }
`;

const OwnerButton = styled.div`
    background-color: #fee500;
    border-radius: 30px;
    text-align: center;
    box-sizing: border-box;

    width: 60px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #fff280;
    }
`;

const MemberButton = styled.div`
    background-color: #1e69cb;
    font-weight: 700;
    border-radius: 30px;
    text-align: center;
    box-sizing: border-box;
    color: white;

    width: 60px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #74b0ff;
        cursor: pointer;
    }
`;

function MemberItem(props: any) {
    const {
        accountInfo,
        memberInfo,
        setSelMemberInfo,
        webviewPopupChange,
        admin,
        sendRingMsg,
        status,
    } = props;

    const [memberMenuPopup, setMemberMenuPopup] = useState(false);

    const memberMenuRef: any = useRef();

    const memberMenuBtnRef: any = useRef();

    function closePopup(e: any) {
        if (memberMenuRef.current != null) {
            if (
                !memberMenuRef.current.contains(e.target) &&
                !memberMenuBtnRef.current.contains(e.target)
            ) {
                setMemberMenuPopup(false);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('click', closePopup);
        return () => {
            window.removeEventListener('click', closePopup);
        };
    }, []);

    /**
     * RING 기능을 위해 대상에게 webSocket 메시지를 보내는 function
     * sendRingMsg의 인자로 대상의 stomp sessionId를 넣어야 합니다.
     */
    function ring() {
        sendRingMsg(memberInfo.sessionId);
    }

    return (
        <>
            <MemberItemComponent>
                <MemberThumbnail
                    src={
                        memberInfo.detail.thumbnailUri != null &&
                        memberInfo.detail.thumbnailUri.length > 0
                            ? memberInfo.detail.thumbnailUri
                            : logo
                    }
                    alt="thumbnail"
                    width="100%"
                    height="100%"
                    borderRadius="50px"
                    status={status}
                />
                <MemberInfo memberInfo={memberInfo} status={status} />
                <ButtonBox>
                    {status === 'on' &&
                        memberInfo.detail.isOff != null &&
                        memberInfo.detail.isOff && (
                            <LingBellButton onClick={ring}>
                                <BellIcon />
                            </LingBellButton>
                        )}
                    {admin ? (
                        <OwnerButton>Owner</OwnerButton>
                    ) : (
                        <>
                            <MemberButton
                                ref={memberMenuBtnRef}
                                onClick={
                                    accountInfo.isAdmin
                                        ? () =>
                                              setMemberMenuPopup(
                                                  !memberMenuPopup,
                                              )
                                        : undefined
                                }
                            >
                                Member
                            </MemberButton>
                        </>
                    )}
                </ButtonBox>
                {memberMenuPopup ? (
                    <Modal
                        memberInfo={memberInfo}
                        ref={memberMenuRef}
                        onClickEvent={() => {
                            setSelMemberInfo(memberInfo);
                            webviewPopupChange('removeMemberAlert', true);
                        }}
                    />
                ) : (
                    <></>
                )}
            </MemberItemComponent>
        </>
    );
}

const ButtonBox = styled.div`
    display: flex;
    gap: 10px;
`;

const LingBellButton = styled.div`
    width: 24px;
    height: 24px;
    background: #ee3030;
    border-radius: 50%;
    cursor: pointer;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    &:hover {
        background: #ee7070;
    }

    svg {
        width: 18px;
        height: 18px;
    }
`;

export default MemberItem;
