import React from "react";
import { IoClose, IoSearch } from 'react-icons/io5';
import { FiUsers, FiSearch } from 'react-icons/fi';
import styled from 'styled-components';

interface IconStyleProps {
  size: number;
}

export interface IconProps extends IconStyleProps {
  type: 'close' | 'search' | 'users';
}

const ICON_TYPE = {
  close: <IoClose />,
  search: <FiSearch />,
  users: <FiUsers />,
};

export const IconComponent = styled.div<IconStyleProps>`
  font-size: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Icon = ({ type, ...style }: IconProps) => {
  return <IconComponent {...style}>{ICON_TYPE[type]}</IconComponent>;
};

Icon.defaultProps = {
  size: 30,
};

export default Icon;