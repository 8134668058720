import React, { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'

import { useAllLands } from './hooks/useAllLands'
import PopularLandCard from './PopularLandCard'
import { IconCheck, IconChevronDown } from 'components/vectors'
import Pagination from './Pagination'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function GridLands({ title }) {
  const { allLands, setSelected, selected, options, offset, page, setPage, limit, setLimit } =
    useAllLands()

  const numPages = Math.ceil(allLands.length / limit)

  function updateWindowDimensions() {
    if (window.innerWidth < 768) {
      setLimit(6)
      console.log('limit', limit)
    }

    if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      setLimit(9)
    }

    if (window.innerWidth >= 1024) {
      setLimit(16)
    }

    console.log('updateWindowDimensions')
    console.log('limit', limit)
  }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  useEffect(() => {
    console.log(page)
    if (numPages && page >= numPages) {
      setPage(numPages)
    }
  }, [numPages])

  return (
    <section className="relative w-full px-4 pb-12 mx-auto max-w-container sm:px-6 lg:px-8 ">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold md:text-2xl">{title}</h2>
        <Listbox value={selected} onChange={setSelected}>
          {({ open }) => (
            <div className="flex items-center pt-1 ">
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-2 pl-2 pr-16 text-xs text-left bg-transparent border border-gray-300 border-solid rounded-full shadow-sm cursor-default sm:pr-20 focus:border-secondary-100 focus:outline-none focus:ring-1 focus:ring-secondary-100 sm:text-sm">
                  <span className="flex items-center">
                    <span className="block ml-3 line-clamp-1">{selected}</span>
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-3 ml-3 pointer-events-none">
                    <IconChevronDown
                      className={classNames(
                        open ? 'rotate-180' : '',
                        'w-4 sm:w-5 h-auto duration-500',
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 w-full py-3 mt-1 overflow-auto text-base bg-white shadow-lg top-10 scrollbar-hide rounded-2xl max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {options.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-secondary-100 text-slate-900' : 'text-slate-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'text-xs sm:text-sm ml-3 block truncate',
                                )}
                              >
                                {option}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-slate-900' : 'text-primary-100',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                )}
                              >
                                <IconCheck className="w-4 h-auto sm:w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>
      </div>

      <div className="grid  grid-cols-2  md:grid-cols-3 grid-rows-3 lg:grid-cols-4 lg:grid-rows-4  gap-1.5">
        {allLands
          .slice(offset, offset + limit > allLands.length ? allLands.length : offset + limit)
          .map((landData) => (
            <PopularLandCard landData={landData} />
          ))}
      </div>

      <Pagination
        allLands={allLands}
        limit={limit}
        page={page}
        setPage={setPage}
        numPages={numPages}
      />
    </section>
  )
}
