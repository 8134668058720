export function useCreateLand(accountInfo, setAlert) {
    const accessToken = window.localStorage.getItem('plikaTk');
    if (accessToken != null && accessToken != 'null') {
        if (accountInfo.myLands != null && accountInfo.myLands.length > 0) {
            setAlert(true);
        } else {
            window.location.href = '/lands/create';
        }
    } else {
        window.localStorage.setItem('login_redirect_url', '/lands/create');
        window.location.href = '/login';
    }
}
