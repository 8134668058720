import { useQuery, useQueryClient } from '@tanstack/react-query'

import { axiosInstance } from 'axiosInstance'
import { popularKeys, spaceKeys } from 'react-query/constants'

import { Land } from 'types'

async function getPopularLands(): Promise<Land[]> {
  const { data } = await axiosInstance.get('/api/metaverse/lands/by/expsr')
  return data.data.popularLand
}

export function usePopularLands() {
  const queryClient = useQueryClient()

  const fallback: Land[] = []
  const { data: popularLands = fallback } = useQuery({
    queryKey: popularKeys.lists(),
    queryFn: getPopularLands,
    onSuccess: (data) => {
      data.map((land: Land) => {
        queryClient.setQueryData(spaceKeys.detail(land.spaceId), land)
      })
    },
  })

  return { popularLands }
}
