import React, {useEffect, useRef, useTransition} from 'react';
import styled from 'styled-components';
import uiStyle from '../../styles/ui.module.scss';
import {BiHelpCircle} from 'react-icons/bi';
import {useTranslation} from 'react-i18next';

function InputWithTitle(props) {
    const {
        title,
        value,
        name,
        placeholder,
        onChange,
        autoFocus,
        alert,
        helpType,
        errorMessage,
    } = props;

    const {t} = useTranslation();

    const helpMessage = {
        title: [
            `${t('create.help.title.text1')}`,
            `${t('create.help.title.text2')}`,
        ],
        website: [
            `${t('create.help.website.text1')}`,
            `${t('create.help.website.text2')}`,
        ],
    };

    // const errorMessage = {
    //     title: 'Title을 입력해주세요',
    //     website: '잘못된 URL 주소에요',
    // };

    const inputRef: any = useRef();

    useEffect(() => {
        if (autoFocus) {
            if (inputRef.current != null) {
                inputRef.current.focus();
            }
        }
    }, [inputRef.current]);

    return (
        <div className={uiStyle.input_title_container}>
            <StyledLabel>
                <Title>
                    <div className={uiStyle.title_box}>{title}</div>
                    {helpType && (
                        <HelpBox>
                            <IconHelpCircle />
                            <div>
                                {helpMessage[helpType].map((el, i) => (
                                    <StyledP key={i}>{el}</StyledP>
                                ))}
                            </div>
                        </HelpBox>
                    )}
                </Title>
                {alert && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </StyledLabel>
            <input
                ref={inputRef}
                type="text"
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                className={
                    alert
                        ? `${uiStyle.input_round} ${uiStyle.alert}`
                        : uiStyle.input_round
                }
                autoComplete='off'
            />
        </div>
    );
}

const StyledLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 12px;
        font-weight: 400;
    }
`;

const StyledP = styled.p``;

const ErrorMessage = styled.p`
    color: #ee3030;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const HelpBox = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    div {
        display: none;
        position: absolute;
        width: 210px;
        height: 90px;
        left: 0px;
        top: 24px;

        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        transition: 0.3s;
        padding: 15px;
        z-index: 10;

        flex-direction: column;

        p {
            font-size: 12px;
        }
    }

    &:hover div {
        display: flex;
    }

    &:hover svg {
        color: #000;
    }
`;

const IconHelpCircle = styled(BiHelpCircle)`
    font-size: 24px;
    color: #ccc;
    cursor: pointer;
    transition: all 0.3s;
`;

export default InputWithTitle;
