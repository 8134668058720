const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export function isValidYoutubeUri(url: any) {
    return (
        url.startsWith('https://www.youtube.com/watch?v=') ||
        url.startsWith('https://www.youtube.com') ||
        url.startsWith('http://www.youtube.com/watch?v=') ||
        url.startsWith('http://www.youtube.com') ||
        url.startsWith('www.youtube.com/watch?v=') ||
        url.startsWith('youtube.com/watch?v=') ||
        url.startsWith('https://youtu.be/') ||
        url.startsWith('http://youtu.be/') ||
        url.startsWith('youtu.be/')
    );
}

export function getEmbedYoutubeUrl(url: any) {
    const match = url.match(regExp);
    let youtubeUri = 'https://www.youtube.com/embed/';
    youtubeUri += match && match[7].length == 11 ? match[7] : false;
    return youtubeUri;
}
