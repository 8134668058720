import React, { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'

import { useModalMode } from 'contexts/ModalModeContext'
import { useFeaturedLand } from './hooks/useFeaturedLand'

import LandUserBadge from './LandUserBadge'
import SvgIconHome from 'components/vectors/IconHome'
import { useLand } from 'contexts/LandContext'
import { useQueryClient } from '@tanstack/react-query'
import { spaceKeys } from 'react-query/constants'
import { useSpace } from './hooks/useSpace'
import { Land } from 'types'

export default function FeaturedLandDetail() {
  const { land } = useLand()
  const queryClient = useQueryClient()
  const { modalMode, toggleModalMode } = useModalMode()
  // const { featuredLand: landData } = useFeaturedLand()
  const landData: Land | undefined = queryClient.getQueryData(spaceKeys.detail(land.id))
  const { enter } = useSpace()

  return (
    <Transition.Root show={modalMode.landDetail} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 "
        onClose={() => toggleModalMode('landDetail', false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity opacity-100 bg-slate-900/25 backdrop-blur"></div>
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {landData ? (
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl group sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white">
                    <div className="relative w-full overflow-hidden aspect-video shadow-86">
                      <LandUserBadge
                        currentUsers={landData.currentJoinMemberTotal}
                        maxUsers={landData.connectUserLimit}
                      />
                      <img
                        className="object-cover w-full h-full"
                        src={landData.templateThumbnail}
                        alt="thumbnail"
                        loading="lazy"
                      />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <button
                          type="button"
                          className="inline-flex justify-center visible px-12 py-3 font-semibold duration-500 border border-transparent rounded-full md:invisible animate-jello group-hover:visible text-slate-900 shadow-86 hover:animate-none sm:text-xl bg-secondary-100 hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-yellow-200 focus:ring-offset-2"
                          onClick={() => enter(landData)}
                        >
                          Enter
                        </button>
                      </div>
                    </div>
                    <div className="px-3 pt-3 pb-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title
                          as="h3"
                          className="mb-2 text-lg font-medium leading-6 text-slate-900 line-clamp-1"
                        >
                          {landData.name}
                        </Dialog.Title>
                        {landData.companyUrl && (
                          <a
                            className="p-2.5 bg-primary-100 rounded-full shrink-0 flex items-center justify-center shadow-86 focus:outline-none hover:bg-primary-50"
                            href={landData.companyUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <SvgIconHome className="w-4 h-4 text-white" />
                          </a>
                        )}
                      </div>
                      <div className="flex items-center gap-2 mb-2">
                        <div className="w-6 h-6 overflow-hidden rounded-full shadow-86 shrink-0 ">
                          <img
                            className="object-cover w-full h-full"
                            src={landData.thumbnailUri}
                            alt="avatar"
                          />
                        </div>
                        <p className="text-sm text-gray-500 line-clamp-1">{landData.nickname}</p>
                      </div>
                      <p className="text-sm text-gray-500">{landData.description}</p>
                    </div>
                  </div>
                </Dialog.Panel>
              ) : null}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
