import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  Navigate,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'
import AdminWebView from './components/webview/AdminWebView'
import FishResult from './components/webview/FishResult'
import RankingList from './components/webview/RankingList'
import Plika from './pages/lands/Plika'
import MemberWebview from './components/webview/MemberWebView'
import FishItemList from './components/webview/FishItemList'
import WebglUpload from './pages/WebglUpload'
import CommonUpload from './pages/CommonUpload'
import AssetBundleUpload from './pages/AssetBundleUpload'
import Kospi from './pages/Kospi'
import Bitcoin from './pages/BitCoin'
import WebviewTest from './pages/WebviewTest'
import AuthTestPage from './pages/AuthTestPage'
import AuthProcessingTestPage from './pages/AuthProcessingTestPage'
import Login from './pages/Login'
import Main from './pages/Main'
import ThreejsTest from './pages/threejsTest'
import { useDispatch, useSelector } from 'react-redux'
import { verifyToken } from './utils/TokenUtil'
import { setLoginStatus } from './reducers/login'
import ThreejsTestImpl from './pages/threejsTestImpl'
import SketchFabTestPage from './pages/SketchFabTestPage'
import LoginAfterProfile from './pages/LoginAfterProfile'
import LandList from './pages/LandList'
import Pricing from './pages/Pricing'
import LandSearch from './pages/LandSearch'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import Myland from './pages/Myland'
import LandCreate from './pages/LandCreate'
import EditLand from './pages/EditLand'
import Space from './pages/Space'
import Plikatest from './pages/lands/Plikatest'
import VonageTestPage from './pages/VonageTestPage'
import VonageScreenTestPageImpl from './pages/VonageScreenTestPageImpl'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styles/reset'
import theme from 'styles/theme'
import Control from 'components/organisms/Control'
import Guide from 'components/tile/Guide'
import GuidePopup from 'components/molecules/GuidePopup'
import LockScreenWebview from 'components/organisms/LockScreenWebview'
import ContentWebview from 'components/modals/webview/ContentWebview'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import RegisterDetails from './pages/RegisterDetails'
import Loading from 'components/organisms/Loading'
import Contact from './pages/Contact'
import Upload from './pages/hanam/Upload'
import CustomLoading from 'components/hanam/CustomLoading'
import DtocControl from 'components/modals/DtocControl'
import Settings from 'components/settings/Settings'
import { AccountContextProvider } from 'contexts/AccountContext'
import LockScreenEditWebview from 'components/organisms/LockScreenWebview/edit'

function App() {
  if (process.env.REACT_APP_MODE == 'prod') {
    const trackingId: string = process.env.REACT_APP_GA_TRACKING_ID as string
    const tagManagerId: string = process.env.REACT_APP_TAG_MANAGER_ID as string
    const tagManagerArgs = { gtmId: tagManagerId }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      ReactGA.initialize(trackingId)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
      hotjar.initialize(3115127, 6)
      TagManager.initialize(tagManagerArgs)
    }, [tagManagerArgs, trackingId])
  }

  return (
    <ThemeProvider theme={theme}>
      <AccountContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/hanam/upload" element={<Upload />} />
            <Route path="/register/details" element={<RegisterDetails />} />
            <Route path="/">
              <Route path="/" element={<Main />} />
              <Route path="/landing/test" element={<Main />} />
              <Route path="login/profile" element={<LoginAfterProfile />} />
              <Route path="lands" element={<LandList></LandList>} />
              <Route path="lands/search" element={<LandSearch></LandSearch>} />
              <Route path="/contact" element={<Contact />} />
              <Route path="pricing" element={<Pricing></Pricing>} />
              <Route path="privacy" element={<Privacy></Privacy>} />
              <Route path="terms" element={<Terms></Terms>} />
              <Route path="mylands/edit/:idName" element={<EditLand></EditLand>} />
              <Route path="space" element={<Space />} />
              <Route path="auth" element={<AuthTestPage />} />
              <Route path="mylands" element={<Myland></Myland>} />
              <Route path="lands/create" element={<LandCreate></LandCreate>} />
              <Route path="login" element={<Login />} />
              <Route path="login/info" element={<RegisterDetails />} />
              <Route path="login/check" element={<LoginAfterProfile />} />
              <Route path="auth/redirect" element={<AuthProcessingTestPage />} />
              <Route path="/lobby/:serviceName" element={<Settings />} />
              <Route path="/app/:spaceName" element={<Space />} />
              <Route path="/:serviceName" element={<Space />} />
              {/*<Route path="3d" element={<Plika />} />*/}
              {/*<Route path="3d-duplicate" element={<Plikatest />} />*/}
              {/* <Route path="edit/webview/*" element={<AdminWebView />} />
                          <Route path="show/webview/*" element={<MemberWebview />} />
                          <Route path="/fishes/:uuid" element={<FishResult />} />
                          <Route
                              path="/fishes/ranking/:uuid"
                              element={<RankingList />}
                          />
                          <Route path="/fishes/ranking" element={<RankingList />} />
                          <Route path="/fishes/items" element={<FishItemList />} /> */}
              <Route path="/common/webgl" element={<CommonUpload />} />
              <Route path="/webgl" element={<WebglUpload />} />
              <Route path="/assetbundle" element={<AssetBundleUpload />} />
              <Route path="ix17e" element={<SketchFabTestPage />} />
              {/* <Route path="/kospi" element={<Kospi />} />
                          <Route path="/coin" element={<Bitcoin />} />
                          <Route path="test" element={<TestPage />} />
                          <Route path="webtest" element={<WebviewTest />} /> */}
              {/* <Route path="threetest" element={<ThreejsTest />} />
                          <Route path="3dtest" element={<ThreejsTestImpl />} />
                          <Route path="sketchtest" element={<SketchFabTestPage />} />
        
                          <Route path="sketchtest" element={<SketchFabTestPage />} />
                          <Route path="vonage/test" element={<VonageTestPage />} />
                          <Route path="vonage/test/2" element={<VonageTestPage2 />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </AccountContextProvider>
    </ThemeProvider>
  )
}

export default App
