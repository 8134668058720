import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';

const GlobalFullHeight = createGlobalStyle`
  html, body #root {
    height: 100%;
  }
`;

interface Props {
    children: React.ReactNode;
}
function FullHeightPage({children}: Props) {
    return (
        <>
            <Page>{children}</Page>
            <GlobalFullHeight />
        </>
    );
}

const Page = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: auto;

    z-index: 999;
    display: flex;
    flex-direction: column;
`;

export default FullHeightPage;
