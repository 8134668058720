import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import axiosI from '../../../utils/AxiosI'

import Text from 'components/atoms/Text'
import Icon from 'components/atoms/Icon'
import InputWithTitle from 'components/ui/InputWithTitle'
import Button from 'components/atoms/Button'
import ToggleWithLabel from 'components/ui/ToggleWithLabel'

const LockScreenWebviewComponent = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
`

const Modal = styled.div`
  background-color: white;
  width: 360px;
  height: 400px;
  border-radius: 20px;
  padding: 33px 30px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  gap: 12px;
`

const OkButton = styled(Button)`
  width: 140px;
  height: 40px;
  border-radius: 20px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const PasswordWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const LockScreenEditWebview = ({
  onClose,
  index,
  passwordData,
  sendMsgWithParam,
  setInsidePassword,
}) => {
  const webviewInner: any = useRef(null)

  function closePopup(e) {
    if (!webviewInner.current.contains(e.target)) {
      onClose()
    }
  }

  const [inputs, setInputs] = useState({
    id: passwordData.id,
    at: passwordData.at,
    password: passwordData.password,
  })

  const { id, at, password } = inputs

  const inputValueHandler = (e) => {
    const { value, name } = e.target
    setInputs({
      ...inputs,
      [name]: value,
    })
  }

  const [toggle, setToggle] = useState({
    setPassword: passwordData.password != null ? true : false,
  })

  const toggleChange = (e) => {
    const { name } = e.target
    setToggle((prev) => ({
      ...prev,
      [name]: !prev[name],
    }))
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault()
    const { setPassword, at, password } = e.target

    const data = {
      at: setPassword.checked ? at.value : inputs.at,
      password: setPassword.checked ? password.value : null,
    }

    const formData = new FormData()

    formData.append(
      'insidePassword',
      new Blob([JSON.stringify(data)], { type: 'application/json' }),
    )

    const requestUri = process.env.REACT_APP_API_HOST + `insidePassword/${id}`

    const accessToken = window.localStorage.getItem('plikaTk')
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
    await axiosI
      .post(requestUri, data, config)
      .then((resp) => {
        if (setPassword.checked) {
          setInsidePassword(id, at.value, password.value)
          onClose()
        } else {
          setInsidePassword(id, inputs.at, null)
          onClose()
        }
      })
      .catch((err) => {
        // console.log(err);
      })
  }

  return (
    <LockScreenWebviewComponent onPointerDown={(e) => closePopup(e)}>
      <Modal ref={webviewInner}>
        <Header>
          <Text size={2.0} weight={700}>
            {passwordData.at}
          </Text>
          <Button type="button" bgColor="white" hoveredBgColor="#cccccc" onClick={() => onClose()}>
            <Icon type="close" size={30} />
          </Button>
        </Header>
        <form onSubmit={(e) => onSubmitHandler(e)}>
          <PasswordWrap>
            <ToggleWithLabel
              id="setPassword"
              toggle={toggle['setPassword']}
              toggleChange={toggleChange}
              title={'Set Password'}
              toggleName={'setPassword'}
              name={'setPassword'}
            ></ToggleWithLabel>
            {toggle['setPassword'] && (
              <>
                <InputWrap>
                  <InputWithTitle
                    title="Room Title"
                    name="at"
                    placeholder="Room Title"
                    value={at}
                    autoFocus={false}
                    onChange={inputValueHandler}
                  ></InputWithTitle>
                </InputWrap>
                <InputWrap>
                  <InputWithTitle
                    title="Password"
                    name="password"
                    placeholder="Password"
                    value={password != null ? password : ''}
                    autoFocus={false}
                    onChange={inputValueHandler}
                  ></InputWithTitle>
                  {/* <TextWrap>
                                    <Text size={0.8} weight={400} color='#EE3030'>잘못된 비밀번호 입니다</Text>
                                </TextWrap> */}
                </InputWrap>
              </>
            )}

            <ButtonWrap>
              <OkButton type="submit" bgColor="#FEE500" hoveredBgColor="#FFF280">
                <Text size={1.5} weight={700}>
                  OK
                </Text>
              </OkButton>
            </ButtonWrap>
          </PasswordWrap>
        </form>
      </Modal>
    </LockScreenWebviewComponent>
  )
}

export default LockScreenEditWebview
