import i18next from 'i18next';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {BellIcon, CloseIcon} from '../../static/svg';

function LingBellAlert(props) {
    const {member, close} = props;
    const {t} = useTranslation()
    return (
        <Block>
            <IconWrap>
                <IconBell />
            </IconWrap>
            <IconClose onClick={close} />
            <UserInfoBox>
                <ImageWrap>
                    <img src={member.thumbnailUri} alt="" />
                </ImageWrap>
                {i18next.language === 'en' ? (
                    <p>
                        <span>{member.nickname}</span>
                    </p>
                ) : (
                    <p>
                        <span>{member.nickname}</span> 님이
                    </p>
                )}
            </UserInfoBox>
            <p>{t('space.common.ring_bell_alert.text1')}</p>
        </Block>
    );
}

const Block = styled.div`
    position: absolute;
    bottom: 96px;
    left: 50%;
    margin-left: -150px;

    width: 300px;
    height: 100px;
    background: #fff;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    z-index: 100;

    p {
        font-size: 18px;
    }

    span {
        font-size: 16px;
        font-weight: 700;
        padding-right: 10px;
    }
`;

const IconClose = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;

    width: 24px;
    height: 24px;
    cursor: pointer;
`;

const IconWrap = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ee3030;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconBell = styled(BellIcon)`
    color: #fff;
`;

const UserInfoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

const ImageWrap = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export default LingBellAlert;
