import React, { forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import styles from '../../../styles/space.module.scss'

import logo from '../../../assets/ui/plika-logo.png'
import {
  CamIcon,
  CamXIcon,
  ChatIcon,
  EditIcon,
  EditXIcon,
  HelpCircleIcon,
  MicIcon,
  MicXIcon,
  PlayersIcon,
  RankIcon,
  ScreenIcon,
  ScreenXIcon,
  SmileIcon,
  SpeakerIcon,
  SpeakerXIcon,
  ToggleIcon,
} from '../../../static/svg'
import Text from 'components/common/atoms/Text'

import toast from 'react-hot-toast'

const IconWrap = styled.div`
  position: relative;

  & figure {
    background: url(../../../assets/ui/chat_icon_pack.png) no-repeat 0 0 / auto 101%;
  }
`

const New = styled(Text)`
  position: absolute;
  right: 0;
  top: -4px;

  @media (max-width: 768px) {
    top: -10px;
  }
`

function UserMenu(props: any, ref: any) {
  const {
    accountInfo,
    admin,
    webviewPopupChange,
    menuPopupChange,
    editModeStatus,
    speakerStatus,
    micStatus,
    screenStatus,
    camStatus,
    chatStatus,
    setStatus,
    vngCallRef,
    videoDeviceRef,
    joinedRoom,
    chatList,
    logoMenuPopup,
    sendMsgWithParam,
    logoMenuBtnRef,
    copyLink,
    logoutModalPopup,
    ModalClose,
    connectedUsers,
    webviewData,
    sideMenuPopup,
    statusValueHandler,
    isPlika,
    setControlWebview,
    mintPopup,
    actionStatus,
    handleToast,
  } = props

  const [newMessage, setNewMessage] = useState(false)

  useEffect(() => {
    console.log('webviewData', webviewData)
  }, [])

  useEffect(() => {
    if (!chatStatus && chatList.length > 0) {
      setNewMessage(true)
    }
  }, [chatList.length])

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const isSmall = useMediaQuery({ query: '(max-width: 768px)' })

  function camStatusHandler() {
    if (vngCallRef.current != null && joinedRoom.current != null && videoDeviceRef.current) {
      setStatus('camStatus', !camStatus)
    }
  }

  function checkMobile() {
    const user = navigator.userAgent
    let is_mobile = false

    if (
      user.indexOf('iPhone') > -1 ||
      user.indexOf('Android') > -1 ||
      user.indexOf('iPad') > -1 ||
      user.indexOf('iPod') > -1
    ) {
      is_mobile = true
    }

    return is_mobile
  }

  const _isMobile = checkMobile()

  return (
    <>
      <div className={styles.unity_user_menu_container}>
        {isSmall === false && (
          <div
            className={styles.user_info}
            onClick={() => webviewPopupChange('profilePopup', true)}
          >
            <Bubble mintPopup={mintPopup}>
              <p>프로필 변경</p>
            </Bubble>
            <figure>
              <img src={accountInfo.thumbnailUri ? accountInfo.thumbnailUri : logo} alt="" />
            </figure>

            <div className={styles.user_info_text_box}>
              <div className={styles.user_nickname}>{accountInfo.nickname}</div>
              <div className={styles.user_email}>{accountInfo.email}</div>
            </div>
          </div>
        )}
        <div className={styles.user_menu_btn_box}>
          {isSmall && (
            <div
              className={styles.user_info}
              onClick={() => webviewPopupChange('profilePopup', true)}
            >
              <figure>
                <Bubble mintPopup={mintPopup}>
                  <p>프로필</p>
                  <p>변경</p>
                </Bubble>
                <img src={accountInfo.thumbnailUri ? accountInfo.thumbnailUri : logo} alt="" />
              </figure>

              <div className={styles.user_info_text_box}>
                <div className={styles.user_nickname}>{accountInfo.nickname}</div>
                <div className={styles.user_email}>{accountInfo.email}</div>
              </div>
            </div>
          )}
          <figure
            onClick={() => setStatus('speakerStatus', !speakerStatus)}
            className={
              speakerStatus
                ? `${styles.icon} ${styles.att}`
                : `${styles.icon} ${styles.att} ${styles.disabled}`
            }
          >
            <Bubble mintPopup={mintPopup}>
              <p>배경음</p>
            </Bubble>
            {speakerStatus ? <SpeakerIcon /> : <SpeakerXIcon />}
          </figure>

          <figure
            onClick={
              vngCallRef.current != null && joinedRoom.current != null
                ? () => setStatus('micStatus', !micStatus)
                : () => toast.success('현재 대화할 상대방이 없어요')
            }
            className={
              micStatus
                ? `${styles.icon} ${styles.att}  ${styles.active}`
                : `${styles.icon} ${styles.att}`
            }
          >
            <Bubble mintPopup={mintPopup}>
              <p>마이크</p>
            </Bubble>
            {micStatus ? (
              <MicIcon className={styles.active_icon} />
            ) : (
              <MicXIcon className={styles.activex_icon} />
            )}
          </figure>
          <figure
            // onClick={webviewData.spaceId == 158? () => setStatus('camStatus', !camStatus): undefined}
            onClick={
              vngCallRef.current && joinedRoom.current != null
                ? () => camStatusHandler()
                : () => toast.success('현재 대화할 상대방이 없어요')
            }
            className={
              camStatus
                ? `${styles.icon} ${styles.att} ${styles.active}`
                : `${styles.icon} ${styles.att}`
            }
          >
            <Bubble mintPopup={mintPopup}>
              <p>화상캠</p>
            </Bubble>
            {camStatus ? (
              <CamIcon className={styles.active_icon} />
            ) : (
              <CamXIcon className={styles.activex_icon} />
            )}
          </figure>
          {!_isMobile && (
            <figure
              onClick={() => {
                if (!joinedRoom.current) {
                  toast.success('현재 대화할 상대방이 없어요')
                }

                if (!screenStatus && vngCallRef.current && joinedRoom.current != null) {
                  vngCallRef.current.startScreenShare('PK_' + joinedRoom.current.participantId)
                } else {
                  vngCallRef.current.stopScreenShare()
                }
              }}
              className={
                screenStatus
                  ? `${styles.icon} ${styles.att} ${styles.active}`
                  : `${styles.icon} ${styles.att}`
              }
            >
              <Bubble mintPopup={mintPopup}>
                <p>화면공유</p>
              </Bubble>
              {screenStatus ? (
                <ScreenIcon className={styles.active_icon} />
              ) : (
                <ScreenXIcon className={styles.activex_icon} />
              )}
            </figure>
          )}
          {webviewData.sceneInfoDtos[0].templateInfoDto.showFishRankingBtn && isSmall === false && (
            <figure
              className={`${styles.icon} ${styles.att} `}
              onClick={() => webviewPopupChange('fishRankingPopup', true)}
            >
              <Rank />
            </figure>
          )}

          {webviewData.sceneInfoDtos != null &&
            webviewData.sceneInfoDtos.length > 0 &&
            webviewData.sceneInfoDtos[0].templateInfoDto.useCommunication && (
              <figure
                ref={ref}
                className={`${styles.icon} ${styles.att} `}
                onClick={() => {
                  setStatus('actionStatus', !actionStatus)
                }}
              >
                <Bubble mintPopup={mintPopup}>
                  <p>감정표현</p>
                </Bubble>
                <Smile />
              </figure>
            )}

          {isMobile && (
            <>
              <IconWrap>
                <figure
                  onClick={() => {
                    setStatus('chatStatus', !chatStatus)
                    setNewMessage(false)
                    !chatStatus
                      ? menuPopupChange('sideMenuPopup', true)
                      : menuPopupChange('sideMenuPopup', false)
                  }}
                  className={
                    chatStatus
                      ? `${styles.chat} ${styles.icon} ${styles.att}  ${styles.active}`
                      : `${styles.chat} ${styles.icon} ${styles.att}`
                  }
                >
                  <Bubble mintPopup={mintPopup}>
                    <p>채팅창</p>
                  </Bubble>
                  <ChatIcon />
                </figure>

                {newMessage && (
                  <New size={14} weight={700} color="#FEE500">
                    NEW
                  </New>
                )}
              </IconWrap>
              <figure
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  admin ? setStatus('editModeStatus', !editModeStatus) : null
                }}
                className={
                  editModeStatus
                    ? `${styles.icon} ${styles.att}  ${styles.active}`
                    : `${styles.icon} ${styles.att}`
                }
              >
                <Bubble mintPopup={mintPopup}>
                  <p>편집모드</p>
                </Bubble>
                {editModeStatus ? (
                  <EditIcon className={styles.edit_icon} />
                ) : (
                  <EditXIcon className={styles.editx_icon} />
                )}
              </figure>
            </>
          )}
        </div>
      </div>
      {isMobile === false && (
        <>
          <LandName>
            {webviewData.logoUrl && (
              <ImageWrap>
                <img src={webviewData.logoUrl} alt="" />
              </ImageWrap>
            )}
            <p>{webviewData.spaceName}</p>
          </LandName>
          <div className={styles.unity_user_menu_container}>
            <div className={styles.user_menu_btn_box}>
              {webviewData.sceneInfoDtos[0].templateInfoDto.showGuideBtn && (
                <figure
                  onClick={() => {
                    // TODO: Guide 창 띄우기
                    setControlWebview(true)
                  }}
                  className={
                    chatStatus
                      ? `${styles.chat} ${styles.icon} ${styles.att}  ${styles.active}`
                      : `${styles.chat} ${styles.icon} ${styles.att}`
                  }
                >
                  <Bubble mintPopup={mintPopup}>
                    <p>도움말</p>
                  </Bubble>
                  <HelpCircleIcon />
                </figure>
              )}
              {connectedUsers != null && (
                <div className={styles.unity_info_bar}>
                  <Bubble mintPopup={mintPopup}>
                    <p>현재 접속인원</p>
                  </Bubble>
                  <div
                    className={
                      sideMenuPopup && !chatStatus
                        ? `${styles.unity_info_players} ${styles.active}`
                        : styles.unity_info_players
                    }
                    onClick={
                      sideMenuPopup
                        ? chatStatus
                          ? () => {
                              statusValueHandler('chatStatus', false)
                            }
                          : () => {
                              menuPopupChange('sideMenuPopup', false)
                              statusValueHandler('chatStatus', false)
                            }
                        : () => {
                            menuPopupChange('sideMenuPopup', true)
                            statusValueHandler('chatStatus', false)
                          }
                    }
                  >
                    {/* <figure
                                        className={
                                            sideMenuPopup && !chatStatus
                                                ? `${styles.icon} ${styles.att} ${styles.active}`
                                                : `${styles.icon} ${styles.att}`
                                        }
                                    >
                
                                    </figure> */}
                    <PlayersIcon />
                    <span>
                      {connectedUsers.connectedMembers != undefined &&
                        connectedUsers.connectedGuests != undefined &&
                        (
                          connectedUsers.connectedMembers.filter((e: any) => e.detail.connected)
                            .length + connectedUsers.connectedGuests.length
                        ).toString()}
                    </span>
                    /
                    {webviewData.sceneInfoDtos != null && webviewData.sceneInfoDtos.length > 0
                      ? webviewData.sceneInfoDtos[0].templateInfoDto.connectUserLimit
                      : 25}
                  </div>
                </div>
              )}
              <IconWrap>
                <figure
                  onClick={() => {
                    setStatus('chatStatus', !chatStatus)
                    setNewMessage(false)
                    !chatStatus
                      ? menuPopupChange('sideMenuPopup', true)
                      : menuPopupChange('sideMenuPopup', false)
                  }}
                  className={
                    chatStatus
                      ? `${styles.chat} ${styles.icon} ${styles.att}  ${styles.active}`
                      : `${styles.chat} ${styles.icon} ${styles.att}`
                  }
                >
                  <Bubble mintPopup={mintPopup}>
                    <p>채팅창</p>
                  </Bubble>
                  <ChatIcon />
                </figure>
                {newMessage && (
                  <New size={14} weight={700} color="#FEE500">
                    NEW
                  </New>
                )}
              </IconWrap>
              <figure
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  admin ? setStatus('editModeStatus', !editModeStatus) : null
                }}
                className={
                  editModeStatus
                    ? `${styles.icon} ${styles.att}  ${styles.active}`
                    : `${styles.icon} ${styles.att}`
                }
              >
                <Bubble mintPopup={mintPopup}>
                  <p>편집모드</p>
                </Bubble>
                {editModeStatus ? (
                  <EditIcon className={styles.edit_icon} />
                ) : (
                  <EditXIcon className={styles.editx_icon} />
                )}
              </figure>
              <figure
                ref={logoMenuBtnRef}
                className={`${styles.icon} ${styles.att}`}
                onClick={() => menuPopupChange('logoMenuPopup', !logoMenuPopup)}
              >
                <Bubble mintPopup={mintPopup}>
                  <p>메뉴</p>
                </Bubble>
                <ToggleIcon />
              </figure>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const LandName = styled.div`
  width: 300px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }
`

const ImageWrap = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

interface BubbleProps {
  mintPopup: any
}

const Bubble = styled.div<BubbleProps>`
  width: 104%;
  height: 40px;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  top: -60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  display: ${(props) => (props.mintPopup === 0 ? 'flex' : 'none')};
  transition: all 0.3s;
  color: #000;

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    width: 180%;
  }

  @media (max-width: 500px) {
    width: 160%;
  }
`

const Smile = styled(SmileIcon)`
  width: 24px !important;
`

const Rank = styled(RankIcon)`
  width: 24px !important;
`

export default forwardRef(UserMenu)
