import React from 'react'

export default function MiddleVideo() {
  return (
    <section className="relative aspect-video sm:aspect-[16/5]">
      <video className="object-cover w-full h-full" autoPlay loop muted>
        <source
          src={process.env.REACT_APP_CDN_URL + '/static/video/web/middle_video.mp4'}
          type="video/mp4"
        />
      </video>

      <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
        <div className="flex flex-col items-center justify-center font-semibold leading-normal sm:font-bold lg:leading-snug sm:text-3xl lg:text-5xl drop-shadow-lg">
          <p>웹 기반 3차원 이벤트를 개설하고</p>
          <p>고객에게 강력한 경험을 제공하세요</p>
        </div>

        <a
          className="px-8 py-3 mt-6 font-semibold text-center duration-500 rounded-full sm:py-4 sm:text-xl md:px-10 md:py-6 md:text-2xl bg-secondary-100 text-slate-900 hover:bg-secondary-50 focus:outline-none drop-shadow-lg focus:ring-4 focus:ring-secondary-50"
          href="https://docs.google.com/forms/d/e/1FAIpQLSeCMHdbWmJztrmR5YVDjBzoUUVm8WQYO8IABnk1pF5xQw4wpw/viewform"
          target="_blank"
          rel="noreferrer"
        >
          무료 컨설팅 문의하기
        </a>
      </div>
    </section>
  )
}
