import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faXmark, faLink } from '@fortawesome/free-solid-svg-icons'
import { getMainAxios } from '../../../services/AxiosService'
import styles from '../../../styles/webview.module.scss'
import uiStyle from '../../../styles/ui.module.scss'
import linkIcon from '../../../assets/ui/link_icon.png'

import tmpImg from '../../../assets/ui/Rectangle.png'
import ContentShowWebview from './ContentShowWebview'
import ContentEditWebview from './ContentEditWebview'

import axiosI from '../../../utils/AxiosI'
import axios from 'axios'
import TextContentEditWebview from './TextContentEditWebview'
import TextContentShowWebview from './TextContentShowWebview'

function ContentWebview(props: any) {
  const { param, editModeStatus, onClose, sendMsgWithParam } = props

  const [isUploading, setUploading] = useState(false)

  const webviewInner: any = useRef(null)

  const [webviewData, setWebviewData] = useState<any>(null)

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + param)
      .then((e) => {
        setWebviewData(e.data.data)
      })
      .catch()
  }, [])

  function closePopup(e: any) {
    if (!isUploading) {
      if (!webviewInner.current.contains(e.target)) {
        onClose()
      }
    }
  }

  return (
    <>
      <div
        className={`${styles.webview_container} ${styles.content_webview}`}
        onPointerDown={(e) => closePopup(e)}
      >
        <div ref={webviewInner} className={`${styles.container_inner}`}>
          {webviewData != null ? (
            webviewData.type == 'IMAGE' ||
            webviewData.type == 'VIDEO' ||
            webviewData.type == 'YOUTUBE' ||
            webviewData.type === 'PDF' ? (
              // TODO: commit 시 !editModeStatus => editModeStatus로 변경
              editModeStatus ? (
                //     <TextContentEditWebview
                //         sendMsgWithParam={sendMsgWithParam}
                //         setUploading={setUploading}
                //         webviewData={webviewData}
                //         onClose={onClose}
                //     ></TextContentEditWebview>
                // ) : (
                //     <TextContentShowWebview
                //         webviewData={webviewData}
                //         onClose={onClose}
                //     ></TextContentShowWebview>
                <ContentEditWebview
                  sendMsgWithParam={sendMsgWithParam}
                  setUploading={setUploading}
                  webviewData={webviewData}
                  onClose={onClose}
                ></ContentEditWebview>
              ) : (
                <ContentShowWebview
                  webviewData={webviewData}
                  onClose={onClose}
                ></ContentShowWebview>
              )
            ) : webviewData.type == 'TEXT' ? (
              editModeStatus ? (
                <TextContentEditWebview
                  sendMsgWithParam={sendMsgWithParam}
                  setUploading={setUploading}
                  webviewData={webviewData}
                  onClose={onClose}
                ></TextContentEditWebview>
              ) : (
                <TextContentShowWebview
                  webviewData={webviewData}
                  onClose={onClose}
                ></TextContentShowWebview>
              )
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

export default ContentWebview
