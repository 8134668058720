import React, { forwardRef, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Text from 'components/atoms/Text'

const SelectModalComponent = styled.div`
  padding: 24px 0 24px 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  background-color: white;

  border-radius: 20px;
`

const TextWrap = styled.div`
  width: 100%;
  height: 24px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 1rem;
  font-weight: 500;

  cursor: pointer;

  * {
    width: 100%;
    padding: 0 21px 0 21px;
    cursor: pointer;
  }

  &:hover {
    background-color: #fee500;
  }
`

const Input = styled.input`
  display: none;
`

const SelectModal = ({ titleData, setSelectModalPopup, selModalBtnRef }: any) => {
  const selModalRef: any = useRef()

  const selectModelClose = (e: any) => {
    if (!selModalBtnRef.current.contains(e.target) && !selModalRef.current.contains(e.target)) {
      setSelectModalPopup(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', selectModelClose)
    return () => {
      window.removeEventListener('click', selectModelClose)
    }
  }, [])

  return (
    <SelectModalComponent ref={selModalRef}>
      {titleData.map((data: any) => (
        <>
          {data.type == 'button' && (
            <TextWrap key={data.title} onClick={(e) => data.onClickEvent(data)}>
              <p className="text-sm font-semibold">{data.title}</p>
            </TextWrap>
          )}
          {data.type == 'file' && (
            <>
              <TextWrap key={data.title}>
                <label className="text-sm font-semibold" htmlFor="content">
                  {data.title}
                </label>
                <Input
                  id="content"
                  type="file"
                  accept=".jpg, .png, .pdf, video/*"
                  onChange={data.onChangeEvent}
                />
              </TextWrap>
            </>
          )}
          {data.type === 'model' && (
            <TextWrap key={data.title}>
              <label className="text-sm font-semibold" htmlFor="model">
                {data.title}
              </label>
              <Input
                id="model"
                type="file"
                accept=".glb, .mtl, .obj, .jpg"
                multiple
                onChange={data.onChangeEvent}
              />
            </TextWrap>
          )}
        </>
      ))}
    </SelectModalComponent>
  )
}

export default forwardRef(SelectModal)
