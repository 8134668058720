import React, {useEffect, useState, useLayoutEffect, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import uiStyle from '../../styles/ui.module.scss';
import styles from '../../styles/modal.module.scss';

import template from '../../assets/ui/template1.png';
import Template from '../templates/Templates';
import Pagination from '../ui/Pagination';
import axios from 'axios';

function LandTemplate(props) {
    const {setTemplateHandler, onClose} = props;

    document.body.style.overflow = 'hidden';

    const [templateList, setTemplateList]: any = useState();
    const [curPageTemplateList, setCurPageTemplateList]: any = useState();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_HOST + 'templates/common').then(e => {
            setTemplateList(e.data.data);
        });
    }, []);

    const [templateType, setTemplateType] = useState<string>('office');

    function changeTemplateType(type) {
        setTemplateType(type);
    }

    const webviewInner: any = useRef(null);

    function closePopup(e) {
        if (!webviewInner.current.contains(e.target)) {
            document.body.style.overflow = 'unset';
            onClose();
        }
    }

    function close() {
        document.body.style.overflow = 'unset';
        onClose();
    }

    return (
        <>
            <div
                className={`${styles.modal_container}`}
                onPointerDown={e => closePopup(e)}
            >
                <div
                    ref={webviewInner}
                    className={`${styles.container_inner} ${styles.template}`}
                >
                    <Wrapper>
                        <div className={styles.modal_header}>
                            <div className={styles.template_category}>
                                <p
                                    className={
                                        templateType == 'office'
                                            ? styles.active
                                            : ''
                                    }
                                    onClick={() => changeTemplateType('office')}
                                >
                                    Office
                                </p>
                                <p
                                    className={
                                        templateType == 'gallery'
                                            ? styles.active
                                            : ''
                                    }
                                    onClick={() =>
                                        changeTemplateType('gallery')
                                    }
                                >
                                    Gallery
                                </p>
                            </div>
                            <button onClick={() => close()}>
                                <FontAwesomeIcon icon={faX} />
                            </button>
                        </div>

                        <ListBlock>
                            <div className={styles.template_list}>
                                {curPageTemplateList != null &&
                                    curPageTemplateList.map((e, i) => (
                                        <Template
                                            key={i}
                                            template={e}
                                            setTemplateHandler={
                                                setTemplateHandler
                                            }
                                        ></Template>
                                    ))}
                            </div>
                            {templateList != null && templateType != null && (
                                <Pagination
                                    totalList={templateList[templateType]}
                                    itemPerPage={6}
                                    setCurPageList={setCurPageTemplateList}
                                ></Pagination>
                            )}
                        </ListBlock>
                    </Wrapper>
                </div>
            </div>
        </>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ListBlock = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export default LandTemplate;
