import * as React from 'react'
import { SVGProps } from 'react'
const SvgIconChevronDoubleRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="icon_chevron_double_right_svg__w-6 icon_chevron_double_right_svg__h-6"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m11.25 4.5 7.5 7.5-7.5 7.5m-6-15 7.5 7.5-7.5 7.5"
    />
  </svg>
)
export default SvgIconChevronDoubleRight
