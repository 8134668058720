import React, {useEffect, useState} from 'react';
import axiosI from '../utils/AxiosI';
import {initLocalStorage} from '../utils/TokenUtil';
import {useDispatch, useSelector} from 'react-redux';
import {setLoginStatus} from '../reducers/login';
import {deleteCookie} from '../utils/CookieUtil';
import axios from 'axios';

function AuthProcessingTestPage() {
    const params = new URLSearchParams(window.location.search);
    const newToken: any = params.get('token');

    initLocalStorage('plikaTk', newToken);

    const [accountInfo, setAccountInfo]: any = useState();

    useEffect(() => {
        if (newToken == 'EXPIRED_REDIS_TOKEN') {
            window.localStorage.removeItem('plikaTk');
            axios
                .delete(process.env.REACT_APP_API_HOST + 'auth/cookies')
                .then(() => {
                    window.location.replace('/');
                })
                .catch(err => {
                    window.location.replace('/');
                });

            const config = {
                headers: {Authorization: `Bearer ${newToken}`},
            };
            axiosI.get(process.env.REACT_APP_API_HOST + `v1/auth/refresh`, config)
            .then(e => {
                if (newToken != null) {
                    const newAccessToken = window.localStorage.getItem('plikaTk');
                    const config = {
                        headers: {Authorization: `Bearer ${newAccessToken}`},
                    };
                    axiosI
                        .get(process.env.REACT_APP_API_HOST + `v1/account/get`, config)
                        .then(e => {
                            if (e.data.data != null) {
                                setAccountInfo(e.data.data);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            })
            .catch(err => {
                console.log(err);
            });

            } else {
                const newAccessToken = window.localStorage.getItem('plikaTk');
                const config = {
                    headers: {Authorization: `Bearer ${newAccessToken}`},
                };
                axiosI
                    .get(process.env.REACT_APP_API_HOST + `v1/account/get`, config)
                    .then(e => {
                        if (e.data.data != null) {
                            setAccountInfo(e.data.data);
                        }
                    })
                    .catch(err => {
                        window.location.replace("/");
                        console.log(err);
                    });
        }
    }, []);

    useEffect(() => {
        if (accountInfo != null) {
            if (
                accountInfo.age == null ||
                accountInfo.job == null ||
                accountInfo.field == null ||
                accountInfo.route == null ||
                accountInfo.object == null
            ) {
                window.location.replace('/login/info');
                return;
            }
            if (!accountInfo.privacyCheck || !accountInfo.ageCheck) {
                window.location.replace('/login/check');
                return;
            }
            if (window.localStorage.getItem('login_redirect_url') != null) {
                const redirectUrl: string | URL = window.localStorage.getItem(
                    'login_redirect_url',
                ) as string;
                window.localStorage.removeItem('login_redirect_url');
                window.location.replace(redirectUrl);
            } else {
                window.location.replace('/');
            }
        } else {
            if (newToken == null) {
                alert('이미 가입된 이메일입니다.');
                window.localStorage.removeItem('plikaTk');
                window.location.replace('/login');
            }
        }
    }, [accountInfo]);

    return <></>;
}

export default AuthProcessingTestPage;
