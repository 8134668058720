import React from 'react';
import styled from 'styled-components';
import {MintLogo, MintPlikaLogo, PlikaLogo, SquareIcon} from '../../static/svg';

function ProcessContent() {
    return (
        <Block>
            <Wrap>
                <div>
                    <h3>SESSION 1 </h3>
                    <h3>MintCollection Showroom</h3>
                </div>
                <ul>
                    <li>
                        <p>
                            <SquareIcon />
                            <MintLogo className="mint" />는 실제 패션플랫폼이며
                            <MintPlikaLogo className="mint_plika" /> 콜라보
                        </p>
                        <p>레이션을 통해 본 팝업스토어가 제작되었어요</p>
                    </li>
                    <li>
                        <p>
                            <SquareIcon />
                            <b>세션 1</b>에서 <MintLogo className="mint mg" />{' '}
                            의 팀원을 만나 간단한 소개를 들어보세요
                        </p>
                    </li>
                    <li>
                        <p>
                            <SquareIcon />
                            <b>세션 1</b>에 있는 커다란 사람모형은 이번 팝업을
                            위해
                            <MintLogo className="mint mg" /> 의
                        </p>
                        <p>
                            제품을 스캔하여 제작되었으며 제작스케치가
                            전시되어있어요
                        </p>
                    </li>
                </ul>
            </Wrap>
        </Block>
    );
}

const Block = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 66px;
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    div:first-child {
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 24px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    li {
        display: flex;
        flex-direction: column;

        font-size: 12px;
        overflow-wrap: break-word;

        p {
            display: flex;
            align-items: center;
        }

        p:nth-child(2) {
            margin-left: 16px;
        }

        svg {
            height: 14px;
            color: #000;
            margin-left: 5px;
        }

        svg:first-child {
            width: 5px;
            margin-right: 5px;
        }

        .mint {
            margin-left: 0;
            width: 32px;

            &.mg {
                margin-left: 5px;
            }
        }

        .mint_plika {
            width: 80px;
        }
    }
`;

export default ProcessContent;
