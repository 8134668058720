import React from 'react';
import styled from 'styled-components';

import Text from 'components/common/atoms/Text';
import {useTranslation} from 'react-i18next';

const Container = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    height: 370px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 34px 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
`;

const TitleWrap = styled.div`
    padding-bottom: 10px;
`;

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 34px;
`;

const Nonadmission = () => {
    const {t} = useTranslation();
    setTimeout(() => {
        window.location.replace('/');
    }, 3000);
    return (
        <Container>
            <ContentWrap>
                <TitleWrap>
                    <Text size={24} weight={700} color="#EE3030">
                        {t('admission_alert.title1')}
                    </Text>
                </TitleWrap>
                <div>
                    <Text size={16} weight={700}>
                        {t('admission_alert.text1')}
                    </Text>
                    <Text size={16} weight={700}>
                        {t('admission_alert.text2')}
                    </Text>
                </div>
            </ContentWrap>
        </Container>
    );
};

export default Nonadmission;
