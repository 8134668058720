import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

interface ButtonStyleProps {
    primary?: boolean;
    bgColor?: string;
    type?: 'icon' | 'normal' | any;
    onClick?: () => void;
}
export interface ButtonProps extends ButtonStyleProps {
    children: ReactNode;
}

const Button: React.FC<ButtonProps> = function ({
    primary = false,
    type = 'normal',
    bgColor,
    children,
    ...props
}) {
    return (
        <ButtonComponent
            primary={primary}
            style={{backgroundColor: bgColor}}
            type={type}
            {...props}
        >
            {children}
        </ButtonComponent>
    );
};

const typeProps = (type: string | undefined) => {
    switch (type) {
        case 'icon':
            return 'font-size: 30px; color: #FFF';
        case 'normal':
            return 'font-size: 14px; padding: 11px 20px;';
        default:
            return '';
    }
};

export const ButtonComponent = styled.button<ButtonStyleProps>`
    ${({primary}) =>
        primary &&
        css`
            color: #fff;
            background-color: #fee500;
        `}

    ${({type}) => typeProps(type)}
`;

export default Button;
