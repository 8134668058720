import React from 'react'

import one from 'assets/img/img_exhibition_map_one.png'
import two from 'assets/img/img_exhibition_map_two.png'

import { CloseIcon } from 'static/svg'
import {
  ImgEmotionEight,
  ImgEmotionFive,
  ImgEmotionFour,
  ImgEmotionNine,
  ImgEmotionOne,
  ImgEmotionSeven,
  ImgEmotionSix,
  ImgEmotionTwo,
  ImgGangnamInteraction,
  ImgGangnamMove,
  ImgGangnamRotation,
  ImgVeiwpoint,
} from 'components/vectors'

const controlMap = {
  6: {
    floor: '1F',
    area: '전남,전북',
    image: one,
  },
  7: {
    floor: '2F',
    area: '광주,제주',
    image: two,
  },
}

export default function ExhibitionControl({ sendMsg, setState, type }) {
  return (
    <div className="absolute z-10 w-full h-full max-w-lg bg-white tall:h-max sm:max-w-none sm:w-max left-2/4 p-7 shadow-86 sm:rounded-xl top-2/4 -translate-y-2/4 -translate-x-2/4">
git      <CloseIcon
        className="absolute h-auto duration-500 cursor-pointer w-7 hover:text-slate-400 text-slate-900 top-3 right-3"
        onClick={() => {
          sendMsg('Webreceiver', 'ControlPopupClose')
          setState(false)
        }}
      />
      <div className="flex flex-col w-full h-full overflow-y-scroll scrollbar-hide xl:flex-row">
        <div className="xl:border-r xl:border-gray-200 xl:pr-7 xl:mr-7">
          <h3 className="mb-5 text-xl font-bold sm:text-2xl">조작방법</h3>
          <div className="grid w-full grid-cols-2 grid-rows-2 gap-2 sm:gap-9 ">
            <div className="flex items-center justify-end pl-2">
              <ImgGangnamMove className="w-auto max-w-xs sm:h-28 xl:h-32 shrink-0" />
            </div>
            <div className="flex items-center pr-2">
              <ImgGangnamRotation className="w-auto max-w-xs sm:h-28 xl:h-32 shrink-0" />
            </div>
            <div className="flex items-center justify-end pl-2">
              <ImgGangnamInteraction className="w-auto max-w-xs sm:h-28 xl:h-32 shrink-0" />
            </div>
            <div className="flex items-center pr-2">
              <ImgVeiwpoint className="w-auto max-w-xs sm:h-28 xl:h-32 shrink-0" />
            </div>
          </div>
          <h3 className="mt-12 mb-5 text-xl font-bold uppercase sm:text-2xl">tips</h3>
          <ul className="flex flex-col gap-3 text-xs">
            <li className="list-disc list-inside">
              접속한 사람들끼리 음성채팅/화상캠/화면공유를 사용할 수 있어요
            </li>

            <li className="list-disc list-inside">서로 다른 층에 있는 사람들은 소통할 수 없어요</li>
            <li className="list-disc list-inside">
              화상캠을 사용할 수 없다면 해당기기가 연결되어 있는지 확인해주세요
            </li>
          </ul>
          <h3 className="mt-12 mb-5 text-xl font-bold uppercase sm:text-2xl">감정표현</h3>
          <div className="flex flex-col sm:flex-row">
            <div className="flex flex-col gap-4 shrink-0">
              <div className="flex items-center gap-5">
                <ImgEmotionOne className="w-auto h-7" />
                <p className="text-xs">손흔들며 인사</p>
              </div>
              <div className="flex items-center gap-5">
                <ImgEmotionTwo className="w-auto h-7" />
                <p className="text-xs">고개숙이며 인사</p>
              </div>
              <div className="flex items-center gap-5">
                <ImgEmotionFour className="w-auto h-7" />
                <p className="text-xs">춤추기</p>
              </div>
              <div className="flex items-center gap-5">
                <ImgEmotionFive className="w-auto h-7" />
                <p className="text-xs">춤추기2</p>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-4 sm:mt-0 sm:ml-16 shrink-0">
              <div className="flex items-center gap-5">
                <ImgEmotionSix className="w-auto h-7" />
                <p className="text-xs">춤추기3</p>
              </div>
              <div className="flex items-center gap-5">
                <ImgEmotionSeven className="w-auto h-7" />
                <p className="text-xs">앉기</p>
              </div>
              <div className="flex items-center gap-5">
                <ImgEmotionEight className="w-auto h-7" />
                <p className="text-xs">앉아서 일하기</p>
              </div>
              <div className="flex items-center gap-5">
                <ImgEmotionNine className="w-auto h-7" />
                <p className="text-xs">바닥에 앉기</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col mt-12 mb-5 text-base font-bold sm:items-center sm:flex-row sm:text-2xl xl:mt-0">
            <h3 className="text-xl sm:mr-3 sm:text-6xl">{controlMap[type].floor}</h3>
            <h3>
              2022 Honam Copyright Fair
              <br />
              {controlMap[type].area} 저작권 서비스센터
            </h3>
          </div>
          <div className="w-full xl:w-[32rem]">
            <img className="object-contain w-full h-full" src={controlMap[type].image} alt="Map" />
          </div>
          {type === 6 ? (
            <div className="mt-5 sm:flex">
              <div className="mb-5 sm:mr-10">
                <h4 className="text-sm font-bold">A. 전남저작권서비스센터</h4>
                <div className="text-sm">
                  <p>1. 애니듀</p>
                  <p>2. 상단스튜디오</p>
                  <p>3. 폴팩토리</p>
                  <p>4. 블루미디어</p>
                  <p>5. 워너비굿즈</p>
                  <p>6. 팜소프트</p>
                  <p>7. 스튜디오티앤티</p>
                </div>
              </div>
              <div>
                <h4 className="text-sm font-bold">B. 전북저작권서비스센터</h4>
                <div className="text-sm">
                  <p>8. 티거래빗아트</p>
                  <p>9. 메디케이시스템</p>
                  <p>10. 시그마웰</p>
                  <p>11. 스튜디오 바카</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-5 sm:flex">
              <div className="mb-5 sm:mr-10">
                <h4 className="text-sm font-bold">A. 광주저작권서비스센터</h4>
                <div className="text-sm">
                  <p>1. 캠프파이어애니웍스</p>
                  <p>2. 네온크리에이션</p>
                  <p>3. 5브릭스</p>
                  <p>4. 지니소프트</p>
                  <p>5. 마로스튜디오</p>
                </div>
              </div>
              <div>
                <h4 className="text-sm font-bold">B. 제주저작권서비스센터</h4>
                <div className="text-sm">
                  <p>6. 온맥스</p>
                  <p>7. 박스트리</p>
                  <p>8. 제주미니</p>
                  <p>9. 위놉스</p>
                  <p>10. 그리메</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
