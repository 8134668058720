import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FiMenu } from 'react-icons/fi'
import styled from 'styled-components'
import { t } from 'i18next'

import '../../styles/common.scss'
import styles from '../../styles/header.module.scss'
import uiStyle from '../../styles/ui.module.scss'

import axiosI from '../../utils/AxiosI'
import { setLogoutstatus } from '../../reducers/login'
import media from '../../lib/styles/media'

import { LeaveIcon, PencilIcon, PlikaIcon } from '../../static/svg'
import plikaLogo from '../../assets/img/plika_logo.png'
import searchIcon from '../../assets/ui/fi_search.png'
import plikaLogoBlack from '../../assets/ui/plika_logo_black.png'
import logout from '../../assets/ui/log_out.png'
import InputRound from '../ui/InputRound'
import Editprofile from '../modals/EditProfile'
import LandCantCreateAlert from 'components/modals/LandCantCreateAlert'
import SearchBar, { SearchBarComponent } from 'components/molecules/SearchBar'
import { InputComponent } from 'components/atoms/Input'
import { useCreateLand } from '../../hooks/useCreateLand'

interface Props {
  setAccountInfoState?: any
  setAlert?: React.Dispatch<React.SetStateAction<boolean>>
}

function Header({ setAccountInfoState, setAlert }: Props) {
  const { pathname } = useLocation()

  const [editPopup, setEditPopup] = useState(false)

  function popupClose() {
    document.body.style.overflow = 'unset'
    setEditPopup(() => false)
  }

  const dispatch = useDispatch()

  const [accountInfo, setAccountInfo]: any = useState()

  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    const accessToken = window.localStorage.getItem('plikaTk')
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
    if (accessToken != null && accessToken.length > 0 && accessToken != 'null') {
      axiosI
        .get(process.env.REACT_APP_API_HOST + `v1/account/get`, config)
        .then((e) => {
          if (e.data.data != null) {
            setAccountInfo(e.data.data)
            if (
              e.data.data.age == null ||
              e.data.data.job == null ||
              e.data.data.age == null ||
              e.data.data.route == null ||
              e.data.data.object == null
            ) {
              window.location.replace('/login/info')
            }
            if (!e.data.data.ageCheck || !e.data.data.privacyCheck) {
              if (!pathname.startsWith('/privacy') && !pathname.startsWith('/terms')) {
                window.location.replace('/login/profile')
              }
            }
            if (setAccountInfoState != null) {
              setAccountInfoState(e.data.data)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      if (setAccountInfoState != null) {
        setAccountInfoState({ accountId: null })
      }
    }
  }, [])

  useEffect(() => {
    if (accountInfo != null) {
      const nicknameBtn = document.getElementById('header_nickname')

      nicknameBtn?.addEventListener('mouseover', function () {
        const headerMyBtn = document.getElementById('header_my')
        if (headerMyBtn != null) {
          headerMyBtn.style.backgroundColor = '#FFF280'
        }
      })

      nicknameBtn?.addEventListener('mouseleave', function () {
        const headerMyBtn = document.getElementById('header_my')
        if (headerMyBtn != null) {
          headerMyBtn.style.backgroundColor = '#FEE500'
        }
      })

      const menu = document.getElementById('header_my_menu')
      menu?.addEventListener('mouseover', function () {
        const headerMyBtn = document.getElementById('header_my')
        if (headerMyBtn != null) {
          headerMyBtn.style.backgroundColor = '#FFF280'
        }
      })
      menu?.addEventListener('mouseleave', function () {
        const headerMyBtn = document.getElementById('header_my')
        if (headerMyBtn != null) {
          headerMyBtn.style.backgroundColor = '#FEE500'
        }
      })

      const logoutBtn = document.querySelectorAll('.header_logout')

      logoutBtn?.forEach((e) => {
        e?.addEventListener('click', function () {
          const accessToken = window.localStorage.getItem('plikaTk')
          const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
          if (accessToken != null && accessToken.length > 0 && accessToken != 'null') {
            axiosI.put(process.env.REACT_APP_API_HOST + 'v1/auth/refresh', config).then((e) => {
              dispatch(setLogoutstatus())
              window.localStorage.removeItem('plikaTk')
              setAccountInfo(null)
              setAccountInfoState({ accountId: null })
              window.location.replace('/')
            })
          }
        })
      })
    }
  }, [accountInfo])

  const [landCreateAlert, setLandCreateAlert]: any = useState(false)

  return (
    <>
      <div className={styles.header_container}>
        <MenuBox show={showMenu}>
          <ButtonBox>
            <button
              // eslint-disable-next-line react-hooks/rules-of-hooks
              onClick={() => useCreateLand(accountInfo, setAlert)}
            >
              + Create
            </button>
            {accountInfo != null ? (
              <div className="my">
                <img src={accountInfo.thumbnailUri} alt="" />
                <div id="header_nickname">{accountInfo.nickname}</div>
              </div>
            ) : (
              <a href="/login">Log in</a>
            )}
          </ButtonBox>
          {accountInfo != null && (
            <UserMenu>
              <UserInfo>
                <div>
                  <h3>{accountInfo.nickname}</h3>
                  <Edit
                    onClick={() => {
                      setEditPopup(true)
                      setShowMenu(false)
                    }}
                  >
                    <PencilIcon />
                  </Edit>
                </div>
                <p>{accountInfo.email}</p>
              </UserInfo>

              <a href="/mylands">
                <PlikaIcon />
                <span>MY LAND</span>
              </a>
              <button className={`${uiStyle.drop_menu_btn} header_logout`}>
                <LeaveIcon />
                <span>Logout</span>
              </button>
            </UserMenu>
          )}
          <SearchBox action="/lands/search">
            <SearchBar />
          </SearchBox>
          <NavBox>
            {/* <button
                            className={
                                pathname == '/lands' ? styles.active : ''
                            }
                        >
                            <a href="/lands">LANDs</a>
                        </button> */}
            <button className={pathname == '/pricing' ? styles.active : ''}>
              <a href="/pricing">Pricing</a>
            </button>
            <button>
              <a href="https://support.plika.io/" target="_blank" rel="noreferrer">
                Help Center
              </a>
            </button>
          </NavBox>
        </MenuBox>

        <div className={styles.header_inner}>
          <a href="/" className={styles.header_logo}>
            <img src={plikaLogo} alt="" />
          </a>
          <IconHamburger
            onClick={() => {
              setShowMenu(!showMenu)
            }}
          />
          <div className={styles.header_nav}>
            <div className={styles.header_nav_btn_wrap}>
              {/* <button
                                className={
                                    pathname == '/lands' ? styles.active : ''
                                }
                            >
                                <a href="/lands">LANDs</a>
                            </button> */}
              <button className={pathname == '/pricing' ? styles.active : ''}>
                <a href="/pricing">Pricing</a>
              </button>
              <button>
                <a href="https://support.plika.io/" target="_blank" rel="noreferrer">
                  Help Center
                </a>
              </button>
            </div>
            <div className={styles.header_nav_menu_wrap}>
              <div className={styles.header_search_bar}>
                <form action="/lands/search">
                  <InputRound
                    name={'name'}
                    action={'/lands/search'}
                    placeholder={t('main.header.search.placeholder')}
                  ></InputRound>
                  <button type="submit">
                    <img src={searchIcon} alt="" />
                  </button>
                </form>
              </div>
              <button
                type="button"
                className="px-6 shadow-86 bg-primary-100 hover:bg-primary-50 text-white duration-500 rounded-full"
                // eslint-disable-next-line react-hooks/rules-of-hooks
                onClick={() => useCreateLand(accountInfo, setAlert)}
              >
                + Create
              </button>
              {accountInfo != null ? (
                <div id="header_my" className={styles.header_my}>
                  <img
                    src={accountInfo.thumbnailUri}
                    alt=""
                    className={styles.header_my_thumbnail}
                  />
                  <div id="header_nickname" className={styles.header_nickname}>
                    {accountInfo.nickname}
                  </div>
                  <div
                    id="header_my_menu"
                    className={`${styles.header_my_menu} ${uiStyle.drop_menu_box}`}
                  >
                    <div className={styles.header_my_menu_info}>
                      <div
                        onClick={() => setEditPopup(true)}
                        className={styles.header_my_menu_edit}
                      ></div>
                      <div className={styles.header_my_menu_nickname}>{accountInfo.nickname}</div>
                      <div className={styles.header_my_menu_email}>{accountInfo.email}</div>
                    </div>
                    <div
                      className={`${styles.header_my_menu_btn_wrap} ${uiStyle.drop_menu_btn_list}`}
                    >
                      <a href="/mylands" className={uiStyle.drop_menu_btn}>
                        <img src={plikaLogoBlack} alt="" />
                        <span>MY LAND</span>
                      </a>
                      <button className={`${uiStyle.drop_menu_btn} header_logout`}>
                        <img src={logout} alt="" />
                        <span>Logout</span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <a href="/login" className={`${uiStyle.btn_theme_yel} ${styles.header_login_btn}`}>
                  Log in
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      {editPopup ? (
        <Editprofile
          accountInfo={accountInfo}
          setAccountInfo={setAccountInfo}
          setAccountInfoState={setAccountInfoState}
          onClose={popupClose}
        ></Editprofile>
      ) : (
        <></>
      )}
    </>
  )
}

const IconHamburger = styled(FiMenu)`
  display: none;

  ${media.medium} {
    display: block;
    font-size: 26px;
    cursor: pointer;
  }
`

interface Props {
  show: any
}

const MenuBox = styled.div<Props>`
  display: ${({ show }: any) => (show ? 'block' : 'none')};
  position: absolute;
  top: 78px;

  width: 100%;
  height: max-content;
  background: #fff;
  z-index: 200;

  ${media.medium} {
    top: 60px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

const ButtonBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 100%;
  padding: 20px;
  border-top: solid 1px #ccc;

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 46px;
    font-size: 16px;
    border-radius: 30px;
  }

  button {
    background: #1e69cb;

    font-weight: 700;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      background: #74b0ff;
    }
  }

  a {
    background: #fee500;
    transition: all 0.3s;
    font-size: 16px;
    font-weight: 700;

    &:hover {
      background: #fff280;
    }
  }

  .my {
    width: 100%;
    height: 100%;
    background: #fee500;
    border-radius: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    transition: all 0.3s;

    &:hover {
      background: #fff280;
    }

    img {
      width: 46px;
      height: 100%;
      border-radius: 50%;
    }
  }
`

const SearchBox = styled.form`
  padding: 20px;

  ${SearchBarComponent} {
    width: 100%;
  }

  ${InputComponent} {
    width: 100%;
  }
`

const NavBox = styled.div`
  display: flex;
  flex-direction: column;

  gap: 30px;
  padding: 0 20px;
  padding-top: 10px;
  padding-bottom: 40px;

  button {
    font-size: 20px;
    font-weight: 700;
    display: flex;

    a {
      width: 100%;
      height: 100%;
      transition: 0.3s;
      display: flex;
    }

    &:hover a {
      color: #1e69cb;
    }
  }
`

const UserMenu = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: solid 1px #ccc;

  a {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 12px;
      font-weight: 500;
    }
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 12px;
      font-weight: 500;
    }
  }
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 16px;
      font-weight: 700;
    }
  }

  p {
    font-size: 12px;
  }
`

const Edit = styled.div`
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
`

export default Header
