import React, { useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { resolveValue, Toaster } from 'react-hot-toast'

export default function CustomToast() {
  return (
    <Toaster containerStyle={{ position: 'absolute', top: '50%' }}>
      {(t) => (
        <Transition
          appear
          show={t.visible}
          className="flex px-8 py-2 transform rounded-full bg-error-100 shadow-86"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <p className="text-xl font-semibold text-white">{resolveValue(t.message)}</p>
        </Transition>
      )}
    </Toaster>
  )
}
