import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Document, Page } from 'react-pdf'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import styles from '../../../styles/webview.module.scss'
import uiStyle from '../../../styles/ui.module.scss'
import '../../../styles/common.scss'

import { getEmbedYoutubeUrl } from '../../../utils/YoutubeUtil'

import { LinkIcon } from '../../../static/svg'
import Rectangle from '../../../assets/ui/Rectangle.png'
import ContentSaveAlert from '../ContentSaveAlert'
import SelectModal from 'components/molecules/SelectModal'
import YoutubeEmbedModal from 'components/molecules/YoutubeEmbedModal'
import Notice from 'components/atoms/Notice'
import { IconChevronLeft, IconChevronRight } from 'components/vectors'
import toast from 'react-hot-toast'
import { pdfjs } from 'react-pdf'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter'
// import Stats from 'three/examples/jsm/libs/stats.module'

import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Cookies } from 'react-cookie'
import { useModalMode } from 'contexts/ModalModeContext'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const FILE_SIZE_MAX_LIMIT = 30 * 1024 * 1024 // 30MB

const cookies = new Cookies()

function ContentEditWebview(props: any) {
  const { t } = useTranslation()
  const formRef: any = useRef()

  function formSubmit() {
    if (formRef.current != null) {
      formRef.current.submit()
    }
  }

  const { webviewData, setUploading, sendMsgWithParam, onClose } = props

  const [savePopup, setSavePopup] = useState(false)

  const [inputs, setInputs] = useState({
    id: webviewData.id,
    contentIndex: webviewData.contentIndex,
    name: webviewData.name,
    desc: webviewData.desc,
    isLinkOpen: webviewData.isLinkOpen,
    linkName: webviewData.linkName,
    linkUrl: webviewData.linkUrl,
    youtubeUri: webviewData.youtubeUri,
    type: webviewData.type,
  })

  const { id, contentIndex, name, desc, isLinkOpen, linkName, linkUrl, youtubeUri, type } = inputs

  const inputValueHandler = (e: any) => {
    const { value, name } = e.target
    setInputs({
      ...inputs,
      [name]: value,
    })
  }

  const youtubeUriValueHandler = (e: any) => {
    const { youtubeUri } = e.target

    setInputs({
      ...inputs,
      youtubeUri: getEmbedYoutubeUrl(youtubeUri.value),
      type: 'YOUTUBE',
    })
    setContentType((prev: any) => ({
      ...prev,
      media: false,
      youtube: true,
      pdf: false,
    }))
    setYoutubeEmbedModal(false)
  }

  const [files, setFiles]: any = useState()

  const deletedFile: any = useRef()

  const imageRef: any = useRef()
  const videoRef: any = useRef()
  const pdfRef: any = useRef()

  const fileHandler = (e: any) => {
    const target = e.currentTarget

    if (target.files[0].size > FILE_SIZE_MAX_LIMIT) {
      target.value = ''
      toast.success('업로드 가능한 최대 용량은 30MB 입니다.')
      return
    }

    console.log('files', e.target.files)
    setFiles(e.target.files)
  }

  useEffect(() => {}, [files])

  const [pdfUrl, setPdfUrl] = useState('')
  const [fileUrl, setFileUrl] = useState('')

  useEffect(() => {
    if (files != null) {
      if (files.length > 0) {
        if (files[0].type.startsWith('video') && files.length === 1) {
          setContentType((prev: any) => ({
            ...prev,
            media: true,
            youtube: false,
            pdf: false,
          }))

          setInputs({
            ...inputs,
            type: 'VIDEO',
          })

          videoRef.current.setAttribute('src', URL.createObjectURL(files[0]))
          videoRef.current.controls = true
          videoRef.current.muted = true
          videoRef.current.autoplay = true
        } else if (files[0].type.startsWith('image') && files.length === 1) {
          console.log('image')
          setContentType((prev: any) => ({
            ...prev,
            media: true,
            youtube: false,
            pdf: false,
            model: false,
          }))
          setInputs({
            ...inputs,
            type: 'IMAGE',
          })

          imageRef.current.setAttribute('src', URL.createObjectURL(files[0]))
          setFileUrl(URL.createObjectURL(files[0]))
        } else if (files[0].type.startsWith('application/pdf') && files.length === 1) {
          setContentType((prev: any) => ({
            ...prev,
            media: false,
            youtube: false,
            pdf: true,
            model: false,
          }))
          setInputs({
            ...inputs,
            type: 'PDF',
          })

          setPdfUrl(URL.createObjectURL(files[0]))
          setPageNum(1)
        } else {
          console.log('3d model')
          setIsLoading(true)
          setContentType((prev: any) => ({
            ...prev,
            media: false,
            youtube: false,
            pdf: false,
            model: true,
          }))
          setInputs({
            ...inputs,
            type: 'MODEL',
          })

          const formData = new FormData()
          for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i])
          }

          const accessToken = localStorage.getItem('plikaTk')

          console.log('accessToken', accessToken)

          let progress = 0

          axios
            .post('https://papi.plika.land/api/v1/converts/', formData, {
              onUploadProgress: function (progressEvent) {
                progress = Math.round((progressEvent.loaded * 100) / progressEvent.total / 2)
                setPercentage(progress)
                //console.log(`Upload Progress: ${progress}%`);
              },
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((data) => {
              console.log(data)
              setGlbPath(data.data.file_url)
              // setGlbPath(URL.createObjectURL(files[0]))
            })
        }
      }

      setSelectModalPopup(false)
    }
  }, [files])

  const [saveNotice, setSaveNotice]: any = useState()

  useEffect(() => {
    if (saveNotice) {
      setTimeout(() => {
        setSaveNotice(false)
      }, 3000)
    }
  }, [saveNotice])

  async function updateContent(e: any) {
    e.preventDefault()
    setSaving(true)
    setUploading(true)

    const { name, desc, linkName, linkUrl } = e.target

    const formData = new FormData()
    const fileDetails: any = []

    const data = {
      name: name != null ? name.value : inputs.name,
      desc: desc != null ? desc.value : inputs.desc,
      isLinkOpen: isLinkOpen,
      linkName: linkName != null ? linkName.value : inputs.linkName,
      linkUrl: linkUrl != null ? linkUrl.value : inputs.linkUrl,
      youtubeUri: youtubeUri != null ? youtubeUri : inputs.youtubeUri,
      typeCode: type,
      deletedFile: deletedFile.current,
      fileDetails,
    }

    let modeNum = 0

    if (webviewData.type == 'IMAGE') {
      modeNum = 0
    } else if (webviewData.type == 'VIDEO') {
      modeNum = 1
    } else if (webviewData.type == 'YOUTUBE') {
      modeNum = 2
    } else if (webviewData.type == 'PDF') {
      modeNum = 4
    }

    if (files != null && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i])

        if (type == 'IMAGE') {
          data.typeCode = 'image'
          modeNum = 0
        } else if (type == 'VIDEO') {
          data.typeCode = 'video'
          modeNum = 1
        } else if (type == 'YOUTUBE') {
          data.typeCode = 'YOUTUBE'
        } else if (type == 'PDF') {
          data.typeCode = 'PDF'
        }

        const fileData = {
          typeCode: 'CONTENT',
          typeCode2: data.typeCode,
          fileNo: i + 1,
          regId: 0,
        }

        data.fileDetails.push(fileData)
      }
    }

    if (deletedFile.current) {
      modeNum = -1
    }

    formData.append(
      'content-update',
      new Blob([JSON.stringify(data)], { type: 'application/json' }),
    )

    const requestUri = process.env.REACT_APP_API_HOST + `contents/${id}`

    await axios
      .patch(requestUri, formData)
      .then((resp) => {
        // UNITY 호출 로직
        if (resp.data.resp_code === 'SUCCESS') {
          if (resp.data.data.fileInfoDtos.length > 0) {
            if (data.typeCode === 'image') {
              modeNum = 0
            } else if (data.typeCode === 'video') {
              modeNum = 1
            } else if (data.typeCode === 'YOUTUBE') {
              modeNum = 2
            } else if (data.typeCode === 'PDF') {
              modeNum = 4
            }
          }

          // let fileUrl = ''

          // if (resp.data.data.fileInfoDtos != null && resp.data.data.fileInfoDtos.length > 0) {
          //   if (data.typeCode === 'PDF') {
          //     fileUrl = resp.data.data.fileInfoDtos
          //   } else {
          //     fileUrl = resp.data.data.fileInfoDtos[0].fileUrl
          //   }
          // } else {
          //   if (webviewData.fileInfoDtos != null && webviewData.fileInfoDtos.length > 0) {
          //     if (data.typeCode === 'PDF') {
          //       fileUrl = webviewData.fileInfoDtos
          //     } else {
          //       fileUrl = webviewData.fileInfoDtos[0].fileUrl
          //     }
          //   }
          // }

          const fileUrl =
            resp.data.data.fileInfoDtos != null && resp.data.data.fileInfoDtos.length > 0
              ? resp.data.data.fileInfoDtos[0].fileUrl
              : webviewData.fileInfoDtos != null && webviewData.fileInfoDtos.length > 0
              ? webviewData.fileInfoDtos[0].fileUrl
              : ''

          const obj = {
            Url: fileUrl,
            BoothId: contentIndex,
            ModeNum: modeNum,
            Name: name.value,
            Desc: desc.value,
            files: [],
          }

          if (data.typeCode === 'PDF') {
            const array = resp.data.data.fileInfoDtos
            array.shift()
            obj.files = array.map((item: any) => {
              return item.fileUrl
            })
          }

          const param = JSON.stringify(obj)

          sendMsgWithParam('Webreceiver', 'UpdateMedia', param)

          setUploading(false)
          setSavePopup(false)
          setSaving(false)
          setSaveNotice(true)
        }
        setUploading(false)
      })
      .catch((err) => {
        alert('파일 업로드에 실패하였습니다.')
        setUploading(false)
        //window.location.reload();
      })
  }

  const [saving, setSaving] = useState(false)

  const [selectModalPopup, setSelectModalPopup] = useState(false)

  const toggleSelectModal = (e: any) => {
    setSelectModalPopup(!selectModalPopup)
  }

  const selModalBtnRef: any = useRef()

  const [youtubeEmbedModal, setYoutubeEmbedModal]: any = useState()

  const [contentType, setContentType]: any = useState({
    media:
      webviewData.fileInfoDtos.length > 0 &&
      (webviewData.option.type == 'IMAGE' || webviewData.option.type == 'VIDEO')
        ? true
        : false,
    youtube:
      webviewData.fileInfoDtos.length > 0 && webviewData.option.type == 'YOUTUBE' ? true : false,
    pdf: webviewData.fileInfoDtos.length > 0 && webviewData.option.type == 'PDF' ? true : false,
    model: webviewData.fileInfoDtos.length > 0 && webviewData.option.type == 'MODEL' ? true : false,
  })

  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const { toggleModalMode } = useModalMode()

  const titleData = [
    {
      title: 'File Upload',
      onChangeEvent: (e: any) => {
        fileHandler(e)
        setSelectModalPopup(false)
      },
      type: 'file',
    },
    {
      title: '3D Model Upload',
      onChangeEvent: (e: any) => {
        fileHandler(e)
        setSelectModalPopup(false)
      },
      type: 'model',
    },
    {
      title: '3D Scan',
      onClickEvent: (e: any) => {
        console.log('scan click')
        toggleModalMode('scanUploader', true)
      },
      type: 'button',
    },
    {
      title: 'Youtube',
      onClickEvent: () => {
        setYoutubeEmbedModal(true)
        setSelectModalPopup(false)
      },
      type: 'button',
    },

    {
      title: 'Delete',
      onClickEvent: () => {
        setContentType((prev: any) => ({
          ...prev,
          media: false,
          youtube: false,
          pdf: false,
          model: false,
        }))
        deletedFile.current = true
        setFiles(null)
        setSelectModalPopup(false)
      },
      type: 'button',
    },
  ]

  const [autoplay, setAutoplay] = useState(false)

  const addPlaying = () => {
    const videoEl = videoRef.current
    if (videoEl != null) {
      if (videoEl.autoplay == false) {
        videoEl.autoplay = true
      }

      setAutoplay(videoEl.autoplay)

      videoEl.play()
    }
  }

  useEffect(() => {
    if (webviewData.fileInfoDtos.length > 0) {
      setPdfUrl(webviewData.fileInfoDtos[0].fileUrl)
    }
  }, [])

  useEffect(() => {
    addPlaying()
  }, [autoplay])

  const handleVisibilityChange = () => {
    const videoElement = videoRef.current
    if (videoElement != null) {
      if (document.hidden) {
        videoElement.pause()
      } else {
        videoElement.play()
      }
    }
  }

  useEffect(() => {
    if (contentType.youtube || type === 'VIDEO') {
      document.addEventListener('visibilitychange', handleVisibilityChange, false)
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange, false)
      }
    }
  })

  const [pageNum, setPageNum] = useState(1)
  const [pages, setPages] = useState(0)

  const wrapperRef = useRef<HTMLDivElement>(null)

  function onPdfDocumentLoadSuccess({ numPages }) {
    setPages(numPages)
    // if (numPages > 20) {
    //   toast.success('PDF 파일은 20페이지까지만 등록 가능합니다.')
    //   console.log('페이지가 20페이지를 초과합니다. 20페이지로 제한됩니다.')
    //   if (webviewData.type === 'IMAGE' || webviewData.type === 'VIDEO') {
    //     setContentType((prev) => ({
    //       ...prev,
    //       media: true,
    //       youtube: false,
    //       pdf: false,
    //     }))

    //     if (webviewData.type === 'IMAGE') {
    //       setInputs((prev) => ({
    //         ...prev,
    //         type: 'IMAGE',
    //       }))
    //     }

    //     if (webviewData.type === 'VIDEO') {
    //       setInputs((prev) => ({
    //         ...prev,
    //         type: 'VIDEO',
    //       }))
    //     }
    //   }

    //   if (webviewData.type === 'YOUTUBE') {
    //     setContentType((prev) => ({
    //       ...prev,
    //       media: false,
    //       youtube: true,
    //       pdf: false,
    //     }))

    //     setInputs((prev) => ({
    //       ...prev,
    //       type: 'YOUTUBE',
    //     }))
    //   }

    //   if (webviewData.type === 'PDF') {
    //     setPdfUrl(webviewData.fileInfoDtos[0].fileUrl)
    //   }

    //   if (webviewData.fileInfoDtos.length <= 0) {
    //     setContentType((prev) => ({
    //       ...prev,
    //       media: false,
    //       youtube: false,
    //       pdf: false,
    //     }))

    //     setInputs((prev) => ({
    //       ...prev,
    //       type: '',
    //     }))
    //   }

    //   // fileTarget.current.value = ''
    //   setFiles(null)
    // } else {
    //   setPages(numPages)
    // }
  }

  // TODO: 3D 모델 업로드 관련 로직. 추후 정리해야 함
  const mountRef = useRef<HTMLDivElement>(null)
  const [glbPath, setGlbPath] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  //const download_ref = useRef(null);

  //setObjects(setObjects)
  useEffect(() => {
    //const canvas = document.querySelector('.webgl')
    const scene = new THREE.Scene()
    scene.background = new THREE.Color(0x808080)
    const camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    )
    camera.position.z = 30
    camera.position.y = 15
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    })

    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio))
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 0.6
    renderer.outputEncoding = THREE.sRGBEncoding
    //document.body.appendChild(renderer.domElement);

    if (mountRef.current) {
      mountRef.current.appendChild(renderer.domElement)
    }

    const hlight = new THREE.AmbientLight(0xffffff, 0.6)
    scene.add(hlight)

    const directionalLight = new THREE.DirectionalLight(0xffffff, 3)
    directionalLight.position.set(0, 1, 0)
    directionalLight.castShadow = true
    scene.add(directionalLight)

    const controls = new OrbitControls(camera, renderer.domElement)
    controls.addEventListener('change', () => {
      renderer.render(scene, camera)
    })
    controls.target.set(0, 0, 0)
    controls.enableDamping = true
    //controls.lookAt(scene.position)
    controls.update()

    const rgbeLoader = new RGBELoader()
    rgbeLoader.load(`${process.env.PUBLIC_URL}/qwantani_puresky_1k.hdr`, function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping
      scene.background = texture
      scene.environment = texture
    })

    const groundTexture = new THREE.TextureLoader().load(
      `${process.env.PUBLIC_URL}/Plikaland_viewerFloor.jpg`,
    )
    groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping
    groundTexture.repeat.set(3000, 3000)
    groundTexture.anisotropy = 16
    groundTexture.encoding = THREE.sRGBEncoding

    const groundMaterial = new THREE.MeshStandardMaterial({ map: groundTexture })

    const floormesh = new THREE.Mesh(new THREE.PlaneGeometry(50000, 50000), groundMaterial)
    floormesh.position.y = -30.0
    floormesh.rotation.x = -Math.PI / 2
    floormesh.receiveShadow = true
    scene.add(floormesh)

    if (glbPath) {
      const loader = new GLTFLoader()
      loader.load(
        glbPath,
        function (gltf) {
          var model = gltf.scene.children[0]
          model.position.set(0, 0, 0)
          var scale_num = 20
          // gltf.scene.position.set(0,0,0);
          var box = new THREE.Box3().setFromObject(model)
          var size = new THREE.Vector3()
          box.getSize(size)
          var scaleVec = new THREE.Vector3(scale_num, scale_num, scale_num).divide(size)
          var scale = Math.min(scaleVec.x, Math.min(scaleVec.y, scaleVec.z))
          model.scale.setScalar(scale)

          box = new THREE.Box3().setFromObject(model)
          var boundingBoxSize = box.max.sub(box.min)
          var height = boundingBoxSize.y
          model.position.y = -(height / 2)
          var aabb = new THREE.Box3().setFromObject(model)
          aabb.getCenter(controls.target)
          scene.add(gltf.scene)
        },
        (xhr) => {
          //console.log((xhr.loaded / xhr.total) * 100 + '% Download Progress')
          setPercentage(Math.round(((xhr.loaded / xhr.total) * 100) / 2) + 50)
        },
        (error) => {
          console.log(error)
        },
      )
    }

    let onWindowResize = function () {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
    }

    window.addEventListener('resize', onWindowResize, false)

    function animate() {
      requestAnimationFrame(animate)

      controls.update()

      render()
    }

    function render() {
      renderer.render(scene, camera)
    }
    animate()

    return () => {
      if (mountRef && mountRef.current != null) {
        mountRef.current.removeChild(renderer.domElement)
      }
    }
  }, [glbPath])

  function download() {
    console.log('download!! ') // glbpath 링크 사용해서 바로 다운로드 받기
  }

  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    if (percentage === 100) {
      setIsLoading(false)
      return
    }
    // const interval = setInterval(() => {
    //   if (percentage < 100) setPercentage(percentage + 2)
    // }, 100)
    // return () => clearInterval(interval)
  }, [percentage])

  function handleExport() {
    console.log('export!!')
    const obj = { url: glbPath, id: 0 }
    const data = JSON.stringify(obj)
    sendMsgWithParam('Webreceiver', 'OnSpawnModel', data)
    onClose()
  }

  function handleExportImg() {
    const obj = { url: [webviewData.fileInfoDtos[0].fileUrl], id: 0, typeDefine: 0 }
    const data = JSON.stringify(obj)
    sendMsgWithParam('Webreceiver', 'OnSpawnCanvas', data)
  }

  return (
    <>
      <form ref={formRef} onSubmit={updateContent}>
        <div className={styles.inner_box}>
          <div className={styles.title_bar}>
            <div className={styles.title}>
              <input
                type="text"
                value={name}
                name="name"
                onChange={inputValueHandler}
                placeholder="Title..."
              />
            </div>
            <div className={styles.close_btn} onClick={onClose}>
              <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
            </div>
          </div>
          <div className={styles.content_body}>
            <div className={styles.content_thumb_box}>
              {!contentType.media &&
                !contentType.youtube &&
                !contentType.pdf &&
                !contentType.model && (
                  <div className={styles.content_box_default}>
                    <p>Upload File (Image or Video)</p>
                    <p>or</p>
                    <p>Youtube link embed</p>
                  </div>
                )}
              <ViewerWrapper className={`${contentType.model ? 'block' : 'hidden'} group`}>
                <div
                  ref={mountRef}
                  className={`${contentType.model ? 'block' : 'hidden'} first:w-full`}
                >
                  <div
                    className={`absolute inset-0 flex items-center justify-center transition-opacity bg-slate-900/25 backdrop-blur ${
                      !isLoading ? 'opacity-0 hidden' : 'opacity-100'
                    }`}
                  >
                    <CircularProgressbar
                      className="w-20"
                      value={percentage}
                      text={`${percentage}%`}
                    />
                  </div>
                  {!isLoading && (
                    <button
                      type="button"
                      className="absolute px-6 py-2 font-semibold text-white transition-opacity duration-300 rounded-full opacity-0 group-hover:opacity-100 hover:bg-primary-50 bottom-2 right-2 bg-primary-100"
                      onClick={handleExport}
                    >
                      Export
                    </button>
                  )}
                </div>
              </ViewerWrapper>
              <Document
                ref={pdfRef}
                file={pdfUrl}
                onLoadSuccess={onPdfDocumentLoadSuccess}
                className={`relative w-full h-full overflow-hidden max-h-max ${
                  contentType.pdf ? 'block' : 'hidden'
                }`}
              >
                <Wrapper ref={wrapperRef}>
                  <TransformWrapper initialScale={1} minScale={1} maxScale={10}>
                    <TransformComponent>
                      <Page
                        // canvasRef={canvasRef}
                        pageNumber={pageNum}
                        width={840}
                        height={640}
                        renderAnnotationLayer={false}
                        // className="w-full h-full min-w-full min-h-full"
                      />
                    </TransformComponent>
                  </TransformWrapper>
                  <div className="absolute z-20 flex items-center gap-4 px-2 py-1 text-white rounded-full bg-slate-900/50 shadow-86 bottom-4 min-h-max left-2/4 -translate-x-2/4 ">
                    <button
                      type="button"
                      onClick={() => (pageNum > 1 ? setPageNum(pageNum - 1) : null)}
                    >
                      <IconChevronLeft className="w-4 h-auto" />
                    </button>
                    <p>
                      {pageNum} / {pages}
                    </p>
                    <button
                      type="button"
                      onClick={() => (pageNum < pages ? setPageNum(pageNum + 1) : null)}
                    >
                      <IconChevronRight className="w-4 h-auto" />
                    </button>
                  </div>
                </Wrapper>
              </Document>

              <div
                className={`${styles.content_box} ${
                  contentType.media ? 'block relative group' : 'hidden relative group'
                }`}
              >
                <img
                  ref={imageRef}
                  className={type == 'IMAGE' ? '' : 'blind'}
                  src={
                    webviewData.fileInfoDtos[0] != null
                      ? webviewData.fileInfoDtos[0].fileUrl
                      : Rectangle
                  }
                  alt=""
                />
                <button
                  type="button"
                  className="absolute z-[999] px-6 py-2 font-semibold text-white transition-opacity duration-300 rounded-full opacity-0 group-hover:opacity-100 hover:bg-primary-50 bottom-2 right-2 bg-primary-100"
                  onClick={handleExportImg}
                >
                  Export
                </button>
                <video
                  controls
                  muted
                  loop
                  ref={videoRef}
                  className={type == 'VIDEO' ? '' : 'blind'}
                  src={
                    webviewData.fileInfoDtos[0] != null ? webviewData.fileInfoDtos[0].fileUrl : null
                  }
                  poster={webviewData.fileInfoDtos[0] == null ? Rectangle : undefined}
                ></video>
              </div>

              <iframe
                className={`${styles.content_youtube_frame} ${
                  contentType.youtube ? 'block' : 'hidden'
                }`}
                src={youtubeUri}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>

              <div className={styles.sel_modal_wrap}>
                <div
                  ref={selModalBtnRef}
                  className={`${styles.upload_btn} ${uiStyle.icon} ${uiStyle.att}`}
                  onClick={(e) => toggleSelectModal(e)}
                ></div>
                {selectModalPopup && (
                  <SelectModal
                    selModalBtnRef={selModalBtnRef}
                    setSelectModalPopup={setSelectModalPopup}
                    titleData={titleData}
                  ></SelectModal>
                )}
              </div>

              {/* <input id="content" type="file" name='content' className="blind" onChange={fileHandler}/> */}

              {isMobile === false && (
                <button
                  type="button"
                  className={
                    isLinkOpen
                      ? `${styles.edit} ${styles.link_btn} ${styles.active}`
                      : `${styles.edit} ${styles.link_btn}`
                  }
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    !isLinkOpen
                      ? setInputs({
                          ...inputs,
                          isLinkOpen: true,
                        })
                      : undefined
                  }}
                >
                  <div
                    className={
                      isLinkOpen ? `${styles.link_icon} ${styles.active}` : `${styles.link_icon}`
                    }
                    onClick={() => {
                      if (isLinkOpen) {
                        setInputs({
                          ...inputs,
                          isLinkOpen: false,
                        })
                      } else {
                        setInputs({
                          ...inputs,
                          isLinkOpen: true,
                        })
                      }
                    }}
                  >
                    <LinkIcon />
                  </div>
                  {isLinkOpen ? (
                    <>
                      <input
                        type="text"
                        placeholder="Link Title"
                        value={linkName}
                        name="linkName"
                        onChange={inputValueHandler}
                      />
                      <input
                        type="text"
                        placeholder="URL"
                        value={linkUrl}
                        name="linkUrl"
                        onChange={inputValueHandler}
                      />
                    </>
                  ) : (
                    <span>Add Link</span>
                  )}
                </button>
              )}
            </div>
            <div className={styles.content_desc}>
              <textarea id="" value={desc} name="desc" onChange={inputValueHandler}></textarea>
              {isMobile && (
                <ButtonBox>
                  <button
                    type="button"
                    className={
                      isLinkOpen
                        ? `${styles.edit} ${styles.link_btn} ${styles.active}`
                        : `${styles.edit} ${styles.link_btn}`
                    }
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      !isLinkOpen
                        ? setInputs({
                            ...inputs,
                            isLinkOpen: true,
                          })
                        : undefined
                    }}
                  >
                    <div
                      className={
                        isLinkOpen ? `${styles.link_icon} ${styles.active}` : `${styles.link_icon}`
                      }
                      onClick={() => {
                        if (isLinkOpen) {
                          setInputs({
                            ...inputs,
                            isLinkOpen: false,
                          })
                        } else {
                          setInputs({
                            ...inputs,
                            isLinkOpen: true,
                          })
                        }
                      }}
                    >
                      <LinkIcon />
                    </div>
                    {isLinkOpen ? (
                      <>
                        <input
                          type="text"
                          placeholder="Link Title"
                          value={linkName}
                          name="linkName"
                          onChange={inputValueHandler}
                        />
                        <input
                          type="text"
                          placeholder="URL"
                          value={linkUrl}
                          name="linkUrl"
                          onChange={inputValueHandler}
                        />
                      </>
                    ) : (
                      <span>Add Link</span>
                    )}
                  </button>

                  <button
                    type="button"
                    className={`${uiStyle.btn_theme_yel} ${styles.save_btn}`}
                    onClick={() => {
                      setSavePopup(true)
                    }}
                  >
                    Save
                  </button>
                </ButtonBox>
              )}
              {isMobile === false && (
                <button
                  type="button"
                  className={`${uiStyle.btn_theme_yel} ${styles.save_btn}`}
                  onClick={() => {
                    setSavePopup(true)
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
          {savePopup ? (
            <ContentSaveAlert
              formSubmit={formSubmit}
              webviewClose={onClose}
              onClose={setSavePopup}
              updateContent={updateContent}
            ></ContentSaveAlert>
          ) : (
            <></>
          )}
        </div>
      </form>
      {youtubeEmbedModal && (
        <YoutubeEmbedModal
          onClickEvent={youtubeUriValueHandler}
          onClose={() => setYoutubeEmbedModal(false)}
        ></YoutubeEmbedModal>
      )}
      {saving && <Notice innerText={'저장중입니다'} width={180} height={40}></Notice>}
      {saveNotice && (
        <Notice innerText={t('space.common.save_content')} width={180} height={40}></Notice>
      )}
    </>
  )
}

const ButtonBox = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    gap: 10px;
  }

  @media (max-width: 500px) {
    height: 30px;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .react-pdf__Page {
    max-width: auto !important;
    max-height: calc(100% - 60px) !important;
  }

  .textLayer {
    display: none;
  }

  canvas {
    /* width: 100% !important;*/
    height: 640px !important;
    object-fit: contain !important;
  }
`

const ViewerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }
`

export default ContentEditWebview
