import React from 'react'

import SvgIconUsers from 'components/vectors/IconUsers'

interface LandUserBadgeProps {
  currentUsers: number
  maxUsers: number
}

export default function LandUserBadge({ currentUsers, maxUsers }: LandUserBadgeProps) {
  return (
    <span className="invisible md:visible absolute flex items-center px-2.5 py-0.5 shadow-lg font-medium rounded-xl text-sm text-gray-300 bg-gray-900/50 top-2 right-2">
      <SvgIconUsers className="w-4 h-auto mr-1" />
      {currentUsers}/{maxUsers}
    </span>
  )
}
