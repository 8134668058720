import * as React from 'react'
import { SVGProps } from 'react'
const SvgImgEmotionEight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 86 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.36 19.418h1.246v-3.024h2.842v-1.176h-2.842v-3.024H47.36v3.024h-2.828v1.176h2.828v3.024Z"
      fill="#000"
    />
    <rect x={1} y={1} width={38} height={28} rx={14} fill="#fff" stroke="#000" strokeWidth={2} />
    <rect x={57} y={1} width={28} height={28} rx={14} fill="#fff" stroke="#000" strokeWidth={2} />
    <path
      d="M10 21h1.652l1.666-5.586c.35-1.12.658-2.268.966-3.43h.056c.322 1.148.63 2.31.98 3.43L16.972 21h1.722l-3.402-10.304h-1.89L10 21Zm1.918-2.926h4.816V16.8h-4.816v1.274Zm9.605 3.122c.406 0 .658-.07.868-.14l-.21-1.218c-.14.028-.196.028-.266.028-.196 0-.378-.154-.378-.588V9.856h-1.624v9.338c0 1.246.434 2.002 1.61 2.002Zm5.15 0c.574 0 1.092-.154 1.526-.294l-.294-1.19c-.238.098-.56.196-.826.196-.798 0-1.134-.49-1.134-1.4v-3.92h2.016V13.3h-2.016v-2.128H24.6l-.182 2.128-1.204.084v1.204h1.12v3.906c0 1.61.616 2.702 2.338 2.702ZM71.004 21.196c1.988 0 3.332-1.176 3.332-2.702 0-1.414-.812-2.212-1.736-2.73v-.07c.63-.49 1.344-1.372 1.344-2.436 0-1.61-1.106-2.716-2.898-2.716-1.694 0-2.954 1.05-2.954 2.66 0 1.078.616 1.862 1.386 2.408v.056c-.952.504-1.834 1.428-1.834 2.786 0 1.596 1.414 2.744 3.36 2.744Zm.672-5.908c-1.148-.462-2.128-.98-2.128-2.086 0-.924.63-1.498 1.47-1.498.994 0 1.582.714 1.582 1.652 0 .7-.308 1.358-.924 1.932Zm-.644 4.732c-1.12 0-1.974-.714-1.974-1.736 0-.896.504-1.652 1.218-2.156 1.4.588 2.534 1.064 2.534 2.31 0 .966-.728 1.582-1.778 1.582Z"
      fill="#000"
    />
  </svg>
)
export default SvgImgEmotionEight
