import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { GrClose } from 'react-icons/gr'

import styles from '../styles/myland.module.scss'

import { useSlide } from '../hooks/useSlide'
import axiosI from '../utils/AxiosI'
import media from '../lib/styles/media'

import loading from '../assets/ui/loading.png'
import Footer from '../components/tile/Footer'
import Header from '../components/tile/Header'
import uiStyle from '../styles/ui.module.scss'
import SpaceDetail from '../components/spaces/SpaceDetail'
import Pagination from '../components/ui/Pagination'
import SpaceEdit from '../components/spaces/SpaceEdit'
import MobileSpaceEdit from 'components/spaces/MobileSpaceEdit'

function Myland() {
  const [accountInfo, setAccountInfo]: any = useState()

  const [landList, setLandList]: any = useState()

  const [curPageLandList, setCurPageLandList]: any = useState()
  const [targetElement, setTargetElement]: any = useState()

  useEffect(() => {
    if (accountInfo != null) {
      if (accountInfo.accountId != null) {
        const accessToken = window.localStorage.getItem('plikaTk')
        const config = {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
        if (accessToken != null && accessToken != 'null' && accessToken.length > 0) {
          axiosI.get(process.env.REACT_APP_API_HOST + 'v1/metaverse/my', config).then((e) => {
            const mylandsList = e.data.data

            setLandList(mylandsList)
          })
        }
      } else {
        window.location.replace('/')
      }
    }
  }, [accountInfo])

  const [
    onMouse,
    index,
    arrayName,
    newItemPosition,
    slideItemMouseOver,
    slideItemMouseLeave,
    slideInfoMouseOver,
    slideInfoMouseLeave,
    gsapAnimation,
  ] = useSlide()

  useEffect(() => {
    if (landList != null) {
      setTargetElement(curPageLandList[index])
    }
  }, [onMouse, index])

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [showLand, setShowLand] = useState(false)
  const [currentLandInfo, setCurrentLandInfo] = useState({
    accountId: null,
    companyUrl: '',
    currentJoinMemberTotal: 0,
    description: '',
    email: '',
    idName: '',
    logoUrl: null,
    name: '',
    nickname: '',
    space_id: null,
    templateThumbnail: '',
    template_id: null,
    thumbnailUri: '',
  })

  function slideItemClick(space: any) {
    setCurrentLandInfo((prev) => ({ ...prev, ...space }))
    setShowLand(true)
  }

  const [spaceJoinedMemberFull, setSpaceJoinedMemberFull] = useState(false)

  function enterLand() {
    if (currentLandInfo.currentJoinMemberTotal >= 6) {
      setSpaceJoinedMemberFull(true)

      setTimeout(() => {
        setSpaceJoinedMemberFull(false)
      }, 3000)
    } else {
      window.location.href = `/app/${currentLandInfo.idName}`
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <Header show={null} setAccountInfoState={setAccountInfo}></Header>
      <div className={styles.my_container}>
        <div className={styles.container_inner}>
          <div className={styles.my_header}>
            <p>MY LAND</p>
          </div>
          {curPageLandList != null && curPageLandList.length > 0 ? (
            <>
              <div className={`${styles.list_item} ${styles.my_list_item}`}>
                {curPageLandList != null &&
                  accountInfo != null &&
                  curPageLandList.map((el: any, i: any) => (
                    <>
                      <div key={i} className={styles.list_item_box}>
                        {isMobile ? (
                          <MobileSpaceEdit
                            admin={el.accountId == accountInfo.accountId}
                            slideItemClick={slideItemClick}
                            index={i}
                            space={el}
                            arrayName={'myLands'}
                          ></MobileSpaceEdit>
                        ) : (
                          <SpaceEdit
                            admin={el.accountId == accountInfo.accountId}
                            slideItemMouseOver={slideItemMouseOver}
                            slideItemMouseLeave={slideItemMouseLeave}
                            index={i}
                            space={el}
                            arrayName={'myLands'}
                          ></SpaceEdit>
                        )}
                      </div>
                    </>
                  ))}
              </div>
            </>
          ) : (
            <>
              <div className={styles.no_result}>
                <p>{t('my_land.text1')}</p>
                <button className={uiStyle.btn_theme_bl}>
                  <a href="/lands/create">Create 3DLAND</a>
                </button>
              </div>
            </>
          )}
        </div>
        {landList != null && landList.myLands.length > 0 && (
          <Pagination
            totalList={landList.myLands}
            itemPerPage={20}
            setCurPageList={setCurPageLandList}
          ></Pagination>
        )}
      </div>

      <Footer></Footer>

      {showLand &&
        (isMobile ? (
          <ListDetail>
            <InfoBox>
              <Thumbnail>
                <img
                  src={
                    currentLandInfo?.templateThumbnail != null
                      ? currentLandInfo?.templateThumbnail
                      : loading
                  }
                  alt=""
                />
              </Thumbnail>
              <TextBox>
                <h3>{currentLandInfo.name}</h3>
                <p>{currentLandInfo.companyUrl}</p>
                <p>{currentLandInfo.description}</p>
              </TextBox>
            </InfoBox>
            <ButtonBox>
              <button onClick={enterLand}>Enter</button>
            </ButtonBox>
            <CloseIcon onClick={() => setShowLand(false)} />
          </ListDetail>
        ) : (
          <></>
        ))}

      {/* {   
                popup
                ? <LandCantCreateAlert onClose={setPopup}></LandCantCreateAlert>
                : <></>                
            } */}

      <div id="over_wrap" className={styles.main_over_wrap}>
        {onMouse && targetElement != null ? (
          <SpaceDetail
            space={targetElement}
            gsap={gsapAnimation}
            newItemPosition={newItemPosition.current}
            slideInfoMouseOver={slideInfoMouseOver}
            slideInfoMouseLeave={slideInfoMouseLeave}
          ></SpaceDetail>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

const ListDetail = styled.div`
  position: sticky;
  width: 100%;
  height: max-content;
  background: #fff;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  border: 1px solid #cccccc;
  box-shadow: 0px -2px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;

  padding: 8px;
  padding-bottom: 20px;
`

const InfoBox = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`

const Thumbnail = styled.div`
  width: 46%;
  height: 260px;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.small} {
    height: 180px;
  }

  @media (max-width: 500px) {
    height: 140px;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 54%;
  gap: 5px;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fee500;
    border-radius: 40px;

    font-size: 22px;
    font-weight: 700;
    transition: 0.3s;
  }

  button:hover {
    background: #fff280;
  }

  ${media.small} {
    button {
      height: 50px;
    }
  }
`

const CloseIcon = styled(GrClose)`
  position: absolute;
  top: 10px;
  right: 10px;

  font-size: 30px;
  font-weight: 900;
  cursor: pointer;
`

export default Myland
