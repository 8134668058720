import React, { createContext, useContext, useState } from 'react'

type Props = {
  children: React.ReactNode
}

type ModalModeContextType = {
  modalMode: {
    searchBar: boolean
    landDetail: boolean
    scanUploader: boolean
  }
  toggleModalMode: (key: string, value: boolean) => void
}

const ModalModeContext = createContext<ModalModeContextType | null>(null)

export function ModalModeProvider({ children }: Props) {
  const [modalMode, setModalMode] = useState({
    searchBar: false,
    landDetail: false,
    scanUploader: false,
  })

  const toggleModalMode = (key: string, value: boolean) => {
    setModalMode((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  return (
    <ModalModeContext.Provider value={{ modalMode, toggleModalMode }}>
      {children}
    </ModalModeContext.Provider>
  )
}

export const useModalMode = () => useContext(ModalModeContext) as ModalModeContextType
