import React from 'react'
import styled from 'styled-components'
import Input, { InputComponent } from 'components/atoms/Input'
import Icon, { IconComponent } from 'components/atoms/Icon'

export const SearchBarComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 240px;
  height: 40px;
  padding: 0 18px;
  border: 1px solid ${({ theme }) => theme.color.gray};
  border-radius: 3em;

  ${InputComponent} {
    &::placeholder {
      color: ${({ theme }) => theme.color.gray};
    }
  }

  ${IconComponent} {
    color: ${({ theme }) => theme.color.black};
  }
`

const SearchBar = () => {
  return (
    <SearchBarComponent>
      <Input name="name" placeholder="LAND 검색..." />
      <button type="submit">
        <Icon type="search" size={24} />
      </button>
    </SearchBarComponent>
  )
}

export default SearchBar
