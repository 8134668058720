import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faXmark, faLink } from '@fortawesome/free-solid-svg-icons'
import styles from '../../../styles/webview.module.scss'
import uiStyle from '../../../styles/ui.module.scss'
import linkIcon from '../../../assets/ui/link_icon.png'
import '../../../styles/common.scss'
import Rectangle from '../../../assets/ui/Rectangle.png'
import ContentSaveAlert from '../ContentSaveAlert'
import _ from 'lodash'
import axios from 'axios'
import InputWithTitle from '../../ui/InputWithTitle'
import InputRound from '../../ui/InputRound'
import { isValidYoutubeUri, getEmbedYoutubeUrl } from '../../../utils/YoutubeUtil'
import Dropdown from 'components/ui/Dropdown'
import SelectModal from 'components/molecules/SelectModal'
import styled from 'styled-components'
import Text from 'components/atoms/Text'
import YoutubeEmbedModal from 'components/molecules/YoutubeEmbedModal'
import Notice from 'components/atoms/Notice'
import defaultImg from '@assets/ui/content_default.png'
import { LinkIcon } from '../../../static/svg'
import { useTranslation } from 'react-i18next'

function TextContentEditWebview(props: any) {
  const { t } = useTranslation()
  const formRef: any = useRef()

  function formSubmit() {
    if (formRef.current != null) {
      formRef.current.submit()
    }
  }

  const { webviewData, setUploading, sendMsgWithParam, onClose } = props

  const [savePopup, setSavePopup] = useState(false)

  const [inputs, setInputs] = useState({
    id: webviewData.id,
    contentIndex: webviewData.contentIndex,
    name: webviewData.name,
    desc: webviewData.desc,
    isLinkOpen: webviewData.isLinkOpen,
    linkName: webviewData.linkName,
    linkUrl: webviewData.linkUrl,
    type: webviewData.type,
  })

  const { id, contentIndex, name, desc, isLinkOpen, linkName, linkUrl, type } = inputs

  const inputValueHandler = (e: any) => {
    const { value, name } = e.target
    setInputs({
      ...inputs,
      [name]: value,
    })
  }

  const [saveNotice, setSaveNotice]: any = useState()

  useEffect(() => {
    if (saveNotice) {
      setTimeout(() => {
        setSaveNotice(false)
      }, 3000)
    }
  }, [saveNotice])

  async function updateContent(e: any) {
    e.preventDefault()

    setUploading(true)

    const { name, desc, linkName, linkUrl } = e.target

    const formData = new FormData()

    const data = {
      name: name != null ? name.value : inputs.name,
      desc: desc != null ? desc.value : inputs.desc,
      isLinkOpen: isLinkOpen,
      linkName: linkName != null ? linkName.value : inputs.linkName,
      linkUrl: linkUrl != null ? linkUrl.value : inputs.linkUrl,
      typeCode: type,
    }

    formData.append(
      'content-update',
      new Blob([JSON.stringify(data)], { type: 'application/json' }),
    )

    const requestUri = process.env.REACT_APP_API_HOST + `contents/${id}`

    await axios
      .patch(requestUri, formData)
      .then((resp) => {
        // UNITY 호출 로직
        if (resp.data.resp_code === 'SUCCESS') {
          const obj = {
            Url: '',
            BoothId: contentIndex,
            ModeNum: 3,
            Name: resp.data.data.name,
            Desc: resp.data.data.desc,
          }
          const param = JSON.stringify(obj)

          sendMsgWithParam('Webreceiver', 'UpdateMedia', param)
          setUploading(false)
          setSavePopup(false)
          setSaveNotice(true)
        }
        setUploading(false)
      })
      .catch((err) => {
        alert('파일 업로드에 실패하였습니다.')
        setUploading(false)
        //window.location.reload();
      })
  }

  return (
    <>
      <StyledForm ref={formRef} onSubmit={updateContent}>
        <InnerBox>
          <TitleBar>
            <div className={`${styles.title} `}>
              <input
                type="text"
                value={name}
                name="name"
                onChange={inputValueHandler}
                placeholder="제목을 입력해주세요"
              />
            </div>
            <div className={styles.close_btn} onClick={onClose}>
              <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
            </div>
          </TitleBar>
          <ContentBody>
            <textarea
              id=""
              value={desc}
              name="desc"
              onChange={inputValueHandler}
              placeholder="내용을 입력해주세요"
            ></textarea>
          </ContentBody>
          <ButtonWrap>
            <button
              type="button"
              className={
                isLinkOpen
                  ? `${styles.edit} ${styles.link_btn} ${styles.active}`
                  : `${styles.edit} ${styles.link_btn}`
              }
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                !isLinkOpen
                  ? setInputs({
                      ...inputs,
                      isLinkOpen: true,
                    })
                  : undefined
              }}
            >
              <div
                className={
                  isLinkOpen ? `${styles.link_icon} ${styles.active}` : `${styles.link_icon}`
                }
                onClick={() => {
                  if (isLinkOpen) {
                    setInputs({
                      ...inputs,
                      isLinkOpen: false,
                    })
                  } else {
                    setInputs({
                      ...inputs,
                      isLinkOpen: true,
                    })
                  }
                }}
              >
                <LinkIcon />
              </div>
              {isLinkOpen ? (
                <>
                  <input
                    type="text"
                    placeholder="Link Title"
                    value={linkName}
                    name="linkName"
                    onChange={inputValueHandler}
                  />
                  <input
                    type="text"
                    placeholder="URL"
                    value={linkUrl}
                    name="linkUrl"
                    onChange={inputValueHandler}
                  />
                </>
              ) : (
                <span>Add Link</span>
              )}
            </button>
            <button
              type="button"
              className={`${uiStyle.btn_theme_yel} ${styles.save_btn}`}
              onClick={() => setSavePopup(true)}
            >
              Save
            </button>
          </ButtonWrap>
          {savePopup ? (
            <ContentSaveAlert
              formSubmit={formSubmit}
              webviewClose={onClose}
              onClose={setSavePopup}
              updateContent={updateContent}
            ></ContentSaveAlert>
          ) : (
            <></>
          )}
        </InnerBox>
      </StyledForm>

      {saveNotice && (
        <Notice innerText={t('space.common.save_content')} width={180} height={40}></Notice>
      )}
    </>
  )
}

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  padding: 26px 30px;
`

export const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  width: 100%;
  height: 100%;

  input {
    width: 840px;
    height: 40px;
    border: 1px solid #cccccc;
    border-radius: 20px;
    padding: 0 16px;

    &::placeholder {
      color: #cccccc;
    }
  }
`

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ContentBody = styled.div`
  width: 100%;
  height: 100%;

  textarea {
    width: 100%;
    height: 620px;
    border: 1px solid #cccccc;
    border-radius: 20px;
    padding: 16px;
    font-size: 16px;
    resize: none;

    &::placeholder {
      color: #cccccc;
    }
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 10px;

  button {
    width: 120px;
    height: 100%;
  }
`

export default TextContentEditWebview
