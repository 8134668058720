import { Listbox, Transition } from '@headlessui/react'
import { IconCheck, IconChevronDown } from 'components/vectors'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { SendIcon } from '../../../static/svg'

const people = [
  {
    id: 1,
    name: 'Wade Cooper',
    avatar:
      'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 2,
    name: 'Arlene Mccoy',
    avatar:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 3,
    name: 'Devon Webb',
    avatar:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80',
  },
  {
    id: 4,
    name: 'Tom Cook',
    avatar:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 5,
    name: 'Tanya Fox',
    avatar:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 6,
    name: 'Hellen Schmidt',
    avatar:
      'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 7,
    name: 'Caroline Schultz',
    avatar:
      'https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 8,
    name: 'Mason Heaney',
    avatar:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 9,
    name: 'Claudie Smitham Claudie Smitham',
    avatar:
      'https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 10,
    name: 'Emil Schaefer',
    avatar:
      'https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface ChatInputProps {
  sendBtnClick: any
  sendTextMsg: any
  sendBtnHover: any
  onSendChat: any
  sendMsgWithParam: any
}

export const ChatInputComponent = styled.div`
  border-top: 1px solid #ffffff;
  margin: 10px 15px;
`

export const FormComponent = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  width: 100%;
  padding-top: 10px;

  svg {
    color: #fff;
    width: 26px;
    height: 26px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      filter: drop-shadow(0px 0px 5px #fff);
    }
  }
`

export const InputComponent = styled.input`
  height: 40px;
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  flex-grow: 1;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;

  &::placeholder {
  }
`

export const ButtonComponent = styled.button`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  margin-right: 4px;
  background: url('../../../static/svg') no-repeat 0 0 / auto 101%;
  cursor: pointer;
`

const ChatInput = React.forwardRef<HTMLInputElement, ChatInputProps>(function (
  { sendBtnClick, sendTextMsg, sendBtnHover, onSendChat, sendMsgWithParam },
  ref,
) {
  function submitText(e: any) {
    e.preventDefault()
    const { textContent } = e.target
    if (textContent.value.trim().length > 0) {
      sendTextMsg(textContent.value)
      prevInputText.current = textContent
      textContentRef.current.value = ''
    } else {
      prevInputText.current = ''
    }
  }

  const textContentRef: any = useRef()
  const inputFocused = useRef<boolean>(false)
  const prevInputText: any = useRef('')

  function EnterKeyPress(e: any) {
    if (e.key == 'Enter' && !inputFocused.current) {
      textContentRef.current.focus()
      inputFocused.current = true
      sendMsgWithParam('Webreceiver', 'ContentSwitch', 0)
    } else if (e.key == 'Enter' && inputFocused.current && prevInputText.current.length <= 0) {
      textContentRef.current.blur()
      inputFocused.current = false
      sendMsgWithParam('Webreceiver', 'ContentSwitch', 1)
    }
  }

  function inputOnFocusHandler() {
    inputFocused.current = true
    sendMsgWithParam('Webreceiver', 'ContentSwitch', 0)
  }

  useEffect(() => {
    window.addEventListener('keyup', EnterKeyPress)
    return () => {
      window.removeEventListener('keyup', EnterKeyPress)
    }
  }, [])

  const [selected, setSelected] = useState<{ id: number; name: string; avatar: string } | null>(
    people[3],
  )

  return (
    <ChatInputComponent>
      {/* <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <div className="flex items-center w-full pt-1">
            <Listbox.Label className="block font-medium text-white pr-9">To</Listbox.Label>
            <div className="relative mt-1">
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg scrollbar-hide rounded-2xl bottom-12 max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <Listbox.Option
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-secondary-100 text-slate-900' : 'text-slate-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={null}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            Everyone
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-slate-900' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <IconCheck className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                  {people.map((person) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-secondary-100 text-slate-900' : 'text-slate-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                        )
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-6 h-6 overflow-hidden border rounded-full border-slate-900">
                              <img
                                src={person.avatar}
                                alt="avatar"
                                className="object-cover w-full h-full"
                              />
                            </div>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'ml-3 block truncate',
                              )}
                            >
                              {person.name}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-slate-900' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <IconCheck className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
              <Listbox.Button className="relative  min-w-[13.125rem] max-w-[13.125rem] w-full py-2 pl-4 pr-10 text-left bg-transparent border border-white border-solid rounded-full shadow-sm cursor-default focus:border-secondary-100 focus:outline-none focus:ring-1 focus:ring-secondary-100 sm:text-sm">
                <span className="flex items-center">
                  {selected ? (
                    <>
                      <div className="flex-shrink-0 w-6 h-6 overflow-hidden border rounded-full border-slate-900">
                        <img
                          src={selected.avatar}
                          alt="avatar"
                          className="object-cover w-full h-full"
                        />
                      </div>
                      <span className="block ml-3 text-white line-clamp-1">{selected.name}</span>
                    </>
                  ) : (
                    <span className="block leading-6 text-white truncate">Everyone</span>
                  )}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-3 ml-3 pointer-events-none">
                  <IconChevronDown
                    className={classNames(
                      open ? 'rotate-180' : '',
                      'w-5 h-5 text-white  duration-500',
                    )}
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            </div>
          </div>
        )}
      </Listbox> */}

      <FormComponent onSubmit={(e) => submitText(e)}>
        <InputComponent
          autoComplete="off"
          ref={textContentRef}
          name="textContent"
          type="text"
          onFocus={() => inputOnFocusHandler()}
          placeholder="Message..."
        />

        <IconWrap>
          <SendIcon
            onPointerLeave={(e) => sendBtnClick(e)}
            onPointerEnter={(e) => sendBtnHover(e)}
            onClick={(e) => {
              onSendChat(e)
            }}
          />
        </IconWrap>
      </FormComponent>
    </ChatInputComponent>
  )
})
ChatInput.displayName = 'ChatInput'

const IconWrap = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
`
export default ChatInput
