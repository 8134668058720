export const colors = {
    gray0: '#ECECEC',
    gray1: '#C6C6C6',
    gray2: '#A0A0A0',
    gray3: '#4B4B4B',
    gray4: '#2E2E2E',
    gray5: '#121212',
    primary: '#FFA000',
    secondary: '#FEE500',
    secondaryButtonText: '#000',
    destructive: 'red',
};
