import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import uiStyle from '../../styles/ui.module.scss'
import styles from '../../styles/modal.module.scss'
import {useTranslation} from 'react-i18next';

function RemoveMemberAlert(props) {

    const { memberInfo, deleteTeamMember, onClose } = props;

    document.body.style.overflow = 'hidden';

    const webviewInner:any = useRef();

    function closeWebview (e) {
        if(!webviewInner.current.contains(e.target)) {
            document.body.style.overflow = 'unset';
            onClose();
        }
    }

    const {t} = useTranslation()
    
    
    return (
        <>
            <div className={`${styles.modal_container} ${styles.alert}`} onPointerDown={(e) => closeWebview(e)}>
                <div ref={webviewInner} className={`${styles.container_inner} ${styles.alert_inner} ${styles.alarm}`}>
                    <div className={styles.modal_header}>
                        <p>{t('space.common.remove_member.title1')}</p>
                        <button onClick={() => onClose()}><FontAwesomeIcon icon={faX} /></button>
                    </div>
                    <div className={styles.modal_desc}>
                    {t('space.common.remove_member.text1', {nickname: memberInfo.detail.nickname, email: memberInfo.detail.email} )}
                    </div>
                    <div className={styles.modal_btn_wrapper}>
                    <button onClick={() => onClose()} className={uiStyle.btn_theme_gray}>Cancel</button>
                        <button className={uiStyle.btn_theme_red} onClick={() => deleteTeamMember(memberInfo)}>Remove</button>                        
                    </div>
                </div>
            </div>
        </>
    )

}

export default RemoveMemberAlert