import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import uiStyle from '../../styles/ui.module.scss'
import styles from '../../styles/modal.module.scss'
import {useTranslation} from 'react-i18next';

function LandCreateAlert(props) {

    const {t} = useTranslation()

    const { onClose } = props;

    document.body.style.overflow = 'hidden';

    function close() {
        document.body.style.overflow = 'unset';
        onClose();
    }
    
    return (
        <>
            <div className={`${styles.modal_container}`}>
                <div className={`${styles.container_inner} ${styles.alarm}`}>
                    <div className={styles.modal_header}>
                        <p>{t('create.create_land.title1')}</p>
                        <button onClick={() => close()}><FontAwesomeIcon icon={faX} /></button>
                    </div>
                    <div className={styles.modal_desc}>
                    {t('create.create_land.text1')}
                        <br/>
                        <br/>
                        {t('create.create_land.text2')}
                    </div>
                    <div className={styles.modal_btn_wrapper}>
                        <button type='button' onClick={() => close()} className={uiStyle.btn_theme_gray}>Cancel</button>
                        <button type='submit' className={uiStyle.btn_theme_yel} >Create</button>                        
                    </div>
                </div>
            </div>
        </>
    )

}

export default LandCreateAlert