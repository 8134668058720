import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import BasicLayout from 'components/layouts/BasicLayout';
import Button from 'components/system/Button';
import {
    MintPlikaLogo,
    PlikalandBetaBlackLogo,
    SquareIcon,
} from '../../static/svg';
import avatarMale from '../../static/png/avatar_male.png';
import avatarFemale from '../../static/png/avatar_female.png';
import circleMale from '../../static/png/circle_male.png';
import circleFemale from '../../static/png/circle_female.png';
import loading from '../../static/png/img_mint_plika_loading.png';
import {useMediaQuery} from 'react-responsive';

const genderMap = {
    0: avatarMale,
    1: avatarFemale,
};

interface SelectProps {
    active: boolean;
}

function AvatarSelectA({loadingThumbnail, sendMsg, accountInfo}: any) {
    const [state, setState] = useState(0);
    const [num, setNum] = useState('');
    const [error, setError] = useState(false);
    const [gender, setGender] = useState(0);
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)',
    });

    const checkContinue = (args: any, max = 6) => {
        const str = args.split('-').join('');
        const arr = [...str].map(v => v.charCodeAt());
        console.log(arr);
        let preStr = 0;
        let chr = 0;

        arr.forEach(s => {
            if (Math.abs(preStr - s) === 0) {
                chr++;
            }
            preStr = s;
        });

        console.log(chr > max);

        return chr > max;
    };

    return (
        <BasicLayout>
            <LoadingImgWrap>
                <img src={loadingThumbnail} alt="loading" />
            </LoadingImgWrap>
            <Content>
                {/*<Description>*/}
                {/*    /!*<div>*!/*/}
                {/*    /!*    <h1>MintCollection</h1>*!/*/}
                {/*    /!*    <h2>Future Costume Lab</h2>*!/*/}
                {/*    /!*</div>*!/*/}

                {/*    /!*<h3>2022.10.26 (WED) ~ 10.30 (SUN)</h3>*!/*/}

                {/*    /!*<p>■ Starfield Hanam Central Atrium</p>*!/*/}

                {/*    /!*<MintPlikaLogo />*!/*/}
                {/*</Description>*/}
                <Modal>
                    <div>
                        <h3>Avatar</h3>
                        <p>아바타 유닛을 선택해주세요</p>
                    </div>
                    <BodyImageWrap>
                        <img src={genderMap[gender]} alt="" />
                    </BodyImageWrap>
                    <SelectImageGroup>
                        <SelectImage
                            onClick={() => setGender(0)}
                            active={gender === 0 ? true : false}
                        >
                            <img src={circleMale} alt="" />
                        </SelectImage>
                        <SelectImage
                            onClick={() => setGender(1)}
                            active={gender === 1 ? true : false}
                        >
                            <img src={circleFemale} alt="" />
                        </SelectImage>
                    </SelectImageGroup>

                    <ButtonWrap>
                        <Button
                            variant="secondary"
                            onClick={() => sendMsg(null, gender)}
                        >
                            ENTER
                        </Button>
                    </ButtonWrap>
                </Modal>
                <Logo>
                    <PlikalandBetaBlackLogo />
                </Logo>
            </Content>
        </BasicLayout>
    );
}

const bottomCheck = keyframes`
    0% {
    height: 0;
    }
    100% {
        height: 10px;
    }
`;

const topCheck = keyframes`
     0% {
    height: 0;
    }
    50% {
        height: 0;
    }
    100% {
        height: 16px;
    }
`;

const LoadingImgWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        filter: brightness(60%);
    }
`;

const Content = styled.div`
    display: flex;
    height: max-content;

    justify-content: center;
    align-items: center;
    gap: 50px;

    h1 {
        font-size: 96px;
        font-weight: 700;
        line-height: 1.2;
    }

    h2 {
        font-size: 64px;
        font-weight: 300;
        line-height: 1.2;
    }

    h3 {
        font-size: 40px;
        font-weight: 500;
        line-height: 1.2;
    }

    p {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2;
    }

    @media (max-width: 1320px) {
        width: 100%;
        height: 100%;
        margin-bottom: 60px;
        padding-left: 88px;
        padding-right: 88px;
        justify-content: space-between;

        h1 {
            font-size: 64px;
        }

        h2 {
            font-size: 48px;
        }

        h3 {
            font-size: 24px;
        }

        p {
            font-size: 20px;
        }
    }

    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
        padding: 30px 50px;
        margin-bottom: 0;

        h1 {
            font-size: 56px;
        }

        h2 {
            font-size: 40px;
        }

        h3 {
            font-size: 20px;
        }

        p {
            font-size: 18px;
        }
    }

    @media (max-width: 500px) {
        padding-left: 22px;
        padding-right: 22px;
        padding-top: 15px;
        gap: 8px;

        h1 {
            font-size: 48px;
            color: #000;
        }

        h2 {
            font-size: 36px;
            font-weight: 300;
            color: #000;
        }

        h3 {
            font-size: 16px;
            color: #000;
        }

        p {
            font-size: 16px;
            color: #000;
        }
    }

    @media (max-width: 375px) {
        padding: 10px;

        h1 {
            font-size: 30px;
        }

        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 14px;
        }

        p {
            font-size: 12px;
        }
    }
`;

const Description = styled.div`
    width: 904px;
    height: 100%;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    color: #fff;

    padding-top: 20px;
    padding-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1320px) {
        width: 660px;
        height: 365px;

        svg {
            width: 338px;
            height: 60px;
        }
    }

    @media (max-width: 900px) {
        height: max-content;

        gap: 30px;
        width: 100%;

        svg {
            width: 225px;
            height: 40px;
        }
    }

    @media (max-width: 500px) {
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        width: 100%;

        svg {
            color: #000;
        }
    }

    @media (max-width: 375px) {
        svg {
            width: max-content;
            height: 30px;
        }
    }
`;

const Logo = styled.div`
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;

    svg {
        width: 100%;
        height: 100%;
        color: #fff;
    }

    @media (max-width: 900px) {
        position: inherit;
        bottom: none;
        left: none;
        transform: none;
    }

    @media (max-width: 500px) {
        display: none;
    }
`;

const Modal = styled.div`
    width: 368px;
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    gap: 20px;

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
    }

    p,
    label {
        font-size: 12px;
        display: block;
        line-height: 18px;
    }

    @media (max-width: 1320px) {
        height: 565px;
        padding: 20px 30px;
    }

    @media (max-width: 900px) {
        height: max-content;
    }

    @media (max-width: 500px) {
        box-shadow: none;
        width: 100%;
        border-radius: 0;
        height: 370px;
    }

    @media (max-width: 375px) {
        padding: 0;
        gap: 10px;
    }
`;

const StyledInput = styled.input`
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 14px;

    padding-left: 18px;
    padding-right: 18px;
    margin-top: 6px;

    &::placeholder {
        color: #ccc;
    }
`;

const AgreeWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 10px;

    label:last-child {
        cursor: pointer;
        width: calc(100% - 20px);
    }
`;

const StyledCheckBox = styled.input`
    display: none;

    &:checked + .check::after {
        height: 10px;
        animation: ${bottomCheck} 0.2s ease 0s forwards;
    }

    &:checked + .check::before {
        height: 16px;
        animation: ${topCheck} 0.4s ease 0s forwards;
    }

    &:checked + .check {
        border-color: #1e69cb;
    }
`;

const StyledLabel = styled.label`
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    transition: border-color ease 0.2s;
    cursor: pointer;
    border-radius: 4px;

    &::before,
    &::after {
        position: absolute;
        height: 0;
        width: 4px;
        background-color: #1e69cb;
        box-sizing: border-box;
        display: inline-block;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        transition: opacity ease 0.5;
    }

    &::before {
        top: 9px;
        left: 6px;
        box-shadow: 0 0 0 1px transparent;
        transform: rotate(-135deg);
    }

    &::after {
        top: 3px;
        left: -2px;
        transform: rotate(-45deg);
    }
`;

const BodyImageWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 100%;
    }

    @media (max-width: 1320px) {
        height: 170px;
    }

    /* @media (max-width: 900px) {
        display: none;
    } */

    @media (max-width: 500px) {
        display: none;
    }
`;

const SelectImageGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
`;

const SelectImage = styled.div<SelectProps>`
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${props => (props.active ? '4px solid #fee500' : '1px solid #ccc')};
    img {
        object-fit: cover;
    }
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        &:disabled {
            color: #ccc;
        }
    }
`;

export default AvatarSelectA;
