import React from 'react'
import Typewriter from 'typewriter-effect'

import { LogoPlikaland } from 'components/vectors'
import SvgIconChevronDubleDown from 'components/vectors/IconChevronDubleDown'
import { useCreateLand } from 'hooks/useCreateLand'

export default function MainVideo({ accountInfo, setAlert }) {
  return (
    <section className="relative aspect-video sm:aspect-[16/7.8]">
      <video className="object-cover w-full h-full" preload="metadata" autoPlay loop muted>
        <source
          src={process.env.REACT_APP_CDN_URL + '/static/video/web/main_video.mp4'}
          type="video/mp4"
        />
      </video>

      <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
        <LogoPlikaland className="hidden w-4/12 h-auto sm:block drop-shadow-lg" />
        <h3 className="flex justify-center text-xl font-bold sm:mt-6 sm:text-3xl lg:text-5xl drop-shadow-lg">
          Build Your&nbsp;
          <span className="text-secondary-100">
            <Typewriter
              options={{
                strings: ['Popup Store', 'Gallery'],
                autoStart: true,
                loop: true,
                delay: 150,
                deleteSpeed: 100,
              }}
            />
          </span>
        </h3>
        <button
          className="px-8 py-3 mt-6 font-semibold text-center duration-500 bg-white rounded-full sm:py-4 sm:text-xl md:px-10 md:py-6 md:text-2xl hover:opacity-75 text-slate-900 focus:outline-none drop-shadow-lg focus:ring-4 focus:ring-secondary-50 "
          // eslint-disable-next-line react-hooks/rules-of-hooks
          onClick={() => useCreateLand(accountInfo, setAlert)}
        >
          체험 시작하기
        </button>
        <SvgIconChevronDubleDown className="hidden w-8 h-auto mt-6 lg:block animate-bounce drop-shadow-86" />
      </div>
    </section>
  )
}
