import React from 'react';
import Footer from '../components/tile/Footer';
import Header from '../components/tile/Header';
import styles from '../styles/terms.module.scss';
import Text from 'components/atoms/Text';

function Privacy() {
    return (
        <>
            <Header show={null}></Header>
            <div className={styles.terms_container}>
                <div className={styles.container_inner}>
                    <div className={styles.terms_header}>
                        <p>개인정보 처리방침</p>
                    </div>
                    <div className={styles.terms_box}>
                        <div>
                            <Text size={1.6} weight={600}>
                                1. 개인정보 처리방침에 대해서
                            </Text>
                            <p>
                                주식회사 플리카(이하 ‘회사’라 함)는 회원의
                                개인정보를 소중히 생각하며, 정보통신망 이용촉진
                                및 정보보호에 관한 법률을 준수하기 위해 노력하고
                                있습니다.
                            </p>
                            <p>
                                이러한 개인정보처리방침은 관련 법률 및 정부
                                지침의 변경과 플리카랜드 서비스(이하 ‘서비스’라
                                함)에 따른 내부 관리 방침에 의하여 수시로 변경될
                                수 있습니다.
                            </p>
                            <p>
                                서비스의 개인정보처리방침이 변경될 경우,
                                변경사항은 서비스 초기화면 또는 서비스
                                웹사이트를 통해 게시되며, 서비스 초기화면 또는
                                웹사이트를 통해 언제라도 확인할 수 있습니다.
                            </p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                2. 개인정보의 수집항목 및 수집방법
                            </Text>
                            <p>가. 수집하는 개인정보의 항목</p>
                            <p>
                                회사는 서비스 제공을 위하여 필요한 아래와 같은
                                최소한의 개인정보를 수집합니다.
                            </p>
                            <p>- [필수] 전자우편주소, 성명, 비밀번호</p>
                            <p>- [선택] 닉네임, 프로필이미지</p>
                            <p>
                                서비스 이용과정에서 아래와 같은 정보가 생성되어
                                수집될 수 있습니다.
                            </p>
                            <p>
                                - PC웹, 모바일 웹 이용 과정에서 단말기정보(OS,
                                화면사이즈, 디바이스 아이디, 폰기종, 단말기
                                모델명), IP주소, 쿠키, 방문일시, 부정이용기록,
                                서비스 이용 기록 등의 정보 서비스 간 제휴, 연계
                                등으로 제3자로부터 개인정보를 제공받고 있습니다.
                            </p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                3. 개인정보의 수집 및 이용목적
                            </Text>
                            <p>
                                회사는 수집한 개인정보를 다음의 목적을 위해
                                활용합니다.
                            </p>
                            <p>
                                - 이용자와 약속한 서비스 제공, 서비스 제공에
                                따른 본인인증, 구매 및 요금결제, 요금 명세서
                                전달, 상품 및 서비스의 배송을 위하여 개인정보를
                                이용합니다.
                            </p>
                            <p>
                                - 회원 가입 의사의 확인, 이용자 본인 확인 및
                                식별, 회원탈퇴 의사의 확인, 문의사항 또는
                                불만처리등 회원관리를 위하여 개인정보를
                                이용합니다.
                            </p>
                            <p>
                                - 법령 및 이용약관을 위반하는 회원에 대한 이용
                                제한 조치, 부정 이용 행위를 포함하여 서비스의
                                원활한 운영에 지장을 주는 행위 및 비인가 행위에
                                대한 방지 및 제재, 계정도용 및 부정거래 방지,
                                고지사항 전달, 분쟁 조정을 위한 기록보존 등
                                이용자 보호 및 서비스 운영을 위하여 개인정보를
                                이용합니다.
                            </p>
                            <p>
                                - 인구통계학적 특성에 따른 서비스 제공, 접속
                                빈도 분석, 기능개선, 서비스 이용에 대한 통계,
                                서비스분석 및 통계에 기반하여 이용자의 상품 구매
                                및 서비스 이용 성향, 관심, 이용기록 분석 등을
                                반영한 신규 서비스 제공(개인 맞춤형 상품 추천
                                서비스 등 포함)등에 개인정보를 이용합니다.
                            </p>{' '}
                            <p>
                                - 이벤트 정보, 광고성 정보 제공 등 이벤트 및
                                프로모션의 목적 등에 개인정보를 이용할 수
                                있습니다.
                            </p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                4. 개인정보의 보유 및 이용기간
                            </Text>
                            <p>
                                회사는 법령에 따른 개인정보 보유/이용기간 또는
                                정보주체로부터 개인정보를 수집시에 동의 받은
                                개인정보 보유/이용기간 내에서 개인정보를
                                처리/보유합니다.
                            </p>
                            <p>
                                개인정보의 수집 및 이용목적이 달성되거나
                                이용기간이 경과되면 지체없이 파기합니다.
                            </p>
                            <p>
                                단, 상기 서비스 이용 종료 및 회원탈퇴 처리
                                이후에도 다음의 정보는 각 항에서 명시한 이유로
                                해당 기간 동안 보존합니다.
                            </p>
                            <p>가. 회사 내부 방침에 의한 정보 보존 사유</p>
                            <p>보존 항목 : 부정이용기록</p>
                            <p>
                                보존 이유 : 부정이용 방지 및 고객 상담 대응 등
                            </p>
                            <p>보존 기간 : 수집일로부터 1년</p>
                            <p>나. 관련법령에 의한 정보 보존 사유</p>
                            <p>
                                상법, 전자상거래 등에서의 소비자보호에 관한 법률
                                등 관계법령의 규정에 의하여 보존할 필요가 있는
                                경우 회사는 관계법령에서 정한 일정한 기간 동안
                                회원정보를 보존합니다. 이 경우 회사는 보존하는
                                정보를 그 보존의 목적으로만 이용하며 보존기간은
                                아래와 같습니다.
                            </p>
                            <br />
                            <p>보존 항목 : 계약 또는 청약철회 등에 관한 기록</p>
                            <p>
                                보존 이유 : 전자상거래 등에서의 소비자보호에
                                관한 법률
                            </p>
                            <p>보존 기간 : 5년</p>
                            <br />
                            <p>
                                보존 항목 : 대금결제 및 재화 등의 공급에 관한
                                기록
                            </p>
                            <p>
                                보존 이유 : 전자상거래 등에서의 소비자보호에
                                관한 법률
                            </p>
                            <p>보존 기간 : 5년</p>
                            <br />
                            <p>
                                보존 항목 : 소비자의 불만 또는 분쟁처리에 관한
                                기록
                            </p>
                            <p>
                                보존 이유 : 전자상거래 등에서의 소비자보호에
                                관한 법률
                            </p>
                            <p>보존 기간 : 3년</p>
                            <br />
                            <p>보존 항목 : 표시/광고에 관한 기록</p>
                            <p>
                                보존 이유 : 전자상거래 등에서의 소비자보호에
                                관한 법률
                            </p>
                            <p>보존 기간 : 6개월</p>
                            <br />
                            <p>
                                보존 항목 : 세법이 규정하는 모든 거래에 관한
                                장부 및 증빙서류
                            </p>
                            <p>보존 이유 : 국세기본법 </p>
                            <p>보존 기간 : 5년</p>
                            <br />
                            <p>보존 항목 : 전자금융 거래에 관한 기록</p>
                            <p>보존 이유 : 전자금융거래법</p>
                            <p>보존 기간 : 5년</p>
                            <br />
                            <p>보존 항목 : 서비스 이용에 관한 로그기록</p>
                            <p>보존 이유 : 통신비밀보호법</p>
                            <p>보존 기간 : 3개월</p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                5. 개인정보의 파기 절차 및 방법
                            </Text>
                            <p>
                                회사는 원칙적으로 개인정보 이용기간의 경과,
                                개인정보 수집 및 이용목적의 달성 등 개인정보가
                                불필요하게 된 때에는 해당 정보를 지체없이
                                파기합니다. 단, 개인정보 보관기간에 대해 별도의
                                동의를 얻는 경우 또는 법령에서 일정기간 정보보관
                                의무를 부과하는 경우에는 해당기간동안 개인정보를
                                별도의 데이터베이스(DB)로 옮겨 안전하게 보관한
                                후 파기합니다. 파기절차 및 방법은 다음과
                                같습니다.
                            </p>
                            <p>1) 파기절차</p>
                            <p>
                                파기 사유가 발생한 개인정보(또는 개인정보파일,
                                게시물)을 선정하고, 회사의 개인정보 보호책임자의
                                승인을 받아 개인정보를 파기합니다.
                            </p>
                            <p>2) 파기방법</p>
                            <p>
                                전자적 파일형태로 저장된 개인정보는 기록을
                                재생할 수 없는 기술적 방법을 사용하여
                                파기합니다.
                            </p>
                            <p>3) 휴면계정 분리보관</p>
                            <p>
                                - 1년 이상 서비스 미이용자의 개인정보보호를
                                위하여 다른 고객의 개인정보와 분리, 보관하여
                                서비스 이용을 제한합니다.
                            </p>
                            <p>
                                - 장기 미이용자의 전자우편주소(ID)를 제외한
                                개인정보를 별도로 분리 보관하며, 전자우편주소는
                                휴면계정 전환 후에도 휴면계정 여부를 판별하기
                                위해 일정기간 보관합니다.
                            </p>
                            <p>
                                - 휴면계정 전환 이후 고객은 별도의 절차를 통해
                                휴면을 해제할 수 있습니다.
                            </p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                6. 개인정보의 기술적/관리적 보호대책
                            </Text>
                            <p>
                                고객의 개인정보는 기본적으로 비밀번호에 의하여
                                보호되며 비밀번호를 포함한 고객의 개인정보는
                                각종 보안 장치에 의해 보호되는 별도 시스템을
                                통해 관리됩니다.
                            </p>
                            <p>
                                아울러 회사는 고객의 개인정보에 접근할 수 있는
                                직원을 최소화하고, 전 직원을 대상으로
                                개인정보보호를 위한 교육을 실시하여 개인정보의
                                유출로 인한 피해를 막기 위하여 노력하고
                                있습니다.
                            </p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                7. 개인정보의 제공
                            </Text>
                            <p>
                                회사는 고객의 개인정보를 원칙적으로 외부에
                                제공하지 않습니다. 다만, 아래의 경우에는 예외로
                                합니다.
                            </p>
                            <p>- 고객이 사전에 동의한 경우</p>
                            <p>
                                - 법령의 규정에 의거하거나, 수사 목적으로 법령에
                                정해진 절차와 방법에 따라 수사기관의 요구가 있는
                                경우
                            </p>
                            <p>
                                - 통계작성, 학술연구 또는 시장조사를 위하여
                                개인을 식별할 수 없는 형태로 제공하는 경우
                            </p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                8. 수집한 개인정보의 위탁
                            </Text>
                            <p>
                                회사는 고객의 동의없이 개인정보를 제3자에게
                                제공하지 않습니다. 단, 다음과 같은 경우에는
                                고객의 동의 하에 개인정보를 제3자에게 제공할 수
                                있습니다.
                            </p>
                            <p>
                                - 서비스 내에서 벌어지는 각종 이벤트 행사에
                                참여한 고객의 개인정보가 해당 이벤트의
                                주최자에게 제공될 수 있습니다. 단, 이러한
                                경우에는 고객의 동의 하에서만 이루어집니다.
                            </p>
                            <p>
                                - 회사가 외부업체(이하 위탁 받는 업체)에 특정
                                서비스의 제공을 위탁하는 경우, 서비스 제공에
                                필요한 고객의 개인정보를 위탁 받는 업체에 제공할
                                수 있습니다. 위탁 받는 업체는 제공받은 고객의
                                개인정보의 수집, 취급, 관리에 있어 위탁 받은
                                목적 외의 용도로 이를 이용하거나 제3자에게
                                제공하지 않습니다.
                            </p>
                            <p>
                                - 회사는 고객에게 제공되는 서비스의 질을
                                향상시키기 위해 기타 서비스, 통계작성 또는
                                시장조사 등 다양한 서비스를 제공할 목적으로 여러
                                분야의 전문 컨텐츠 사업자 혹은 비즈니스 사업자와
                                함께 파트너쉽을 맺을 수 있습니다. 회사는
                                파트너쉽을 맺은 제휴사와 고객의 개인정보를 제공,
                                공유할 경우 반드시 고객의 동의를 받아 필요한
                                최소한의 정보를 제휴사에게 제공, 공유하며 이때
                                누구에게 어떤 목적으로 어떠한 정보(제휴사명,
                                제휴목적, 공유하는 개인정보)를 공유하는 지
                                명시합니다.
                            </p>
                            <p>
                                - 회사는 개인정보처리방침과 서비스 이용약관에
                                명시한 부분 외에 제3자에게 개인정보를 제공하는
                                경우에는 제공 전에 고객에게 고지 또는 통지할
                                것이며, 고객은 항상 이러한 정보제공에 대하여
                                허락하지 않을 수 있습니다. 개인정보가 제공되는
                                것을 원하지 않는 경우에는 특정 서비스를 이용하지
                                않거나 특정한 형태의 판촉이나 이벤트에 참여할 수
                                없습니다.
                            </p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                9. 개인정보 보호책임자 및 담당부서 안내
                            </Text>
                            <p>
                                회사는 고객의 개인정보를 보호하고 개인정보와
                                관련된 불만을 처리하기 위하여 아래와 같이
                                개인정보보호책임자와 담당부서를 지정하고
                                있습니다.
                            </p>
                            <p>개인정보보호 책임자</p>
                            <p>- 성명: 고수영</p>
                            <p>- 직책: CEO</p>
                            <p>- 전자우편: info@plika.io</p>
                        </div>
                        <br />

                        <div>
                            <Text size={1.6} weight={600}>
                                10. 고지의 의무
                            </Text>
                            <p>
                                회사는 개인정보처리방침을 통하여 고객이 제공하는
                                개인정보가 어떠한 용도와 방식으로 이용되고
                                있으며, 개인정보보호를 위해 어떠한 조치가
                                취해지고 있는지 알려드립니다. 회사는
                                개인정보처리방침을 개정하는 경우, 개정 최소 7일
                                전에 서비스 초기화면(혹은 웹사이트 공지사항)을
                                통하여 공지하거나 전자우편 발송 등 전자적 수단을
                                통하여 별도로 통지를 합니다.
                            </p>
                            <p>
                                다만, 회원에게 불리하거나 고객의 권리에 중대한
                                영향을 미치는 사항의 경우에는 최소 30일 전에
                                공지하며, 필요시 고객의 동의를 다시 받을 수도
                                있습니다.
                            </p>
                            <br />
                            <p>공고일자 : 2022년 07월 29일</p>
                            <p>시행일자 : 2022년 08월 01일</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Privacy;
