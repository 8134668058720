export const mediaQuery = (mixWidth: number) => `
    @media (max-width: ${mixWidth}px) 
`;

const media = {
    xxlarge: mediaQuery(1920),
    xlarge: mediaQuery(1440),
    large: mediaQuery(1200),
    medium: mediaQuery(1024),
    small: mediaQuery(768),
    xsmall: mediaQuery(375),
    custom: mediaQuery,
};

export default media;
