import * as React from 'react'
import { SVGProps } from 'react'
const SvgImgEmotionFive = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 86 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.36 19.418h1.246v-3.024h2.842v-1.176h-2.842v-3.024H47.36v3.024h-2.828v1.176h2.828v3.024Z"
      fill="#000"
    />
    <rect x={1} y={1} width={38} height={28} rx={14} fill="#fff" stroke="#000" strokeWidth={2} />
    <rect x={57} y={1} width={28} height={28} rx={14} fill="#fff" stroke="#000" strokeWidth={2} />
    <path
      d="M10 21h1.652l1.666-5.586c.35-1.12.658-2.268.966-3.43h.056c.322 1.148.63 2.31.98 3.43L16.972 21h1.722l-3.402-10.304h-1.89L10 21Zm1.918-2.926h4.816V16.8h-4.816v1.274Zm9.605 3.122c.406 0 .658-.07.868-.14l-.21-1.218c-.14.028-.196.028-.266.028-.196 0-.378-.154-.378-.588V9.856h-1.624v9.338c0 1.246.434 2.002 1.61 2.002Zm5.15 0c.574 0 1.092-.154 1.526-.294l-.294-1.19c-.238.098-.56.196-.826.196-.798 0-1.134-.49-1.134-1.4v-3.92h2.016V13.3h-2.016v-2.128H24.6l-.182 2.128-1.204.084v1.204h1.12v3.906c0 1.61.616 2.702 2.338 2.702ZM70.752 21.196c1.792 0 3.472-1.302 3.472-3.57s-1.414-3.276-3.136-3.276c-.56 0-.98.126-1.414.35l.238-2.632h3.822v-1.372h-5.236l-.308 4.9.798.504c.602-.392.994-.574 1.638-.574 1.176 0 1.96.784 1.96 2.142 0 1.372-.868 2.198-2.03 2.198-1.078 0-1.834-.518-2.408-1.106l-.784 1.05c.742.728 1.764 1.386 3.388 1.386Z"
      fill="#000"
    />
  </svg>
)
export default SvgImgEmotionFive
