import React from "react";
import { ReactNode } from 'react';
import styled from 'styled-components';

interface TextStyleProps {
  size?: number;
  weight?: number;
  color?: string;
}

interface TextProps extends TextStyleProps {
  children: ReactNode;
  toggle?: boolean;
}

export const TextComponent = styled.div<TextStyleProps>(
  ({ size, weight, color }) => ({
    fontSize: size !== undefined ? `${size}rem` : `initial`,
    fontWeight: weight,
    color,
  }),
);

const Text = ({ children, ...style }: TextProps) => {
  return <TextComponent {...style}>{children}</TextComponent>;
};

export default Text;
