import React from 'react'

interface Props {
  type: 'starter' | 'team' | 'enterprise'
}

export default function TierCardPrice({ type }: Props) {
  if (type === 'starter')
    return (
      <div className="flex flex-col items-center justify-center my-8">
        <span className="mr-2 text-5xl font-extrabold uppercase">free</span>
        <span className="text-xl text-white">pricing</span>
      </div>
    )

  if (type === 'team')
    return (
      <div className="my-8 ">
        <div className="flex items-baseline justify-center">
          <span className="mr-2 text-5xl font-extrabold">₩200,000</span>
          <span className="text-gray-500 ">/월</span>
        </div>
        <span className="text-xl text-white">pricing</span>
      </div>
    )

  return (
    <div className="flex flex-col items-center justify-center my-8">
      <span className="mr-2 text-5xl font-extrabold capitalize">custom</span>
      <span className="text-xl text-gray-500">pricing</span>
    </div>
  )
}
