import { useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosInstance } from 'axiosInstance'

import { newKeys, spaceKeys } from 'react-query/constants'
import { Land } from 'types'

async function getNewLands(): Promise<Land[]> {
  const { data } = await axiosInstance.get('api/metaverse/lands/ranks')
  return data.data.landsOrderByRegdt
}

export function useNewLands() {
  const queryClient = useQueryClient()

  const fallback: Land[] = []
  const { data: newLands = fallback } = useQuery({
    queryKey: newKeys.lists(),
    queryFn: getNewLands,
    onSuccess: (data) => {
      data.map((land: Land) => {
        queryClient.setQueryData(spaceKeys.detail(land.spaceId), land)
      })
    },
  })

  return { newLands }
}
