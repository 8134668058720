import {useEffect, useRef, useState} from 'react';

export function usePagination(
    totalList: Array<any>,
    itemPerPage: number,
    setCurPageList,
) {
    const [lastPage, setLastPageState]: any = useState();

    const [pageNum, setPageNum] = useState(1);

    const [pageCount, setPageCount]: any = useState([]);

    const pageStatus = useRef('');

    useEffect(() => {
        if (totalList != null) {
            if (totalList.length > 0) {
                setLastPageState(Math.ceil(totalList.length / itemPerPage));
            } else {
                setLastPageState(1);
            }
        }
    }, [totalList]);

    useEffect(() => {
        if (lastPage != null) {
            if (pageNum > lastPage) {
                setPageNum(lastPage);
            }
            if (pageNum <= 0) {
                setPageNum(1);
            }
            let pageStart = Math.trunc(pageNum / 5);
            if (pageNum % 5 >= 1) {
                pageStart *= 5;
                pageStart++;
            } else {
                pageStart--;
                pageStart *= 5;
                pageStart++;
            }

            const pageEnd =
                pageStart + 4 >= lastPage ? lastPage : pageStart + 4;

            const pageCounts = [pageStart];
            let pagePlus = pageStart;

            for (let i = pageStart; i < pageEnd; i++) {
                pageCounts.push(++pagePlus);
            }

            setPageCount(pageCounts);

            if (lastPage <= 5) {
                pageStatus.current = 'one';
            } else if (pageNum <= 5) {
                pageStatus.current = 'first';
            } else if (pageCounts.slice(-1)[0] >= lastPage) {
                pageStatus.current = 'last';
            } else {
                pageStatus.current = '';
            }

            setCurPageList(
                totalList.slice(
                    (pageNum - 1) * itemPerPage,
                    (pageNum - 1) * itemPerPage + itemPerPage,
                ),
            );
        }
    }, [pageNum, lastPage, totalList]);

    function setPage(e) {
        setPageNum(e);
    }

    function setNextPage(e) {
        setPageNum(pageCount.slice(-1)[0] + 1);
    }

    function setPrevPage(e) {
        setPageNum(pageCount[0] - 1);
    }

    function setFirstPage() {
        setPageNum(1);
    }

    function setLastPage() {
        setPageNum(lastPage);
    }

    return [
        pageNum,
        pageStatus,
        lastPage,
        pageCount,
        setPage,
        setNextPage,
        setPrevPage,
        setFirstPage,
        setLastPage,
    ];
}
