import React, { createContext, useState, useContext } from 'react'

type Props = {
  children: React.ReactNode
}

export type AccountType = {
  id: number
  email: string
  nickname: string
  profileImage: string
}

export type AccountContextType = {
  account: AccountType
  updateAccount: (id: number, email: string, nickname: string, profileImage: string) => void
}

const AccountContext = createContext<AccountContextType | null>(null)

export function AccountContextProvider({ children }: Props) {
  
  const [account, setAccount] = useState({
    id: 0,
    email: '',
    nickname: '',
    profileImage: '',
  })

  const updateAccount = (id: number, email: string, nickname: string, profileImage: string) => {
    setAccount((prev) => ({
      ...prev,
      id,
      email,
      nickname,
      profileImage,
    }))
  }

  return (
    <AccountContext.Provider value={{ account, updateAccount }}>{children}</AccountContext.Provider>
  )
}

export const useAccount = () => useContext(AccountContext) as AccountContextType
