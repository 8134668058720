import React, { useRef, useState, useEffect } from 'react'
import Unity from 'react-unity-webgl'
import axios from 'axios'
import SockJS from 'sockjs-client'
import * as StompJs from '@stomp/stompjs'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import styles from '../styles/space.module.scss'
import '../styles/vonage.scss'

import axiosI from '../utils/AxiosI'
import { createUnityContext } from '../utils/UnityUtil'
import { createRandomNickname } from '../utils/RandomUtil'
import { VngCall } from '../utils/VonageCallUtils2'
import media from '../lib/styles/media'
import { connectedGuest, connectedMember, connectedUsers, VonageParticipant } from 'types'

import startSound from '../assets/audio/ringSound.mp3'
import { PlayersIcon, ToggleIcon } from '../static/svg'
import FishItemList from '../components/webview/FishItemList'
import LogoMenu from '../components/ui/space/LogoMenu'
import SideMenu from '../components/ui/space/SideMenu'
import RemoveMemberAlert from '../components/modals/RemoveMemberAlert'
import UserMenu from '../components/ui/space/UserMenu'
import SpaceExitAlert from '../components/modals/SpaceExitAlert'
import ChangeMemberAlert from '../components/modals/ChangeMemberAlert'
import Editprofile from '../components/modals/EditProfile'
import ContentWebview from '../components/modals/webview/ContentWebview'
import RankingListWebview from '../components/modals/webview/RankingListWebview'
import FishResultWebview from '../components/modals/webview/FishResultWebview'
import Loading from 'components/organisms/Loading'
import Control from 'components/organisms/Control'
import GuidePopup from 'components/molecules/GuidePopup'
import LockScreenWebview from 'components/organisms/LockScreenWebview'
import Nonadmission from 'components/modals/webview/Nonadmission'
import PasswordSetting from 'components/modals/webview/PasswordSetting'
import Notice from 'components/atoms/Notice'
import LogoutModal from 'components/molecules/LogoutModal'
import LockScreenEditWebview from 'components/organisms/LockScreenWebview/edit'
import LingBellAlert from 'components/modals/LingBellAlert'
import AvatarSelect from 'components/hanam/AvatarSelect'
import ModalLayout from 'components/hanam/ModalLayout'
import WelcomeContent from 'components/hanam/WelcomeContent'
import ProcessContent from 'components/hanam/ProcessContent'
import ShowRoomContent from 'components/hanam/ShowRoomContent'
import PositionCircle from 'components/hanam/PositionCircle'
import { log } from 'console'
import CustomLoading from 'components/hanam/CustomLoading'
import MintLoading from 'components/hanam/MintLoading'
import LoadingThree from 'components/organisms/Loading/LoadingThree'
import AvatarSelectA from 'components/avatarSelect/AvatarSelectA'
import ControlA from 'components/organisms/Control/controlA'
import DtocControl from 'components/modals/DtocControl'
import LoadingFour from 'components/organisms/Loading/LoadingFour'
import Elevator from 'components/settings/Elevator'
import External from 'components/settings/External'
import ControlWebview from 'components/settings/GangnamControl'
import GangnamControl from 'components/settings/GangnamControl'

import { Toaster } from 'react-hot-toast'
import CustomToast from 'components/spaces/CustomToast.jsx'
import ExhibitionControl from 'components/settings/ExhibitionControl'
import ScanUploader from 'components/object/ScanUploader'
import { ModalModeProvider } from 'contexts/ModalModeContext'

const msgList = {
  noSession: '현재 대화할 상대방이 없어요',
}

window.addEventListener('orientationchange', function (event) {
  event.preventDefault()
})

const vngCall = new VngCall()
/**
 * RING 기능에 사용할 임시 사운드 파일
 */
const RING_SOUND = new Audio(startSound)
RING_SOUND.volume = 0.3

const Space = (props) => {
  const { spaceName, serviceName } = useParams()
  const { t } = useTranslation()

  const [idName, setIdName] = useState<string | null>(null)

  /**
   * 접속자 정보 상태 관리
   */
  const [accountInfo, setAccountInfo]: any = useState({
    accountId: null,
    nickname: null,
    thumbnailUri: null,
    email: null,
    isMember: null,
    isLogin: null,
    isAdmin: null,
    sessionId: null,
    isOff: null,
    vonageAreaIndex: null,
    vonagePaticipating: null,
  })

  useEffect(() => {
    if (accountInfo.sessionId != null) {
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          setAccountInfo((prev) => ({
            ...prev,
            isOff: true,
          }))
        } else if (document.visibilityState == 'visible') {
          setAccountInfo((prev) => ({
            ...prev,
            isOff: false,
          }))
        }
      })

      if (!accountInfo.isOff) {
        RING_SOUND.pause()
        RING_SOUND.currentTime = 0
      }
    }
  }, [accountInfo])

  const accountRef: any = useRef()
  const [accountloaded, setAccountLoaded] = useState<boolean>(false)

  /**
   * 현재 스페이스의 보니지 room을 변경할 때 호출되는 메소드
   * @param roomIndex (int) 변경할 roomIndex를 인자로 받습니다.
   */
  const roomIndexRef: any = useRef()

  /**
   * 현 스페이스의 보니지 세션 리스트
   */
  const [vonageSessionList, setVonageSessionList]: any = useState()

  useEffect(() => {
    if (spaceName) {
      setIdName(spaceName)
    }
    if (serviceName) {
      axios.get(process.env.REACT_APP_API_HOST + `space/${serviceName}`).then((e) => {
        setIdName(e.data.data)
      })
    }

    window.addEventListener('beforeunload', (e) => beforeUnloadHandler(e))

    return () => {
      window.removeEventListener('beforeunload', (e) => beforeUnloadHandler(e))
    }
  }, [])

  useEffect(() => {
    if (idName != null) {
      window.localStorage.removeItem('login_redirect_url')
      /**
       * 현 스페이스의 버전과 이름 조회
       */
      axios
        .get(process.env.REACT_APP_API_HOST + `land/${idName}`)
        .then((e) => {
          setWebviewData(e.data.data)
          webviewDataRef.current = e.data.data
          insidePasswordRef.current = e.data.data.sceneInfoDtos[0].insidePasswords
        })
        .catch((err) => {
          // console.log(err);
        })

      /**
       * chatRoomId를 조회합니다.
       */
      axios.get(process.env.REACT_APP_API_HOST + `chat/${idName}/room`).then((e) => {
        console.log(e)
        setChatRoomId(e.data.data.roomCode)
        chatRoomIdRef.current = e.data.data.roomCode
      })
    }
  }, [idName])

  /**
   * 스페이스 접속 관리
   *
   */
  const [access, setAccess]: any = useState(false)

  function accessPasswordCheck(password) {
    if (password == webviewData.password) {
      setAccess(true)
      return true
    } else {
      setAccess(false)
      return false
    }
  }

  const [admission, setAdmission]: any = useState(null)

  /**
   * stomp, chat 관리
   */
  const [chatRoomId, setChatRoomId]: any = useState(null)
  const chatRoomIdRef: any = useRef(null)
  const [stompClient, setStompClient]: any = useState(null)
  const stompClientRef = useRef()
  const sockSessionIdList = useRef<Array<String>>([])

  useEffect(() => {
    if (accountInfo.isOff != null && stompClientRef.current != null) {
      if (accountInfo.isOff) {
        const requestUri = process.env.REACT_APP_API_HOST + 'chat/member/isoff'
        const data = {
          sessionId: accountInfo.sessionId,
          isOff: true,
        }
        axios.post(requestUri, data).then((e) => {
          // console.log(e);
        })
        sendOffStatusMsg()
      } else if (!accountInfo.isOff) {
        const requestUri = process.env.REACT_APP_API_HOST + 'chat/member/isoff'
        const data = {
          sessionId: accountInfo.sessionId,
          isOff: false,
        }
        axios.post(requestUri, data).then((e) => {
          // console.log(e);
        })
        sendOnStatusMsg()
      }
    }
  }, [accountInfo.isOff, stompClientRef.current])
  /**
   * chatRoomId 와 accountInfo 가 존재하면 stomp 연결
   */
  useEffect(() => {
    if (chatRoomId != null && accountloaded === true && admission === true && access === true) {
      /**
       * 현재 account로 현재 스페이스의 chatRoom에 이미 접속해 있는지 검사하는 axios 요청
       */
      const accessToken = window.localStorage.getItem('plikaTk')
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      if (accountInfo.isLogin) {
        if (accessToken != null && accessToken != 'null' && accessToken.length > 0) {
          axiosI
            .get(process.env.REACT_APP_API_HOST + `v1/chat/${idName}/room/account/exist`, config)
            .then((response) => {
              if (response.data.data === true) {
                // window.location.replace('/');
                return
              }
            })
            .catch((err) => {
              // console.log(err);
            })
        }
      }

      const stomp = new StompJs.Client({
        brokerURL: process.env.REACT_APP_WEBSOCKET_HOST + 'socket/chat',
        webSocketFactory: () => new SockJS(process.env.REACT_APP_API_HOST + 'socket/chat'),
        connectHeaders: {
          login: '',
          passcode: '',
          roomId: chatRoomId,
          accountInfo: JSON.stringify(accountInfo),
          // nickname : accountInfo.nickname,
          // email : accountInfo.email,
          // thumbnailUri : accountInfo.imgUrl
        },
        debug: (str) => {
          // console.log(str);
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 1000,
        heartbeatOutgoing: 1000,
      })

      stomp.activate()

      setStompClient(stomp)

      console.log('채팅 접속')
      console.log('접속 세션 닉네임 : ', accountInfo.nickname)
    }
  }, [chatRoomId, accountloaded, admission, access])

  /**
   * stomp 연결 후 채팅룸 접속자 리스트 조회
   */
  const [chatList, setChatList]: any = useState([])
  useEffect(() => {
    if (stompClient != null) {
      stompClient.onConnect = (frame) => {
        const sessionId = stompClient.webSocket._transport.url.split('/')[6]
        sockSessionIdList.current.push(sessionId)
        setAccountInfo((prev) => ({
          ...prev,
          sessionId: sessionId,
        }))

        console.log('접속 세션 아이디 : ', sessionId)
        accountRef.current.sessionId = sessionId

        stompClientRef.current = stompClient
        /**
         * 현재 스페이스에 접속한 유저들의 리스트를 조회합니다.
         */
        axios.get(process.env.REACT_APP_API_HOST + `chat/${idName}/room/members`).then((e) => {
          setConnectedUsers(e.data.data)
          e.data.data.connectedMembers.forEach((e) => {
            if (e.sessionId != null) {
              setVonageParticipantList((prev) => [
                ...prev,
                {
                  sessionId: e.sessionId,
                  vonageAreaIndex: e.vonageAreaIndex,
                  vonageParticipating: null,
                },
              ])
            }
          })
          e.data.data.connectedGuests.forEach((e) => {
            if (e.sessionId != null) {
              setVonageParticipantList((prev) => [
                ...prev,
                {
                  sessionId: e.sessionId,
                  vonageAreaIndex: e.vonageAreaIndex,
                  vonageParticipating: null,
                },
              ])
            }
          })
        })

        const callback = function (message) {
          const messageBody = JSON.parse(message.body)

          if (messageBody.messageType === 'OBJECT' && messageBody.code === 801) {
            console.log('801', messageBody)
            setChatList((prev) => [...prev, messageBody])
          }

          if (messageBody.messageType === 'MESSAGE') {
            console.log('MESSAGE', messageBody)
            setChatList((prev) => [...prev, JSON.parse(message.body)])
          }

          /**
           * 새로운 참여자 접속
           */
          if (messageBody.messageType === 'INFO' && messageBody.code === 100) {
            if (messageBody.isMember) {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers?.map((el) => {
                  return el.detail.email == messageBody.email
                    ? {
                        ...el,
                        sessionId: messageBody.sessionId,
                        detail: {
                          admin: messageBody.isAdmin,
                          email: messageBody.email,
                          login: messageBody.isLogin,
                          member: messageBody.isMember,
                          nickname: messageBody.nickname,
                          thumbnailUri: messageBody.thumbnailUri,
                          connected: true,
                        },
                      }
                    : el
                }) as connectedMember[],
                connectedGuests: prev?.connectedGuests as connectedGuest[],
              }))
            } else {
              const newMember: connectedGuest = {
                sessionId: messageBody.sessionId,
                detail: {
                  admin: false,
                  email: messageBody.email,
                  login: messageBody.isLogin,
                  member: false,
                  nickname: messageBody.nickname,
                  thumbnailUri: messageBody.thumbnailUri,
                  connected: true,
                  isOff: messageBody.isOff,
                  vonageAreaIndex: null,
                },
              }
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers as connectedMember[],
                connectedGuests: [...(prev?.connectedGuests as connectedGuest[]), newMember],
              }))
            }
          } else if (
            /**
             * 참여자 퇴장
             */
            messageBody.messageType === 'INFO' &&
            messageBody.code === 101
          ) {
            if (messageBody.isMember) {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers?.map((el) => {
                  return el.detail.email == messageBody.email
                    ? {
                        ...el,
                        detail: {
                          ...el.detail,
                          connected: false,
                        },
                      }
                    : el
                }) as connectedMember[],
                connectedGuests: prev?.connectedGuests as connectedGuest[],
              }))
            } else {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers as connectedMember[],
                connectedGuests: prev?.connectedGuests?.filter((el) => {
                  return el.sessionId != messageBody.sessionId
                }),
              }))
            }

            setVonageParticipantList((prev) =>
              prev.filter((e) => {
                return e.sessionId != messageBody.sessionId
              }),
            )
          } else if (
            /**
             * 참여자 프로필 변경
             */
            messageBody.messageType === 'INFO' &&
            messageBody.code === 102
          ) {
            if (messageBody.isMember) {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers?.map((el) => {
                  return el.sessionId == messageBody.sessionId
                    ? {
                        ...el,
                        sessionId: messageBody.sessionId,
                        detail: {
                          nickname: messageBody.nickname,
                          thumbnailUri: messageBody.thumbnailUri,
                          admin: messageBody.isAdmin,
                          email: messageBody.email,
                          login: messageBody.isLogin,
                          member: messageBody.isMember,
                          connected: true,
                        },
                      }
                    : el
                }) as connectedMember[],
                connectedGuests: prev?.connectedGuests as connectedGuest[],
              }))
            } else {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers as connectedMember[],
                connectedGuests: prev?.connectedGuests?.map((el) => {
                  return el.sessionId == messageBody.sessionId
                    ? {
                        ...el,
                        sessionId: messageBody.sessionId,
                        detail: {
                          nickname: messageBody.nickname,
                          thumbnailUri: messageBody.thumbnailUri,
                          admin: messageBody.isAdmin,
                          email: messageBody.email,
                          login: messageBody.isLogin,
                          member: messageBody.isMember,
                          connected: true,
                        },
                      }
                    : el
                }) as connectedGuest[],
              }))
            }

            setParticipants((prev) =>
              prev.map((e) => {
                return e.sessionId == messageBody.sessionId
                  ? {
                      ...e,
                      nickname: messageBody.nickname,
                      thumbnailUri: messageBody.thumbnailUri,
                    }
                  : e
              }),
            )
          } else if (
            /**
             * 멤버 참여자 게스트로 권한 변경
             */
            messageBody.messageType === 'INFO' &&
            messageBody.code === 103
          ) {
            if (messageBody.isConnected) {
              const newMember: connectedGuest = {
                sessionId: messageBody.sessionId,
                detail: {
                  admin: false,
                  email: messageBody.email,
                  login: messageBody.isLogin,
                  member: false,
                  nickname: messageBody.nickname,
                  thumbnailUri: messageBody.thumbnailUri,
                  connected: messageBody.isConnected,
                  isOff: messageBody.isOff,
                  vonageAreaIndex: messageBody.vonageAreaIndex,
                },
              }
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers?.filter((el) => {
                  return el.detail.email != messageBody.email
                }) as connectedMember[],
                connectedGuests: [...(prev?.connectedGuests as connectedGuest[]), newMember],
              }))
              if (messageBody.sessionId == accountInfo.sessionId) {
                setAccountInfo((prev) => ({
                  ...prev,
                  isMember: false,
                }))
                statusValueHandler('editModeStatus', false)
              }
            } else {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers?.filter((el) => {
                  return el.detail.email != messageBody.email
                }) as connectedMember[],
                connectedGuests: prev?.connectedGuests as connectedGuest[],
              }))
            }
          } else if (
            /**
             * 게스트 참여자 멤버로 권한 변경
             */
            messageBody.messageType === 'INFO' &&
            messageBody.code === 104
          ) {
            const newMember: connectedMember = {
              sessionId: messageBody.sessionId,
              detail: {
                admin: false,
                email: messageBody.email,
                login: messageBody.isLogin,
                member: true,
                nickname: messageBody.nickname,
                thumbnailUri: messageBody.thumbnailUri,
                connected: messageBody.isConnected,
                isOff: messageBody.isOff,
                vonageAreaIndex: messageBody.vonageAreaIndex,
              },
            }
            setConnectedUsers((prev) => {
              return {
                ...prev,
                connectedMembers: [...(prev?.connectedMembers as connectedMember[]), newMember],
                connectedGuests: prev?.connectedGuests?.filter((el) => {
                  return el.sessionId != messageBody.sessionId
                }) as connectedGuest[],
              }
            })

            if (messageBody.sessionId == accountInfo.sessionId) {
              setAccountInfo((prev) => ({
                ...prev,
                isMember: true,
              }))
            }
          } else if (
            /**
             * 호출 받았을 때 RING 기능 실행
             */
            messageBody.messageType === 'INFO' &&
            messageBody.code === 201
          ) {
            if (messageBody.targetSessionId == accountInfo.sessionId) {
              const callMemberInfo = {
                email: messageBody.email,
                nickname: messageBody.nickname,
                sessionId: messageBody.sessionId,
                thumbnailUri: messageBody.thumbnailUri,
              }
              setCallMeMember(callMemberInfo)

              RING_SOUND.play()

              setTimeout(() => {
                closeRingAlert()
              }, 8000)
            }
          } else if (
            /**
             * 자리비움 시 RING 버튼 생성
             */
            messageBody.messageType === 'INFO' &&
            messageBody.code === 301
          ) {
            if (messageBody.isMember) {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers?.map((el) => {
                  return el.sessionId == messageBody.sessionId
                    ? {
                        ...el,
                        sessionId: messageBody.sessionId,
                        detail: {
                          nickname: messageBody.nickname,
                          thumbnailUri: messageBody.thumbnailUri,
                          admin: messageBody.isAdmin,
                          email: messageBody.email,
                          login: messageBody.isLogin,
                          member: messageBody.isMember,
                          connected: true,
                          isOff: true,
                        },
                      }
                    : el
                }) as connectedMember[],
                connectedGuests: prev?.connectedGuests as connectedGuest[],
              }))
            } else {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers as connectedMember[],
                connectedGuests: prev?.connectedGuests?.map((el) => {
                  return el.sessionId == messageBody.sessionId
                    ? {
                        ...el,
                        sessionId: messageBody.sessionId,
                        detail: {
                          nickname: messageBody.nickname,
                          thumbnailUri: messageBody.thumbnailUri,
                          admin: messageBody.isAdmin,
                          email: messageBody.email,
                          login: messageBody.isLogin,
                          member: messageBody.isMember,
                          connected: true,
                          isOff: true,
                        },
                      }
                    : el
                }) as connectedGuest[],
              }))
            }
          } else if (
            /**
             * 자리비움에서 돌아왔을 시 RING 버튼 제거
             */
            messageBody.messageType === 'INFO' &&
            messageBody.code === 302
          ) {
            if (messageBody.isMember) {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers?.map((el) => {
                  return el.sessionId == messageBody.sessionId
                    ? {
                        ...el,
                        sessionId: messageBody.sessionId,
                        detail: {
                          nickname: messageBody.nickname,
                          thumbnailUri: messageBody.thumbnailUri,
                          admin: messageBody.isAdmin,
                          email: messageBody.email,
                          login: messageBody.isLogin,
                          member: messageBody.isMember,
                          connected: true,
                          isOff: false,
                        },
                      }
                    : el
                }) as connectedMember[],
                connectedGuests: prev?.connectedGuests as connectedGuest[],
              }))
            } else {
              setConnectedUsers((prev) => ({
                ...prev,
                connectedMembers: prev?.connectedMembers as connectedMember[],
                connectedGuests: prev?.connectedGuests?.map((el) => {
                  return el.sessionId == messageBody.sessionId
                    ? {
                        ...el,
                        sessionId: messageBody.sessionId,
                        detail: {
                          nickname: messageBody.nickname,
                          thumbnailUri: messageBody.thumbnailUri,
                          admin: messageBody.isAdmin,
                          email: messageBody.email,
                          login: messageBody.isLogin,
                          member: messageBody.isMember,
                          connected: true,
                          isOff: false,
                        },
                      }
                    : el
                }) as connectedGuest[],
              }))
            }

            const returnMember = callingMember.filter((e) => {
              return e == messageBody.sessionId
            })

            if (returnMember != null) {
              RING_SOUND.pause()
              RING_SOUND.currentTime = 0
            }
          } else if (
            /**
             * 내부 비밀번호 변경 시
             */
            messageBody.messageType === 'OBJECT' &&
            messageBody.code === 501
          ) {
            insidePasswordRef.current = messageBody.obj
          } else if (
            /**
             * hanam 전용 3d스캔 모델 파일 업로드 시
             */
            messageBody.messageType === 'OBJECT' &&
            messageBody.code === 601
          ) {
            const obj = {
              Url: messageBody.obj.file_url,
              BoothId: messageBody.obj.content_data.contentIndex,
              ModeNum: 4,
              Name: messageBody.obj.content_data.name,
              Desc: messageBody.obj.content_data.desc,
            }
            sendMsgWithParam('Webreceiver', 'UpdateScanData', JSON.stringify(obj))
          } else if (
            /**
             * 다른 유저의 보니지 구역 번호 설정(입장)
             */
            messageBody.messageType === 'INFO' &&
            messageBody.code === 701
          ) {
            setVonageParticipantList((prev) =>
              prev.filter((e) => {
                return e.sessionId == messageBody.sessionId
              }).length <= 0
                ? [
                    ...prev,
                    {
                      sessionId: messageBody.sessionId,
                      vonageAreaIndex: messageBody.vonageAreaIndex,
                      vonageParticipating: null,
                    },
                  ]
                : prev.map((e) => {
                    return e.sessionId == messageBody.sessionId
                      ? {
                          ...e,
                          vonageAreaIndex: messageBody.vonageAreaIndex,
                        }
                      : e
                  }),
            )
          }
        }

        const subscription = stompClient.subscribe('/sub/room/' + chatRoomId, callback)

        stompClient.subscribe('/sub/room/whisper/' + accountInfo.sessionId, callback)

        stompClient.onStompError = function (frame) {
          // console.log(
          //     'Broker reported error: ' + frame.headers['message'],
          // );
          // console.log('Additional details: ' + frame.body);
          console.log('onStompError', frame)
        }

        stompClient.onDisconnect = function (frame) {
          console.log('onDisconnect', frame)
        }
      }
    }
  }, [stompClient])

  const [callMeMember, setCallMeMember] = useState<any>(null)
  const [callingMember, setCallingMember] = useState<any>([])

  function closeRingAlert() {
    setCallMeMember(null)
  }

  enum messageType {
    INFO = 'INFO',
    MESSAGE = 'MESSAGE',
    OBJECT = 'OBJECT',
  }

  function sendTextMsg(textContent) {
    stompClient.publish({
      destination: `/pub/send/${chatRoomId}`,
      body: JSON.stringify({
        messageType: messageType.MESSAGE,
        // 'roomId' : chatRoomId,
        nickname: accountInfo.nickname,
        thumbnailUri: accountInfo.thumbnailUri,
        textContent: textContent,
        isOff: accountInfo.isOff,
      }),
      skipContentLengthHeader: true,
    })

    unityInstance.current.SendMessage('Webreceiver', 'OnMessagePop', textContent)
  }

  function sendInfoMsg(code, textContent) {
    stompClient.publish({
      destination: `/pub/send/info/${chatRoomId}`,
      body: JSON.stringify({
        messageType: messageType.INFO,
        code: code,
        sessionId: accountInfo.sessionId,
        // 'roomId' : chatRoomId,
        nickname: accountInfo.nickname,
        thumbnailUri: accountInfo.thumbnailUri,
        isMember: accountInfo.isMember,
        isLogin: accountInfo.isLogin,
        isAdmin: accountInfo.isAdmin,
        textContent: textContent,
        isOff: accountInfo.isOff,
        vonageAreaIndex: accountInfo.vonageAreaIndex,
      }),
      skipContentLengthHeader: true,
    })
  }

  function sendInfoUpdateProfileoMsg(updateProfile) {
    stompClient.publish({
      destination: `/pub/send/info/${chatRoomId}`,
      body: JSON.stringify({
        messageType: messageType.INFO,
        code: 102,
        sessionId: accountInfo.sessionId,
        email: accountInfo.email,
        // 'roomId' : chatRoomId,
        nickname: updateProfile.nickname,
        thumbnailUri: updateProfile.thumbnailUri,
        isMember: accountInfo.isMember,
        isLogin: accountInfo.isLogin,
        isAdmin: accountInfo.isAdmin,
        textContent: `${updateProfile.email} 님의 프로필이 변경되었습니다.`,
        isOff: accountInfo.isOff,
        vonageAreaIndex: accountInfo.vonageAreaIndex,
      }),
      skipContentLengthHeader: true,
    })
  }

  /**
   * RING기능을 위해 대상에게 webSocket 메시지를 보내는 메소드
   * @param targetSessionId RING기능을 위한 대상의 stomp sessionId를 인자로 받습니다.
   */
  function sendRingMsg(targetSessionId) {
    stompClient.publish({
      destination: `/pub/send/ring/${chatRoomId}`,
      body: JSON.stringify({
        messageType: messageType.INFO,
        code: 201,
        sessionId: accountInfo.sessionId,
        email: accountInfo.email,
        // 'roomId' : chatRoomId,
        nickname: accountInfo.nickname,
        thumbnailUri: accountInfo.thumbnailUri,
        isMember: accountInfo.isMember,
        isLogin: accountInfo.isLogin,
        isAdmin: accountInfo.isAdmin,
        textContent: `${targetSessionId}에게 ring`,
        targetSessionId: targetSessionId,
        isOff: accountInfo.isOff,
      }),
      skipContentLengthHeader: true,
    })
    setCallingMember([...callingMember, targetSessionId])
    RING_SOUND.play()
  }

  /**
   * 자리비움 상태를 표시하기 위해 webSocket 메시지를 보내는 메소드
   */
  function sendOffStatusMsg() {
    stompClient.publish({
      destination: `/pub/send/info/${chatRoomId}`,
      body: JSON.stringify({
        messageType: messageType.INFO,
        code: 301,
        sessionId: accountInfo.sessionId,
        email: accountInfo.email,
        // 'roomId' : chatRoomId,
        nickname: accountInfo.nickname,
        thumbnailUri: accountInfo.thumbnailUri,
        isMember: accountInfo.isMember,
        isLogin: accountInfo.isLogin,
        isAdmin: accountInfo.isAdmin,
        textContent: `자리비움 ON`,
        isOff: accountInfo.isOff,
      }),
      skipContentLengthHeader: true,
    })
  }

  /**
   * 자리비움 상태를 표시하기 위해 webSocket 메시지를 보내는 메소드
   */
  function sendOnStatusMsg() {
    stompClient.publish({
      destination: `/pub/send/info/${chatRoomId}`,
      body: JSON.stringify({
        messageType: messageType.INFO,
        code: 302,
        sessionId: accountInfo.sessionId,
        email: accountInfo.email,
        // 'roomId' : chatRoomId,
        nickname: accountInfo.nickname,
        thumbnailUri: accountInfo.thumbnailUri,
        isMember: accountInfo.isMember,
        isLogin: accountInfo.isLogin,
        isAdmin: accountInfo.isAdmin,
        textContent: `자리비움 OFF`,
        isOff: accountInfo.isOff,
      }),
      skipContentLengthHeader: true,
    })
  }

  /**
   * 내부 비밀번호 변경 시 다른사람들에게 알리기 위해 webSocket 메시지를 보내는 메소드
   */
  function sendObjectMsg(obj) {
    stompClient.publish({
      destination: `/pub/send/object/${chatRoomId}`,
      body: JSON.stringify({
        messageType: messageType.OBJECT,
        code: 501,
        sessionId: accountInfo.sessionId,
        obj: obj,
      }),
      skipContentLengthHeader: true,
    })
  }

  /**
   * 접속자 리스트 관리
   */
  const [connectedUsers, setConnectedUsers] = useState<connectedUsers>()

  /**
   * beforeUnloadHandler
   */

  function beforeUnloadHandler(e) {
    const data = {
      spaceId: webviewDataRef.current.spaceId,
      chatRoomCode: chatRoomIdRef.current,
      sessionIds: sockSessionIdList.current,
    }
    console.log(data)

    axios.put(process.env.REACT_APP_API_HOST + `chat/members`, data).then((e) => {
      console.log(e)
    })

    if (stompClient != null) {
      stompClient.forceDisconnect()
    }

    window.localStorage.removeItem('opentok_client_id')
    window.localStorage.removeItem('roomDetail')
    window.localStorage.removeItem('joinMember')
    window.localStorage.removeItem('participantsList')
    window.localStorage.removeItem('vonage_session_id')
    window.localStorage.removeItem('vonage_user_token')

    if (vngCallRef.current != null) {
      vngCallRef.current.leaveRoom()
    }
  }

  /**
   * 인터페이스 출력 상태
   */
  const [showInterface, setShowInterface]: any = useState(false)

  /**
   * 현 스페이스 버전 상태 관리
   */
  const [webviewData, setWebviewData]: any = useState(null)
  const webviewDataRef: any = useRef()
  /**
   * 유니티 인스턴스 상태 관리
   * unityContext load완료 후 값 생성
   */
  const unityInstance: any = useRef()
  const [MemberInfoReq, setMemberInfoReq] = useState(false)

  /**
   * unityInstance 로딩 완료되고, unity에서 req가 도착한 경우 실행
   * unity에 접속한 유저의 정보를 전송
   */
  const [webglBuildLoading, setWebglBuildLoading]: any = useState(true)
  useEffect(() => {
    if (MemberInfoReq && accountloaded && !webglBuildLoading) {
      const memberInfoRes = {
        nickname: accountInfo.nickname,
        imgUrl: accountInfo.thumbnailUri,
        email: accountInfo.email,
        isMember: accountInfo.isMember,
        isLogin: accountInfo.isLogin,
        isAdmin: accountInfo.isAdmin,
        sessionId: accountInfo.sessionId,
      }

      unityInstance.current.SendMessage(
        'Webreceiver',
        'GetMemberInfoRes',
        JSON.stringify(memberInfoRes),
      )
    }
  }, [MemberInfoReq, accountloaded, webglBuildLoading])

  /**
   * unityContext 관리
   */
  const [unityContext, setUnityContext]: any = useState(null)

  /**
   * 접속 유저의 포톤 접속 아이디
   */
  const photonIdRef = useRef()

  /**
   * 가이드 창 상태 관리
   */
  const [controlWebview, setControlWebview]: any = useState(false)
  /**
   * 가이드 팝업창의 팝업 유무
   */
  const [guidePopup, setGuidePopup]: any = useState({
    mainGuide: false,
    subGuide: false,
  })
  /**
   * 가이드 팝업창에 표시될 텍스트
   */
  const [guidePopupInnerText, setGuidePopupInnerText]: any = useState({
    mainGuide: '',
    subGuide: '',
  })

  // Mint 팝업 상태 관리
  const [mintPopup, setMintPopup]: any = useState(null)

  /**
   * 스페이스 버전, 기타 정보 받아옴
   */
  useEffect(() => {
    if (webviewData != null) {
      /**
       * 현재 스페이스의 Vonage세션 리스트를 조회합니다.
       */
      const accessToken = window.localStorage.getItem('plikaTk')
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      axios
        .get(process.env.REACT_APP_API_HOST + `api/v1/media/${idName}/0`, config)
        .then((resp) => {
          setVonageSessionList(resp.data.data.sessionDetailList)
          vngCallRef.current = vngCall
        })
        .catch((err) => {
          // console.log(err);
        })

      /**
       * 스페이스 공개 여부 상태
       */
      if (webviewData.isAccess != null && !webviewData.isAccess) {
        setAccess(false)
      } else {
        setAccess(true)
      }

      /**
       * 스페이스 접속 가능 상태 여부
       */
      if (
        webviewData.currentJoinMemberTotal >=
        webviewData.sceneInfoDtos[0].templateInfoDto.connectUserLimit
      ) {
        setAdmission(false)
      } else {
        setAdmission(true)
      }

      /**
       * 회원정보를 조회합니다.
       */
      if (accessToken != 'null' && accessToken != null && accessToken.length > 0) {
        axiosI
          .get(process.env.REACT_APP_API_HOST + `v1/profile/spaces/${idName}`, config)
          .then((e) => {
            if (e.data.data != null) {
              if (
                e.data.data.age == null ||
                e.data.data.job == null ||
                e.data.data.age == null ||
                e.data.data.route == null ||
                e.data.data.object == null
              ) {
                window.location.replace('/login/info')
              }
              if (!e.data.data.ageCheck || !e.data.data.privacyCheck) {
                {
                  window.location.replace('/login/profile')
                }
              }
              const memberInfo = {
                accountId: e.data.data.accountId,
                nickname: e.data.data.profileNickname,
                thumbnailUri: e.data.data.thumbnailUri,
                email: e.data.data.accountEmail,
                isMember: false,
                isLogin: true,
                isAdmin: false,
                sessionId: '',
                isOff: false,
              }

              if (e.data.data.authInfo != null) {
                memberInfo.isMember = true
                statusValueHandler('editModeStatus', true)
                if (
                  e.data.data.authInfo.accountAuthType == 'SPACE_ADMIN' ||
                  e.data.data.authInfo.accountAuthType == 'SCENE_ADMIN'
                ) {
                  memberInfo.isAdmin = true
                  statusValueHandler('editModeStatus', true)
                }
              }
              setAccountInfo(memberInfo)
              setAccountLoaded(true)
              accountRef.current = memberInfo
              // if (accountInfo.isMember || accountInfo.isAdmin) {
              //     statusValueHandler('editModeStatus', true);
              //     console.log('editModeStatus 활성화');
              // }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        const spaceInfoData = window.sessionStorage.getItem('plika_space_info_data')
        if (spaceInfoData != null) {
          const spaceInfoDataList: Array<any> = JSON.parse(spaceInfoData)
          const spaceInfo = spaceInfoDataList.filter((e) => {
            return e.spaceId == webviewData.spaceId
          })

          if (spaceInfo.length > 0) {
            const nickname = spaceInfo[0].nickname
            const memberInfo = {
              nickname: nickname,
              thumbnailUri: process.env.REACT_APP_CDN_URL + '/static/img/ui/user_guest.jpg',
              email: spaceInfo[0].email,
              isMember: false,
              isLogin: false,
              isAdmin: false,
              sessionId: '',
            }
            setAccountInfo(memberInfo)
            setAccountLoaded(true)
            accountRef.current = memberInfo
          } else {
            const randomNickname = createRandomNickname()
            const memberInfo = {
              nickname: randomNickname,
              thumbnailUri: process.env.REACT_APP_CDN_URL + '/static/img/ui/user_guest.jpg',
              email: null,
              isMember: false,
              isLogin: false,
              isAdmin: false,
              sessionId: '',
            }
            setAccountInfo(memberInfo)
            setAccountLoaded(true)
            accountRef.current = memberInfo
          }
        } else {
          const randomNickname = createRandomNickname()
          const memberInfo = {
            nickname: randomNickname,
            thumbnailUri: process.env.REACT_APP_CDN_URL + '/static/img/ui/user_guest.jpg',
            email: null,
            isMember: false,
            isLogin: false,
            isAdmin: false,
            sessionId: '',
          }
          setAccountInfo(memberInfo)
          setAccountLoaded(true)
          accountRef.current = memberInfo
          //
        }
      }
    }
  }, [webviewData])

  /**
   * 유니티 빌드파일 로딩
   */
  useEffect(() => {
    if (
      webviewData != null &&
      vonageSessionList != null &&
      chatRoomId != null &&
      access &&
      admission
    ) {
      const { usedSeparateBuild, spaceIdName, usedCustomBuild, spaceVersion, version } = webviewData
      const cdnUrl = process.env.REACT_APP_CDN_URL
      let baseUrl = ''

      if (usedSeparateBuild) {
        baseUrl = `${cdnUrl}/spaceBuild/${spaceIdName}/Build/`
      } else {
        baseUrl = `${cdnUrl}/${usedCustomBuild ? 'custom' : 'common'}/${
          spaceVersion === 'V1' ? 'v1' : 'v2'
        }/space/Build/`
      }
      setUnityContext(createUnityContext(baseUrl, version))
    }
  }, [webviewData, vonageSessionList, chatRoomId, access, admission])

  const [apiHostRes, setApiHostRes] = useState(false)

  useEffect(() => {
    if (unityInstance.current != null && apiHostRes != null && apiHostRes) {
      unityInstance.current.SendMessage('Webreceiver', 'ApiHostRes', process.env.REACT_APP_API_HOST)
      const connectData = {
        isCommon: webviewData.isCommon,
        spaceId: webviewData.spaceId,
        spaceName: webviewData.spaceName,
        spaceIdName: webviewData.spaceIdName,
        assetBundleUrl: webviewData.assetBundleUrl,
        photonConnectPlayerCount: webviewData.sceneInfoDtos[0].templateInfoDto.connectUserLimit,
      }

      unityInstance.current.SendMessage('Webreceiver', 'ConnectData', JSON.stringify(connectData))
      setApiHostRes(false)
    }
  }, [unityInstance.current, apiHostRes])

  const [avatarSelectModal, setAvatarSelectModal] = useState(false)

  function avatarSelectModalClose(email, avatarNum) {
    sendMsgWithParam('Webreceiver', 'ReceiveAvatarData', avatarNum)
    setAvatarSelectModal(false)

    const formData = new FormData()

    // if (accountInfo.isLogin) {
    //     const accessToken = window.localStorage.getItem('plikaTk');
    //     const config = {
    //         headers: {Authorization: `Bearer ${accessToken}`},
    //     };
    //     const data = {
    //         accountId: accountInfo.accountId,
    //         email,
    //     };
    //     axios.patch(
    //         `${process.env.REACT_APP_API_HOST}v1/account/update`,
    //         data,
    //         config,
    //     );
    // }

    if (!accountInfo.isLogin) {
      if (email != null) {
        const data = {
          chatSessionId: accountInfo.sessionId,
          email,
        }
        formData.append(
          'guestUpdateDto',
          new Blob([JSON.stringify(data)], {
            type: 'application/json',
          }),
        )
        axios.patch(`${process.env.REACT_APP_API_HOST}api/guest/update`, formData)
      }
    }
  }

  const [loadingComplete, setLoadingComplete] = useState(false)

  useEffect(() => {
    if (unityContext != null) {
      /**
       * unityContext 빌드파일 로딩 완료 후 로딩화면 제거
       * unityInstance 설정
       */
      unityContext.on('loaded', function () {
        //const loadingbar = document.getElementById('unity-loading-bar');
        //if (loadingbar != null) {
        //   loadingbar.style.display = 'none';
        //}
        unityInstance.current = unityContext.unityInstance
        setWebglBuildLoading(false)

        if (!webviewData.sceneInfoDtos[0].templateInfoDto.useLoadingClose) {
          setLoadingComplete(true)
        }

        if (
          webviewData.sceneInfoDtos[0].templateInfoDto.usingCustomAvatar &&
          webviewData.sceneInfoDtos[0]
        ) {
          if (accountRef.current.isMember) {
            sendMsgWithParam('Webreceiver', 'ReceiveAvatarData', 2)
          } else {
            setAvatarSelectModal(true)
          }
        }
      })

      unityContext.on('loadingComplete', function () {
        setLoadingComplete(true)
      })

      /**
       * JSLIB : ControlWebview
       * 최초 접속 시 Control 모달창 띄움
       */
      unityContext.on('controlWebview', function () {
        setControlWebview(true)
      })

      /**
       * JSLIB : MainGuidePopup
       * 메인가이드 팝업
       */
      unityContext.on('mainGuidePopup', function (index) {
        setGuidePopupInnerText((prev) => ({
          ...prev,
          mainGuide: t(
            `space.templates.${webviewData.sceneInfoDtos[0].templateInfoDto.templateId}.text${index}`,
          ),
        }))
        setGuidePopup((prev) => ({ ...prev, mainGuide: true }))
      })

      /**
       * JSLIB : MainGuideClose
       * 메인가이드 닫기
       */
      unityContext.on('mainGuideClose', function () {
        setGuidePopupInnerText((prev) => ({
          ...prev,
          mainGuide: '',
        }))
        setGuidePopup((prev) => ({ ...prev, mainGuide: false }))
      })

      /**
       *  JSLIB : SubGuidePopup
       *  서브가이드 팝업
       */
      unityContext.on('subGuidePopup', function (index) {
        setGuidePopupInnerText((prev) => ({
          ...prev,
          subGuide: t(
            `space.templates.${webviewData.sceneInfoDtos[0].templateInfoDto.templateId}.text${index}`,
          ),
        }))

        setGuidePopup((prev) => ({ ...prev, subGuide: true }))
      })

      /**
       *  JSLIB : SubGuideClose
       *  서브가이드 닫기
       */
      unityContext.on('subGuideClose', function () {
        setGuidePopupInnerText((prev) => ({
          ...prev,
          subGuide: '',
        }))
        setGuidePopup((prev) => ({ ...prev, subGuide: false }))
      })

      /**
       * JSLIB: MINT Popup
       * MintCollection 팝업 열기
       */
      unityContext.on('customGuidePopup', function (index: number) {
        setIsModal(true)
        setMintPopup(index)
      })
      /**
       * JSLIB: MINT Popup
       * MintCollection 팝업 닫기
       */
      unityContext.on('customGuideClose', function () {
        setMintPopup(null)
        setIsModal(false)
      })

      /**
       * JSLIB : ApiHostReq
       * 백엔드 ApiHost 주소 요청
       */
      unityContext.on('apiHostReq', function () {
        // unityInstance.current.SendMessage(
        //     'Webreceiver',
        //     'ApiHostRes',
        //     process.env.REACT_APP_API_HOST,
        // );
        // const connectData = {
        //     isCommon: webviewData.isCommon,
        //     spaceId: webviewData.spaceId,
        //     spaceName: webviewData.spaceName,
        //     spaceIdName: webviewData.spaceIdName,
        //     assetBundleUrl: webviewData.assetBundleUrl,
        //     photonConnectPlayerCount:
        //         webviewData.sceneInfoDtos[0].templateInfoDto
        //             .connectUserLimit,
        // };

        // unityInstance.current.SendMessage(
        //     'Webreceiver',
        //     'ConnectData',
        //     JSON.stringify(connectData),
        // );
        setApiHostRes(true)
      })

      /**
       *  JSLIB : GetMemberInfoReq
       *  접속자의 회원 정보 조회
       *  axios 응답 후 accountInfo, accountRef, unityReq 설정
       */
      unityContext.on('getMemberInfoReq', function () {
        setMemberInfoReq(true)
      })

      /**
       * JSLIB : SetMemberPhotonId
       * 접속자의 포톤 아이디 설정
       * @param photonId 포톤 아이디를 인자로 받습니다
       */
      unityContext.on('setMemberPhotonId', function (photonId) {
        photonIdRef.current = photonId
      })

      /**
       * JSLIB : ShowInterface
       * 유니티 로딩 완료 후 인터페이스 출력
       */
      unityContext.on('showInterface', function () {
        setShowInterface(true)
      })

      /**
       * JSLIB : showContentWebview
       * 컨텐츠 웹뷰 팝업
       * @param path 백엔드에 통신할 path를 인자로 받습니다
       */
      unityContext.on('showContentWebview', function (path) {
        param.current = path
        webviewPopupChange('ContentWebviewPopup', true)
      })

      /**
       * JSLIB : fishWebview
       * 낚시 미니게임 결과 웹뷰 팝업
       * @param fishUuid 낚시 결과에 대한 uuid를 인자로 받습니다
       */
      unityContext.on('fishWebview', function (fishUuid) {
        param.current = fishUuid
        webviewPopupChange('fishResultPopup', true)
      })

      /**
       * JSLIB : fishItemWebview
       * 물고기 확률표 웹뷰 팝업
       */
      unityContext.on('fishItemWebview', function () {
        param.current = ''
        webviewPopupChange('fishItemPopup', true)
      })

      /**
       * JSLIB : lockscreenWebview
       * 회의실 비밀번호 웹뷰 팝업
       */
      unityContext.on('lockscreenWebview', function (index) {
        openLockScreenWebview(index)
      })

      /**
       * JSLIB : lockscreenEditWebview
       * 회의실 비밀번호 수정 웹뷰 팝업
       */
      unityContext.on('lockscreenEditWebview', function (index) {
        param.current = index
        webviewPopupChange('lockscreenEditPopup', true)
      })

      /**
       * JSLIB : connectVoiceChat
       * voiceChat에 연결합니다
       */
      unityContext.on('connectVoiceChat', function (roomIndex) {
        const roomStorage: any = vngCall.getRoomStorage()
        isDisconnectedRef.current[roomIndex] = false
        if (!isConnectedRef.current[roomIndex]) {
          vngCall
            .getToken(
              idName as string,
              roomIndex,
              accountRef,
              vonageSessionList[roomIndex].sessionId,
            )
            .then((e) => {
              if (
                roomStorage[roomIndex].participantId == null ||
                roomStorage[roomIndex].participantId?.length <= 0
              ) {
                isConnectedRef.current[roomIndex] = true
                vngCall.joinRoom(
                  accountRef.current,
                  isConnectedRef,
                  isDisconnectedRef,
                  roomIndex,
                  joinedRoom,
                  setLocalParticipant,
                  participantsRef,
                  setParticipants,
                  setFocusVonage,
                  setFullScreenStatus,
                  statusValueHandler,
                  sendMsg,
                  sendMsgWithParam,
                  setAccountInfo,
                  connectedUsers,
                )
              }
            })
        }
      })

      /**
       * JSLIB : disconnectVoiceChat
       * voiceChat과 연결을 끊습니다
       */
      unityContext.on('disconnectVoiceChat', function (roomIndex) {
        isDisconnectedRef.current[roomIndex] = true
        const roomStorage: any = vngCall.getRoomStorage()
        if (roomStorage[roomIndex] != null) {
          if (
            roomStorage[roomIndex].participantId != null &&
            roomStorage[roomIndex].participantId?.length > 0
          ) {
            if (roomStorage[roomIndex] != null) {
              vngCall.leaveRoom(
                roomIndex,
                setAccountInfo,
                isDisconnectedRef,
                joinedRoom,
                setParticipants,
                setLocalParticipant,
                statusValueHandler,
                participantsRef,
              )
              setParticipants([]) // room 참여자 초기화
              joinedRoom.current = null // room객체 Ref 초기화
              setLocalParticipant(null) // 로컬참여자(본인) 초기화
              participantsRef.current = []
              statusValueHandler('micStatus', false)
              statusValueHandler('screenStatus', false)
              statusValueHandler('camStatus', false)
            }
          }
        }
      })

      /**
       * JSLIB : enterVonageArea
       * vonageArea에 입장
       */
      unityContext.on('enterVonageArea', function (index) {
        const roomStorage: any = vngCall.getRoomStorage()
        console.log('index', index)
        if (
          accountRef.current.vonageAreaIndex != index &&
          accountRef.current.vonageAreaIndex != null
        ) {
          isDisconnectedRef.current[accountRef.current.vonageAreaIndex] = true
          if (roomStorage[accountRef.current.vonageAreaIndex] != null) {
            if (
              roomStorage[accountRef.current.vonageAreaIndex].participantId != null &&
              roomStorage[accountRef.current.vonageAreaIndex].participantId?.length > 0
            ) {
              vngCall.leaveRoom(
                accountRef.current.vonageAreaIndex,
                setAccountInfo,
                isDisconnectedRef,
                joinedRoom,
                setParticipants,
                setLocalParticipant,
                statusValueHandler,
                participantsRef,
              )
            }
          }
        }
        setAccountInfo((prev) => ({
          ...prev,
          vonageAreaIndex: index,
        }))
        accountRef.current.vonageAreaIndex = index
      })

      /**
       * JSLIB : getCurrentMemberList
       * 호출될 때 callCurrentMemberList상태를 true로 설정합니다.
       */
      unityContext.on('getCurrentMemberList', function () {
        setCallCurrentMemberList(true)
      })

      unityContext.on('internalPortalWebview', function () {
        sendMsgWithParam('Webreceiver', 'ContentSwitch', 0)
        setPortal(true)
      })

      unityContext.on('externalPortalWebview', function (id) {
        sendMsgWithParam('Webreceiver', 'ContentSwitch', 0)
        setExternal(true)
        setPortalId(id)
      })
    }
  }, [unityContext])

  /**
   * getCurrentMemberList 호출 상태가 true일때 응답으로 sendMessage
   */
  const [callCurrentMemberList, setCallCurrentMemberList] = useState<boolean>(false)

  useEffect(() => {
    if (callCurrentMemberList != null && callCurrentMemberList) {
      const param = JSON.stringify(connectedUsers)
      unityInstance.current.SendMessage('Webreceiver', 'GetCurrentMemberList', param)
      setCallCurrentMemberList(false)
    }
  }, [callCurrentMemberList])

  /**
   * FIXME : 모바일 환경에서의 가로/세로 모드의 반응형을 위한 이벤트 선언
   */
  window.addEventListener('orientationchange', function (event) {
    event.preventDefault()
  })

  /**
   * JSLIB으로 받은 인자의 상태 관리
   */
  const param = useRef<string>('')

  /**
   * Sendbird 사용을 위한 커스텀 이벤트
   */
  // const muteEvnet = new CustomEvent('muteMyMic');
  // const unMuteEvent = new CustomEvent('unmuteMyMic');
  /**
   * Sendbird userId
   */
  // const voiceUserId = useRef<string | null>();

  /**
   * unityInstance의 sendMsg 공용 메소드
   * @param object 유니티 오브젝트 이름을 인자로 받습니다
   * @param method 유니티 메소드 이름을 인자로 받습니다
   */
  const sendMsg: any = (object, method) => {
    if (unityInstance != null && unityInstance.current != null) {
      unityInstance.current.SendMessage(object, method)
    }
  }
  /**
   * unityInstance의 sendMsg 공용 메소드
   * @param object 유니티 오브젝트 이름을 인자로 받습니다
   * @param method 유니티 메소드 이름을 인자로 받습니다
   * @param param 유니티 메소드의 파라미터를 인자로 받습니다
   */
  const sendMsgWithParam: any = (object, method, param) => {
    if (unityInstance != null && unityInstance.current != null) {
      unityInstance.current.SendMessage(object, method, param)
    }
  }

  /**
   * 웹뷰 팝업 닫기 공용 메소드
   */
  const closeWebview: any = () => {
    unityInstance.current.SendMessage('Webreceiver', 'ContentSwitch', 1)
    webviewPopupClose()
  }

  function webviewPopupClose() {
    param.current = ''
    setWebviewPopup((prev) => ({
      ...prev,
      ContentWebviewPopup: false,
      removeMemberAlert: false,
      changeMemberAlert: false,
      profilePopup: false,
      exitPopup: false,
      fishRankingPopup: false,
      fishResultPopup: false,
      fishItemPopup: false,
      lockscreenPopup: false,
      lockscreenEditPopup: false,
    }))
  }

  /**
   * 웹뷰 팝업창 상태 관리
   */
  const [webviewPopup, setWebviewPopup] = useState({
    ContentWebviewPopup: false,
    removeMemberAlert: false,
    changeMemberAlert: false,
    profilePopup: false,
    exitPopup: false,
    fishRankingPopup: false,
    fishResultPopup: false,
    fishItemPopup: false,
    lockscreenPopup: false,
    lockscreenEditPopup: false,
    actionPopup: false,
  })

  const {
    ContentWebviewPopup,
    removeMemberAlert,
    changeMemberAlert,
    profilePopup,
    exitPopup,
    fishRankingPopup,
    fishResultPopup,
    fishItemPopup,
    lockscreenPopup,
    lockscreenEditPopup,
    actionPopup,
  } = webviewPopup

  /**
   * 웹뷰 팝업창 활성/비활성 시 sendMessage로 키보드 인풋 제어
   */
  useEffect(() => {
    if (unityInstance.current != null) {
      for (const key in webviewPopup) {
        if (webviewPopup[key]) {
          unityInstance.current.SendMessage('Webreceiver', 'ContentSwitch', 0)
          return
        }
      }
      unityInstance.current.SendMessage('Webreceiver', 'ContentSwitch', 1)
    }
  }, [webviewPopup])

  /**
   * 웹뷰 팝업 state 관리 공용 메소드
   * @param name 웹뷰 이름을 인자로 받습니다
   * @param value 웹뷰 팝업 여부를 인자로 받습니다
   */
  function webviewPopupChange(name, value) {
    setWebviewPopup((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  /**
   * 메뉴 팝업 상태 관리
   */
  const [menuPopup, setMenuPopup] = useState({
    logoMenuPopup: false,
    sideMenuPopup: false,
  })

  const { logoMenuPopup, sideMenuPopup } = menuPopup

  /**
   * 메뉴 팝업 state 관리 공용 메소드
   * @param name 메뉴 이름을 인자로 받습니다
   * @param value 메뉴 팝업 여부를 인자로 받습니다
   */
  function menuPopupChange(name, value) {
    setMenuPopup((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  /**
   * 메뉴 팝업 닫기 공용 메소드
   */
  function menuPopupClose() {
    setMenuPopup((prev) => ({
      ...prev,
      logoMenuPopup: false,
      sideMenuPopup: false,
    }))
  }

  /**
   * 사이드메뉴에서 선택한 멤버의 정보 상태 관리
   */
  const [selMemberInfo, setSelMemberInfo] = useState()

  /**
   * status 상태 관리
   */
  const [status, setStatus] = useState({
    speakerStatus: true,
    micStatus: false,
    screenStatus: false,
    camStatus: false,
    chatStatus: false,
    editModeStatus:
      accountInfo != null && (accountInfo.isMember || accountInfo.isAdmin) ? true : false,
    actionStatus: false,
  })

  useEffect(() => {
    if (status != null && unityInstance.current != null) {
      if (status.editModeStatus) {
        sendMsgWithParam('Webreceiver', 'OnUserManagerTrigger', 0)
      } else {
        sendMsgWithParam('Webreceiver', 'OnUserManagerTrigger', 1)
      }
    }
  }, [status, unityInstance.current])

  const {
    speakerStatus,
    micStatus,
    screenStatus,
    chatStatus,
    editModeStatus,
    camStatus,
    actionStatus,
  } = status

  /**
   * status 관리 공용 메소드
   * @param name status 이름을 인자로 받습니다
   * @param value status 상태를 인자로 받습니다
   */
  const statusValueHandler = (name, value) => {
    // if(name == 'chatStatus' && value){
    //     unityInstance.current.SendMessage('Webreceiver', 'ContentSwitch', 0);
    // }

    setStatus((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  /**
   * micStatus 값에 따라 vonage 마이크 on/off
   */
  useEffect(() => {
    if (vngCallRef.current != null && joinedRoom.current != null) {
      if (micStatus) {
        vngCallRef.current.startAudioShare()
      } else {
        vngCallRef.current.stopAudioShare()
      }
    }
  }, [micStatus])

  /**
   * camStatus 값에 따라 vonage 화상캠 on/off
   */
  const cameraDeviceRef = useRef(false)
  useEffect(() => {
    if (vngCallRef.current != null && joinedRoom.current != null) {
      if (camStatus) {
        vngCallRef.current.startVideoShare()
      } else {
        vngCallRef.current.stopVideoShare()
      }
    }
  }, [camStatus])

  /**
   * speakerStatus 값에 따라 유니티의 배경음 재생 여부 on/off
   */
  useEffect(() => {
    if (unityInstance.current != null) {
      if (speakerStatus) {
        unityInstance.current.SendMessage('Webreceiver', 'MusicSwitch', 0)
      } else {
        unityInstance.current.SendMessage('Webreceiver', 'MusicSwitch', 1)
      }
    }
  }, [speakerStatus])

  /**
   * 로고 메뉴 Ref
   */
  const logoMenuBtnRef: any = useRef()

  /**
   * Vonage Call
   */
  const vngCallRef: any = useRef()

  const videoDeviceRef = useRef(false)
  useEffect(() => {
    if (vngCallRef.current != null) {
      vngCallRef.current.getDevices().then((devices) => {
        const videoDevices = devices.filter((device) => {
          return device.kind == 'videoInput'
        })
        if (videoDevices.length == 0) {
          const videoTag = document.getElementById('localFaceCam') as HTMLMediaElement
          if (videoTag != null) {
            videoTag.srcObject = null
            videoTag.classList.remove('active')
          }
          videoDeviceRef.current = false
          statusValueHandler('camStatus', false)
        } else {
          videoDeviceRef.current = true
        }
      })

      if (navigator.mediaDevices != null) {
        navigator.mediaDevices.ondevicechange = (event) => {
          vngCallRef.current.getDevices().then((devices) => {
            const videoDevices = devices.filter((device) => {
              return device.kind == 'videoInput'
            })
            if (videoDevices.length == 0) {
              const videoTag = document.getElementById('localFaceCam') as HTMLMediaElement
              if (videoTag != null) {
                videoTag.srcObject = null
                videoTag.classList.remove('active')
              }
              videoDeviceRef.current = false
              statusValueHandler('camStatus', false)
            } else {
              joinedRoom.current.camera
                .setVideoDevice(videoDevices[0].deviceId)
                .then((e) => {
                  videoDeviceRef.current = true
                })
                .catch((err) => {
                  // console.log(err);
                })
            }
          })
        }
      }
    }
  }, [vngCallRef.current])

  /**
   * vonage 세션에 참가한 room
   */
  const joinedRoom: any = useRef(null)

  /**
   * vonage 세션에 참가한 participants
   */
  const [participants, setParticipants]: any = useState([])
  useEffect(() => {
    console.log(participants)
  }, [participants])
  const participantsRef = useRef([])

  /**
   * vonage 세션에 참가한 본인 정보
   */
  const [localParticipant, setLocalParticipant]: any = useState(null)

  /**
   * vonage 세션에 참가한 본인 participantId
   */
  const localParticipantId = useRef()

  /**
   * vonage 포커스 여부
   */
  const [focusVonage, setFocusVonage] = useState<boolean>(false)
  // const [focusScreenShare, setFocusScreenShare]: any = useState(false);

  useEffect(() => {
    if (focusVonage == false) {
      if (fullScreenStatus) {
        setFullScreenStatus(false)
      }
    }
  }, [focusVonage])

  /**
   * 포커스 된 participant
   */
  const [focusParticipant, setFocusParticipant]: any = useState()

  /**
   * 이전에 포커스 됐었던 participant
   */
  const prevFocusParticipant: any = useRef()

  /**
   * 화면공유 풀스크린 상태 관리
   */
  const [fullScreenStatus, setFullScreenStatus]: any = useState()

  /**
   * 화면공유 focus handler
   * @param e 이벤트를 인자로 받습니다
   */
  function screenShareFocusHandler(e) {
    e.preventDefault()
    if (e.currentTarget.id.startsWith('PK_')) {
      if (focusVonage) {
        const focusEl = document.getElementById('PK_' + focusParticipant.id)
        document.querySelectorAll('.MP_subscriber_overlay')?.forEach((e) => {
          e.classList.remove('focused')
        })
        document.querySelectorAll('.localFaceCam')?.forEach((e) => {
          e.classList.remove('focused')
        })
        document.querySelectorAll('.OT_video-element')?.forEach((e) => {
          e.classList.remove('focused')
        })
        focusEl?.classList.remove('focused')
      }

      e.currentTarget.childNodes.forEach((el) => {
        if (el.id.startsWith('OT_')) {
          if (
            e.target.className.includes('MP_subscriber_overlay', 0) ||
            e.target.className.includes('localFaceCam', 0) ||
            e.target.className.includes('OT_publisher', 0)
          ) {
            if (e.target.className.includes('MP_subscriber_overlay', 0)) {
              const targetVideoEl = e.target.parentNode.firstChild.lastChild
              const userMediaStream = new MediaStream(
                targetVideoEl.captureStream().getVideoTracks(),
              )
              const foucsVideoElement: any = document.getElementById('focusScreen')
              foucsVideoElement.srcObject = userMediaStream
              document.querySelectorAll('.localFaceCam')?.forEach((e) => {
                e.classList.remove('focused')
              })
              document.querySelectorAll('.OT_video-element')?.forEach((e) => {
                e.classList.remove('focused')
              })

              e.target.classList.add('focused')
            } else if (e.target.className.includes('localFaceCam', 0)) {
              const targetVideoEl = e.target
              const userMediaStream = new MediaStream(
                targetVideoEl.captureStream().getVideoTracks(),
              )
              const foucsVideoElement: any = document.getElementById('focusScreen')
              foucsVideoElement.srcObject = userMediaStream
              document.querySelectorAll('.MP_subscriber_overlay')?.forEach((e) => {
                e.classList.remove('focused')
              })
              document.querySelectorAll('.OT_video-element')?.forEach((e) => {
                e.classList.remove('focused')
              })
              e.target.classList.add('focused')
            }
          } else if (e.target.className.includes('OT_video-element')) {
            const userMediaStream = new MediaStream(e.target.captureStream().getVideoTracks())
            const foucsVideoElement: any = document.getElementById('focusScreen')
            foucsVideoElement.srcObject = userMediaStream
            document.querySelectorAll('.MP_subscriber_overlay')?.forEach((e) => {
              e.classList.remove('focused')
            })
            document.querySelectorAll('.localFaceCam')?.forEach((e) => {
              e.classList.remove('focused')
            })
            e.target.classList.add('focused')
          }

          if (e.currentTarget.id.split('_')[1] == joinedRoom.current.participantId) {
            prevFocusParticipant.current = focusParticipant
            setFocusParticipant(localParticipant)

            setFocusVonage(true)
          } else {
            const targetTag: any = document.getElementById(e.currentTarget.id)

            participants.forEach((el) => {
              if (el.id == e.currentTarget.id.split('_')[1]) {
                setFocusParticipant(el)
              }
            })
            prevFocusParticipant.current = focusParticipant
            // const addParticipant = {
            //     id: e.currentTarget.id.split('_')[1],
            //     nickname: targetTag?.firstChild?.firstChild?.lastChild.innerText,
            //     thumbnailUri: targetTag?.firstChild?.firstChild?.firstChild.src
            // };
            // setFocusParticipant(addParticipant);
            setFocusVonage(true)
          }
          // e.currentTarget.classList.add('focused');
        } else if (el.id.startsWith('localFaceCam')) {
          const targetVideoEl = e.target
          const userMediaStream = new MediaStream(targetVideoEl.captureStream().getVideoTracks())
          const foucsVideoElement: any = document.getElementById('focusScreen')
          foucsVideoElement.srcObject = userMediaStream
          document.querySelectorAll('.MP_subscriber_overlay')?.forEach((e) => {
            e.classList.remove('focused')
          })
          document.querySelectorAll('.OT_video-element')?.forEach((e) => {
            e.classList.remove('focused')
          })
          e.target.classList.add('focused')
          setFocusParticipant(localParticipant)
          setFocusVonage(true)
        }
      })
    } else {
      const focusEl = document.getElementById('PK_' + focusParticipant.id)
      document.querySelectorAll('.MP_subscriber_overlay')?.forEach((e) => {
        e.classList.remove('focused')
      })
      document.querySelectorAll('.localFaceCam')?.forEach((e) => {
        e.classList.remove('focused')
      })
      document.querySelectorAll('.OT_video-element')?.forEach((e) => {
        e.classList.remove('focused')
      })
      focusEl?.classList.remove('focused')
      setFocusVonage(false)
    }
  }

  /**
   * vonage 세션 접속중 상태 관리
   */
  const isConnectedRef = useRef({})
  const connectingRef = useRef(false)

  /**
   * vonage 세션 접속 해제 상태 관리
   */
  const isDisconnectedRef = useRef({})

  /**
   * vonage 세션에서 연결 해제 시 공용 메소드
   * participants, joinedRoom, localParticipant, micStatus, screenStatus 를 초기화합니다.
   */
  function initiateVonageSession() {
    setParticipants([]) // room 참여자 초기화
    joinedRoom.current = null // room객체 Ref 초기화
    setLocalParticipant(null) // 로컬참여자(본인) 초기화
    participantsRef.current = []
    statusValueHandler('micStatus', false)
    statusValueHandler('screenStatus', false)
  }

  /**
   * 회원 팀원에서 삭제
   */
  function deleteTeamMember(memberInfo) {
    const data = {
      idName: idName,
      email: memberInfo.detail.email,
      sessionId: memberInfo.sessionId,
    }

    const accessToken = window.localStorage.getItem('plikaTk')
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }

    axios.put(process.env.REACT_APP_API_HOST + 'v1/profile/delete', data, config).then((e) => {
      const deleteAccount: connectedMember | undefined = connectedUsers?.connectedMembers?.find(
        (el) => el.detail.email == memberInfo.detail.email,
      )

      if (deleteAccount != undefined) {
        stompClient.publish({
          destination: `/pub/send/info/${chatRoomId}`,
          body: JSON.stringify({
            messageType: messageType.INFO,
            code: 103,
            sessionId: deleteAccount.sessionId,
            email: memberInfo.detail.email,
            isLogin: deleteAccount.detail.login,
            isMember: deleteAccount.detail.member,
            isAdmin: deleteAccount.detail.admin,
            // 'roomId' : chatRoomId,
            nickname: deleteAccount.detail.nickname,
            thumbnailUri: deleteAccount.detail.thumbnailUri,
            isConnected: deleteAccount.detail.connected,
            textContent: `${memberInfo.detail.email}를 게스트로 변경하였습니다.`,
          }),
          skipContentLengthHeader: true,
        })
      }

      webviewPopupChange('removeMemberAlert', false)
    })
  }

  /**
   * 회원 팀원으로 초대
   */
  function addTeamMember(memberInfo) {
    const data = {
      idName: idName,
      email: memberInfo.detail.email,
      sessionId: memberInfo.sessionId,
    }

    const accessToken = window.localStorage.getItem('plikaTk')
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
    axios.post(process.env.REACT_APP_API_HOST + 'v1/profile/add', data, config).then((e) => {
      const addAccount: connectedGuest | undefined = connectedUsers?.connectedGuests?.find(
        (el) => el.detail.email == memberInfo.detail.email,
      )

      if (addAccount != undefined) {
        stompClient.publish({
          destination: `/pub/send/info/${chatRoomId}`,
          body: JSON.stringify({
            messageType: messageType.INFO,
            code: 104,
            sessionId: addAccount.sessionId,
            email: memberInfo.detail.email,
            isLogin: addAccount.detail.login,
            isMember: addAccount.detail.member,
            isAdmin: addAccount.detail.admin,
            // 'roomId' : chatRoomId,
            nickname: addAccount.detail.nickname,
            thumbnailUri: addAccount.detail.thumbnailUri,
            isConnected: addAccount.detail.connected,
            textContent: `${memberInfo.detail.email}를 멤버로 변경하였습니다.`,
          }),
          skipContentLengthHeader: true,
        })
      }

      webviewPopupChange('changeMemberAlert', false)
    })
  }

  const [copyLinkNotice, setCopyLinkNotice]: any = useState()

  useEffect(() => {
    if (copyLinkNotice) {
      setTimeout(() => {
        setCopyLinkNotice(false)
      }, 3000)
    }
  }, [copyLinkNotice])

  function copyLink() {
    navigator.clipboard.writeText(window.document.location.href)
    setCopyLinkNotice(true)
  }

  const [logoutModal, setLogoutModal]: any = useState()

  const logoutModalPopup = () => {
    sendMsgWithParam('Webreceiver', 'ContentSwitch', 0)
    setLogoutModal(true)
  }

  function modalClose() {
    sendMsgWithParam('Webreceiver', 'ContentSwitch', 1)
    setLogoutModal(false)
  }

  function userMenuLogout() {
    const accessToken = window.localStorage.getItem('plikaTk')
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
    if (accessToken != null && accessToken != 'null' && accessToken.length > 0) {
      axiosI
        .put(process.env.REACT_APP_API_HOST + 'v1/auth/refresh', config)
        .then((e) => {
          window.localStorage.removeItem('plikaTk')
          window.location.href = '/'
        })
        .catch((err) => {
          // console.log(err);
        })
    }
  }

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const insidePasswordRef = useRef<any>()

  function setInsidePassword(id, at, password) {
    insidePasswordRef.current = insidePasswordRef.current.map((e) =>
      e.id == id ? { ...e, at: at, password: password } : e,
    )
    sendObjectMsg(insidePasswordRef.current)
  }

  const openLockScreenWebview = (index) => {
    if (insidePasswordRef.current != null && insidePasswordRef.current.length > 0) {
      if (insidePasswordRef.current[index].password != null) {
        param.current = index
        webviewPopupChange('lockscreenPopup', true)
      } else {
        sendMsgWithParam('Webreceiver', 'OpenPrivateDoor')
      }
    } else {
      sendMsgWithParam('Webreceiver', 'OpenPrivateDoor')
    }
  }

  const isTablet = useMediaQuery({ query: '(max-width: 1320px)' })
  const scrollRef = useRef<any>(null)
  const [isDrag, setIsDrag] = useState(false)
  const [startX, setStartX] = useState<any>(0)
  const [startY, setStartY] = useState<any>(0)

  const onDragStart = (e) => {
    e.preventDefault()
    setIsDrag(true)
    if (isTablet) {
      scrollRef.current && setStartX(e.pageX + scrollRef.current.scrollLeft)
    } else {
      scrollRef.current && setStartY(e.pageY + scrollRef.current.scrollTop)
    }
  }

  const onDragEnd = () => {
    setIsDrag(false)
  }

  const throttle = (func, ms) => {
    let throttled = false
    return (...args) => {
      if (!throttled) {
        throttled = true
        setTimeout(() => {
          func(...args)
          throttled = false
        }, ms)
      }
    }
  }

  const onDragMove = (e) => {
    if (isDrag) {
      const { scrollWidth, scrollHeight, clientWidth, clientHeight, scrollTop, scrollLeft } =
        scrollRef.current

      if (isTablet) {
        if (scrollRef.current) {
          scrollRef.current.scrollLeft = startX - e.pageX
        }

        if (scrollLeft === 0) {
          setStartX(e.pageX)
        } else if (scrollWidth <= clientWidth + scrollLeft) {
          setStartX(e.pageX + scrollWidth)
        }
      } else {
        scrollRef.current.scrollTop = startY - e.pageY

        if (scrollTop === 0) {
          setStartY(e.pageY)
        } else if (scrollHeight <= clientHeight + scrollTop) {
          setStartY(e.pageY + scrollHeight)
        }
      }
    }
  }

  const delay = 100
  const onThrottleDragMove = throttle(onDragMove, delay)

  const [isModal, setIsModal] = React.useState(true)

  const handleActionItem = (index) => {
    sendMsgWithParam('Webreceiver', 'OnAvatarAction', index)
  }

  const positionCircleRef = useRef<HTMLDivElement>(null)
  const actionButtonRef = useRef<HTMLElement>(null)

  function handleClickOutside(e: MouseEvent | TouchEvent): void {
    if (
      actionStatus &&
      positionCircleRef.current &&
      !positionCircleRef.current.contains(e.target as Node) &&
      actionButtonRef.current &&
      !actionButtonRef.current.contains(e.target as Node)
    ) {
      statusValueHandler('actionStatus', false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  })

  const [vonageParticipantList, setVonageParticipantList] = useState<Array<VonageParticipant>>([])

  const vonageTimer = useRef<any>()
  useEffect(() => {
    if (accountInfo.vonageAreaIndex != null) {
      clearTimeout(vonageTimer.current)
      vonageTimer.current = setTimeout(() => {
        sendInfoMsg(701, 'join')
        const accessToken = window.localStorage.getItem('plikaTk')
        const config = {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
        axios
          .patch(
            process.env.REACT_APP_API_HOST + `chat/member/vonage`,
            {
              sessionId: accountInfo.sessionId,
              vonageSessionIndex: accountInfo.vonageAreaIndex,
            },
            config,
          )
          .then((e) => {
            // console.log(e);
          })
          .catch((err) => {
            // console.log(err);
          })
      }, 1500)
    }
  }, [accountInfo.vonageAreaIndex])

  useEffect(() => {
    if (vonageParticipantList.length > 0) {
      const myVonageAreaParticipants = new Array(0)
      const vonageAreaList = new Array<number>(vonageSessionList.length)
      vonageAreaList.fill(0)
      vonageParticipantList.filter((e) => {
        return (
          e.vonageAreaIndex == accountInfo.vonageAreaIndex &&
          e.vonageAreaIndex != null &&
          accountInfo.vonageAreaIndex != null
        )
      })

      vonageParticipantList.forEach((e) => {
        if (
          e.vonageAreaIndex == accountInfo.vonageAreaIndex &&
          e.vonageAreaIndex != null &&
          accountInfo.vonageAreaIndex != null
        ) {
          myVonageAreaParticipants.push(e)
        }

        if (e.vonageAreaIndex != null) {
          vonageAreaList[e.vonageAreaIndex]++
        }
      })

      const roomStorage: any = vngCall.getRoomStorage()

      if (myVonageAreaParticipants.length >= 2) {
        if (!isConnectedRef.current[accountInfo.vonageAreaIndex]) {
          if (!accountInfo.vonageParticipating) {
            connectingRef.current = true
            isDisconnectedRef.current[accountInfo.vonageAreaIndex] = false
            isConnectedRef.current[accountInfo.vonageAreaIndex] = true
            vngCall
              .getToken(
                idName as string,
                accountInfo.vonageAreaIndex,
                accountRef,
                vonageSessionList[accountInfo.vonageAreaIndex].sessionId,
              )
              .then((e) => {
                vngCall.joinRoom(
                  accountRef.current,
                  isConnectedRef,
                  isDisconnectedRef,
                  accountInfo.vonageAreaIndex,
                  joinedRoom,
                  setLocalParticipant,
                  participantsRef,
                  setParticipants,
                  setFocusVonage,
                  setFullScreenStatus,
                  statusValueHandler,
                  sendMsg,
                  sendMsgWithParam,
                  setAccountInfo,
                  connectedUsers,
                )
              })
          }
        }
      } else {
        if (accountInfo.vonageAreaIndex != null) {
          isDisconnectedRef.current[accountInfo.vonageAreaIndex] = true
          if (roomStorage[accountInfo.vonageAreaIndex] != null) {
            if (
              roomStorage[accountInfo.vonageAreaIndex].participantId != null &&
              roomStorage[accountInfo.vonageAreaIndex].participantId?.length > 0
            ) {
              vngCall.leaveRoom(
                accountInfo.vonageAreaIndex,
                setAccountInfo,
                isDisconnectedRef,
                joinedRoom,
                setParticipants,
                setLocalParticipant,
                statusValueHandler,
                participantsRef,
              )
            }
          }
        }
      }
      console.log(vonageParticipantList)

      const vonageAreaListObj = {
        list: vonageAreaList,
      }
      sendMsgWithParam('Webreceiver', 'UpdateVoiceBoundary', JSON.stringify(vonageAreaListObj))
    }
  }, [vonageParticipantList])

  function vonageJoinTest(index) {
    const roomStorage: any = vngCall.getRoomStorage()
    if (accountInfo.vonageAreaIndex != index && accountInfo.vonageAreaIndex != null) {
      //구역바뀔때
      isDisconnectedRef.current[accountInfo.vonageAreaIndex] = true
      if (roomStorage[accountInfo.vonageAreaIndex] != null) {
        if (
          roomStorage[accountInfo.vonageAreaIndex].participantId != null &&
          roomStorage[accountInfo.vonageAreaIndex].participantId?.length > 0
        ) {
          vngCall.leaveRoom(
            accountInfo.vonageAreaIndex,
            setAccountInfo,
            isDisconnectedRef,
            joinedRoom,
            setParticipants,
            setLocalParticipant,
            statusValueHandler,
            participantsRef,
          )
        }
      }
    }
    setAccountInfo((prev) => ({
      ...prev,
      vonageAreaIndex: index,
    }))
    accountRef.current.vonageAreaIndex = index
  }

  // TODO: JSlib으로 받아 온 값이 internal일 경우 Elevator
  // external일 경우 받아온 값으로 /portal/{lortalId}
  const [portal, setPortal] = useState(false)
  const [external, setExternal] = useState(false)
  const [portalId, setPortalId] = useState(null)

  // TODO: Toast
  const [toastStatus, setToastStatus] = useState(false)
  const [toastMsg, setToastMsg] = useState('')

  function handleToast(type) {
    console.log('toast')
    setToastStatus(true)
    setToastMsg(msgList[type])
  }

  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false)
        setToastMsg('')
      }, 1000)
    }
  }, [toastStatus])

  return (
    <ModalModeProvider>
      {portal && (
        <Elevator
          setState={setPortal}
          spaceName={spaceName}
          serviceName={serviceName}
          spaceId={webviewData.spaceId}
          sendMsg={sendMsgWithParam}
        />
      )}
      {external && <External setState={setExternal} id={portalId} sendMsg={sendMsgWithParam} />}

      <div className="container">
        <ScanUploader sendMsgWithParam={sendMsgWithParam} onClose={closeWebview} />
        <CustomToast />
        <PositionCircle
          ref={positionCircleRef}
          onActionItem={handleActionItem}
          actionStatus={actionStatus}
          setStatus={statusValueHandler}
        />

        {mintPopup !== null && isModal && (
          <ModalLayout setIsModal={setIsModal}>
            {mintPopup === 0 && <WelcomeContent />}
            {mintPopup === 1 && <ProcessContent />}
            {mintPopup === 2 && <ShowRoomContent />}
          </ModalLayout>
        )}
        {/* <SetPasswordAlert /> */}
        <div id="unity-container" className={styles.unity_container}>
          {/* <LingBellAlert /> */}
          {unityContext != null ? (
            <Unity
              unityContext={unityContext}
              className={`${styles.unity_canvas} unity_canvas`}
              tabIndex={1}
            />
          ) : (
            <></>
          )}
          {/* <div id="unity-loading-bar" className={styles.unity_loading_bar}>
                    <figure>
                        <img className={styles.unity_loading_img} src={LoadingImg} alt="" />
                        <img src={logo} alt="" className={styles.unity_loading_logo} />
                        <div className={styles.bounce}>
                            <div className={styles.bounce1}></div>
                            <div className={styles.bounce2}></div>
                            <div className={styles.bounce3}></div>
                        </div>
                    </figure>
                    <div className={styles.animation_container}>
                        
                        <img className={styles.unity_loading_plika_logo} src={plikaBetaLogo} alt="" />
                    </div>
                </div> */}
          {avatarSelectModal &&
            webviewData.sceneInfoDtos[0].templateInfoDto.avatarSelectTemplate == 0 && (
              <AvatarSelect accountInfo={accountInfo} sendMsg={avatarSelectModalClose} />
            )}
          {avatarSelectModal &&
            webviewData.sceneInfoDtos[0].templateInfoDto.avatarSelectTemplate == 1 && (
              <AvatarSelectA
                loadingThumbnail={webviewData.sceneInfoDtos[0].templateInfoDto.thumbnail}
                accountInfo={accountInfo}
                sendMsg={avatarSelectModalClose}
              />
            )}

          {!loadingComplete &&
            webviewData != null &&
            webviewData.sceneInfoDtos[0].templateInfoDto.loadingTemplate === 0 && (
              <Loading
                spaceName={webviewData.spaceName}
                logoUrl={webviewData.logoUrl}
                templateThumbnail={webviewData.sceneInfoDtos[0].templateInfoDto.thumbnail}
                usedCustom={webviewData.usedCustomBuild}
              />
            )}
          {!loadingComplete &&
            webviewData != null &&
            webviewData.sceneInfoDtos[0].templateInfoDto.loadingTemplate === 1 && (
              <CustomLoading
                spaceName={webviewData.spaceName}
                logoUrl={webviewData.logoUrl}
                templateThumbnail={webviewData.sceneInfoDtos[0].templateInfoDto.thumbnail}
                usedCustom={webviewData.usedCustomBuild}
              />
            )}
          {!loadingComplete &&
            webviewData != null &&
            webviewData.sceneInfoDtos[0].templateInfoDto.loadingTemplate === 2 && (
              <MintLoading
                spaceName={webviewData.spaceName}
                logoUrl={webviewData.logoUrl}
                templateThumbnail={webviewData.sceneInfoDtos[0].templateInfoDto.thumbnailVideo}
                usedCustom={webviewData.usedCustomBuild}
              />
            )}
          {!loadingComplete &&
            webviewData != null &&
            webviewData.sceneInfoDtos[0].templateInfoDto.loadingTemplate === 3 && (
              <LoadingThree
                spaceName={webviewData.spaceName}
                logoUrl={webviewData.logoUrl}
                templateThumbnail={webviewData.sceneInfoDtos[0].templateInfoDto.thumbnail}
                usedCustom={webviewData.usedCustomBuild}
              />
            )}
          {!loadingComplete &&
            webviewData != null &&
            webviewData.sceneInfoDtos[0].templateInfoDto.loadingTemplate === 4 && (
              <LoadingFour
                templateThumbnail={webviewData.sceneInfoDtos[0].templateInfoDto.thumbnail}
              />
            )}

          {controlWebview && webviewData.sceneInfoDtos[0].templateInfoDto.guideType === 0 && (
            <Control setControlWebview={setControlWebview} sendMsg={sendMsg}></Control>
          )}
          {controlWebview && webviewData.sceneInfoDtos[0].templateInfoDto.guideType === 1 && (
            <ControlA setControlWebview={setControlWebview} sendMsg={sendMsg}></ControlA>
          )}
          {controlWebview && webviewData.sceneInfoDtos[0].templateInfoDto.guideType === 2 && (
            <DtocControl setControlWebview={setControlWebview} sendMsg={sendMsg}></DtocControl>
          )}

          {controlWebview &&
            (webviewData.sceneInfoDtos[0].templateInfoDto.guideType === 3 ||
              webviewData.sceneInfoDtos[0].templateInfoDto.guideType === 4 ||
              webviewData.sceneInfoDtos[0].templateInfoDto.guideType === 5) && (
              <GangnamControl
                setState={setControlWebview}
                sendMsg={sendMsg}
                type={webviewData.sceneInfoDtos[0].templateInfoDto.guideType}
              />
            )}

          {controlWebview &&
            (webviewData.sceneInfoDtos[0].templateInfoDto.guideType === 6 ||
              webviewData.sceneInfoDtos[0].templateInfoDto.guideType === 7) && (
              <ExhibitionControl
                sendMsg={sendMsg}
                setState={setControlWebview}
                type={webviewData.sceneInfoDtos[0].templateInfoDto.guideType}
              />
            )}

          {guidePopup.subGuide && (
            <GuidePopup bgColor="white" innerText={guidePopupInnerText.subGuide} />
          )}
          {showInterface && (
            <>
              {/* <div
                                ref={logoMenuBtnRef}
                                className={styles.unity_logo_menu}
                            >
                                <div
                                    className={styles.unity_logo_menu_img}
                                    onClick={() =>
                                        menuPopupChange('logoMenuPopup', true)
                                    }
                                ></div>
                                {logoMenuPopup ? (
                                    <LogoMenu
                                        accountInfo={accountInfo}
                                        logoMenuBtnRef={logoMenuBtnRef}
                                        menuPopupChange={menuPopupChange}
                                        sendMsgWithParam={sendMsgWithParam}
                                    ></LogoMenu>
                                ) : (
                                    <></>
                                )}
                            </div> */}

              {/* {connectedUsers != null && (
                                <div className={styles.unity_info_bar}>
                                    <div className={styles.unity_info_name}>
                                        {webviewData.spaceName}
                                    </div>
                                    <div
                                        className={
                                            sideMenuPopup && !status.chatStatus
                                                ? `${styles.unity_info_players} ${styles.active}`
                                                : styles.unity_info_players
                                        }
                                        onClick={
                                            sideMenuPopup
                                                ? chatStatus
                                                    ? () => {
                                                          statusValueHandler(
                                                              'chatStatus',
                                                              false,
                                                          );
                                                      }
                                                    : () => {
                                                          menuPopupChange(
                                                              'sideMenuPopup',
                                                              false,
                                                          );
                                                          statusValueHandler(
                                                              'chatStatus',
                                                              false,
                                                          );
                                                      }
                                                : () => {
                                                      menuPopupChange(
                                                          'sideMenuPopup',
                                                          true,
                                                      );
                                                      statusValueHandler(
                                                          'chatStatus',
                                                          false,
                                                      );
                                                  }
                                        }
                                    >
                                        <figure>
                                            <div
                                                className={
                                                    sideMenuPopup &&
                                                    !status.chatStatus
                                                        ? `${styles.icon} ${styles.att} ${styles.active}`
                                                        : `${styles.icon} ${styles.att}`
                                                }
                                            ></div>
                                        </figure>
                                        <span>
                                            {connectedUsers.connectedMembers !=
                                                undefined &&
                                                connectedUsers.connectedGuests !=
                                                    undefined &&
                                                (
                                                    connectedUsers.connectedMembers.filter(
                                                        e => e.detail.connected,
                                                    ).length +
                                                    connectedUsers
                                                        .connectedGuests.length
                                                ).toString()}
                                        </span>
                                        /{webviewData.sceneInfoDtos[0].templateInfoDto.connectUserLimit}
                                    </div>
                                </div>
                            )} */}

              <SideMenu
                spaceName={webviewData.spaceName}
                accountInfo={accountInfo}
                chatRoomId={chatRoomId}
                stompClient={stompClient}
                sendTextMsg={sendTextMsg}
                chatList={chatList}
                connectedUsers={connectedUsers}
                sideMenuPopup={sideMenuPopup}
                setSelMemberInfo={setSelMemberInfo}
                chatStatus={chatStatus}
                setChatStatus={statusValueHandler}
                exitPopup={exitPopup}
                webviewPopupChange={webviewPopupChange}
                menuPopupChange={menuPopupChange}
                sendMsgWithParam={sendMsgWithParam}
                sendRingMsg={sendRingMsg}
              ></SideMenu>
              {copyLinkNotice && (
                <Notice
                  innerText={t('space.common.save_clipboard')}
                  width={330}
                  height={40}
                ></Notice>
              )}

              {logoutModal && (
                <LogoutModal onClickEvent={userMenuLogout} onClose={modalClose}></LogoutModal>
              )}

              {isMobile && (
                <TopInterface>
                  <LandName>
                    {webviewData.logoUrl && (
                      <ImageWrap>
                        <img src={webviewData.logoUrl} alt="" />
                      </ImageWrap>
                    )}
                    {webviewData.spaceName}
                  </LandName>
                  <PlayersBox>
                    <div className={styles.unity_user_menu_container}>
                      <div className={styles.user_menu_btn_box}>
                        {connectedUsers != null && (
                          <div className={styles.unity_info_bar}>
                            <div
                              className={
                                sideMenuPopup && !chatStatus
                                  ? `${styles.unity_info_players} ${styles.active}`
                                  : styles.unity_info_players
                              }
                              onClick={
                                sideMenuPopup
                                  ? chatStatus
                                    ? () => {
                                        statusValueHandler('chatStatus', false)
                                      }
                                    : () => {
                                        menuPopupChange('sideMenuPopup', false)
                                        statusValueHandler('chatStatus', false)
                                      }
                                  : () => {
                                      menuPopupChange('sideMenuPopup', true)
                                      statusValueHandler('chatStatus', false)
                                    }
                              }
                            >
                              <PlayersIcon />
                              <span>
                                {connectedUsers.connectedMembers != undefined &&
                                  connectedUsers.connectedGuests != undefined &&
                                  (
                                    connectedUsers.connectedMembers.filter(
                                      (e) => e.detail.connected,
                                    ).length + connectedUsers.connectedGuests.length
                                  ).toString()}
                              </span>
                              /{webviewData.sceneInfoDtos[0].templateInfoDto.connectUserLimit}
                            </div>
                          </div>
                        )}
                        <figure
                          ref={logoMenuBtnRef}
                          className={`${styles.icon} ${styles.att}`}
                          onClick={() => menuPopupChange('logoMenuPopup', true)}
                        >
                          <ToggleIcon />
                        </figure>
                      </div>
                    </div>
                  </PlayersBox>
                </TopInterface>
              )}
              {logoMenuPopup ? (
                <LogoMenu
                  accountInfo={accountInfo}
                  logoMenuBtnRef={logoMenuBtnRef}
                  menuPopupChange={menuPopupChange}
                  sendMsgWithParam={sendMsgWithParam}
                  webviewPopupChange={webviewPopupChange}
                  copyLink={copyLink}
                  logoutModalPopup={logoutModalPopup}
                  modalClose={modalClose}
                ></LogoMenu>
              ) : (
                <></>
              )}

              <BottomInterface>
                {accountInfo != null && (
                  <>
                    <UserMenu
                      handleToast={handleToast}
                      setControlWebview={setControlWebview}
                      ref={actionButtonRef}
                      chatList={chatList}
                      accountInfo={accountInfo}
                      admin={accountInfo.isMember}
                      webviewPopupChange={webviewPopupChange}
                      menuPopupChange={menuPopupChange}
                      editModeStatus={editModeStatus}
                      speakerStatus={speakerStatus}
                      micStatus={micStatus}
                      camStatus={camStatus}
                      screenStatus={screenStatus}
                      chatStatus={chatStatus}
                      status={status}
                      setStatus={statusValueHandler}
                      vngCallRef={vngCallRef}
                      videoDeviceRef={videoDeviceRef}
                      joinedRoom={joinedRoom}
                      logoMenuPopup={logoMenuPopup}
                      sendMsgWithParam={sendMsgWithParam}
                      logoMenuBtnRef={logoMenuBtnRef}
                      copyLink={copyLink}
                      logoutModalPopup={logoutModalPopup}
                      modalClose={modalClose}
                      connectedUsers={connectedUsers}
                      webviewData={webviewData}
                      sideMenuPopup={sideMenuPopup}
                      statusValueHandler={statusValueHandler}
                      useRankIcon={false}
                      mintPopup={mintPopup}
                      actionStatus={actionStatus}
                    ></UserMenu>
                  </>
                )}
              </BottomInterface>
            </>
          )}
          <div
            id="media-container"
            className="media-container"
            onMouseDown={onDragStart}
            onMouseMove={onThrottleDragMove}
            onMouseUp={onDragEnd}
            onMouseLeave={onDragEnd}
            ref={scrollRef}
            style={participants == null ? { display: 'none' } : { display: 'flex' }}
          >
            <div id="roomContainer"></div>

            <div
              id="playerContainer"
              className="playerContainer"
              onMouseDown={onDragStart}
              onMouseMove={onThrottleDragMove}
              onMouseUp={onDragEnd}
              onMouseLeave={onDragEnd}
              ref={scrollRef}
            >
              {localParticipant != null && (
                <div
                  id={'PK_' + localParticipant.id}
                  className="playerBox"
                  onClick={screenShareFocusHandler}
                >
                  <div className="playerInfoBox">
                    <div className="playerInfo">
                      <img src={accountInfo.thumbnailUri} alt="" />
                      <span>{accountInfo.nickname}</span>
                    </div>
                    <div className={micStatus ? 'micStatus' : 'micStatus disabled'}></div>
                  </div>
                  <video autoPlay id="localFaceCam" className="localFaceCam"></video>
                </div>
              )}

              {participants != null &&
                participants.length > 0 &&
                participants.map((el: any, i: any) =>
                  el.id != null ? (
                    <div
                      id={'PK_' + el.id}
                      key={i}
                      className={`${camStatus || screenStatus ? 'playerBox active ' : 'playerBox'}`}
                      onClick={screenShareFocusHandler}
                    >
                      <div className="playerInfoBox">
                        <div className="playerInfo">
                          <img src={el.thumbnailUri} alt="" />
                          <span>{el.nickname}</span>
                        </div>
                        <div className="micStatus"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="blind" key={i}></div>
                  ),
                )}
            </div>
          </div>
          <div
            id="PK_focusScreenShare"
            className={
              focusVonage
                ? fullScreenStatus
                  ? chatStatus || sideMenuPopup
                    ? `focusScreen enabled fullscreen exceptChat`
                    : `focusScreen enabled fullscreen`
                  : chatStatus || sideMenuPopup
                  ? `focusScreen enabled exceptChat`
                  : `focusScreen enabled`
                : `focusScreen`
            }
          >
            <div className="playerInfoBox">
              {focusVonage && (
                <>
                  <div id={'PK_focus_' + focusParticipant.id} className="playerInfo">
                    <img src={focusParticipant.thumbnailUri} alt="" />
                    <span>{focusParticipant.nickname}</span>
                  </div>
                  <div className="micStatus"></div>
                </>
              )}
            </div>
            {focusVonage && (
              <div className="btnBox">
                {fullScreenStatus ? (
                  <div className="smallscreenBtn" onClick={() => setFullScreenStatus(false)}></div>
                ) : (
                  <div className="fullscreenBtn" onClick={() => setFullScreenStatus(true)}></div>
                )}
                <div className="closeBtn" onClick={screenShareFocusHandler}></div>
              </div>
            )}

            <VideoWrap>
              <video autoPlay id="focusScreen"></video>
            </VideoWrap>
          </div>
        </div>

        {exitPopup ? (
          <SpaceExitAlert webviewPopupChange={webviewPopupChange}></SpaceExitAlert>
        ) : (
          <></>
        )}

        {profilePopup && accountInfo != null ? (
          <Editprofile
            webviewData={webviewData}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
            accountRef={accountRef}
            sendInfoUpdateProfileoMsg={sendInfoUpdateProfileoMsg}
            setLocalParticipant={setLocalParticipant}
            webviewPopupChange={webviewPopupChange}
            inSpace={true}
            sendMsgWithParam={sendMsgWithParam}
          ></Editprofile>
        ) : (
          <></>
        )}
        {fishRankingPopup && (
          <RankingListWebview
            uuid={param.current}
            webviewPopupChange={webviewPopupChange}
          ></RankingListWebview>
        )}
        {changeMemberAlert && selMemberInfo != null ? (
          <ChangeMemberAlert
            memberInfo={selMemberInfo}
            addTeamMember={addTeamMember}
            onClose={() => webviewPopupChange('changeMemberAlert', false)}
          ></ChangeMemberAlert>
        ) : (
          <></>
        )}
        {removeMemberAlert && selMemberInfo != null ? (
          <RemoveMemberAlert
            memberInfo={selMemberInfo}
            deleteTeamMember={deleteTeamMember}
            onClose={() => webviewPopupChange('removeMemberAlert', false)}
          ></RemoveMemberAlert>
        ) : (
          <></>
        )}
        {ContentWebviewPopup ? (
          <ContentWebview
            param={param.current}
            onClose={closeWebview}
            editModeStatus={editModeStatus}
            sendMsgWithParam={sendMsgWithParam}
          ></ContentWebview>
        ) : (
          <></>
        )}

        {fishResultPopup ? (
          <FishResultWebview
            sendMsg={sendMsg}
            sendMsgWithParam={sendMsgWithParam}
            webviewPopupChange={webviewPopupChange}
            param={param}
          />
        ) : (
          <></>
        )}
        {fishItemPopup ? <FishItemList onClose={closeWebview} /> : <></>}
        {lockscreenPopup ? (
          <LockScreenWebview
            onClose={closeWebview}
            passwordAt={insidePasswordRef.current[param.current].at}
            passwordProp={insidePasswordRef.current[param.current].password}
            sendMsgWithParam={sendMsgWithParam}
          />
        ) : (
          <></>
        )}

        {lockscreenEditPopup ? (
          <LockScreenEditWebview
            onClose={closeWebview}
            index={param.current}
            passwordData={insidePasswordRef.current[param.current]}
            sendMsgWithParam={sendMsgWithParam}
            setInsidePassword={setInsidePassword}
          />
        ) : (
          <></>
        )}

        {access == false && admission && (
          <PasswordSetting
            spaceName={webviewData.spaceName}
            accessPasswordCheck={accessPasswordCheck}
          />
        )}

        {admission == false && <Nonadmission />}

        {callMeMember != null && (
          <LingBellAlert member={callMeMember} close={closeRingAlert}></LingBellAlert>
        )}
      </div>
    </ModalModeProvider>
  )
}

const TopInterface = styled.div`
  width: 100%;
  height: 60px;

  position: absolute;
  top: 0;
  padding: 10px 20px;
  border-bottom: solid 1px #eee;
  backdrop-filter: blur(5px);

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.small} {
    height: 50px;
  }
`

const LandName = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;

  ${media.small} {
    font-size: 14px;
    gap: 8px;
  }
`

const ImageWrap = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.small} {
    width: 30px;
    height: 30px;
  }
`

const BottomInterface = styled.div`
  width: 100%;
  height: 60px;

  position: absolute;
  bottom: 0;
  padding: 10px 20px;
  border-top: solid 1px #eee;
  backdrop-filter: blur(5px);

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.small} {
    padding: 0 20px;
    height: 50px;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`

const PlayersBox = styled.div`
  width: max-content;
`

const ScreenInterface = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
`

const VideoWrap = styled.div`
  width: 100%;
  height: 100%;
`

export default Space
