import React from 'react';
import styled from 'styled-components';

import Footer from 'components/tile/Footer';
import Header from 'components/tile/Header';
import Guide from 'components/tile/Guide';

function Contact() {
    return (
        <Page>
            <Header show={null} />
            <Content>
                <p>Contact Us</p>
                <p>info@plika.io</p>
            </Content>
            <Guide />
            <Footer />
        </Page>
    );
}

const Page = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    width: 100%;
    height: 660px;
    background: #1e69cb;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    p {
        color: #fff;
        font-size: 64px;
        font-weight: 700;
    }
`;

export default Contact;
