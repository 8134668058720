import * as React from 'react'
import { SVGProps } from 'react'
const SvgIconCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="icon_check_svg__w-6 icon_check_svg__h-6"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
  </svg>
)
export default SvgIconCheck
