import { useRef, useState } from "react";
import { gsap, Linear } from "gsap";

let inTimer;


export function useSlide () {
    // SpaceDetail 컴포넌트의 활성여부
    const [ onMouse, setOnMouse ] :any = useState();    

    const [ index, setIndex ] :any = useState();

    const [ arrayName, setArrayName]: any = useState();
    
    // SpaceDetail 컴포넌트 위치
    const newItemPosition = useRef({top:0, left:0});

    const gsapAnimation:any = useRef();
    
    // 마우스 오버 시 SpaceDetail 위치 설정과, 컴포넌트 활성여부 On
    function listOver (target, index, arrayName) {
        
        const slideItemPosition = target.target.closest('.slide-item').getBoundingClientRect(); 
        
        newItemPosition.current = {
            top: window.pageYOffset + slideItemPosition.y,
            left: window.pageXOffset + slideItemPosition.x
        };

        setArrayName(arrayName);
        setIndex(index);
        setOnMouse(true);
    }

    const slideItemMouseOver = function (target, index, arrayName) {        
        clearTimeout(inTimer);
        inTimer = setTimeout(listOver, 450, target, index, arrayName);        
    }
    
    const slideItemMouseLeave = function (e) {    
        clearTimeout(inTimer);
    }

    const slideInfoMouseOver = function (e) {     
        clearTimeout(inTimer);
    }

    const slideInfoMouseLeave = function (e) {
        clearTimeout(inTimer);
        
        gsapAnimation.current.reverse();
        
        setTimeout(() => {
            setOnMouse(false);
        }, 400);
    }

  

    return [onMouse, index, arrayName, newItemPosition, slideItemMouseOver, slideItemMouseLeave, slideInfoMouseOver, slideInfoMouseLeave, gsapAnimation];
}