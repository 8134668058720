import axios from 'axios';
import * as VideoExpress from '@vonage/video-express';
import Participant from '@vonage/video-express/dist/internal/participant';
import {any} from 'prop-types';

/**
 * VngCall - Vonage 솔루션을 이용한 미디어 기능 유틸 클래스
 *
 * @Author plika_cswsoo
 */
export class VngCall {
    private _roomStorage: Array<VideoExpress.Room | null> = [];
    private _sessionStorage: Array<object> = [];

    readonly VONAGE_SESSION_ID = 'vonage_session_id';
    readonly VONAGE_USER_TOKEN = 'vonage_user_token';

    /**
     * VngCall에서 Room 객체를 공용으로 사용하기 위한 필드입니다.
     */
    private _room: any; //_current_room

    /**
     * getRoom
     */
    public getCurRoom() {
        return this._room;
    }

    public getRoomStorage() {
        return this._roomStorage;
    }
    /**
     * Room 인스턴스를 생성하기 위한 API Key를 저장하기 위한 필드입니다.
     */
    private API_KEY;

    /**
     * VngCall 인스턴스 생성자입니다.
     * .env에서 API KEY를 받아와 멤버변수에 할당하여줍니다.
     */
    constructor() {
        this.API_KEY = process.env.REACT_APP_VONAGEAPI_KEY;

        // this.initLocalStorage();
        const arr: any = [];

        arr.map((e: any) => {
            this._sessionStorage.push(e);
        });
    }

    /**
     *  Init Method
     */
    private initLocalStorage() {
        const sessionId = window.localStorage.getItem(this.VONAGE_SESSION_ID);
        const userToken = window.localStorage.getItem(this.VONAGE_USER_TOKEN);
        const opkClientId = window.localStorage.getItem('opentok_client_id');

        if (sessionId != null) {
            window.localStorage.removeItem(this.VONAGE_SESSION_ID);
        }

        if (userToken != null) {
            window.localStorage.removeItem(this.VONAGE_USER_TOKEN);
        }

        if (opkClientId != null) {
            window.localStorage.removeItem('opentok_client_id');
        }
    }

    /**
     * [DEMO] 테스트용 데모버전으로 사용하고자 선언된 임시 메서드입니다.
     *
     * createDemoeSession()메서드를 실행합니다 createDemoeSession에 전달되는 인자의 default는 1입니다.
     */
    public demoInit(amount: any, spaceName: any): void {
        this.createDemoSession(amount, spaceName);
    }

    /**
     * Session, Token Related Method
     */
    /**
     *  [DEMO] 테스트용 데모버전으로 사용하고자 선언된 임시 메서드입니다.
     *  데모에 사용하고자 하는 세션을 생성하는 메서드 입니다.
     *
     * @param amount (int) 생성하고자 하는 세션의 양을 인자로 받습니다.
     * @param spaceName (string) 생성하고자 하는 세션이 속할 스페이스 이름을 인자로 받습니다.
     */
    private createDemoSession(amount: number, spaceName: string): void {
        const data = {
            amount,
        };

        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        // TODO: 통신 URI 수정
        axios
            .post(
                process.env.REACT_APP_API_HOST +
                    `api/v1/media/${spaceName}/0/create`,
                data,
                config,
            )
            .then(resp => {
                const sessionDetailList: string[] =
                    resp.data.data.sessionDetailList;

                window.localStorage.setItem(
                    this.VONAGE_SESSION_ID,
                    JSON.stringify(sessionDetailList),
                );
            })
            .catch(err => console.log(err));
    }

    public exitSession(): void {
        const token = window.localStorage.getItem('opentok_client_id');
        const sessionId = this._room.roomId;

        const data = {
            sessionId,
            token,
        };

        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        axios
            .put(
                process.env.REACT_APP_API_HOST + 'api/v1/media/' + sessionId,
                data,
                config,
            )
            .then(resp => {
                // console.log('성공적 퇴장');
                // console.log(resp);
            })
            .catch(err => {
                // console.log('퇴장 실패');
                // console.log(err);
            });
    }

    /**
     * LocalStorage 안에 저장된 `Session Id`로 `User Token`을 발급받아 `User Token`을 LocalStorage에 저장합니다.
     *
     * @param index (int) 토큰을 발급받을 세션의 인덱스를 인자로 받습니다.
     * @param idName (string) 토큰을 발급받을 세션이 속한 스페이스의 이름을 인자로 받습니다.
     * @returns void
     */
    public async getToken(
        idName: string,
        index: number,
        accountInfo: any,
        sessionId: string,
    ): Promise<void> {
        console.log(sessionId);

        if (sessionId == null) {
            alert('세션 생성을 먼저 해주십시오.');
            return;
        }

        const accountInfoData = {
            nickname: accountInfo.current.nickname,
            thumbnailUri: accountInfo.current.thumbnailUri,
            sessionId: accountInfo.current.sessionId,
            email: accountInfo.current.email,
        };
        console.log(this._roomStorage[index]);

        if (
            this._roomStorage[index]?.participantId != null &&
            (this._roomStorage[index]?.participantId?.length as number) >= 0
        ) {
            return;
        }
        // FIXME: credential 정보 받아올 수 있도록 수정
        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        await axios
            .post(
                process.env.REACT_APP_API_HOST +
                    `api/v1/media/${idName}/0/session/` +
                    sessionId,
                accountInfoData,
                config,
            )
            .then(resp => {
                console.log(resp);

                const vonageToken = resp.data.data.token;

                this._room = new VideoExpress.Room({
                    apiKey: this.API_KEY,
                    sessionId: sessionId,
                    token: vonageToken,
                    participantName: accountInfo.current.nickname,
                    roomContainer: 'playerContainer',
                });

                this._roomStorage[index] = this._room;
            })
            .catch(err => {
                // console.log(err);
            });
    }

    /**
     *  Room Related Method
     */

    /**
     * 이미 생성된 세션의 리스트를 받아옵니다.
     *
     * @param spaceIdName 입장하고자 하는 공간의 이름을 인자로 받습니다.
     * @param sceneIndex 입장하고자 하는 공간의 씬의 인덱스를 인자로 받습니다.
     */
    public async getSessionList(spaceIdName: string): Promise<void> {
        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        // TODO : 통신 URI 변경
        await axios
            .get(
                process.env.REACT_APP_API_HOST +
                    `api/v1/media/${spaceIdName}/0`,
                config,
            )
            .then(resp => {
                window.localStorage.setItem(
                    this.VONAGE_SESSION_ID,
                    JSON.stringify(resp.data.data.sessionDetailList),
                );
            })
            .catch(err => {
                // console.log(err);
            });
    }

    /**
     *  VngCall에 할당된 Room 객체를 변경하는 메서드 입니다. 새로운 방에 입장한 후, 입장한 방에서 명령을 실행하려면 반드시 Room 객체를 변경하여야 합니다.
     *
     * @param sessionId LocalStorage에 저장된 `Session Id`입니다. LocalStorage에는 vonage_session_id라는 이름으로 저장되어있습니다.
     * @param userToken LocalStorage에 저장된 `User Token`입니다. LocalStorage에는 vonage_user_token라는 이름으로 저장되어있습니다.
     * @returns room:VideoExpress.Room
     */
    public changeRoom(sessionId: any, userToken: any, index: any): object {
        const beforeRoom = this._room;

        const newRoom = this.getRoom(sessionId, userToken);

        this.setRoom(newRoom).then(() => {
            const afterRoom = this._room;

            this.saveUserData(afterRoom.participants);
        });

        const data = {
            roomIndex: index,
            room: this._room,
        };

        return data;
    }

    /**
     * 실제 Room 객체를 생성하는 메서드 입니다.
     *
     * @param sessionId LocalStorage에 저장된 `Session Id`입니다. LocalStorage에는 vonage_session_id라는 이름으로 저장되어있습니다.
     * @param userToken LocalStorage에 저장된 `User Token`입니다. LocalStorage에는 vonage_user_token라는 이름으로 저장되어있습니다.
     * @returns room VideoExpress.Room
     */
    private getRoom(sessionId, userToken): VideoExpress.Room {
        return new VideoExpress.Room({
            apiKey: this.API_KEY,
            sessionId: sessionId,
            token: userToken,
            participantName: 'test',
            roomContainer: 'playerContainer',
            managedLayoutOptions: {
                cameraPublisherContainer: 'playerContainer',
                screenPublisherContainer: 'playerContainer',
            },
        });
    }

    /**
     * VngCall에서 입장한 방을 세팅하기 위한 세터메서드입니다.
     *
     * @param room (VideoExpress.Room) Room 관련 명령을 실행하고자 하는 입장된 방을 인자로 받습니다.
     */
    private async setRoom(room: VideoExpress.Room): Promise<void> {
        this._room = room;
    }


    /**
     * Room에 입장하는 메서드 입니다. 3D 공간에서 구역이 바뀌고 Room이 바뀌고 난 다음 호출되어야 합니다.
     *
     *
     */
    public joinRoom(
        accountInfo,
        isConnectedRef,
        isDisconnectedRef,
        roomIndex,
        joinedRoom,
        setLocalParticipant,
        participantsRef,
        setParticipants,
        setFocusVonage,
        setFullScreenStatus,
        statusValueHandler,
        sendMsg,
        sendMsgWithParam,
        setAccountInfo,
        connectedUsers
    ): void {
        this._roomStorage[roomIndex]
            ?.join({
                //     targetElement: "media-container",
                publisherProperties: {
                    resolution: '1280x720',
                    publishAudio: true,
                    publishVideo: false,
                    mirror: false,
                    audioBitrate: 15,
                    audioFallbackEnabled: true,
                },
            })
            .then(() => {
                isConnectedRef.current[roomIndex] = false;

                if (isDisconnectedRef.current[roomIndex]) {
                    this.leaveRoom(
                        roomIndex,
                        setAccountInfo,
                        isDisconnectedRef,
                        joinedRoom,
                        setParticipants,
                        setLocalParticipant,
                        statusValueHandler,
                        participantsRef,
                    );

                    return;
                }

                const localParticipant = {
                    id: this._roomStorage[roomIndex]?.participantId,
                    nickname: accountInfo.nickname,
                    thumbnailUri: accountInfo.thumbnailUri,
                };

                setLocalParticipant(localParticipant);
                joinedRoom.current = this._roomStorage[roomIndex];

                this._room = this._roomStorage[roomIndex];

                setAccountInfo((prev: any) => ({
                    ...prev,
                    prevVonageParticipating: true,
                    vonageParticipating: true,
                }));
            })
            .catch(err => {
                console.log(err);
                this.leaveRoom(
                    roomIndex,
                    setAccountInfo,
                    isDisconnectedRef,
                    joinedRoom,
                    setParticipants,
                    setLocalParticipant,
                    statusValueHandler,
                    participantsRef,
                );
            });

        this._roomStorage[roomIndex]?.camera.disableVideo();
        this._roomStorage[roomIndex]?.camera.disableAudio();

        this._roomStorage[roomIndex]?.camera.on(
            'audioLevelUpdated',
            audioLevel => {
                const localParticipantEl: HTMLElement | null =
                    document.getElementById(
                        'PK_' + this._roomStorage[roomIndex]?.participantId,
                    );

                const imgTag =
                    localParticipantEl?.getElementsByTagName('img')[0];

                const micTag =
                    localParticipantEl?.getElementsByClassName('micStatus')[0];

                const cameraTag =
                    localParticipantEl?.getElementsByClassName(
                        'localFaceCam',
                    )[0];

                if (cameraTag != null) {
                    if (
                        !this._roomStorage[roomIndex]?.camera.isVideoEnabled()
                    ) {
                        cameraTag.classList.add('disabled');
                    }
                }

                const focusScreenShareEl: HTMLElement | null =
                    document.getElementById('PK_focusScreenShare');

                if (
                    focusScreenShareEl != null &&
                    focusScreenShareEl.firstChild != null
                ) {
                    if (focusScreenShareEl.firstChild.firstChild != null) {
                        const focus = focusScreenShareEl.firstChild
                            .firstChild as HTMLElement;
                        const focusScreenShareParticipantId =
                            focus.id.split('_')[2];

                        if (
                            focusScreenShareParticipantId ==
                            this._roomStorage[roomIndex]?.participantId
                        ) {
                            if (
                                !this._roomStorage[
                                    roomIndex
                                ]?.camera.isAudioEnabled()
                            ) {
                                const micTag = focusScreenShareEl.firstChild
                                    ?.lastChild as HTMLElement;
                                micTag.classList.add('disabled');
                            } else {
                                const micTag = focusScreenShareEl.firstChild
                                    ?.lastChild as HTMLElement;
                                micTag.classList.remove('disabled');
                            }
                        }
                    }
                }

                if (
                    !this._roomStorage[roomIndex]?.camera.isVideoEnabled() &&
                    !this._roomStorage[roomIndex]?.screen.isVideoEnabled()
                ) {
                    const focusScreenShareEl: HTMLElement | null =
                        document.getElementById('PK_focusScreenShare');

                    if (
                        focusScreenShareEl != null &&
                        focusScreenShareEl.firstChild &&
                        focusScreenShareEl.firstChild.firstChild != null
                    ) {
                        const foucs = focusScreenShareEl.firstChild
                            .firstChild as HTMLElement;
                        const focusScreenShareParticipantId =
                            foucs.id.split('_')[2];

                        if (
                            focusScreenShareParticipantId ==
                            this._roomStorage[roomIndex]?.participantId
                        ) {
                            setFullScreenStatus(false);
                            setFocusVonage(false);
                        }
                    }
                }

                if (imgTag != null && micTag != null) {
                    if (
                        !this._roomStorage[roomIndex]?.camera.isAudioEnabled()
                    ) {
                        if (micTag != null) {
                            micTag.classList.add('disabled');
                        }
                    } else {
                        micTag.classList.remove('disabled');
                    }

                    if (audioLevel > 0.05) {
                        imgTag.classList.add('active');
                        sendMsgWithParam('Webreceiver', 'OnVoiceSpeak', 0);
                    } else {
                        imgTag.classList.remove('active');
                        sendMsgWithParam('Webreceiver', 'OnVoiceSpeak', 1);
                    }
                }
            },
        );

        this._roomStorage[roomIndex]?.camera.on('destroyed', () => {
            statusValueHandler('micStatus', false);
            statusValueHandler('screenStatus', false);
            statusValueHandler('camStatus', false);
        });

        this._roomStorage[roomIndex]?.screen.on('started', () => {
            statusValueHandler('screenStatus', true);
        });

        this._roomStorage[roomIndex]?.screen.on('stopped', () => {
            statusValueHandler('screenStatus', false);
        });

        this._roomStorage[roomIndex]?.on('connected', () => {
            isConnectedRef.current[roomIndex] = false;
        });

        this._roomStorage[roomIndex]?.on('disconnected', reason => {
            this._roomStorage[roomIndex]?.leave();
            this._roomStorage[roomIndex] = null;
            joinedRoom.current = null;
            setLocalParticipant(null);
            setParticipants([]);
            statusValueHandler('micStatus', false);
            statusValueHandler('screenStatus', false);
            statusValueHandler('camStatus', false);
            isDisconnectedRef.current[roomIndex] = false;
            setAccountInfo((prev: any) => ({
                ...prev,
                vonageParticipating: false,
            }));
        });

        this._roomStorage[roomIndex]?.on(
            'activeSpeakerChanged',
            (participant: Participant) => {
                if (
                    participant != undefined &&
                    participant.camera != undefined
                ) {
                    if (participant.camera.isAudioEnabled()) {
                        // console.log('detect!');
                    }
                }
            },
        );

        function participantJoinedHandler(participant) {
            const nickNameTag = document.createElement('div');
            nickNameTag.id = participant.name + '_tag';

            participant.on('cameraCreated', async cameraSubscriber => {
                const userData = JSON.parse(
                    participant.camera._stream.connection.data,
                );


                const memberParticipant = connectedUsers.connectedMembers.filter(e => {
                    return e.sessionId == userData.sessionId;
                })

                const guestParticipant = connectedUsers.connectedGuests.filter(e => {
                    return e.sessionId == userData.sessionId;
                })

                const addParticipant = {
                    id: participant.id,
                    nickname: userData.nickname,
                    thumbnailUri: userData.thumbnailUri,
                    sessionId: userData.sessionId,
                    isAudioEnabled: false,
                };

                if(memberParticipant.length > 0) {
                    addParticipant.nickname = memberParticipant[0].detail.nickname;
                    addParticipant.thumbnailUri = memberParticipant[0].detail.thumbnailUri;
                }

                if(guestParticipant.length > 0) {
                    addParticipant.nickname = guestParticipant[0].detail.nickname;
                    addParticipant.thumbnailUri = guestParticipant[0].detail.thumbnailUri;
                }

                const findParticipant = participantsRef.current.find(e => {
                    return e.id == participant.id;
                });

                /**
                 * setParticipants로 참여자를 목록에 추가함
                 * participants 목록에 참여자가 추가될 때 새로 렌더링
                 */
                if (findParticipant == null) {
                    await setParticipants(prev => [...prev, addParticipant]);
                }

                participantsRef.current.push(addParticipant);

                //cameraSubscriber.id > OT_widget-container > video(OT_video-element)

                let videoEnabled = false;

                cameraSubscriber.on('audioLevelUpdated', audioLevel => {
                    if (cameraSubscriber.isVideoEnabled() && !videoEnabled) {
                        const targetEl = document.getElementById(
                            'PK_' + participant.id,
                        ) as HTMLDivElement | null;

                        if (targetEl != null) {
                            targetEl?.prepend(
                                cameraSubscriber.getSubscriberElement(),
                            );
                            targetEl?.classList.add('active');
                            videoEnabled = true;
                        }
                    }

                    if (!cameraSubscriber.isVideoEnabled() && videoEnabled) {
                        const targetEl = document.getElementById(
                            'PK_' + participant.id,
                        ) as HTMLDivElement | null;

                        if (targetEl != null) {
                            targetEl?.removeChild(
                                cameraSubscriber.getSubscriberElement(),
                            );
                            targetEl?.classList.remove('active');
                            videoEnabled = false;
                        }

                        const focusScreenShareEl: HTMLElement | null =
                            document.getElementById('PK_focusScreenShare');

                        if (
                            focusScreenShareEl != null &&
                            focusScreenShareEl.firstChild != null
                        ) {
                            if (
                                focusScreenShareEl.firstChild.firstChild != null
                            ) {
                                const focus = focusScreenShareEl.firstChild
                                    .firstChild as HTMLElement;
                                const focusScreenShareParticipantId =
                                    focus.id.split('_')[2];

                                if (
                                    focusScreenShareParticipantId ==
                                    participant.id
                                ) {
                                    if (!cameraSubscriber.isAudioEnabled()) {
                                        const micTag = focusScreenShareEl
                                            .firstChild
                                            ?.lastChild as HTMLElement;
                                        micTag.classList.add('disabled');
                                    } else {
                                        const micTag = focusScreenShareEl
                                            .firstChild
                                            ?.lastChild as HTMLElement;
                                        micTag.classList.remove('disabled');
                                    }
                                }
                            }
                        }
                    }

                    const participantEl: HTMLElement | null =
                        document.getElementById('PK_' + participant.id);

                    if (
                        participantEl != null &&
                        participantEl.firstChild != null &&
                        participantEl.firstChild.firstChild != null
                    ) {
                        const imgTag =
                            participantEl?.getElementsByTagName('img')[0];

                        const micTag =
                            participantEl?.getElementsByClassName(
                                'micStatus',
                            )[0];
                        if (!cameraSubscriber.isAudioEnabled()) {
                            if (micTag != null) {
                                micTag.classList.add('disabled');
                            }
                        } else {
                            micTag.classList.remove('disabled');
                        }

                        if (audioLevel > 0.05) {
                            imgTag.classList.add('active');
                        } else {
                            imgTag.classList.remove('active');
                        }
                    }

                    const focusScreenShareEl: HTMLElement | null =
                        document.getElementById('PK_focusScreenShare');

                    if (
                        focusScreenShareEl != null &&
                        focusScreenShareEl.firstChild != null
                    ) {
                        if (focusScreenShareEl.firstChild.firstChild != null) {
                            const focus = focusScreenShareEl.firstChild
                                .firstChild as HTMLElement;
                            const focusScreenShareParticipantId =
                                focus.id.split('_')[2];

                            if (
                                focusScreenShareParticipantId == participant.id
                            ) {
                                if (!cameraSubscriber.isAudioEnabled()) {
                                    const micTag = focusScreenShareEl.firstChild
                                        ?.lastChild as HTMLElement;
                                    micTag.classList.add('disabled');
                                } else {
                                    const micTag = focusScreenShareEl.firstChild
                                        ?.lastChild as HTMLElement;
                                    micTag.classList.remove('disabled');
                                }
                            }
                        }
                    }
                });
            });

            participant.on('cameraDestroyed', () => {
                const focusScreenShareEl: HTMLElement | null =
                    document.getElementById('PK_focusScreenShare');
                if (
                    focusScreenShareEl != null &&
                    focusScreenShareEl.firstChild &&
                    focusScreenShareEl.firstChild.firstChild != null
                ) {
                    const focus = focusScreenShareEl.firstChild
                        .firstChild as HTMLElement;
                    const focusScreenShareParticipantId =
                        focus.id.split('_')[2];
                    if (focusScreenShareParticipantId == participant.id) {
                        setFullScreenStatus(false);
                        setFocusVonage(false);
                    }
                }
            });

            participant.on('screenCreated', () => {
                const targetEl: HTMLElement | null = document.getElementById(
                    'PK_' + participant.id,
                );
                targetEl?.appendChild(
                    participant.screen.getSubscriberElement(),
                );
                targetEl?.classList.add('active');
            });

            participant.on('screenDestroyed', () => {
                const targetEl: HTMLElement | null = document.getElementById(
                    'PK_' + participant.id,
                );
                targetEl?.classList.remove('active');
                targetEl?.classList.remove('focused');

                const focusScreenShareEl: HTMLElement | null =
                    document.getElementById('PK_focusScreenShare');
                if (
                    focusScreenShareEl != null &&
                    focusScreenShareEl.firstChild &&
                    focusScreenShareEl.firstChild.firstChild != null
                ) {
                    const foucs = focusScreenShareEl.firstChild
                        .firstChild as HTMLElement;
                    const focusScreenShareParticipantId =
                        foucs.id.split('_')[2];

                    if (focusScreenShareParticipantId == participant.id) {
                        setFullScreenStatus(false);
                        setFocusVonage(false);
                    }
                }
            });

            participant.on('audioEnabled', () => {
                // console.log('!');
            });

            participant.on('audioDisabled', () => {
                // console.log('!');
            });
        }

        this._roomStorage[roomIndex]?.on(
            'participantJoined',
            participantJoinedHandler,
        );

        function participantLeftHandler(participant: any, thisRoom: any) {
            participantsRef.current = participantsRef.current.filter(
                (el: any) => {
                    return el.id != participant.id;
                },
            );

            // if (participantsRef.current.length <= 0) {
            //     sendMsg('Webreceiver', 'OnVonageExit');
            //     setLocalParticipant(null);
            //     setParticipants([]);
            //     participantsRef.current = [];
            //     thisRoom.leave();
            //     thisRoom = null;
            //     joinedRoom.current = null;
            //     setAccountInfo(prev => ({
            //         ...prev,
            //         vonageParticipating: false,
            //     }));
            //     return;
            // }

            setParticipants(prev =>
                prev.map(el => {
                    if (el.id == participant.id) {
                        return {...el, id: null};
                    } else {
                        return el;
                    }
                }),
            );
        }

        this._roomStorage[roomIndex]?.on('participantLeft', participant =>
            participantLeftHandler(participant, this._roomStorage[roomIndex]),
        );

        this._roomStorage[roomIndex]?.on(
            'activeSpeakerChanged',
            participant => {
                // console.log('active speaker changed !! ', participant);
            },
        );

        window.localStorage.setItem(
            'participantsList',
            JSON.stringify(this._roomStorage[roomIndex]?.participants),
        );

        return this._room;
    }

    /**
     * Room에서 퇴장하는 메서드 입니다. 3D 공간에서 구역을 벗어날 경우 호출되어야 합니다.
     */
    public leaveRoom(
        index,
        setAccountInfo,
        isDisconnectedRef,
        joinedRoom,
        setParticipants,
        setLocalParticipant,
        statusValueHandler,
        participantsRef,
    ): void {
        this._roomStorage[index]
            ?.leave()
            .then(e => {
                console.log('방 퇴장 : ', index);
                setAccountInfo(prev => ({
                    ...prev,
                    vonageParticipating: false,
                }));
                isDisconnectedRef.current[index] = false;
                this._roomStorage[index] = null;
                joinedRoom.current = null;
                participantsRef.current = [];
                setParticipants([]); // room 참여자 초기화
                setLocalParticipant(null); // 로컬참여자(본인) 초기화
                statusValueHandler('micStatus', false);
                statusValueHandler('screenStatus', false);
                statusValueHandler('camStatus', false);
            })
            .catch(err => {
                console.log(err);
            });

        this._roomStorage[index] = null;
    }

    /*
     * Room Event Related Method
     */
    /**
     * 화면공유를 시작하는 메서드입니다.
     * @returns (boolean) 화면공유가 제대로 실행되었는지 boolean으로 return 합니다.
     */
    public startScreenShare(targetElementId): boolean {
        if (this._room.screen.isVideoEnabled()) {
            return false;
        }

        this._room
            .startScreensharing(targetElementId)
            .then(() => {
                // console.log('completely shared screen');
            })
            .catch((err: any) => {
                // console.log(err);
            });

        return true;
    }

    /**
     * 화면공유를 정지하는 메서드 입니다.
     * @returns (boolean) 화면공유가 제대로 정지되었는지 boolean으로 return 합니다.
     */
    public stopScreenShare(): boolean {
        if (this._room.screen.isVideoEnabled()) {
            this._room.stopScreensharing();
            return true;
        } else {
            return false;
        }
    }

    /**
     * 화상공유를 시작하는 메서드 입니다.
     * @returns (boolean) 화상공유가 제대로 실행되었는지 boolean으로 return 합니다.
     */
    public startVideoShare(): boolean {
        // Uncaught TypeError: Cannot read properties of undefined (reading 'isVideoEnabled')
        if (!this._room.camera.isVideoEnabled()) {
            this._room.camera.enableVideo();
            setTimeout(() => {
                const videoTag = document.getElementById(
                    'localFaceCam',
                ) as HTMLMediaElement;

                const targetEl = document.querySelector(
                    '.OT_publisher',
                ) as HTMLElement;
                const videoEl = targetEl?.firstChild?.lastChild as any;

                const userMediaStream = new MediaStream(
                    videoEl?.captureStream().getVideoTracks(),
                );

                videoTag.srcObject = userMediaStream;
                videoTag.autoplay = true;
                const playerEl = document.getElementById(
                    'PK_' + this._room.participantId,
                ) as HTMLDivElement | null;

                if (playerEl != null) {
                    playerEl?.prepend(videoTag);
                    videoTag.classList.add('active');
                }
            }, 2000);

            return true;
        } else {
            return false;
        }
    }

    /**
     * 화상공유를 정지하는 메서드 입니다.
     * @returns (boolean) 화상공유가 제대로 정지되었는지 boolean으로 return 합니다.
     */
    public stopVideoShare(): boolean {
        if (this._room.camera.isVideoEnabled()) {
            this._room.camera.disableVideo();
            const videoTag = document.getElementById(
                'localFaceCam',
            ) as HTMLMediaElement;
            videoTag.srcObject = null;
            videoTag.classList.remove('active');
            return true;
        } else {
            return false;
        }
    }

    /**
     * 음성공유를 시작하는 메서드 입니다.
     * @returns (boolean) 음성공유가 제대로 실행되었는지 boolean으로 return 합니다.
     */
    public startAudioShare(): boolean {
        // Uncaught TypeError: Cannot read properties of undefined (reading 'isAudioEnabled')

        if (!this._room.camera.isAudioEnabled()) {
            // status 바꾼다?
            // elent 색을 바꾼다?
            this._room.camera.enableAudio();
            return true;
        } else {
            return false;
        }
    }

    /**
     * 음성공유를 정지하는 메서드 입니다.
     * @returns (boolean) 음성공유가 제대로 정지되었는지 boolean으로 return 합니다.
     */
    public stopAudioShare(): boolean {
        // Uncaught TypeError: Cannot read properties of undefined (reading 'isAudioEnabled')

        if (this._room.camera.isAudioEnabled()) {
            // customEvent audioDisabled this._room_.participant
            // 이벤트 fire
            // participant.isAudioEnabled = true
            this._room.camera.disableAudio();
            return true;
        } else {
            return false;
        }
    }

    private async saveUserData(data): Promise<void> {
        const roomDataArr: Array<string> = [];
        const roomData = window.localStorage.getItem('roomDetail');

        if (roomData !== null) {
            roomDataArr.push(data);
            window.localStorage.setItem(
                'roomDetail',
                JSON.stringify(roomDataArr),
            );
        } else {
            window.localStorage.setItem('roomDetail', JSON.stringify(data));
        }
    }

    public getDevices() {
        return VideoExpress.getDevices();
    }
}

/**
 * 세션을 생성하기 위한 임시 메서드 입니다.
 *
 * @param spaceName (string) 생성할 세션이 속할 스페이스 이름을 인자로 받습니다.
 */
export function createSession(spaceName: string): void {
    const data: object = {
        amount: 12,
    };
    const accessToken = window.localStorage.getItem('plikaTk');
    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
    };
    axios
        .post(
            process.env.REACT_APP_API_HOST +
                `api/v1/media/${spaceName}/0/create`,
            data,
            config,
        )
        .then(resp => {
            const sessionDetailList: string[] =
                resp.data.data.sessionDetailList;

            window.localStorage.setItem(
                'vonage_session_id',
                JSON.stringify(sessionDetailList),
            );
        })
        .catch(err => {
            // console.log(err);
        });
}
