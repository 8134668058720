import React from 'react'
import styled from 'styled-components'

interface ImageStyleProps {
    width?: string
    height?: string
    borderRadius?: string;    
}

export interface ImageProps extends ImageStyleProps {
    src: any
    alt: any
}

export const ImageComponent = styled.img<ImageStyleProps>(({width, height, borderRadius}) => ({
        width: width !== undefined ? width : `auto`,
        height: height !== undefined ? height : `auto`,
        borderRadius: borderRadius !== undefined ? borderRadius : `none`
}))

const Image: React.FC<ImageProps> = function ({src, alt, ...style}) {
    return <ImageComponent src={src} alt={alt} {...style} />
}

export default Image