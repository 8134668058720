import React from 'react';
import styled from 'styled-components';
import {CloseIcon} from '../../static/svg';

interface Props {
    children?: React.ReactNode;
    setIsModal?: any;
}

interface StyleProps {
    visible: boolean;
}

function ModalLayout({children, setIsModal}: Props) {
    return (
        <Block>
            <Content>{children}</Content>
            <Icon onClick={() => setIsModal(false)}>
                <CloseIcon />
            </Icon>
        </Block>
    );
}

const Block = styled.div`
    width: 340px;
    height: 450px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 22px;
    right: 22px;
    z-index: 99;
    padding: 15px 12px;

    @media (max-width: 1320px) {
        top: 10px;
        right: 10px;
    }

    @media (max-width: 1024px) {
        top: 222px;
    }

    @media (max-width: 500px) {
        top: 60px;
        right: inherit;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 20px);
        height: 450px;
    }
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    h3 {
        font-size: 24px;
        font-weight: 700;
    }

    h4 {
        font-size: 16px;
        font-weight: 500;
    }

    p {
        font-size: 12px;
    }
`;

const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    &:hover {
        background: #ccc;
    }

    svg {
        width: 30px;
        height: 30px;
    }
`;

export default ModalLayout;
