import React, { useEffect, useState, useLayoutEffect } from 'react';
import styles from '../../styles/spaceItem.module.scss'
import loading from '../../assets/ui/loading.png'
import logo from '../../assets/ui/plika-logo.png'
import users from '../../assets/ui/fi_users.png'
import uiStyle from '../../styles/ui.module.scss'

function Space(props) {

    const { index, space, arrayName, slideItemMouseOver, slideItemMouseLeave } = props;

    
    
      return (
        <>
            <div data-value={props.index} className={`${styles.main_list_li} slide-item`}  key={index} onPointerEnter={(e) => {slideItemMouseOver(e, index, arrayName)}} onPointerLeave={(e) => slideItemMouseLeave(e)}>
                <div className={styles.main_list_thumb}>
                    <img src={space.templateThumbnail != null ? space.templateThumbnail : loading} alt="" className={styles.main_list_bg}/>
                    {
                        space.logoUrl != null &&
                        <img src={space.logoUrl != null ? space.logoUrl : logo} alt="" className={styles.main_list_logo} />
                    }
                    
                    <div className={styles.main_list_users}>
                        <img src={users} alt="" />
                        <div><span>{space.currentJoinMemberTotal}</span>/{space.connectUserLimit}</div>
                    </div>
                    <div className={styles.main_list_name}>{space.name}</div>
                    <div className={styles.main_list_profile}>
                        <img src={space.thumbnailUri != null ? space.thumbnailUri : logo} alt="" />
                        <span>{space.nickname != null ? space.nickname : space.name}</span>
                    </div>
                </div>
            </div>  
        </>
      )
    
  
}

export default Space