import SvgImgEmotionFive from 'components/vectors/ImgEmotionFive'
import SvgImgEmotionFour from 'components/vectors/ImgEmotionFour'
import SvgImgEmotionOne from 'components/vectors/ImgEmotionOne'
import SvgImgEmotionTwo from 'components/vectors/ImgEmotionTwo'
import SvgImgGangnamInteraction from 'components/vectors/ImgGangnamInteraction'
import SvgImgGangnamMove from 'components/vectors/ImgGangnamMove'
import SvgImgGangnamRotation from 'components/vectors/ImgGangnamRotation'
import React, { useEffect, useState } from 'react'
import { CloseIcon } from 'static/svg'
import one from 'assets/img/img_map_one.png'
import two from 'assets/img/img_map_two.png'
import three from 'assets/img/img_map_three.png'
import SvgImgEmotionSix from 'components/vectors/ImgEmotionSix'
import SvgImgEmotionSeven from 'components/vectors/ImgEmotionSeven'
import SvgImgEmotionEight from 'components/vectors/ImgEmotionEight'
import SvgImgEmotionNine from 'components/vectors/ImgEmotionNine'

const controlMap = {
  3: {
    floor: '1F',
    image: one,
  },
  4: {
    floor: '2F',
    image: two,
  },
  5: {
    floor: '3F',
    image: three,
  },
}

export default function GangnamControl({ setState, sendMsg, type }) {
  return (
    <div className="max-w-lg sm:max-w-none absolute z-10 w-full h-full sm:w-max max-h-[50rem]  bg-white xl:h-max left-2/4 p-7 shadow-86 rounded-xl top-2/4 -translate-y-2/4 -translate-x-2/4">
      <CloseIcon
        className="absolute h-auto duration-500 cursor-pointer w-7 hover:text-slate-400 text-slate-900 top-3 right-3"
        onClick={() => {
          sendMsg('Webreceiver', 'ControlPopupClose')
          setState(false)
        }}
      />
      <div className="flex flex-col w-full h-full overflow-y-scroll scrollbar-hide xl:overflow-hidden xl:flex-row">
        <div className="xl:border-r xl:border-gray-200 xl:pr-7 xl:mr-7">
          <h3 className="mb-5 text-2xl font-bold">조작방법</h3>
          <div className="flex flex-col items-center w-full gap-2 sm:flex-row xl:gap-6">
            <SvgImgGangnamMove className="w-auto max-w-xs sm:h-28 xl:h-32 shrink-0" />
            <SvgImgGangnamRotation className="w-auto max-w-xs sm:h-28 xl:h-32 shrink-0" />
            <SvgImgGangnamInteraction className="w-auto max-w-xs sm:h-28 xl:h-32 shrink-0" />
          </div>
          <h3 className="mt-12 mb-5 text-2xl font-bold uppercase">tips</h3>
          <ul className="flex flex-col gap-3 text-xs">
            <li className="list-disc list-inside">
              방에 들어온 사람들끼리만 음성채팅/화상캠/화면공유를 사용할 수 있어요.
              <br />
              공용부에 있는 사람들은 위치에 상관없이 서로 소통할 수 있어요.
            </li>
            <li className="list-disc list-inside">엘리베이터는 가까이 가면 사용할 수 있어요.</li>
            <li className="list-disc list-inside">
              서로 다른 층에 있는 사람들은 소통할 수 없어요.
            </li>
            <li className="list-disc list-inside">
              화상캠을 사용할 수 없다면 접근기기에 화상캠이 연결되어 있는지 확인해주세요.
            </li>
          </ul>
          <h3 className="mt-12 mb-5 text-2xl font-bold uppercase">감정표현</h3>
          <div className="flex flex-col sm:flex-row">
            <div className="flex flex-col gap-4 shrink-0">
              <div className="flex items-center gap-5">
                <SvgImgEmotionOne className="w-auto h-7" />
                <p className="text-xs">손흔들며 인사</p>
              </div>
              <div className="flex items-center gap-5">
                <SvgImgEmotionTwo className="w-auto h-7" />
                <p className="text-xs">고개숙이며 인사</p>
              </div>
              <div className="flex items-center gap-5">
                <SvgImgEmotionFour className="w-auto h-7" />
                <p className="text-xs">춤추기</p>
              </div>
              <div className="flex items-center gap-5">
                <SvgImgEmotionFive className="w-auto h-7" />
                <p className="text-xs">춤추기2</p>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-4 sm:mt-0 sm:ml-16 shrink-0">
              <div className="flex items-center gap-5">
                <SvgImgEmotionSix className="w-auto h-7" />
                <p className="text-xs">손흔들며 인사</p>
              </div>
              <div className="flex items-center gap-5">
                <SvgImgEmotionSeven className="w-auto h-7" />
                <p className="text-xs">고개숙이며 인사</p>
              </div>
              <div className="flex items-center gap-5">
                <SvgImgEmotionEight className="w-auto h-7" />
                <p className="text-xs">춤추기</p>
              </div>
              <div className="flex items-center gap-5">
                <SvgImgEmotionNine className="w-auto h-7" />
                <p className="text-xs">춤추기2</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="mt-12 mb-5 text-2xl font-bold xl:mt-0">
            공간 안내({controlMap[type].floor} MAP)
          </h3>
          <div className="w-full xl:w-[32rem]">
            <img className="object-contain w-full h-full" src={controlMap[type].image} alt="Map" />
          </div>
        </div>
      </div>
    </div>
  )
}
