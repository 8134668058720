import React, {useEffect, useState, useLayoutEffect, useRef} from 'react';
import styles from '../../styles/spaceItem.module.scss';
import loading from '../../assets/ui/loading.png';
import logo from '../../assets/ui/plika-logo.png';
import users from '../../assets/ui/fi_users.png';
import uiStyle from '../../styles/ui.module.scss';
import linkIcon from '../../assets/ui/link_icon.png';

function SpaceEdit(props) {
    const {
        admin,
        index,
        space,
        arrayName,
        slideItemMouseOver,
        slideItemMouseLeave,
    } = props;

    const copyText = () => {
        navigator.clipboard.writeText(
            process.env.REACT_APP_HOST + 'app/' + space.idName,
        );
    };


    return (
        <>
            <div>
                <div
                    data-value={index}
                    className={`${styles.main_list_li} slide-item`}
                    key={index}
                >
                    <div
                        className={styles.main_list_thumb}
                        onPointerEnter={e => {
                            slideItemMouseOver(e, index, arrayName);
                        }}
                        onPointerLeave={e => slideItemMouseLeave(e)}
                    >
                        <img
                            src={
                                space.templateThumbnail != null
                                    ? space.templateThumbnail
                                    : loading
                            }
                            alt=""
                            className={styles.main_list_bg}
                        />
                        {space.logoUrl != null && (
                            <img
                                src={
                                    space.logoUrl != null
                                        ? space.logoUrl
                                        : logo
                                }
                                alt=""
                                className={styles.main_list_logo}
                            />
                        )}
                        <div className={styles.main_list_users}>
                            <img src={users} alt="" />
                            <div>
                                <span>{space.currentJoinMemberTotal}</span>/{space.connectUserLimit}
                            </div>
                        </div>
                        <div className={styles.main_list_name}>
                            {space.name}
                        </div>
                        <div className={styles.main_list_profile}>
                            <img
                                src={
                                    space.thumbnailUri != null
                                        ? space.thumbnailUri
                                        : logo
                                }
                                alt=""
                            />
                            <span>
                                {space.nickname != null
                                    ? space.nickname
                                    : space.name}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.edit_menu}>
                    <figure onClick={copyText}>
                        <img src={linkIcon} alt="" />
                    </figure>
                    <span>{`plika.land/app/${space.idName}`}</span>
                    {admin ? (
                        <button className={uiStyle.btn_theme_bl}>
                            <a href={`/mylands/edit/${space.idName}`}>Edit</a>
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
}

export default SpaceEdit;
