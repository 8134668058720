import * as React from 'react'
import { SVGProps } from 'react'
const SvgIconChevrondown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="icon_chevron_down_svg__w-6 icon_chevron_down_svg__h-6"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
  </svg>
)
export default SvgIconChevrondown
