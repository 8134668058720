import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { getSpaceInfo, useSettings } from './hooks/useSettings'
import { CloseIcon } from 'static/svg'
import Labels from './Labels'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

export default function Elevator({ setState, sendMsg, serviceName, spaceName, spaceId }) {
  // TODO: get current land data
  const [portalList, setPortalList] = useState<any>()
  const [portalTitle, setPortalTitle] = useState('')
  const [currentSpace, setCurrentSpace] = useState('')
  const [select, setSelect] = useState({
    portalLabel: '',
    portalName: '',
    accessUrl: '',
    portalTitle: '',
  })

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_HOST}space/current/${spaceId}/portals`).then((data) => {
      setPortalTitle(data.data.data.title)
      const filtered = data.data.data.portals.filter((portal: any) => {
        return portal.useLabel === true
      })
      setCurrentSpace(spaceName)
      setPortalList(filtered)
    })
  }, [])

  useEffect(() => {
    if (serviceName) {
      console.log('serviceName', serviceName)
      axios.get(`${process.env.REACT_APP_API_HOST}space/${serviceName}`).then((data: any) => {
        console.log('스페이스 이름', data)
        setCurrentSpace(data)
      })
    }
  }, [serviceName])

  useEffect(() => {
    console.log(portalList)
  }, [portalList])

  if (portalList) {
    return (
      <div className="absolute z-10 w-full mx-2 bg-white left-2/4 p-7 shadow-86 rounded-xl top-2/4 sm:max-w-md -translate-y-2/4 -translate-x-2/4">
        <CloseIcon
          className="absolute w-6 h-auto duration-500 cursor-pointer hover:text-slate-400 text-slate-900 top-3 right-3"
          onClick={() => {
            setState(false)
            sendMsg('Webreceiver', 'ContentSwitch', 1)
          }}
        />

        {select.portalLabel === '' ? (
          <Labels
            portalTitle={portalTitle}
            portalList={portalList}
            setState={setState}
            setSelect={setSelect}
            spaceName={currentSpace}
          />
        ) : (
          <>
            <h3 className="mb-12 text-xl font-semibold uppercase md:text-2xl text-slate-900">
              {select?.portalTitle}
            </h3>
            <p className="mb-40 font-medium text-slate-900">
              {select?.portalLabel} {select?.portalName}으로 이동하시겠어요?
            </p>
            <div className="flex items-center justify-between gap-4">
              <button
                type="button"
                className="inline-flex justify-center w-full px-12 py-3 font-semibold duration-500 bg-gray-300 border border-transparent rounded-full text-slate-900 shadow-86 sm:text-xl hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                onClick={() =>
                  setSelect((prev) => ({
                    ...prev,
                    portalLabel: '',
                    portalName: '',
                    accessUrl: '',
                    portalTitle: '',
                  }))
                }
              >
                Cancel
              </button>
              <button
                type="button"
                className="inline-flex justify-center w-full px-12 py-3 font-semibold duration-500 border border-transparent rounded-full text-slate-900 shadow-86 sm:text-xl bg-secondary-100 hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-yellow-200 focus:ring-offset-2"
                onClick={() => {
                  // TODO: 해당 스페이스로 이동
                  window.location.href = `/app/${select.accessUrl}`
                }}
              >
                OK
              </button>
            </div>
          </>
        )}
      </div>
    )
  } else {
    return <></>
  }
}
