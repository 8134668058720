import React from 'react'

import { Land as FeaturedLandType } from 'types'
import { useLand } from 'contexts/LandContext'
import { useModalMode } from 'contexts/ModalModeContext'

import LandUserBadge from './LandUserBadge'

interface FeaturedLandProps {
  landData: FeaturedLandType
}

export default function FeaturedLandCard({ landData }: FeaturedLandProps) {
  const { updateId } = useLand()

  const { toggleModalMode } = useModalMode()
  return (
    <>
      <div
        className="relative duration-500 cursor-pointer max-auto hover:-translate-y-1 rounded-3xl"
        onClick={() => {
          updateId(landData.spaceId)
          toggleModalMode('landDetail', true)
        }}
      >
        <div className="relative w-full overflow-hidden rounded-md shadow-86 aspect-video">
          <LandUserBadge
            currentUsers={landData.currentJoinMemberTotal}
            maxUsers={landData.connectUserLimit}
          />
          <img
            className="object-cover w-full h-full"
            src={landData.templateThumbnail}
            alt="thumbnail"
          />
        </div>
        <div className="px-1 py-1 sm:py-2 md:px-2">
          <h3 className="sm:mb-[.125rem] md:mb-1 text-sm sm:text-base font-semibold md:text-xl text-slate-900 line-clamp-1">
            {landData.name}
          </h3>
          <div className="flex items-center gap-2">
            <div className="hidden w-6 h-6 overflow-hidden rounded-full sm:inline-block shadow-86 shrink-0">
              <img
                className="object-cover w-full h-full"
                src={landData.thumbnailUri}
                alt="avatar"
              />
            </div>
            <p
              className="text-[0.825rem] sm:text-[0.9375rem] md:text-base text-slate-600
             line-clamp-1"
            >
              {landData.nickname}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
