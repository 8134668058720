import React from 'react';
import '../styles/SketchFabViewer.scss';

function SketchFabTestPage() {
    return (
        <>
            <div className="sketchfab-embed-wrapper">
                <iframe
                    id="viewer-container"
                    title="Horyong ix17e"
                    frameBorder="0"
                    allowFullScreen
                    // mozallowfullscreen="true"
                    // webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    // xr-spatial-tracking
                    // execution-while-out-of-viewport
                    // execution-while-not-rendered
                    // web-share
                    src="https://sketchfab.com/models/4b37b1f4213144e7948430fe8b7cd45c/embed"
                ></iframe>
            </div>
        </>
    );
}

export default SketchFabTestPage;
