import React from 'react';
import styled from 'styled-components';
import Image from 'components/common/atoms/Image';
import Text from 'components/common/atoms/Text';
import media from '../../../lib/styles/media';

interface ButtonItemProps {
    title: string;
    onClickEvent?: any;
    icon: any;
}

const ButtonItemComponent = styled.div`
    padding-left: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    text-align: left;
    box-sizing: border-box;
    gap: 10px;

    &:hover {
        background-color: #fee500;
        cursor: pointer;
    }

    p {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ${media.medium} {
        height: 34px;
    }
`;

const ButtonItem: React.FC<ButtonItemProps> = function ({
    title,
    onClickEvent,
    icon,
}) {
    return (
        <ButtonItemComponent onClick={onClickEvent}>
            <Image
                src={icon}
                width="20px"
                height="20px"
                alt="Button Item Icon"
            />
            <p>{title}</p>
        </ButtonItemComponent>
    );
};

export default ButtonItem;
