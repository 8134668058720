import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import styles from '../styles/landcreate.module.scss';
import uiStyle from '../styles/ui.module.scss';

import axiosI from '../utils/AxiosI';
import {urlPatternValidation} from '../utils/UrlValidation';

import Rectangle from '../assets/ui/Rectangle.png';
import logo from '../assets/ui/plika-logo.png';
import Footer from '../components/tile/Footer';
import Header from '../components/tile/Header';
import InputWithTitle from '../components/ui/InputWithTitle';
import TextareaWithTitle from '../components/ui/TextareaWithTitle';
import ToggleWithTitle from '../components/ui/ToggleWithTitle';
import LandTemplate from '../components/modals/LandTemplate';
import LandDeleteAlert from '../components/modals/LandDeleteAlert';
import LandChangeAlert from '../components/modals/LandChangeAlert';
import Text from 'components/atoms/Text';
import ToggleWithLabel from '../components/ui/ToggleWithLabel';

function EditLand() {
    const {idName} = useParams();

    const [accountInfo, setAccountInfo]: any = useState();

    const [spaceInfo, setSpaceInfo]: any = useState();

    useEffect(() => {
        if (spaceInfo != null) {
            setInputs(prev => ({
                ...prev,
                name: spaceInfo.name,
                url: spaceInfo.companyUrl != null ? spaceInfo.companyUrl : '',
                description:
                    spaceInfo.description != null ? spaceInfo.description : '',
                isPrivate: spaceInfo.isPrivate,
                isAccess: spaceInfo.isAccess,
                password: spaceInfo.password,
                templateId:
                    spaceInfo.sceneInfoDtos[0].templateInfoDto.templateId,
            }));

            setToggle(prev => ({
                ...prev,
                isAccess: !spaceInfo.isAccess,
                isPrivate: spaceInfo.isPrivate,
            }));
        }
    }, [spaceInfo]);

    const [template, setTemplate]: any = useState(null);

    function setTemplateHandler(template: any) {
        setTemplate(template);
        setInValidValueCheck((prev: any) => ({
            ...prev,
            template: false,
        }));
        setPopup(false);
        document.body.style.overflow = 'unset';
    }

    const [popup, setPopup] = useState(false);

    function templatePopup() {
        setPopup(() => !popup);
    }

    function templatePopupClose() {
        setPopup(false);
    }

    useEffect(() => {
        if (accountInfo != null) {
            if (accountInfo.accountId == null) {
                window.location.replace('/');
            } else {
                axios
                    .get(process.env.REACT_APP_API_HOST + `land/info/${idName}`)
                    .then(e => {
                        setSpaceInfo(e.data.data);
                    })
                    .catch(err => {
                        window.history.back();
                    });
            }
        }
    }, [accountInfo]);

    function setAccountInfoState(account: any) {
        setAccountInfo(account);
    }

    const [files, setFiles]: any = useState();

    const imageRef: any = useRef();

    const fileHandler = (e: any) => {
        if (e.target.files != null && e.target.files.length > 0) {
            setFiles(e.target.files);
        }
    };

    useEffect(() => {
        if (files != null) {
            imageRef.current.setAttribute('src', URL.createObjectURL(files[0]));
        } else {
            imageRef.current.setAttribute('src', '');
        }
    }, [files]);

    function fileDeleteHandler() {
        setFiles(null);
        deletedFile.current = true;
    }

    const [inputs, setInputs] = useState({
        name: '',
        url: '',
        description: '',
        isAccess: false,
        isPrivate: false,
        password: '',
        templateId: '',
    });

    const {name, url, description, isPrivate, isAccess, password, templateId} =
        inputs;

    const inputValueHandler = (e: any) => {
        const {value, name} = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });

        setInValidValueCheck((prev: any) => ({
            ...prev,
            [name]: null,
        }));

        if (name == 'password') {
            if (value.length > 16) {
                setInValidPasswords((prev: any) => ({
                    ...prev,
                    password: true,
                }));
            } else if (value.length >= 0) {
                setInValidPasswords((prev: any) => ({
                    ...prev,
                    password: false,
                }));
            }
        }

        if (name == 'insidePassword') {
            if (value.length > 16) {
                setInValidPasswords((prev: any) => ({
                    ...prev,
                    insidePassword: true,
                }));
            } else if (value.length >= 0) {
                setInValidPasswords((prev: any) => ({
                    ...prev,
                    insidePassword: false,
                }));
            }
        }
    };

    const [inValidValueCheck, setInValidValueCheck]: any = useState({
        name: null,
        url: null,
        password: null,
        template: null,
    });

    const [titleErrorType, setTitleErrorType] = useState<string | null>(null);

    const [inValidPasswords, setInValidPasswords]: any = useState({
        password: null,
    });

    const deletedFile = useRef<boolean>(false);

    async function updateLand(e: any) {
        e.preventDefault();

        const {id, name, url, description, isAccess, password, isPrivate} =
            e.target;

        if (url.value.length > 0) {
            if (!urlPatternValidation(url.value)) {
                setInValidValueCheck((prev: any) => ({
                    ...prev,
                    url: true,
                }));
                closeSavePopup();
                return;
            }

            if (!url.value.startsWith('http')) {
                url.value = 'https://' + url.value;
            }
        }

        if (
            name.value == null ||
            name.value.length <= 0 ||
            (isAccess.checked && password.value.length <= 0)
        ) {
            if (name.value.length <= 0) {
                setInValidValueCheck((prev: any) => ({
                    ...prev,
                    name: true,
                }));
                setTitleErrorType('Title을 입력해 주세요');
            }

            if (description.value.length <= 0) {
                setInValidValueCheck((prev: any) => ({
                    ...prev,
                    description: true,
                }));
            }

            if (isAccess.checked && password.value.length <= 0) {
                setInValidValueCheck((prev: any) => ({
                    ...prev,
                    password: true,
                }));
            }

            closeSavePopup();
            return;
        }

        const formData = new FormData();

        const data = {
            id: id.value,
            spaceName: name.value,
            spaceDescription: description.value,
            companyUrl: url.value,
            isAccess: isAccess.checked,
            password: password != null ? password.value : undefined,
            templateId: template != null ? template.templateId : null,
            isDeletedLogo: deletedFile.current,
            isPrivate: isPrivate.checked,
        };

        if (files !== undefined && files != null && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('logoImage', files[i]);
            }
        }

        formData.append(
            'updateSpaceRequest',
            new Blob([JSON.stringify(data)], {type: 'application/json'}),
        );

        const requestUri = process.env.REACT_APP_API_HOST + `space`;

        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        if (
            accessToken != null &&
            accessToken != 'null' &&
            accessToken.length > 0
        ) {
            await axiosI
                .put(requestUri, formData, config)
                .then(resp => {
                    // UNITY 호출 로직
                    if (resp.data.resp_code === 'SUCCESS') {
                        closeSavePopup();
                        window.location.replace('/mylands');
                    }
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        if (err.response.data.err_desc.indexOf('1001') != -1) {
                            setInValidValueCheck((prev: any) => ({
                                ...prev,
                                name: true,
                            }));
                            setTitleErrorType('이미 존재하는 이름입니다');
                        } else if (
                            err.response.data.err_desc.indexOf('1002') != -1
                        ) {
                            // 팀 이름을 입력하지 않음
                        } else if (
                            err.response.data.err_desc.indexOf('1003') != -1
                        ) {
                            // 올바른 url 형태가 아님
                        }
                    }
                    closeSavePopup();
                    //window.location.reload();
                });
        }
    }

    const [delpopup, setDelPopup] = useState(false);

    function closeDelPopup() {
        document.body.style.overflow = 'unset';
        setDelPopup(() => !delpopup);
    }

    const [savePopup, setSavePopup] = useState(false);

    function closeSavePopup() {
        document.body.style.overflow = 'unset';
        setSavePopup(() => !savePopup);
    }

    const [toggle, setToggle] = useState({
        isAccess: false,
        isPrivate: false,
    });

    const toggleChange = (e: any) => {
        const {name} = e.target;
        setToggle((prev: any) => ({
            ...prev,
            [name]: !prev[name],
        }));
    };

    interface TextWrapProps {
        toggle: any;
    }

    const TextWrap = styled.div<TextWrapProps>`
        visibility: ${({toggle}: any) => (toggle ? 'visible' : 'hidden')};
    `;

    function deleteSpace() {
        const accessToken = window.localStorage.getItem('plikaTk');
        const config = {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
        const data = {
            id: spaceInfo.id,
        };
        if (
            accessToken != null &&
            accessToken != 'null' &&
            accessToken.length > 0
        ) {
            axiosI
                .put(
                    process.env.REACT_APP_API_HOST + 'v1/metaverse/delete',
                    data,
                    config,
                )
                .then(e => {
                    window.location.replace('/mylands');
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    }

    return (
        <>
            <Header
                show={null}
                setAccountInfoState={setAccountInfoState}
            ></Header>
            <div className={styles.land_create_container}>
                <div className={styles.container_inner}>
                    <div className={styles.header}>
                        <p>Edit LAND</p>
                    </div>
                    <div className={styles.main}>
                        <form
                            onSubmit={e => {
                                updateLand(e);
                            }}
                        >
                            <input
                                type="hidden"
                                name={'id'}
                                value={spaceInfo != null && spaceInfo.id}
                            />
                            <div className={styles.box_thumb}>
                                <img
                                    className={styles.thumb_bg}
                                    src={
                                        template == null
                                            ? spaceInfo != null
                                                ? spaceInfo.sceneInfoDtos[0]
                                                      .templateInfoDto.thumbnail
                                                : Rectangle
                                            : template.thumbnail
                                    }
                                    alt=""
                                />
                                <div className={styles.box_logo}>
                                    <label
                                        htmlFor="att-file"
                                        className={`${styles.logo}`}
                                    >
                                        <figure>
                                            <img
                                                ref={imageRef}
                                                src={
                                                    spaceInfo != null &&
                                                    spaceInfo.logoUrl != null
                                                        ? spaceInfo.logoUrl
                                                        : logo
                                                }
                                                alt=""
                                            />
                                        </figure>
                                        <div
                                            className={`${styles.icon} ${styles.att}`}
                                        ></div>
                                        <span className={styles.logo_text}>
                                            Upload logo
                                        </span>
                                    </label>
                                    <input
                                        type="file"
                                        id="att-file"
                                        className="blind"
                                        accept="image/*"
                                        onChange={fileHandler}
                                    />
                                    <button
                                        type="button"
                                        className={styles.delete}
                                        onClick={fileDeleteHandler}
                                    ></button>
                                </div>
                                {spaceInfo != null &&
                                    !spaceInfo.sceneInfoDtos[0].templateInfoDto
                                        .isCustom && (
                                        <>
                                            <button
                                                type="button"
                                                className={
                                                    inValidValueCheck.template
                                                        ? `${uiStyle.btn_theme_red} ${styles.sel_btn}`
                                                        : `${uiStyle.btn_theme_bl} ${styles.sel_btn}`
                                                }
                                                onClick={templatePopup}
                                            >
                                                Select LAND Template
                                            </button>
                                            {inValidValueCheck.template && (
                                                <ErrorMessage>
                                                    랜드 템플릿을 선택해 주세요
                                                </ErrorMessage>
                                            )}
                                        </>
                                    )}

                                <div className={styles.text_wrapper}>
                                    <div className={styles.text_team}>
                                        {spaceInfo != null
                                            ? spaceInfo.name
                                            : ''}
                                    </div>
                                    <div className={styles.profile_box}>
                                        <figure>
                                            <img
                                                src={
                                                    accountInfo != null
                                                        ? accountInfo.thumbnailUri
                                                        : logo
                                                }
                                                alt=""
                                            />
                                        </figure>
                                        <span>
                                            {accountInfo != null
                                                ? accountInfo.nickname
                                                : logo}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.input_wrapper}>
                                <div className={styles.input_item}>
                                    <InputWithTitle
                                        title={'Title*'}
                                        name={'name'}
                                        onChange={inputValueHandler}
                                        alert={inValidValueCheck.name}
                                        placeholder="LAND..."
                                        helpType="title"
                                        errorMessage={titleErrorType}
                                        value={name}
                                    ></InputWithTitle>
                                </div>
                                <div className={styles.input_item}>
                                    <InputWithTitle
                                        title={'WebSite'}
                                        name={'url'}
                                        onChange={inputValueHandler}
                                        alert={inValidValueCheck.url}
                                        placeholder="www..."
                                        helpType="website"
                                        errorMessage="잘못된 URL 주소에요"
                                        value={url}
                                    ></InputWithTitle>
                                </div>
                                <div className={styles.input_item}>
                                    <TextareaWithTitle
                                        title={'Introduction'}
                                        name={'description'}
                                        maxlength={200}
                                        onChange={inputValueHandler}
                                        placeholder="예: 팀 커뮤니케이션이 가능한 3차원 오피스를 만듭니다.&#13;&#10;최대 200자"
                                        helpType="introduction"
                                        value={description}
                                    ></TextareaWithTitle>
                                </div>
                                <div className={styles.input_item}>
                                    <ToggleWithTitle
                                        toggle={toggle['isAccess']}
                                        toggleChange={toggleChange}
                                        title={'Set URL-Access Password'}
                                        toggleName={'isAccess'}
                                        name={'password'}
                                        onChange={inputValueHandler}
                                        helpType="password"
                                        id="passwordId"
                                        alert={inValidValueCheck['password']}
                                        value={password}
                                    ></ToggleWithTitle>
                                    <TextWrap
                                        toggle={inValidPasswords.password}
                                    >
                                        <Text
                                            size={1.4}
                                            weight={400}
                                            color="#EE3030"
                                        >
                                            16자 이내로 적어주세요
                                        </Text>
                                    </TextWrap>
                                    <div className={styles.input_item}>
                                        <ToggleWithLabel
                                            id="private"
                                            toggle={toggle['isPrivate']}
                                            toggleChange={toggleChange}
                                            title={'Set Private'}
                                            toggleName={'isPrivate'}
                                            name={'isPrivate'}
                                            helpType="private"
                                        ></ToggleWithLabel>
                                    </div>
                                </div>
                                <div className={styles.submit_btn}>
                                    <button
                                        type="button"
                                        onClick={() => setDelPopup(true)}
                                        className={`${uiStyle.btn_theme_red} ${styles.submit_btn}`}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setSavePopup(true)}
                                        className={`${uiStyle.btn_theme_yel} ${styles.submit_btn}`}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            {delpopup ? (
                                <LandDeleteAlert
                                    onClose={closeDelPopup}
                                    onClickEvent={deleteSpace}
                                ></LandDeleteAlert>
                            ) : (
                                <></>
                            )}
                            {savePopup ? (
                                <LandChangeAlert
                                    onClose={closeSavePopup}
                                ></LandChangeAlert>
                            ) : (
                                <></>
                            )}
                        </form>
                    </div>
                </div>
            </div>

            {popup ? (
                <LandTemplate
                    setTemplateHandler={setTemplateHandler}
                    onClose={templatePopupClose}
                ></LandTemplate>
            ) : (
                <></>
            )}

            <Footer></Footer>
        </>
    );
}

const ErrorMessage = styled.p`
    position: absolute;
    top: calc(50% + 40px);
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ee3030;
`;

export default EditLand;
