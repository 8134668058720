import * as React from 'react'
import { SVGProps } from 'react'
const SvgImgEmotionSix = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 86 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.36 19.418h1.246v-3.024h2.842v-1.176h-2.842v-3.024H47.36v3.024h-2.828v1.176h2.828v3.024Z"
      fill="#000"
    />
    <rect x={1} y={1} width={38} height={28} rx={14} fill="#fff" stroke="#000" strokeWidth={2} />
    <rect x={57} y={1} width={28} height={28} rx={14} fill="#fff" stroke="#000" strokeWidth={2} />
    <path
      d="M10 21h1.652l1.666-5.586c.35-1.12.658-2.268.966-3.43h.056c.322 1.148.63 2.31.98 3.43L16.972 21h1.722l-3.402-10.304h-1.89L10 21Zm1.918-2.926h4.816V16.8h-4.816v1.274Zm9.605 3.122c.406 0 .658-.07.868-.14l-.21-1.218c-.14.028-.196.028-.266.028-.196 0-.378-.154-.378-.588V9.856h-1.624v9.338c0 1.246.434 2.002 1.61 2.002Zm5.15 0c.574 0 1.092-.154 1.526-.294l-.294-1.19c-.238.098-.56.196-.826.196-.798 0-1.134-.49-1.134-1.4v-3.92h2.016V13.3h-2.016v-2.128H24.6l-.182 2.128-1.204.084v1.204h1.12v3.906c0 1.61.616 2.702 2.338 2.702ZM71.298 21.196c1.666 0 3.08-1.344 3.08-3.388 0-2.184-1.176-3.234-2.912-3.234-.77 0-1.722.476-2.324 1.288l.07 1.176c.616-.938 1.358-1.302 1.974-1.302 1.092 0 1.694.742 1.694 2.072s-.7 2.142-1.596 2.142c-1.26 0-2.044-1.246-2.044-3.906 0-3.15 1.106-4.214 2.478-4.214.602 0 1.232.322 1.61.77l.882-.98c-.588-.63-1.428-1.106-2.576-1.106-2.044 0-3.892 1.582-3.892 5.53 0 3.5 1.596 5.152 3.556 5.152Z"
      fill="#000"
    />
  </svg>
)
export default SvgImgEmotionSix
