import { DefaultTheme } from 'styled-components';

export const size = {
  pc: '75em', // 1200px
  tab: '56.25em', // 900px
  mobile: '31.25em', // 500px
  mobileS: '23.125em', // 370px
};

const theme: DefaultTheme = {
  color: {
      black: '#000000',
      gray: '#8E8E8E',
      lightGray: '#E5E5E5',
    blue: '#1e69cb',
    lightBlue: '#74B0FF',
    yellow: '#FEE500',
    lightYellow: '#FFF280',
    red: '#EE3030',
    lightRed: '#EE7070'
  },

  window: {
    pc: `@media screen and (max-width: ${size.pc})`,
    tab: `@media screen and (max-width: ${size.tab})`,
    mobile: `@media screen and (max-width: ${size.mobile})`,
    mobileS: `@media screen and (max-width: ${size.mobileS})`,
  },
};

export default theme;