import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';

import Text, {TextComponent} from 'components/common/atoms/Text';
import Button, {ButtonComponent} from 'components/atoms/Button';
import {t} from 'i18next';
import {useTranslation} from 'react-i18next';

const Container = styled.form`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    height: 370px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    ${ButtonComponent} {
        width: 140px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Input = styled.input`
    width: 300px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px 18px;
`;

const TitleWrap = styled.div`
    padding-bottom: 10px;
`;

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 34px;
`;

const InputWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: end;
`;

interface ErrorProps {
    isValid: any;
}

const Error = styled(Text)<ErrorProps>`
    visibility: ${({isValid}: any) => (isValid ? 'hidden' : 'visible')};
`;

const PasswordSetting = ({spaceName, accessPasswordCheck}: any) => {
    const [isValid, setIsValid] = useState(true);
    const InputRef = useRef<HTMLInputElement | any>();

    function passwordSubmit(e: any) {
        e.preventDefault();

        const {password} = e.target;
        const isValid = accessPasswordCheck(password.value);
        if (isValid) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    useEffect(() => {
        if (InputRef.current != null) {
            InputRef.current.focus();
        }
    }, [InputRef.current]);

    const {t} = useTranslation();

    return (
        <Container onSubmit={e => passwordSubmit(e)}>
            <ContentWrap>
                <TitleWrap>
                    <Text size={24} weight={700}>
                        {spaceName}
                    </Text>
                </TitleWrap>
                <Text size={16} weight={700}>
                    {t('password_set.text1')}
                </Text>
                <InputWrap>
                    <Text size={16} weight={700}>
                        Password
                    </Text>

                    <Input
                        ref={InputRef}
                        name={'password'}
                        placeholder="Password"
                        size={14}
                    />

                    <Error
                        size={12}
                        weight={400}
                        color="#EE3030"
                        isValid={isValid}
                    >
                        {t('password_set.error1')}
                    </Error>
                </InputWrap>
                <ButtonWrap>
                    <Button
                        type="submit"
                        bgColor="#FEE500"
                        hoveredBgColor="#FFF280"
                    >
                        <Text size={20} weight={700}>
                            OK
                        </Text>
                    </Button>
                </ButtonWrap>
            </ContentWrap>
        </Container>
    );
};

export default PasswordSetting;
