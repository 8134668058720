import { useQuery, useQueryClient } from '@tanstack/react-query'

import { axiosInstance } from 'axiosInstance'
import { featuredKeys, spaceKeys } from 'react-query/constants'
import { Land } from 'types'

async function getFeaturedLands(): Promise<Land[]> {
  const { data } = await axiosInstance.get('/api/metaverse/lands/by/expsr')
  return data.data.featuredLand
}

export function useFeaturedLands() {
  const queryClient = useQueryClient()

  const fallback: Land[] = []
  const { data: featuredLands = fallback } = useQuery({
    queryKey: featuredKeys.lists(),
    queryFn: getFeaturedLands,
    onSuccess: (data) => {
      data.map((land: Land) => {
        queryClient.setQueryData(spaceKeys.detail(land.spaceId), land)
      })
    },
  })

  return { featuredLands }
}
