import React from 'react';
import styled, {keyframes, css} from 'styled-components';
import {
    HandWavingIcon,
    HeartIcon,
    MotionFirstIcon,
    MotionSecondIcon,
    MotionThirdIcon,
    ThumbsUpIcon,
} from '../../static/svg';

const PositionCircle = (
    {onActionItem, actionStatus, setStatus}: any,
    ref: any,
) => {
    return (
        <Circle ref={ref} actionStatus={actionStatus}>
            <FirstBlock>
                <ActionItem
                    onClick={() => {
                        onActionItem(5);
                        setStatus('actionStatus', false);
                    }}
                >
                    <Heart />
                </ActionItem>
                <ActionItem
                    onClick={() => {
                        onActionItem(2);
                        setStatus('actionStatus', false);
                    }}
                >
                    <MotionThird />
                </ActionItem>
            </FirstBlock>

            <SecondBlock>
                <ActionItem
                    onClick={() => {
                        onActionItem(4);
                        setStatus('actionStatus', false);
                    }}
                >
                    <ThumbsUp />
                </ActionItem>
                <ActionItem>
                    <MotionSecond
                        onClick={() => {
                            onActionItem(1);
                            setStatus('actionStatus', false);
                        }}
                    />
                </ActionItem>
            </SecondBlock>

            <ThirdBlock>
                <ActionItem>
                    <MotionFirst
                        onClick={() => {
                            onActionItem(0);
                            setStatus('actionStatus', false);
                        }}
                    />
                </ActionItem>
                <ActionItem
                    onClick={() => {
                        onActionItem(3);
                        setStatus('actionStatus', false);
                    }}
                >
                    <HandWaving />
                </ActionItem>
            </ThirdBlock>
        </Circle>
    );
};

interface CircleProps {
    actionStatus: any;
}

const Circle = styled.div<CircleProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 360px;
    height: 360px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    transition: all 0.6s;
    z-index: 99;
    transform: translate(-50%, -50%)
        ${props => (props.actionStatus === true ? `scale(1)` : `scale(0)`)};
`;

const FirstBlock = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(40deg);
    height: 116%;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const SecondBlock = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    height: 116%;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ThirdBlock = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-40deg);
    height: 116%;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ActionItem = styled.div`
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        transform: scale(1.2);
    }
`;

const Heart = styled(HeartIcon)`
    width: 36px;
    transform: rotate(-40deg);
`;

const MotionThird = styled(MotionThirdIcon)`
    width: 28px;
    transform: rotate(-40deg);
`;

const MotionSecond = styled(MotionSecondIcon)`
    width: 24px;
    transform: rotate(-90deg);
`;

const ThumbsUp = styled(ThumbsUpIcon)`
    width: 36px;
    transform: rotate(-90deg);
`;

const MotionFirst = styled(MotionFirstIcon)`
    width: 25px;
    transform: rotate(40deg);
`;

const HandWaving = styled(HandWavingIcon)`
    width: 36px;
    transform: rotate(40deg);
`;

export default React.forwardRef(PositionCircle);
