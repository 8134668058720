import { useQuery, useQueryClient } from '@tanstack/react-query'

import { axiosInstance } from 'axiosInstance'
import { realTimeKeys, spaceKeys } from 'react-query/constants'
import { Land } from 'types'

async function getRealTimeLands(): Promise<Land[]> {
  const { data } = await axiosInstance.get('api/metaverse/lands/ranks')
  return data.data.landsOrderByCurrentJoinMemberTotal
}

export function useRealTimeLands() {
  const queryClient = useQueryClient()

  const fallback: Land[] = []
  const { data: realTimeLands = fallback } = useQuery({
    queryKey: realTimeKeys.lists(),
    queryFn: getRealTimeLands,
    onSuccess: (data) => {
      data.map((land: Land) => {
        queryClient.setQueryData(spaceKeys.detail(land.spaceId), land)
      })
    },
  })

  return { realTimeLands }
}
