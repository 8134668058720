import { useNavigate } from 'react-router-dom'
import { Land } from 'types'

interface UseSpace {
  enter: (landData: Land) => Promise<void>
}

export function useSpace(): UseSpace {
  const navigate = useNavigate()
  //
  async function enter(landData: Land) {
    if (landData.currentJoinMemberTotal >= landData.connectUserLimit) {
      // TODO: show error message
    } else {
      window.location.href = `/app/${landData.idName}`
    }
  }

  return {
    enter,
  }
}
