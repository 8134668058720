import React from "react";
import Footer from "../components/tile/Footer";
import Header from "../components/tile/Header";
import styles from '../styles/terms.module.scss'

function Terms () {

    return (
        <>
        <Header show={null}></Header>
            <div className={styles.terms_container}>
                <div className={styles.container_inner}>
                    <div className={styles.terms_header}>
                        <p>플리카랜드 이용약관</p>
                    </div>
                    <div className={styles.terms_box}>
                        <p>
                        제 1 조 (목적)
                        </p>
                        <p>
                        제 1 조 (목적) 이 약관은 주식회사 플리카에서 제공하는 서비스 및 제반서비스의 이용과 관련하여 플리카와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.
                        </p>
                        
                        <br />
                        <p>
                        제 2 조 정의)
                        </p>
                        <p>
                        이 약관에서 사용하는 용어의 정의는 다음과 같습니다.            
                        </p>
                        <br/>
                        <p>
                        - “이용자”라 함은 “plika.land”의 사이트에 접속하여 이 약관에 따라 “plika.land”가 제공하는 &quot;서비스&quot; 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.
                        </p>
                        <p>
                        - “회원&quot;이라 함은 회사의 &quot;서비스&quot;에 접속하여 이 약관에 따라 &quot;회사&quot;와 이용계약을 체결하고 &quot;회사&quot;가 제공하는 &quot;서비스&quot;를 이용하는 고객을 말합니다.
                        </p>
                        <p>
                        - “아이디&quot;이라 함은 &quot;회원&quot;의 식별과 &quot;서비스&quot; 이용을 위하여 &quot;회원&quot;이 정하고 &quot;회사&quot;가 승인하는 문자와 숫자의 조합을 의미합니다. 
                        </p>
                        <p>
                        - “서비스”라 함은 “plika.land”가 “이용자”에게 전자적으로 제공하는 용역 및 온라인 서비스를 의미합니다. “서비스”는“plika.land”가 제공하는 “콘텐츠”를 포함하는 개념이며, 본 계약에서는 “서비스 및 콘텐츠”로 표시하는 경우도 있습니다.
                        </p>
                        <p>
                            - ”게시물”이라 함은 &quot;회원”이 &quot;서비스&quot;를 이용함에 있어 &quot;서비스상&quot;에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
                        </p>
                        <br />
                        <p>
                        제 3 조 (약관의 게시와 개정)
                        </p>
                        <p>
                        주식회사 플리카(이하 “회사”)은 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소, 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 온라인 서비스초기화면에 게시합니다. 다만, 약관은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                        </p>
                        <br />
                        <p>
                        1. ”회사”는 이 약관의 내용을 &quot;회원&quot;이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.
“회사”는 &quot;약관의규제에관한법률&quot;, &quot;정보통신망이용촉진및정보보호등에관한법률(이하 &quot;정보통신망법&quot;)&quot; 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                        </p>
                        <p>
                        2. “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 공지 외에 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
                        </p>
                        <p>
                        3. “회사”가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                        </p>
                        <p>
                        4. “회원”이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
                        </p>
                        <br />
                        <p>
                        제 4 조 (약관의 해석)
                        </p>
                        <p>
                        “회사”는 “서비스”의 원활한 제공을 위하여 별도의 이용정책을 둘 수 있으며, 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 이용정책, 관계법령 또는 상관례에 따릅니다.
                        </p>
                        <br />
                        <p>
                        제 5 조 (이용계약 체결)
                        </p>
                        <p>
                        이용계약은 &quot;회원&quot;이 되고자 하는 자(이하 &quot;가입신청자&quot;)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 &quot;회사&quot;가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
                        </p>
                        <p>
                        “회사&quot;는 &quot;가입신청자&quot;의 신청에 대하여 &quot;서비스&quot; 이용을 승낙함을 원칙으로 합니다. 다만, &quot;회사&quot;는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다. 
                        </p>
                        <p>
                        1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 &quot;회사&quot;의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
                        </p>
                        <p>
                        2. 허위의 정보를 기재하거나, &quot;회사&quot;가 제시하는 내용을 기재하지 않은 경우
                        </p>
                        <p>
                        3. ”가입신청자”의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
                        </p>
                        <p>
                        4. 제1항에 따른 신청에 있어 &quot;회사&quot;는 &quot;회원&quot;의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
                        </p>
                        <p>
                        5. &quot;회사&quot;는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
                        </p>
                        <p>
                        6. 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, &quot;회사&quot;는 원칙적으로 이를 가입신청자에게 알리도록 합니다.
                        </p>
                        <p>
                        7. 이용계약의 성립 시기는 &quot;회사&quot;가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.
                        </p>
                        <p>
                        8. &quot;회사&quot;는 &quot;회원&quot;에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
                        </p>
                        <p>
                        9. &quot;회사&quot;는 &quot;회원&quot;에 대하여 &quot;영화 및 비디오물의진흥에관한법률&quot; 및 &quot;청소년보호법&quot;등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.
                        </p>
                        <br />
                        <p>
                        제 6 조 (회원정보의 변경)
                        </p>
                        <p>
                        1. ”회원&quot;은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 “아이디” 등은 수정이 불가능합니다.
                        </p>
                        <p>
                        2. “회원&quot;은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 &quot;회사&quot;에 대하여 그 변경사항을 알려야 합니다.
                        </p>
                        <p>
                        3. “회원&quot;은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 “아이디” 등은 수정이 불가능합니다.
                        </p>
                        <p>
                        4. 제2항의 변경사항을 &quot;회사&quot;에 알리지 않아 발생한 불이익에 대하여 &quot;회사&quot;는 책임지지 않습니다.
                        </p>
                        <br />
                        <p>
                        제 7 조 (개인정보보호 의무) 
                        </p>
                        <p>
                        1. &quot;회사&quot;는 &quot;정보통신망법&quot; 등 관계 법령이 정하는 바에 따라 &quot;회원&quot;의 개인정보를 보호하기 위해 노력합니다. 
                        </p>
                        <p>
                        2. 개인정보의 보호 및 사용에 대해서는 관련법 및 &quot;회사&quot;의 개인정보처리방침이 적용됩니다. 다만, &quot;회사&quot;의 공식 사이트 이외의 링크된 사이트에서는 &quot;회사&quot;의 개인정보처리방침이 적용되지 않습니다.
                        </p>
                        <br />
                        <p>
                        제 8 조 (&quot;회원&quot;의 &quot;아이디&quot; 및 &quot;비밀번호&quot;의 관리에 대한 의무)
                        </p>
                        <p>
                        1. “회원”의 “아이디”와 “비밀번호”에 관한 관리책임은 “회원”에게 있으며, 이를 제 3자가 이용하도록 하여서는 안 됩니다.
                        </p>
                        <p>
                        2. “회사”는 “회원”의 “아이디”가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 “회사” 및 “회사”의 운영자로 오인할 우려가 있는 경우, 해당 “아이디”의 이용을 제한할 수 있습니다.
                        </p>
                        <p>
                        3. “회원&quot;은 &quot;아이디&quot; 및 &quot;비밀번호&quot;가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 &quot;회사&quot;에 통지하고 &quot;회사&quot;의 안내에 따라야 합니다.
                        </p>
                        <p>
                        4. 제3항의 경우에 해당 &quot;회원&quot;이 &quot;회사&quot;에 그 사실을 통지하지 않거나, 통지한 경우에도 &quot;회사&quot;의 안내에 따르지 않아 발생한 불이익에 대하여 &quot;회사&quot;는 책임지지 않습니다. 
                        </p>
                        <br />
                        <p>
                        제 9 조 (&quot;회원&quot;에 대한 통지)
                        </p>
                        <p>
                        1. “회사&quot;가 &quot;회원&quot;에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 화면, 전자우편주소 등으로 할 수 있습니다.
                        </p>
                        <p>
                        2. “회사”는 “회원” 전체에 대해 통지의 경우 7일 이상 “회사”의 공지사항에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 
                        </p>
                        <br />
                        <p>
                        제 10 조 (&quot;회사&quot;의 의무)
                        </p>
                        <p>
                        1. ”회사&quot;는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 &quot;서비스&quot;를 제공하기 위하여 최선을 다하여 노력합니다.
                        </p>
                        <p>
                        2. &quot;회사&quot;는 &quot;회원&quot;이 안전하게 &quot;서비스&quot;를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
                        </p>
                        <p>
                        3. “회사&quot;는 서비스이용과 관련하여 발생하는 ”회원”의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.
                        </p>
                        <p>
                        4. ”회사&quot;는 서비스이용과 관련하여 &quot;회원&quot;으로부터 제기된 의견이나 만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. &quot;회원&quot;이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 &quot;회원&quot;에게 처리과정 및 결과를 전달합니다.
                        </p>
                        <br />
                        <p>
                        제 11 조 (&quot;회원&quot;의 의무)
                        </p>
                        <p>
                        1. &quot;회원&quot;은 다음 행위를 하여서는 안 됩니다.
                        </p>
                        <p>
                        - 신청 또는 변경 시 허위내용의 등록
                        </p>
                        <p>
                        - 타인의 정보도용
                        </p>
                        <p>
                        - “회사&quot;가 게시한 정보의 변경
                        </p>
                        <p>
                        - “회사&quot;가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
                        </p>
                        <p>
                        - “회사&quot;와 기타 제3자의 저작권 등 지적재산권에 대한 침해
                        </p>
                        <p>
                        - “회사&quot; 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                        </p>
                        <p>
                        - 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 &quot;서비스&quot;에 공개 또는 게시하는 행위
                        </p>
                        <p>
                        - 회사의 동의 없이 영리를 목적으로 &quot;서비스&quot;를 사용하는 행위
                        </p>
                        <p>
                        - 기타 불법적이거나 부당한 행위
                        </p>
                        <p>
                        2. “회원&quot;은 관계법, 이 약관의 규정, 이용정책, 이용안내 및 &quot;서비스&quot;와 관련하여 공지한 주의사항, &quot;회사&quot;가 통지하는 사항 등을 준수하여야 하며, 기타 &quot;회사&quot;의 업무에 방해되는 행위를 하여서는 안 됩니다.
                        </p>
                        <br />
                        <p>
                        제 12 조 (&quot;서비스&quot;의 제공 등)
                        </p>
                        <p>
                        1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                        </p>
                        <p>
                        - 가상공간 대여
                        </p>
                        <p>
                        - 가상공간 공유 플랫폼
                        </p>
                        <p>
                        - 기업용 커뮤니티
                        </p>
                        <p>
                        - 기타 &quot;회사&quot;가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 &quot;회원&quot;에게 제공하는 일체의 서비스
                        </p>
                        <p>
                        2. 회사는 &quot;서비스&quot;를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
                        </p>
                        <p>
                        3. “서비스&quot;는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
                        </p>
                        <p>
                        4. “회사&quot;는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 &quot;서비스&quot;의 제공을 일시적으로 중단할 수 있습니다. 이 경우 &quot;회사&quot;는 &quot;회원&quot;에게 통지합니다. 다만, &quot;회사&quot;가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                        </p>
                        <p>
                        5. ”회사&quot;는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
                        </p>                        
                        <br />
                        

<p>제 13 조 (&quot;서비스&quot;의 변경)</p>
<p>1. “회사&quot;는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 &quot;서비스&quot;를 변경할 수 있습니다.</p>
<p>2. “서비스&quot;의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.</p>
<p>3. “회사&quot;는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 &quot;회원&quot;에게 별도의 보상을 하지 않습니다.</p>
<br />

<p>제 14 조 (정보의 제공 및 광고의 게재)</p>
<p>1. &quot;회사&quot;는 &quot;회원&quot;이 &quot;서비스&quot; 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 &quot;회원&quot;에게 제공할 수 있습니다. 다만, &quot;회원&quot;은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.</p>
<p>2. 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 &quot;회원&quot;의 사전 동의를 받아서 전송합니다. 다만, &quot;회원&quot;의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.</p>
<p>3. “회사&quot;는 &quot;서비스&quot;의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 &quot;회원&quot;은 수신거절을 &quot;회사&quot;에게 할 수 있습니다.</p>
<p>4. ”이용자(회원, 비회원 포함)&quot;는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.</p>
<br />

<p>제 15 조 (&quot;게시물&quot;의 저작권)</p>
<p>1. ”회원&quot;이 &quot;서비스&quot; 내에 게시한 &quot;게시물&quot;의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</p>
<p>2. “회원&quot;은 본인이 작성한 게시물(작품)을 서비스내에 공개할 경우 게시물 이용에 동의하며, 구체적인 내용은 “회사” 저작권 이용정책에 따릅니다.</p>
<p>3. &quot;회원&quot;이 &quot;서비스&quot; 내에 게시하는 &quot;게시물&quot;은 검색결과 내지 &quot;서비스&quot; 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, &quot;회원&quot;은 언제든지 고객센터 또는 &quot;서비스&quot; 내 관리기능을 통해 해당 게시물에 대해 삭제, 비공개 등의 조치를 취할 수 있습니다.</p>
<br />

<p>제 16 조 (&quot;게시물&quot;의 관리)</p>
<p>1. “회원&quot;의 &quot;게시물&quot;이 &quot;정보통신망법&quot; 및 &quot;저작권법&quot;등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 &quot;게시물&quot;의 게시중단 및 삭제 등을 요청할 수 있으며, &quot;회사&quot;는 관련법에 따라 조치를 취하여야 합니다.</p>
<p>2. ”회사&quot;는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 &quot;게시물&quot;에 대해 임시조치 등을 취할 수 있습니다.</p>
<br />

<p>제 17 조 (권리의 귀속)</p>
<p>1. “서비스&quot;에 대한 저작권 및 지적재산권은 &quot;회사&quot;에 귀속됩니다. 단, &quot;회원&quot;의 &quot;게시물&quot; 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.</p>
<p>2. “회사&quot;는 서비스와 관련하여 &quot;회원&quot;에게 &quot;회사&quot;가 정한 이용조건에 따라 계정, 콘텐츠, 등을 이용할 수 있는 이용권만을 부여하며, &quot;회원&quot;은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.</p>
<br />

<p>제 18 조 (계약해제, 해지 등)</p>
<p>1. ”회원&quot;은 언제든지 서비스 초기화면의 내 정보 관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, &quot;회사&quot;는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.</p>
<p>2. “회원&quot;이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 &quot;회사&quot;가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 &quot;회원&quot;의 모든 데이터는 소멸됩니다.</p>
<p>3. “회원&quot;이 계약을 해지하는 경우, &quot;회원&quot;이 작성한 &quot;게시물&quot; 중 프로젝트 등과 같이 본인 계정에 등록된 게시물 일체는 삭제됩니다. 다만, 공용게시판에 등록된 “게시물”, 공유 등이 되어 재게시된 “게시물”은 삭제되지 않으니, 직접 삭제 또는 “회사”에 삭제 요청을 통하여 사전에 삭제 후 탈퇴하시기 바랍니다.</p>
<p>4. 전항과 관련하여 “회원”이 저작자가 아니거나 타인이 공동으로 권리를 보유한 “게시물”의 경우 삭제되지 않을 수 있습니다.</p>
<br />

<p>제 19 조 (이용제한 등)</p>
<p>1. “회사&quot;는 &quot;회원&quot;이 이 약관의 의무를 위반하거나 &quot;서비스&quot;의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 &quot;서비스&quot; 이용을 단계적으로 제한할 수 있습니다.</p>
<p>2. “회사&quot;는 전항에도 불구하고, 결제도용, &quot;저작권법&quot; 및 &quot;컴퓨터프로그램보호법&quot;을 위반한 불법프로그램의 제공 및 운영방해, &quot;정보통신망법&quot;을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 &quot;서비스&quot; 이용을 통해 획득한 기타 혜택 등도 모두 소멸되며, &quot;회사&quot;는 이에 대해 별도로 보상하지 않습니다.</p>
<p>3. ”회사&quot;는 &quot;회원&quot;이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.</p>
<p>4. “회사&quot;는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용정책에서 정하는 바에 의합니다.</p>
<p>5. 본 조에 따라 &quot;서비스&quot; 이용을 제한하거나 계약을 해지하는 경우에는 &quot;회사&quot;는 제9조[&quot;회원&quot;에 대한 통지]에 따라 통지합니다.</p>
<p>6. “회원&quot;은 본 조에 따른 이용제한 등에 대해 &quot;회사&quot;가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 &quot;회사&quot;가 인정하는 경우 &quot;회사&quot;는 즉시 &quot;서비스&quot;의 이용을 재개합니다.</p>
<br />

<p>제 20 조 (책임제한)</p>
<p>1. “회사&quot;는 천재지변 또는 이에 준하는 불가항력으로 인하여 &quot;서비스&quot;를 제공할 수 없는 경우에는 &quot;서비스&quot; 제공에 관한 책임이 면제됩니다.</p>
<p>2. “회사&quot;는 &quot;회원&quot;의 귀책사유로 인한 &quot;서비스&quot; 이용의 장애에 대하여는 책임을 지지 않습니다.</p>
<p>3. “회사&quot;는 &quot;회원&quot;이 &quot;서비스&quot;와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</p>
<p>4. “회사&quot;는 &quot;회원&quot; 간 또는 &quot;회원&quot;과 제3자 상호간에 &quot;서비스&quot;를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.</p>
<p>5. “회사&quot;는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</p>
<br />


<p>제 21 조 (준거법 및 재판관할)</p>
<p>1. &quot;회사&quot;와 &quot;회원&quot; 간 제기된 소송은 대한민국법을 준거법으로 합니다.</p>
<p>2. &quot;회사&quot;와 &quot;회원&quot;간 발생한 분쟁에 관한 소송은 제소 당시의 &quot;회원&quot;의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 &quot;회원&quot;의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.</p>
<br />

<p>제 22 조 (이용계약의 성립 등)</p>
<p>1. “이용자&quot;는 “회사”가 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. 2. “회사”는 계약 체결 전에 각 호의 사항에 관하여 &quot;이용자&quot;가 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.</p>
<p>- “콘텐츠&quot; 또는 “서비스” 목록의 열람 및 선택</p>
<p>- 전자우편주소 등의 입력</p>
<p>- 약관내용, 청약철회가 불가능한 &quot;콘텐츠&quot;나 “서비스”에 대해 “회사”가 취한 조치에 관련한 내용에 대한 확인</p>
<p>- 이 약관에 동의하고 위 제3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)</p>
<p>- “콘텐츠&quot; 또는 “서비스”의 이용신청에 관한 확인 또는 “회사”의 확인에 대한 동의 결제방법의 선택</p>
<p>3. “회사”는 &quot;이용자&quot;의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다. </p>
<p>- 실명이 아니거나 타인의 명의를 이용한 경우 </p>
<p>- 허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지 않은 경우</p>
<br />


<p>제 23 조 (미성년자 이용계약에 관한 특칙)</p>
<p>- “회사”는 만 20세 미만의 미성년이용자가 유료서비스를 이용하고자 하는 경우에 부모 등 법정 대리인의 동의를 얻거나, 계약체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 계약체결 전에 고지하는 조치를 취합니다.</p>
<br />

<p>제 24 조 (플리카의 의무)</p>
<p>“회사”는 법령과 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실하게 하여야 합니다.</p>
<p>“회사”는 &quot;이용자&quot;가 “콘텐츠” 및 “서비스” 이용내역을 수시로 확인할 수 있도록 조치합니다.</p>
<p>“회사”는 “콘텐츠” 및 “서비스” 이용과 관련하여 &quot;이용자&quot;로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체없이 처리합니다. 이용자가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 그 처리과정 및 결과를 전달합니다.</p>
<p>“회사”는 이 약관에서 정한 의무 위반으로 인하여 &quot;이용자&quot;가 입은 손해를 배상합니다.</p>
<br />

<p>제 25 조 (&quot;이용자&quot;의 의무)</p>
<p>&quot;이용자&quot;는 다음 행위를 하여서는 안 됩니다.</p>
<p>1. 신청 또는 변경 시 허위내용의 기재</p>
<p>2. 타인의 정보도용</p>
<p>3. “회사”에 게시된 정보의 변경</p>
<p>4. “회사”가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</p>
<p>5. “회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
<p>6. “회사”및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행</p>위
<p>7. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 “회사”의 사이트에 공개 또는 게시하는 행위</p>
<p>8. 기타 불법적이거나 부당한 행위</p>
<p>9. ”이용자&quot;는 관계법령, 이 약관의 규정, 이용안내 및 &quot;콘텐츠&quot;와 “서비스”와 관련하여 공지한 주의사항, “회사”가 통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다.</p>
<br />


<p>제 26 조 (지급방법)</p>
<p>“콘텐츠&quot; 및 “서비스”의 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가능한 방법으로 할 수 있습니다. 다만, “회사”는 &quot;이용자&quot;의 지급방법에 대하여 어떠한 명목의 수수료도 추가하여 징수하지 않습니다.</p>
<p>- 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</p>
<p>- 선불카드, 직불카드, 신용카드 등의 각종 카드결제</p>
<p>- 전화 또는 휴대전화를 이용한 결제</p>
<p>- 기타 전자적 지급방법에 의한 대금지급 등</p>
<br />


<p>제 27 조 (정보의 제공 및 광고의 게재)</p>
<p>1. “회사”는 &quot;이용자&quot;가 콘텐츠 또는 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 &quot;회원&quot;에게 제공할 수 있습니다. 다만, &quot;회원&quot;은 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.</p>
<p>2. 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 &quot;회원&quot;의 사전 동의를 받아서 전송합니다.</p>
<p>3. “회사”는 &quot;콘텐츠&quot;서비스 제공과 관련하여 콘텐츠 또는 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 &quot;회원&quot;은 수신거절을 “회사”에게 할 수 있습니다.</p>
<br />

<p>제 28 조 (게시물의 삭제)</p>
<p>1. “회사”는 게시판에 정보통신망이용촉진 및 정보보호 등에 관한 법률을 위반한 청소년유해매체물이 게시되어 있는 경우에는 이를 지체 없이 삭제 합니다. 다만, 19세 이상의 &quot;이용자&quot;만 이용할 수 있는 게시판은 예외로 합니다.</p>
<p>2. “회사”가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 자는 “회사”에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 “회사”는 지체 없이 필요한 조치를 취하고 이를 즉시 신청인에게 통지합니다.</p>
<br />


<p>제 29 조 (저작권 등의 귀속)</p>
<p>1. “회사”가 작성한 저작물, 특히 “회사”가 제공하는 “콘텐츠”와 “서비스” 에 대한 저작권 기타 지적재산권은 &quot;“회사”에 귀속합니다.</p>
<p>2. “회사”가 제공하는 서비스 중 제휴계약에 의해 제공되는 저작물에 대한 저작권 기타 지적재산권은 해당 제공업체에 귀속합니다.</p>
<p>3. ”이용자&quot;는 “회사”가 제공하는 서비스를 이용함으로써 얻은 정보나 저작물 중“회사” 또는 제공업체에 지적재산권이 귀속된 정보나 저작물을 “회사” 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다. “이용자”는 “회사”가 제공하는 유료 및 무료 콘텐츠를 “이용자”와 “회사”와의 이용계약의 목적에 부합하는 범위 내에서만 이용 가능합니다.</p>
<p>4. “회사”는 약정에 따라 &quot;이용자&quot;의 저작물을 사용하는 경우 당해 &quot;이용자&quot;의 허락을 받습니다.</p>
<br />

<p>제 30 조 (개인정보보호)</p>
<p>“회사”는 &quot;정보통신망법&quot; 등 관계 법령이 정하는 바에 따라 &quot;이용자&quot;의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및“회사”의 개인정보취급방침이 적용됩니다.</p>
<br />

<p>제 31 조 (콘텐츠하자 등에 의한 이용자피해보상)</p>
<p>“회사”는 콘텐츠하자 등에 의한 이용자피해보상의 기준·범위·방법 및 절차에 관한 사항을 디지털콘텐츠이용자보호지침에 따라 처리합니다.</p>
<br />

<p>제 32 조 (면책조항)</p>
<p>1. “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 &quot;콘텐츠&quot;와 “서비스”를 제공할 수 없는 경우에는 &quot;콘텐츠&quot;와 “서비스” 제공에 관한 책임이 면제됩니다.</p>
<p>2. “회사”는 &quot;이용자&quot;의 귀책사유로 인한 &quot;콘텐츠&quot;와 “서비스” 이용의 장애에 대하여는 책임을 지지 않습니다.</p>
<p>3. “회사”는 &quot;회원&quot;이 &quot;콘텐츠&quot;와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</p>
<p>4. “회사”는 &quot;이용자&quot; 상호간 또는 &quot;이용자&quot;와 제3자 간에 &quot;콘텐츠&quot;와 “서비스”를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다.</p>
<br />


<p>제 33 조 (분쟁의 해결)</p>
<p>1. “회사”는 분쟁이 발생하였을 경우에 &quot;이용자&quot;가 제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가 곤란한 경우에 “회사”는 &quot;이용자&quot;에게 그 사유와 처리일정을 통보합니다.</p>
<br />

<p>제 34 조 (준거법 및 관할법원)</p>
<p>1. “회사”와 “이용자” 간 제기된 소송은 대한민국법을 준거법으로 합니다.</p>
<p>2. “회사”와 “이용자”간 발생한 분쟁에 관한 소송은 제소 당시의 “이용자”의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 “이용자”의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.</p>
<p>3. 해외에 주소나 거소가 있는 “이용자”의 경우 “회사”와 “이용자”간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.</p>
<br />

<p>제 35 조 (기타조항)</p>
<p>1. 회원은 상대방의 사전 서면 동의 없이 이 약관상의 권리와 의무를 제3자에게 양도 할 수 없습니다.</p>
<p>2. 이 약관과 관련하여 당사자 간의 합의에 의하여 추가로 작성된 계약서, 협정서, 통보서 등과 회사의 정책변경, 법령의 제정·개정 또는 공공기관의 고시·지침 등에 의하여 회사가 서비스를 통해 회원에게 공지하는 내용도 본 약관의 일부를 구성합니다.</p>
<br />

<p>부칙</p>
<p>1. 이 약관은 2022년 08월 01일부터 적용됩니다.</p>
<br />

<p>- 공고일자: 2022년 7월 29일</p>
<p>- 시행일자: 2022년 8월 1일</p>
<br />
<p>주식회사 플리카</p>

                        
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Terms