import * as Cookie from '../utils/CookieUtil';

export const LOGINSTATUS = "LOGIN/STATUS";
export const LOGOUTSTATUS = "LOGOUT/STATUS";

export const setLoginStatus = () => ({ type: LOGINSTATUS });
export const setLogoutstatus = () => ({ type: LOGOUTSTATUS });

const initialState = {
    status : false
}

export function isLogged (state = initialState , action) {
    
    
    switch (action.type) {
        case LOGINSTATUS:
            return {
                ...state,
                status: true
            }
        case LOGOUTSTATUS:
            window.localStorage.removeItem('plikaTk');
            Cookie.deleteCookie('ref_token');
            return {
                ...state,
                status: false
            }
        default:
            return state;
    }
}