import React from 'react';
import styled from 'styled-components';
import Text from 'components/common/atoms/Text';

interface MemberInfoProps {
    memberInfo?: any;
    status: 'on' | 'off';
}

export const MemberInfoComponent = styled.div<MemberInfoProps>`
    display: flex;
    flex-direction: column;
    color: ${(props: MemberInfoProps) =>
        props.status === 'on' ? '#fff' : '#ccc'};
    flex-grow: 1;
    margin-left: 10px;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MemberInfo: React.FC<MemberInfoProps> = function ({memberInfo, status}) {
    return (
        <MemberInfoComponent status={status}>
            <Text size={16} weight={700}>
                {memberInfo.detail.nickname}
            </Text>
            {/* <Text size={12} weight={400}>
                {memberInfo.detail.email}
            </Text> */}
        </MemberInfoComponent>
    );
};

export default MemberInfo;
