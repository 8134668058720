import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import uiStyle from '../../styles/ui.module.scss'
import styles from '../../styles/modal.module.scss'
import {useTranslation} from 'react-i18next';

function SpaceExitAlert(props) {

    const { webviewPopupChange } = props;

    document.body.style.overflow = 'hidden';

    const webviewInner:any = useRef();

    function closeWebview (e) {
        if(!webviewInner.current.contains(e.target)) {
            webviewPopupChange('exitPopup', false);
        }
    }

    const {t} = useTranslation()
    
    return (
        <>
            <div className={`${styles.modal_container}`} onPointerDown={(e) => closeWebview(e)}>
                <div ref={webviewInner} className={`${styles.container_inner} ${styles.alarm}`}>
                    <div className={styles.modal_header}>
                        <p>{t('space.common.leave_land.title1')}</p>
                        <button onClick={() => webviewPopupChange('exitPopup', false)}><FontAwesomeIcon icon={faX} /></button>
                    </div>
                    <div className={styles.modal_desc}>
                    {t('space.common.leave_land.text1')}
                    </div>
                    <div className={styles.modal_btn_wrapper}>
                        <button onClick={() => webviewPopupChange('exitPopup', false)} className={uiStyle.btn_theme_gray}>Cancel</button>
                        <button className={uiStyle.btn_theme_yel}><a href="/">OK</a></button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default SpaceExitAlert