import TierCardBenefits from './tier-card-benefits'
import TierCardButton from './tier-card-button'
import TierCardPrice from './tier-card-price'
import TierCardSummary from './tier-card-summary'
import ChannelService from '../utils/channeltalk';

interface Props {
  type: 'starter' | 'team' | 'enterprise',
    accountInfo : object
}

export default function TierCard({ type, accountInfo }: Props) {
  return (
    <article className="flex flex-col w-full max-w-[22.5rem] h-[37.5rem] p-8 mx-auto text-center text-gray-900 bg-white border border-gray-100 shadow-md rounded-2xl ">
      <h3 className="mb-4 text-xl font-bold capitalize">{type}</h3>
      <TierCardSummary type={type} />
      <TierCardPrice type={type} />
      <TierCardBenefits type={type} />
      <TierCardButton type={type} accountInfo={accountInfo}/>
    </article>
  )
}
