import React from 'react'

export default function Solutions() {
  return (
    <>
      <section className="relative flex flex-col justify-between w-full px-4 py-20 pb-20 mx-auto lg:flex-row max-w-container sm:px-6 lg:px-8">
        <div className="w-full lg:w-7/12 overflow-hidden rounded-lg shadow-lg aspect-video lg:aspect-[4/3]">
          <video className="object-cover w-full h-full" autoPlay loop muted>
            <source
              src={process.env.REACT_APP_CDN_URL + '/static/video/web/communication_video.mp4'}
            />
          </video>
        </div>
        <div className="flex flex-col justify-center w-full gap-4 px-1 py-4 lg:px-10 lg:w-5/12 lg:gap-7 max-w-max">
          <div className="flex flex-col text-lg font-bold leading-normal sm:text-2xl xl:text-4xl text-slate-900 max-w-max">
            <p>3차원 팝업스토어를 통해</p>
            <p className="bg-underline">브랜드 경험을 제공하세요</p>
          </div>
          <p className="text-sm leading-7 sm:text-base text-slate-700 max-w-max">
            제품의 3차원 디스플레이, 실시간 고객상담, 여러 채널에 분산된 정보들을 웹 기반 가상공간
            형식의 팝업스토어에서 제공할 수 있습니다.
          </p>
        </div>
      </section>
      <section className="relative flex flex-col justify-between w-full px-4 pb-20 mx-auto lg:flex-row-reverse max-w-container sm:px-6 lg:px-8">
        <div className="w-full lg:w-7/12 overflow-hidden rounded-lg shadow-lg aspect-video lg:aspect-[4/3]">
          <video className="object-cover w-full h-full" autoPlay loop muted>
            <source
              src={process.env.REACT_APP_CDN_URL + '/static/video/web/communication_video.mp4'}
            />
          </video>
        </div>
        <div className="flex flex-col justify-center w-full gap-4 px-1 py-4 lg:px-10 lg:w-5/12 lg:gap-7">
          <div className="flex flex-col text-lg font-bold leading-normal sm:text-2xl xl:text-4xl text-slate-900 max-w-max">
            <p>3차원 갤러리를 통해</p>
            <p className="bg-underline">입체적인 전시경험을 제공하세요</p>
          </div>
          <p className="text-sm leading-7 sm:text-base text-slate-700">
            미디어아트, 회화, 사진, 3D아트 등의 콘텐츠들을 웹 기반 가상공간 형식의 갤러리에서
            제공하고 실시간으로 소통하는 이벤트를 운영할 수 있습니다.
          </p>
        </div>
      </section>
    </>
  )
}
