import React from 'react';
import styled from 'styled-components';
import ButtonItem from '../molecules/ButtonItem';

interface ModalProps {
    ref: any;
    onClickEvent: any;
    memberInfo: any;
}

interface ModalComponentProps {
    ref: any;
}

export const ModalComponent = styled.div<ModalComponentProps>`
    position: absolute;
    top: 100%;
    right: 150px;
    font-size: 16px;
`;

const ButtonList = styled.div`
    width: 150px;
    position: absolute;
    top: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    z-index: 1;

    padding-top: 20px;
    padding-bottom: 20px;
`;

const BtnItem = styled.div`
    padding-left: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    text-align: left;
    box-sizing: border-box;
    color: #cccccc;
`;

const Modal: React.FC<ModalProps> = React.forwardRef(function (
    {onClickEvent, memberInfo},
    ref,
) {
    return (
        <ModalComponent ref={ref}>
            <ButtonList>
                {
                    /**
                     * 멤버인 회원
                     */
                    memberInfo !== undefined && memberInfo.detail.member && (
                        <>
                            <ButtonItem label="Register Member" disabled />
                            <ButtonItem
                                label="Remove Member"
                                onClickEvent={onClickEvent}
                            />
                        </>
                    )
                }

                {
                    /**
                     * 멤버 아닌 회원
                     */
                    memberInfo !== undefined &&
                        !memberInfo.detail.member &&
                        memberInfo.detail.login && (
                            <>
                                <ButtonItem
                                    label="Register Member"
                                    onClickEvent={onClickEvent}
                                />
                                <ButtonItem label="Remove Member" disabled />
                            </>
                        )
                }

                {
                    /**
                     * 멤버 아닌 비회원
                     */
                    memberInfo !== undefined &&
                        !memberInfo.detail.member &&
                        !memberInfo.detail.login && (
                            <>
                                <ButtonItem label="Register Member" disabled />
                                <ButtonItem label="Remove Member" disabled />
                            </>
                        )
                }
            </ButtonList>
        </ModalComponent>
    );
});
Modal.displayName = 'Modal';
export default Modal;
