import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import App from './App'
import { createStore, applyMiddleware, compose } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers/Reducers'
import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import './index.css'
import { createBrowserRouter } from 'react-router-dom'
import Root from 'routes/Root'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// const enhancer =
//   process.env.NODE_ENV === "production"
//     ? compose(applyMiddleware(),composeWithDevTools())
//     : compose(applyMiddleware(),composeWithDevTools());

const store = createStore(rootReducer, composeWithDevTools())

const queryClient = new QueryClient()

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
    <ReactQueryDevtools initialIsOpen={true} />
  </QueryClientProvider>,
)
